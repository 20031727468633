<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <p class="tb-head">
        All Products {{ totalPage ? "(" + totalPage + ")" : "" }}
      </p>
      <div class="search-box rps">
        <div class="search-in">
          <div class="in-search-sec">
            <input
              type="text"
              id="searchBar"
              placeholder="Search"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="productName"
              autofocus
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div class="search-link all-options">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ isvFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="isvFilText !== 'ISV'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('sellerId', '', 'ISV')"
                    >
                      {{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of isvArr"
                      (click)="checkVal('sellerId', i._id, i.companyName)"
                    >
                      {{ i.companyName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ domainFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="domainFilText !== 'Domain'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('domain', '', 'Domain')"
                    >
                      {{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of domainArr"
                      (click)="checkVal('domain', i._id, i.value)"
                    >
                      {{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ statusFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="
                      productStatusArr.length > 15 ? 'overflow-class' : ''
                    "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="statusFilText !== 'Status'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('status', '', 'Status')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of productStatusArr"
                      (click)="checkVal('status', i._id, i.value)"
                      >{{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="table-order upper">
        <table>
          <tbody>
            <tr>
              <th>ISV</th>
              <th>Product</th>
              <th>Created date</th>
              <th>Domain</th>
              <th>Sub-domain</th>
              <th>Status</th>
            </tr>
            <tr
              *ngFor="
                let product of resArr
                  | paginate
                    : {
                        itemsPerPage: filters.count,
                        currentPage: p,
                        totalItems: totalPage
                      }
              "
            >
              <td class="td-img-box">
                <div
                  class="td-img"
                  *ngIf="
                    product?.sellerId?.companyImage &&
                    product?.sellerId?.hasOwnProperty('companyImage') &&
                    product?.sellerId?.userImage !== ''
                  "
                >
                  <img
                    src="{{ imageUrl }}{{ product?.sellerId?.companyImage }}"
                    alt="img"
                  />
                </div>

                <div
                  class="td-img"
                  *ngIf="
                    !product?.sellerId?.companyImage ||
                    !product?.sellerId.hasOwnProperty('companyImage') ||
                    product?.sellerId?.companyImage === ''
                  "
                >
                  <img src="../assets/images/user.png" alt="img" />
                </div>

                <span>
                  {{ product?.sellerId?.companyName }}
                </span>
              </td>
              <td (click)="goToRequest(product?._id)" class="pointer">
                {{ product?.productName }}
              </td>
              <td>
                {{
                  product?.createdAt
                    ? sharedService.formatDays(product?.createdAt)
                    : ""
                }}
              </td>
              <td>{{ product?.domain?.value }}</td>
              <td>{{ product?.subDomain?.value }}</td>
              <td class="blue upper-a">{{ product?.status?.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-align pagi-page">
        <div class="paginationClass">
          <pagination-controls
            (pageChange)="pageChange($event)"
            class="float-right"
            previousLabel="Prev"
            nextLabel="Next"
          ></pagination-controls>
        </div>

        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ countPerPageText || "10 Per Page"
              }}<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                (click)="checkVal('10PERPAGE', '10', '10 Per Page')"
                class="dropdown-item"
                type="button"
              >
                10 Per Page
              </button>
              <button
                class="dropdown-item"
                value="25"
                type="button"
                (click)="checkVal('25PERPAGE', '25', '25 Per Page')"
              >
                25 Per Page
              </button>
              <button
                class="dropdown-item"
                name="countPerPage"
                value="50"
                type="button"
                (click)="checkVal('50PERPAGE', '50', '50 Per Page')"
              >
                50 Per Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
