<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <p class="tb-head">
        All HLCs {{ totalPage ? "(" + totalPage + ")" : "" }}
      </p>
      <div class="search-box rps">
        <div class="search-in">
          <div class="in-search-sec search-bar">
            <input
              type="text"
              id="searchBar"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="searchTerm"
              autofocus
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div class="search-link">
          <p-multiSelect
            [options]="rpStatusArr"
            [(ngModel)]="rpStatusValue"
            defaultLabel="Status"
            optionLabel="value"
            [maxSelectedLabels]="1"
            (onChange)="checkVal('status', $event)"
          ></p-multiSelect>
          <p-multiSelect
            [options]="geographyArr"
            [(ngModel)]="geographyValue"
            defaultLabel="Geo"
            optionLabel="value"
            [maxSelectedLabels]="1"
            (onChange)="checkVal('geo', $event)"
          ></p-multiSelect>
          <p-multiSelect
            [options]="industryArr"
            [(ngModel)]="industryValue"
            defaultLabel="Industry"
            optionLabel="value"
            [maxSelectedLabels]="1"
            (onChange)="checkVal('industry', $event)"
          ></p-multiSelect>
          <p-multiSelect
            [options]="domainArr"
            [(ngModel)]="domainValue"
            defaultLabel="Domain"
            optionLabel="value"
            [maxSelectedLabels]="1"
            (onChange)="checkVal('domain', $event)"
          ></p-multiSelect>

          <br />
          <div class="col-md-12 mb-4 text-white text-center d-inline">
            <span
              *ngFor="let item of rpStatusValue"
              class="badge customcolor msg"
              >STATUS : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('status', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span
              *ngFor="let item of geographyValue"
              class="badge customcolor msg"
              >GEOGRAPHY : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('geo', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span
              *ngFor="let item of industryValue"
              class="badge customcolor msg"
            >
              INDUSTRY : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('industry', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span *ngFor="let item of domainValue" class="badge customcolor msg"
              >DOMAIN : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('domain', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
          </div>

          <!-- <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ statusFilText || "Status"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="statusFilText !== 'Status'"
                      (click)="checkVal('status', '', 'Status')"
                    >
                      {{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of rpStatusArr"
                      (click)="checkVal('status', i._id, i.value)"
                    >
                      {{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ geoFilText || "Geo"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      *ngIf="geoFilText !== 'Geo'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('geo', '', 'Geo')"
                    >
                      {{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of geographyArr"
                      (click)="checkVal('geo', i._id, i.value)"
                    >
                      {{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ industryFilText || "Industry"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': industryArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="industryFilText !== 'Industry'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('industry', '', 'Industry')"
                    >
                      {{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of industryArr"
                      (click)="checkVal('industry', i._id, i.value)"
                    >
                      {{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ domainFilText || "Domain"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': domainArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="domainFilText !== 'Domain'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('domain', '', 'Domain')"
                    >
                      {{ "All" }}</a
                    >

                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of domainArr"
                      (click)="checkVal('domain', i._id, i.value)"
                    >
                      {{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="table-order upper">
        <table>
          <tbody>
            <tr>
              <th>High Level Connector</th>
              <th>Connecttions</th>
              <th>Proposals sent</th>
              <th>Accepted by isv</th>
              <th>Intros made</th>
              <th>deals closed</th>
              <th>Commissions</th>
              <th>Agreement start date</th>
              <th>Agreement end date</th>
              <th>Status</th>
            </tr>
            <tr
              *ngFor="
                let rp of resArr
                  | paginate
                    : {
                        itemsPerPage: filters.count,
                        currentPage: p,
                        totalItems: totalPage
                      }
              "
            >
              <td class="td-img-box">
                <div
                  class="td-img"
                  *ngIf="
                    rp?.user?.userImage &&
                    rp?.user.hasOwnProperty('userImage') &&
                    rp?.user?.userImage !== ''
                  "
                >
                  <img
                    class="img-thumbnail"
                    src="{{ imageUrl }}{{ rp?.user?.userImage }}"
                    alt="img"
                    height="30"
                    width="25"
                  />
                </div>

                <div
                  class="td-img"
                  *ngIf="
                    !rp?.user?.userImage ||
                    !rp?.user.hasOwnProperty('userImage') ||
                    rp?.user?.userImage === ''
                  "
                >
                  <img
                    class="img-thumbnail"
                    src="../assets/images/user.png"
                    alt="img"
                  />
                </div>

                <span (click)="goToRequest(rp?.user?._id)" class="pointer">
                  {{ rp?.user?.firstName + " " + rp?.user?.lastName }}
                </span>
              </td>
              <td>{{ rp?.rpProfileMap.length }}</td>
              <td>{{ rp?.proposal }}</td>
              <td>{{ rp?.acceptedProposal }}</td>
              <td>{{ rp?.introsMade }}</td>
              <td>{{ rp?.closedDeal }}</td>
              <td>{{ rp?.commissions }}</td>
              <td>
                {{
                  rp?.wsmAgreement?.startDate
                    ? sharedService.formatDays(rp?.wsmAgreement?.startDate)
                    : ""
                }}
              </td>
              <td>
                {{
                  rp?.wsmAgreement?.endDate
                    ? sharedService.formatDays(rp?.wsmAgreement?.endDate)
                    : ""
                }}
              </td>
              <td class="blue upper-a">{{ rp?.status.value || "" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Type a message -->

      <!-- class="active" -->
      <!-- <div class="d-align pagi-page">
        <div
          id="pagination"
          class="pagination"
          *ngFor="let page of totalPageArr; let i = index"
        >
          <a
            *ngIf="totalPageArr[page] === 0"
            (click)="nextVal('PREV')"
            [ngClass]="
              totalPage % filters.count < filters.skip ? '' : 'disable-link'
            "
            href="javascript:void(0)"
            >Prev</a
          >
          <a
            id="number"
            *ngIf="i < 5"
            href="javascript:void(0)"
            [ngClass]="i === filters.skip / filters.count ? 'active' : ''"
            name="number"
            (click)="skipPageFn(i)"
            >{{ page + 1 | number: "1.0-0" }}</a
          >
          <a
            (click)="nextVal('NEXT')"
            href="javascript:void(0)"
            *ngIf="totalPageArr[page] === totalPageArr.length - 1"
            [ngClass]="
              totalPage - filters.skip <= filters.count ? 'disable-link' : ''
            "
            >Next<i class="fa ml-1 fa-angle-right"></i
          ></a>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              6 per page<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                (click)="checkVal('6PERPAGE', '6')"
                class="dropdown-item"
                type="button"
              >
                6 Per Page
              </button>
              <button
                class="dropdown-item"
                value="25"
                type="button"
                (click)="checkVal('25PERPAGE', '25')"
              >
                25 Per Page
              </button>
              <button
                class="dropdown-item"
                name="countPerPage"
                value="50"
                type="button"
                (click)="checkVal('50PERPAGE', '50')"
              >
                50 Per Page
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <div class="d-align pagi-page">
        <!-- <div class="pagination"> -->
        <div class="paginationClass">
          <pagination-controls
            (pageChange)="pageChange($event)"
            class="float-right"
            previousLabel="Prev"
            nextLabel="Next"
          ></pagination-controls>

          <div class="user">
            <div class="btn-group">
              <button
                type="button"
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {{ countPerPageText || "10 Per Page"
                }}<i class="fa fa-angle-down ml-1"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                x-placement="bottom-end"
                style="
                  position: absolute;
                  will-change: transform;
                  top: 0px;
                  left: 0px;
                  transform: translate3d(-69px, 42px, 0px);
                "
              >
                <button
                  (click)="checkVal('10PERPAGE', '10', '10 Per Page')"
                  class="dropdown-item"
                  type="button"
                >
                  10 Per Page
                </button>
                <button
                  class="dropdown-item"
                  value="25"
                  type="button"
                  (click)="checkVal('25PERPAGE', '25', '25 Per Page')"
                >
                  25 Per Page
                </button>
                <button
                  class="dropdown-item"
                  name="countPerPage"
                  value="50"
                  type="button"
                  (click)="checkVal('50PERPAGE', '50', '50 Per Page')"
                >
                  50 Per Page
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </section>
</main>
