import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'app-sales-get-started',
  templateUrl: './sales-get-started.component.html',
  styleUrls: ['./sales-get-started.component.scss'],
})
export class SalesGetStartedComponent implements OnInit {
  public stakeHolder: any;
  public salesProcessForm: FormGroup;
  public stages: any;
  public productId: string;
  public errMsg: any;
  public tagsArray = [];
  public payload: {};
  public toEdit: string;
  public productDetails: any;
  public salesNumber = 1;

  constructor(
    private isv: IsvService,
    private fb: FormBuilder,
    private router: Router,
    public route: ActivatedRoute,
    public sharedService: CommonService,
    private stepService: StepsService,
    private dropdown: DropdownService
  ) {
    this.productId = localStorage.getItem('productId');
  }

  ngOnInit(): void {
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');

    this.getStakeHolders();
    this.salesProcessForm = this.fb.group({
      salesProcess: this.fb.array([this.SalesDefault()]),
      pilot: [''],
      freeTrial: [''],
      poc: [''],
      training: [''],
      implementationDuration: ['', Validators.required],
      implementationResource: ['', Validators.required],
    });

    if (localStorage.getItem('onBoard') == 'true' && !this.toEdit) {
      this.stepService.currentStep$.next(this.stepService.steps$.value[0]);
    }
  }

  createSalesControl(): FormArray {
    return this.salesProcessForm.get('salesProcess') as FormArray;
  }

  SalesDefault() {
    if (this.stakeHolder && this.stakeHolder.length) {
      this.salesNumber = this.stakeHolder.length;
    }
    return this.fb.group({
      stage: [this.salesNumber],
      stageName: [''],
      stakeHolder: null,
    });
  }

  addStages() {
    this.createSalesControl().push(this.SalesDefault());
  }

  removeStages(i: number) {
    this.createSalesControl().removeAt(i);
  }

  getStakeHolders() {
    this.isv.getStakeHolder().subscribe(
      (res) => {
        this.stakeHolder = res.details.stakeHolder;
        this.getStages();
      },
      (err) => {
        console.log(err);
        this.sharedService.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  getStages() {
    this.isv.getStages().subscribe(
      (stage) => {
        if (!this.toEdit) {
          this.stages = stage.details.stages;
          this.setSalesProcess();
        }
        if (this.toEdit) {
          this.getProductDetail();
        }
      },
      (err) => {
        console.log(err);
        this.sharedService.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  setSalesProcess() {
    if (!this.toEdit) {
      this.stages.forEach((stage) =>
        this.tagsArray.push(
          this.fb.group({
            stage: stage.stage,
            stageName: stage.stageName,
            stakeHolder: stage.stakeHolder,
          })
        )
      );
    } else {
      this.productDetails.salesProcess.forEach((element) => {
        this.tagsArray.push(
          this.fb.group({
            stage: element.stage,
            stageName: element.stageName,
            stakeHolder: element.stakeHolder,
          })
        );
      });
    }
    this.salesProcessForm.setControl(
      'salesProcess',
      this.fb.array(this.tagsArray || [])
    );
  }

  getProductDetail() {
    this.isv.getSellerProductRegData(this.productId, 3).subscribe(
      (data) => {
        this.productDetails = data?.details?.product;
        this.stages = data?.details?.product?.salesProcess;
        for (let stage of this.stages) {
          stage.stage = parseInt(stage.stage);
        }
        this.stages.sort((a, b) => a.stage - b.stage);
        this.setFormValues();
        this.setSalesProcess();
      },
      (err) => {
        console.log(err);
        this.sharedService.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  // -DRAG DROP-----------------------
  // stages = [
  //   'Value prop pitch',
  //   'Demo',
  //   'Free Trial / POC Go forward with exec sponsorship',
  //   'POC Execution',
  //   'Free Trial / POC outcome discussion',
  //   'Business case and Proposal creation',
  //   'Go-forward decision',
  //   'Legal discussion',
  //   'Final negotiation with procurement',
  //   'PO Received/ Sales cycle closed'
  // ];

  get formControls(): FormArray {
    return this.salesProcessForm.get('salesProcess') as FormArray;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.formControls.controls,
      event.previousIndex,
      event.currentIndex
    );
    this.stages = [];
    let number = 1;
    let stages;
    stages = this.formControls.controls;
    stages.forEach((element) => {
      this.stages.push(element.value);
    });
    this.stages.forEach((element) => {
      element.stage = number++;
    });
  }

  // -DRAG DROP-----------------------

  addSalesProcess() {
    if (this.salesProcessForm.invalid) {
      return;
    } else {
      this.isv
        .addSalesProcessDetails(this.salesProcessForm.value, this.productId)
        .subscribe(
          (data) => {
            if (data) {
              if (data.details.onBoard) {
                localStorage.setItem('onBoard', data.details.onBoard);
              }
              this.router.navigate(['admin/onboarding-progress']);
            }
          },
          (err) => {
            console.log(err);
            this.sharedService.showToastMessage(
              'ERROR',
              err.error.message,
              5000
            );
            setTimeout(() => {
              this.errMsg = err.error.error;
            }, 3000);
            this.errMsg = '';
          }
        );
    }
  }

  selected(val) {
    console.log(val);
  }

  get f() {
    return this.salesProcessForm.controls;
  }

  setFormValues() {
    this.salesProcessForm.patchValue({
      pilot: this.productDetails.pilot,
      freeTrial: this.productDetails.freeTrial,
      poc: this.productDetails.poc,
      training: this.productDetails.training,
      implementationDuration: this.productDetails.implementationDuration,
      implementationResource: this.productDetails.implementationResource,
    });
  }
}
