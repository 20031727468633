<aside class="aside-side">
  <div class="side-inner">
    <div class="side-one">
      <div class="box-content text-left">
        <h3>Go-to Market Strategy Analysis</h3>
        <h5>Consistent, Focused, Swift</h5>
      </div>
    </div>
    <div class="side-two">
      <div class="side-accor">
        <div id="accordion">
          <accordion [isAnimated]="true">
            <accordion-group [isOpen]="5 >= currentStep || 1.5 > lastStep">
              <div accordion-heading>
                <a class="card-link" href="javascript:void(0);">
                  <div class="link-one">
                    <h4>GSA Framework - Step 1</h4>
                    <p>Onboard your product</p>
                  </div>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </a>
              </div>
              <div id="collapseOne">
                <div class="side-list">
                  <ul>
                    <li>
                      <p><span>01.</span> Get Started</p>
                      <span
                        id="checkProgess"
                        [ngClass]="{
                          success:
                            lastStep == 1.1 ||
                            lastStep > 1.1 ||
                            currentStep >= 1
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                    <li>
                      <p><span>02.</span> Target Roles</p>
                      <span
                        id="targetRoles"
                        [ngClass]="{
                          success:
                            lastStep == 1.1 ||
                            lastStep > 1.2 ||
                            currentStep >= 2
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                    <li>
                      <p><span>03.</span> Target Industries</p>
                      <span
                        id="targetIndustries"
                        [ngClass]="{
                          success:
                            lastStep == 1.2 ||
                            lastStep > 1.3 ||
                            currentStep >= 3
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                    <li>
                      <p><span>04.</span> Target Domain & Sub-Domain</p>
                      <span
                        id="targetDomain"
                        [ngClass]="{
                          success:
                            lastStep == 1.3 ||
                            lastStep > 1.3 ||
                            currentStep >= 4
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                    <li>
                      <p><span>05.</span> Target Geographies</p>
                      <span
                        id="targetGeographies"
                        [ngClass]="{
                          success:
                            lastStep == 1.4 ||
                            lastStep > 1.4 ||
                            currentStep >= 5
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
            </accordion-group>
            <accordion-group
              [isDisabled]="5 >= currentStep || 1.5 > lastStep"
              [isOpen]="currentStep > 5 && 10 >= currentStep"
            >
              <div accordion-heading>
                <a class="card-link" href="javascript:void(0);">
                  <div class="link-one">
                    <h4>GSA Framework - Step 2</h4>
                    <p>Provide additional product details</p>
                  </div>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </a>
              </div>
              <div id="collapseTwo">
                <div class="side-list">
                  <ul>
                    <li>
                      <p><span>01.</span> Product Details</p>
                      <span
                        [ngClass]="{
                          success:
                            lastStep == 1.5 ||
                            lastStep > 2.1 ||
                            currentStep >= 6
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                    <li>
                      <p>
                        <span>02.</span> Customer References and Analyst
                        Coverage
                      </p>
                      <span
                        [ngClass]="{
                          success:
                            lastStep == 2.1 ||
                            lastStep > 2.2 ||
                            currentStep >= 7
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                    <li>
                      <p><span>03.</span> ROI and Payback period</p>
                      <span
                        [ngClass]="{
                          success:
                            lastStep == 2.2 ||
                            lastStep > 2.3 ||
                            currentStep >= 8
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                    <li>
                      <p><span>04.</span> Target Size of Companies</p>
                      <span
                        [ngClass]="{
                          success:
                            lastStep == 2.3 ||
                            lastStep > 2.3 ||
                            currentStep >= 9
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                    <li>
                      <p><span>05.</span> Previous US Experience</p>
                      <span
                        [ngClass]="{
                          success:
                            lastStep == 2.4 ||
                            lastStep > 2.4 ||
                            currentStep >= 10
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
            </accordion-group>
            <accordion-group
              [isDisabled]="10 >= currentStep || 2.5 > lastStep"
              [isOpen]="currentStep == 11"
            >
              <div accordion-heading>
                <a class="card-link" href="javascript:void(0);">
                  <div class="link-one">
                    <h4>GSA Framework - Step 3</h4>
                    <p>Provide sales process details</p>
                  </div>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </a>
              </div>
              <div id="collapseThree">
                <div class="side-list">
                  <ul>
                    <li>
                      <p><span>01.</span>Sales Process</p>
                      <span
                        [ngClass]="{
                          success: lastStep == 2.5 || currentStep == 11
                        }"
                        class="check mr-0"
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
  </div>
  <div class="aside-point"></div>
  <i class="fa fa-times" aria-hidden="true"></i>
</aside>
