import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'app-roi-payback',
  templateUrl: './roi-payback.component.html',
  styleUrls: ['./roi-payback.component.scss'],
})
export class RoiPaybackComponent implements OnInit {
  @Input() step: StepModel;
  roiForm: FormGroup;
  roidetails: boolean;
  productName: string;
  productId: any;
  productDetails: any;
  toEdit: any;

  constructor(
    private fb: FormBuilder,
    private isv: IsvService,
    private actroute: ActivatedRoute,
    private stepSer: StepsService,
    private dropdown: DropdownService,
    public sharedService: CommonService
  ) {
    this.productId = localStorage.getItem('productId');
    this.productName = localStorage.getItem('productName');
  }

  ngOnInit(): void {
    this.getProductDetails();
    this.toEdit = this.actroute.snapshot.queryParamMap.get('toEdit');
    this.roiForm = this.fb.group({
      roiProvided: [''],
      paybackPeriod: [''],
      proofPoints: [''],
      isRoiMeasured: ['', Validators.required],
    });
  }

  addRoiDetails() {
    if (!this.roiForm.invalid) {
      this.isv.addRoiPayback(this.roiForm.value, this.productId).subscribe(
        (res) => {
          this.onCompleteStep();
          this.stepSer.onNextStep();
        },
        (err) => {
          console.log(err);
          this.sharedService.showToastMessage('ERROR', err.error.error, 5000);
        }
      );
    }
  }

  isRoiMeasured(val) {
    if (val == 'true') {
      this.roiForm.patchValue({ isRoiMeasured: 'true' });
      this.roiForm.get('roiProvided').setValidators([Validators.required]);
      this.roiForm.get('roiProvided').updateValueAndValidity();
      this.roiForm.get('paybackPeriod').setValidators([Validators.required]);
      this.roiForm.get('paybackPeriod').updateValueAndValidity();
    } else {
      // this.roiForm.setValue({
      //   roiProvided: '',
      //   paybackPeriod: '',
      //   proofPoints: '',
      //   isRoiMeasured: 'false'
      // })
      this.roiForm.patchValue({
        isRoiMeasured: 'false',
      });
      this.roiForm.get('roiProvided').setValidators([]);
      this.roiForm.get('roiProvided').updateValueAndValidity();
      this.roiForm.get('paybackPeriod').setValidators([]);
      this.roiForm.get('paybackPeriod').updateValueAndValidity();
    }
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

  get f() {
    return this.roiForm.controls;
  }
  getProductDetails() {
    const productId = localStorage.getItem('productId');
    this.isv.getProductStatus(productId, 2).subscribe(
      (data) => {
        // console.log(data.details.product);
        this.productDetails = data.details.product;
        this.setRoiDetails();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  setRoiDetails() {
    this.roidetails = this.productDetails.isRoiMeasured;
    if (!this.productDetails.isRoiMeasured) {
      this.roiForm.patchValue({
        isRoiMeasured: this.productDetails.isRoiMeasured,
      });

      // console.log(this.roiForm.value);
    } else {
      this.roiForm.patchValue({
        roiProvided: this.productDetails.roiProvided,
        paybackPeriod: this.productDetails.paybackPeriod,
        proofPoints: this.productDetails.proofPoints,
        isRoiMeasured: this.productDetails.isRoiMeasured,
      });
    }

    // this.roidetails = this.productDetails.isRoiMeasured
  }
}
