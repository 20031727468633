<div class="panel panel-primary setup-content" id="step-5">
  <section class="mail-verify lets padding-88">
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5>
            Which geographies do you want to penetrate with WholeSoft Market’s
            help?<span class="ml-0"
              ><button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or
                                            decision maker;  The BEST ENTRY POINT person could well
                                            become your eventual main Champion"
              >
                <img src="../assets/images/info.svg" /></button
            ></span>
          </h5>
        </div>
        <div class="form-field sign-form">
          <form [formGroup]="geoForm">
            <div class="form-sec domain normal mb-15">
              <div class="row">
                <div class="col-12">
                  <!-- <label for="Geographies" class="geolabel">Geographies</label> -->
                  <div class="form-group select-fa">
                    <!-- <mat-form-field appearance="outline">
                      <mat-label>Geographies</mat-label>
                      <mat-select formControlName="targetGeography" multiple>
                        <mat-option *ngFor="let item of geographies" value="{{ item._id }}">{{ item.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->
                    <label for="exampleFormControlSelect1">Geographies</label>
                    <select
                      required=""
                      formControlName="targetGeography"
                      class="form-control"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Select geographies
                      </option>
                      <option
                        (change)="setFormValues(item._id)"
                        [selected]="
                          productDetails?.targetGeography?._id === item?._id
                        "
                        *ngFor="let item of geographies"
                        value="{{ item._id }}"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="theme-btn dom width-fit text-right">
          <button
            class="btn"
            [class.active]="geoForm.valid"
            (click)="AddTargetGeographies()"
            type="submit"
          >
            FINISH
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
