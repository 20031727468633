<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <div class="connec-head">
        <div class="head-one">
          <p class="tb-head capitalized">My WSM network</p>
          <div class="rewards conn">
            <div class="box">
              <h3>
                <span><img src="../assets/images/Shape.svg" alt="" /></span
                >Connections
              </h3>
            </div>
            <div class="box">
              <h3 class="text-right">{{ totalItems }}</h3>
            </div>
          </div>
        </div>
        <div class="head-2">
          <button
            class="btn pure-blue"
            type="button"
            data-toggle="modal"
            data-target="#exampleModal1"
            (click)="addTargetReset()"
          >
            + Add New Connection
          </button>
        </div>
      </div>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input
              type="text"
              placeholder="Search"
              (keyup)="search()"
              id="search"
              autofocus
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div class="search-link">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ rpFilText || "High Level Connector"
                    }}<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu cust_drop"
                    [ngClass]="{ 'overflow-class': filterRpData.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      *ngIf="rpFilText !== 'High Level Connector'"
                      (click)="
                        rpFilter('NONE'); checkVal('RP', '', 'High Level Connector')
                      "
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      *ngFor="let data of filterRpData"
                      (click)="rpFilter(data._id); checkVal('RP', data, '')"
                      >{{ data?.firstName }} {{ data?.lastName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ accFilText || "Account"
                    }}<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu cust_drop"
                    [ngClass]="{
                      'overflow-class': filterAccountName.length > 11
                    }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      *ngIf="accFilText !== 'Account'"
                      (click)="
                        accountFilter('NONE');
                        checkVal('ACCOUNT', '', 'Account')
                      "
                      >All</a
                    >

                    <a
                      class="dropdown-item"
                      *ngFor="let data of filterAccountName"
                      (click)="
                        accountFilter(data._id);
                        checkVal('ACCOUNT', data._id, data.accountName)
                      "
                      >{{ data.accountName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ dateFiltext || "Date Added"
                    }}<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="dropdown-item"
                      *ngIf="dateFiltext !== 'Date Added'"
                      (click)="dayFilter(0); checkVal('DATE', '', 'Date Added')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="
                        dayFilter(7); checkVal('DATE', '', 'Last 7 Days')
                      "
                      >Last 7 Days</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="
                        dayFilter(30); checkVal('DATE', '', 'Last 30 Days')
                      "
                      >Last 30 Days</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="
                        dayFilter(90); checkVal('DATE', '', 'Last 90 Days')
                      "
                      >Last 90 Days</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ strengthFilText || "Strength of connection"
                    }}<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': filterStrength.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      *ngIf="strengthFilText !== 'Strength of connection'"
                      (click)="
                        strengthFilter('NONE');
                        checkVal('STRENGTH', '', 'Strength of connection')
                      "
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      *ngFor="let data of filterStrength"
                      (click)="
                        strengthFilter(data._id);
                        checkVal('STRENGTH', '', data.value)
                      "
                      >{{ data.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ natureRelationFilText || "Nature of Relationship"
                    }}<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': relationship.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      *ngIf="natureRelationFilText !== 'Nature of Relationship'"
                      (click)="
                        strengthFilter('NONE');
                        checkVal('NATURE', '', 'Nature of Relationship')
                      "
                      >All</a
                    >

                    <a
                      class="dropdown-item"
                      *ngFor="let data of relationship"
                      (click)="
                        strengthFilter(data._id);
                        checkVal('NATURE', '', data.value)
                      "
                      >{{ data.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="spinner-border custom_loaders my-5"
        role="status"
        *ngIf="loader"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <section class="connections" *ngIf="!loader">
        <div class="row">
          <div
            class="col-lg-4 col-sm-6"
            *ngFor="
              let d of connectionData
                | paginate
                  : {
                      itemsPerPage: count,
                      currentPage: p,
                      totalItems: totalItems
                    }
            "
          >
            <div class="user-box" (click)="viewProfile(d._id)">
              <div class="box">
                <div class="box-img">
                  <span *ngIf="d.photo == ''">
                    <!-- <img [src]="d.photo" alt="" /> -->
                    <img src="../assets/images/user.png" />
                  </span>
                  <img [src]="d.photo" alt="" />
                </div>
                <div class="box-con">
                  <h3>{{ d?.firstName }} {{ d?.lastName }}</h3>
                  <p>{{ d?.currentTitle }} at {{ d?.currentCompanyName }}</p>
                  <!-- <p>{{ d?.accountName }}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="d-align pagi-page mt-3" *ngIf="!loader">
        <div class="paginationClass">
          <pagination-controls
            (pageChange)="pageChange($event)"
            class="float-right"
            previousLabel="Prev"
            nextLabel="Next"
          ></pagination-controls>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ countPerPageText || "10 per page"
              }}<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                (click)="checkVal('10PERPAGE', 10, '10 Per Page')"
                class="dropdown-item"
                type="button"
              >
                10 Per Page
              </button>
              <button
                class="dropdown-item"
                value="25"
                type="button"
                (click)="checkVal('25PERPAGE', 25, '25 Per Page')"
              >
                25 Per Page
              </button>
              <button
                class="dropdown-item"
                name="countPerPage"
                value="50"
                type="button"
                (click)="checkVal('50PERPAGE', 50, '50 Per Page')"
              >
                50 Per Page
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- View Connection -->
      <section class="connection-side-top">
        <div class="container demo">
          <div
            class="modal left fade"
            id="exampleModal"
            tabindex=""
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header-new">
                  <div class="header-blue">
                    <h3>View Connection</h3>
                  </div>
                  <span data-dismiss="modal" class="cross">
                    <img src="../assets/images/cross-white.svg" alt=""
                  /></span>
                </div>
                <div class="modal-body">
                  <div class="connection-side">
                    <div class="profile-top conn-view">
                      <div class="profile">
                        <div class="profile-img-top">
                          <!-- <div class="profile-img">
                              <img [src]="connectionDetails.image" alt="" />
                            </div> -->
                          <div
                            class="profile-img-top"
                            *ngIf="connectionDetails.image == ''"
                          >
                            <!-- <img [src]="d.photo" alt="" /> -->
                            <img
                              src="../assets/images/user.png"
                              width="100"
                              height="100"
                            />
                          </div>
                          <div
                            class="profile-img-top"
                            *ngIf="connectionDetails.image !== ''"
                          >
                            <img [src]="connectionDetails.image" alt="" />
                          </div>

                          <div class="fa-penci isv-fa">
                            <i class="fa fa-pencil"></i>
                            <input
                              type="file"
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                              #fileInput
                              (change)="uploadFile($event)"
                            />
                          </div>
                        </div>
                        <div class="profile-con">
                          <h2>{{ connectionDetails?.name }}</h2>
                          <div class="con-in">
                            <h3>
                              {{ connectionDetails?.title }}
                              <!-- <span><i class="fa fa-pencil"></i></span> -->
                            </h3>
                          </div>
                          <div class="con-in">
                            <h3>
                              {{ connectionDetails?.companyName }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="conn-form">
                      <form>
                        <div class="form-sec normal mb-0">
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="form-group">
                                <label for="linkedinUrl">Linkedin URL</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="linkedinUrl"
                                  aria-describedby="emailHelp"
                                  [(ngModel)]="connectionDetails.linkedInUrl"
                                  name="linkedInUrl"
                                  placeholder="https://www.linkedin.com/"
                                />
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class="form-group">
                                <label for="jobTitle">Job Title</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="jobTitle"
                                  aria-describedby="emailHelp"
                                  [(ngModel)]="connectionDetails.jobTitle"
                                  name="jobTitle"
                                  placeholder=""
                                />
                                <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class="form-group">
                                <label for="strengthOfConnection"
                                  >Strength of connection</label
                                >
                                <div class="select-fa">
                                  <select
                                    class="form-control"
                                    id="strengthOfConnection"
                                    [(ngModel)]="connectionDetails.strengthId"
                                    name="strength"
                                  >
                                    <option
                                      *ngFor="let data of strength"
                                      value="{{ data._id }}"
                                    >
                                      {{ data.value }}
                                    </option>
                                  </select>
                                  <!-- <i
                                      class="fa fa-angle-down"
                                      aria-hidden="true"
                                    ></i> -->
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12">
                              <div class="form-group">
                                <label for="natureOfRelationship"
                                  >Nature of Relationship</label
                                >
                                <div class="select-fa">
                                  <select
                                    class="form-control"
                                    id="natureOfRelationship"
                                    [(ngModel)]="connectionDetails.natureId"
                                    name="nature"
                                  >
                                    <option
                                      *ngFor="let data of newRelationship"
                                      value="{{ data._id }}"
                                    >
                                      {{ data.value }}
                                    </option>
                                  </select>
                                  <!-- <i
                                      class="fa fa-angle-down"
                                      aria-hidden="true"
                                    ></i> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="conn-footer">
                      <div class="theme-btn footer-btn blue text-center">
                        <button class="btn active" (click)="saveChange()">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- container -->
      </section>
      <!-- Add connection -->
      <section class="connection-side-top">
        <form [formGroup]="connectionForm">
          <div class="container demo">
            <div
              class="modal left fade"
              id="exampleModal1"
              tabindex=""
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header-new mb-30">
                    <div class="header-blue">
                      <h3>Add New Connection</h3>
                    </div>
                    <span
                      data-dismiss="modal"
                      class="cross"
                      (click)="closeDialog(0)"
                    >
                      <img src="../assets/images/cross-white.svg" alt=""
                    /></span>
                  </div>
                  <div class="modal-body">
                    <div class="connection-side">
                      <div class="conn-form">
                        <form [formGroup]="connectionForm">
                          <div class="form-sec normal mb-0">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label for="reffPartner"
                                    >High Level Connector *</label
                                  >
                                  <div class="select-fa">
                                    <select
                                      class="form-control"
                                      id="reffPartner"
                                      formControlName="rpId"
                                    >
                                      <option
                                        *ngFor="let data of rpData"
                                        value="{{ data._id }}"
                                      >
                                        {{ data.firstName }} {{ data.lastName }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label for="linkedinurl2"
                                    >Linkedin URL
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="linkedinurl2"
                                    aria-describedby="emailHelp"
                                    [(ngModel)]="connectionDetails.linkedInUrl"
                                    name="linkedInUrl"
                                    [ngModelOptions]="{ standalone: true }"
                                    value="{{ connectionDetails.linkedInUrl }}"
                                    placeholder=""
                                    id="linkedInUrlId"
                                  />
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label for="accountName"
                                    >Account Name
                                    <sup class="text-danger"> * </sup>
                                  </label>
                                  <div class="select-fa">
                                    <select
                                      class="form-control"
                                      id="accountName"
                                      formControlName="account_name"
                                    >
                                      <option
                                        *ngFor="let data of accountName"
                                        value="{{ data._id }}"
                                      >
                                        {{ data.accountName }}
                                      </option>
                                      <!-- <option>Broadridge</option>
                                                                          <option>Farmers Trust</option>
                                                                          <option>Northern Trust Corporation</option>
                                                                          <option>Wells Fargo</option> -->
                                    </select>
                                    <!-- <i
                                        class="fa fa-angle-down"
                                        aria-hidden="true"
                                      ></i> -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label for="firstName">First Name *</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="firstName"
                                    formControlName="first_name"
                                    aria-describedby="emailHelp"
                                    value=""
                                    placeholder=""
                                    (keyup)="myFunction()"
                                  />
                                  <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label for="lastName">Last Name *</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="lastName"
                                    formControlName="last_name"
                                    aria-describedby="emailHelp"
                                    value=""
                                    placeholder=""
                                  />
                                  <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                                </div>
                              </div>

                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label for="jobTitle2"
                                    >Job Title
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="jobTitle2"
                                    formControlName="job_title"
                                    aria-describedby="emailHelp"
                                    value=""
                                    placeholder=""
                                  />
                                  <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                                </div>
                              </div>

                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label for="strengthofConnection"
                                    >Strength of connection</label
                                  >
                                  <div class="select-fa">
                                    <select
                                      class="form-control"
                                      id="strengthofConnection"
                                      formControlName="strength"
                                    >
                                      <option
                                        *ngFor="let data of strength"
                                        value="{{ data._id }}"
                                      >
                                        {{ data.value }}
                                      </option>
                                      <!-- <option>Super Tight</option>
                                                                          <option>Very Strong</option>
                                                                          <option>Strong</option>
                                                                          <option>Average</option>
                                                                          <option>Weak</option> -->
                                    </select>
                                    <!-- <i
                                        class="fa fa-angle-down"
                                        aria-hidden="true"
                                      ></i> -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label for="natureofrealtionship2"
                                    >Nature of Relationship</label
                                  >
                                  <div class="select-fa">
                                    <select
                                      class="form-control"
                                      id="natureofrealtionship2"
                                      formControlName="relationship"
                                    >
                                      <option
                                        *ngFor="let data of relationship"
                                        value="{{ data._id }}"
                                      >
                                        {{ data.value }}
                                      </option>
                                      <!-- <option>You were directly reporting to Subhadeep Bhattacharjee</option>
                                                                          <option>You were reporting to Subhadeep Bhattacharjee as N-2</option>
                                                                          <option>You had Subhadeep Bhattacharjee. as a direct subordinate</option>
                                                                          <option>You had Subhadeep Bhattacharjee. as a N-2 subordinate</option>
                                                                          <option>Subhadeep Bhattacharjee is an Alumni peer you meet on a regular basis</option>
                                                                          <option>Subhadeep Bhattacharjee is a friend of yours, you meet socially on a regular basis</option>
                                                                          <option>Subhadeep Bhattacharjee is a Board Peer you meet on a regular basis</option>
                                                                          <option>Subhadeep Bhattacharjee was in the same Exec team - C-Level as you</option>                                                                    
                                                                          <option>Subhadeep Bhattacharjee reports directly to a C-Level with whom you have a very strong relationship</option>
                                                                          <option>Subhadeep Bhattacharjee is part of the same organization as a C-Level with whom I have a very strong relationship</option>
                                                                          <option>I've sold Software to Subhadeep Bhattacharjee in the past </option>    -->
                                    </select>
                                    <!-- <i
                                        class="fa fa-angle-down"
                                        aria-hidden="true"
                                      ></i> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="conn-footer">
                        <div class="theme-btn footer-btn blue text-center">
                          <button
                            class="btn active"
                            [disabled]="!connectionForm.valid"
                            (click)="addConnection()"
                          >
                            ADD NEW CONNECTION
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- container -->
        </form>
      </section>
    </div>
  </section>
</main>
