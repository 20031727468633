import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GetDataService } from 'src/app/services/get-data.service';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropdownService } from 'src/app/services/dropdown.service';
import { CommonService } from 'src/app/utils/common.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-all-rp',
  templateUrl: './all-rp.component.html',
  styleUrls: ['./all-rp.component.scss'],
})
export class AllRpComponent implements OnInit {
  public imageUrl = environment.apiUrl;
  p: any = 1;

  countPerPageText: string = '10 Per Page';

  geographyArr: any[] = [];
  industryArr: any[] = [];
  domainArr: any[] = [];
  rpStatusArr: any[] = [];

  geographyValue: any[] = [];
  industryValue: any[] = [];
  domainValue: any[] = [];
  rpStatusValue: any[] = [];

  public filters: any = {
    searchQuery: {
      name: '',
      status: '',
      geography: '',
      industry: '',
      domain: '',
    },
    count: 10,
    skip: 0,
  };

  searchTerm: FormControl = new FormControl();

  resArr: any[] = [];
  totalPage: number;

  statusFilText: string = 'Status';
  geoFilText: string = 'Geo';
  industryFilText: string = 'Industry';
  domainFilText: string = 'Domain';

  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
  }

  ngOnInit(): void {
    this.getRpStatus();
    this.getIndustry();
    this.getDomain();
    this.getGeography();

    this.fetchRp();

    this.searchTerm.valueChanges
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe(
        (term) => {
          this.filters.count = this.filters.count ? this.filters.count : 0;
          this.filters.skip = 0;
          this.p = 1;

          if (term) {
            this.filters.searchQuery.name = term;

            this.fetchRp();
          } else if (term === '') {
            this.filters.searchQuery.name = '';
            this.fetchRp();
          }
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;

          this.common.showToastMessage(
            `ERROR`,
            `Oppss... Something Went Wrong`
          );
        }
      );
  }

  fetchRp() {
    this.getData.getAllRp(this.filters).subscribe(
      (res) => {
        this.resArr = res.details.agents;
        this.totalPage = res.details.totalPage;

        if (this.resArr.length <= 0 && this.totalPage === 0) {
          this.common.showToastMessage(`ERROR`, 'No Records Found', 7000);
          return;
        }
      },
      (err) => {
        this.resArr = [];
        this.totalPage = 0;

        this.common.showToastMessage(
          `ERROR`,
          `No Records Found For Selected Filter`
        );
      }
    );
  }

  pageChange(page) {
    this.p = page;
    this.filters.skip = (page - 1) * this.filters.count;

    // const searchBar: any = document.getElementById('searchBar');
    if (this.searchTerm.value === '') {
      this.filters.searchQuery.name = '';
    }

    this.fetchRp();
  }

  checkVal(opt: any, e: any, accVal?: any) {
    // const searchBar: any = document.getElementById('searchBar');

    if (this.searchTerm.value === '') {
      this.filters.searchQuery.companyName = '';
    }
    // console.log(opt, e);
    if (opt === '10PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = accVal;
    }
    if (opt === '25PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = accVal;
    }
    if (opt === '50PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = accVal;
    }

    if (opt === 'status') {
      let tempStatus = '';
      this.rpStatusValue.forEach(
        (a, b) => (tempStatus = (tempStatus ? `${tempStatus},` : '') + a._id)
      );

      this.filters.searchQuery.status = tempStatus;
    }

    if (opt === 'geo') {
      let tempGeo = '';

      this.geographyValue.forEach(
        (a, b) => (tempGeo = (tempGeo ? `${tempGeo},` : '') + a._id)
      );

      this.filters.searchQuery.geography = tempGeo;
    }

    if (opt === 'industry') {
      let tempIndustry = '';
      this.industryValue.forEach(
        (a, b) =>
          (tempIndustry = (tempIndustry ? `${tempIndustry},` : '') + a._id)
      );

      this.filters.searchQuery.industry = tempIndustry;
    }

    if (opt === 'domain') {
      let tempDomain = '';
      this.domainValue.forEach(
        (a, b) => (tempDomain = (tempDomain ? `${tempDomain},` : '') + a._id)
      );

      this.filters.searchQuery.domain = tempDomain;
    }

    this.filters.skip = 0;
    this.p = 1;

    this.fetchRp();
  }

  clearItem(option, item) {
    if (this.searchTerm.value === '') {
      this.filters.searchQuery.companyName = '';
    }

    if (option === 'status') {
      let tempStatus = '';

      this.rpStatusValue = this.rpStatusValue.filter(
        (el) => el._id !== item._id
      );

      this.rpStatusValue.forEach(
        (a, b) => (tempStatus = (tempStatus ? `${tempStatus},` : '') + a._id)
      );
      this.filters.searchQuery.status = tempStatus;
    }

    if (option === 'geo') {
      let tempGeo = '';

      this.geographyValue = this.geographyValue.filter(
        (el) => el._id !== item._id
      );

      this.geographyValue.forEach(
        (a, b) => (tempGeo = (tempGeo ? `${tempGeo},` : '') + a._id)
      );
      this.filters.searchQuery.geography = tempGeo;
    }

    if (option === 'industry') {
      let tempIndustry = '';

      this.industryValue = this.industryValue.filter(
        (el) => el._id !== item._id
      );

      this.industryValue.forEach(
        (a, b) =>
          (tempIndustry = (tempIndustry ? `${tempIndustry},` : '') + a._id)
      );
      this.filters.searchQuery.industry = tempIndustry;
    }

    if (option === 'domain') {
      let tempDomain = '';

      this.domainValue = this.domainValue.filter((el) => el._id !== item._id);

      this.domainValue.forEach(
        (a, b) => (tempDomain = (tempDomain ? `${tempDomain},` : '') + a._id)
      );
      this.filters.searchQuery.domain = tempDomain;
    }

    this.filters.skip = 0;
    this.p = 1;

    this.fetchRp();
  }

  resetFilters() {
    this.filters = {
      searchQuery: {
        name:
          this.searchTerm.value && this.searchTerm.value !== ' '
            ? this.searchTerm.value
            : '',
        status: '',
        geography: '',
        industry: '',
        domain: '',
      },
      count: this.filters.count ? this.filters.count : 6,
      skip: 0,
    };

    this.statusFilText = 'Status';
    this.geoFilText = 'Geo';
    this.industryFilText = 'Industry';
    this.domainFilText = 'Domain';

    this.p = 1;
    this.fetchRp();
  }

  goToRequest(id) {
    this.router.navigate([`/admin/add-rp/${id}`]);
  }

  async getRpStatus() {
    const res = await this.dropdown.getRpStatus().toPromise();
    this.rpStatusArr = res?.details?.rpStatus;
  }

  async getGeography() {
    const res = await this.dropdown.getGeography().toPromise();
    this.geographyArr = res?.details?.geography;
  }

  async getIndustry() {
    const res = await this.dropdown.getIndustry().toPromise();
    this.industryArr = res?.details?.industry;
  }

  async getDomain() {
    const res = await this.dropdown.getRPDomains().toPromise();
    this.domainArr = res?.details?.rpDomain;
  }
}
