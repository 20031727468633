import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { format, differenceInDays, subDays } from 'date-fns';
import * as Express from 'multer-s3';

interface AWSResponse {
  awsFileInfo: {
    [key in FileType] : Express.Multer.File
  };
}

export enum FileType {
  ProfilePhoto = 'profile_photos',
  IsvDocument = 'isv_documents',
  HlcDocument = 'hlc_documents',
  CompanyLogos = 'company_logos',
  CharityLogos = 'charity_logos'
}

@Injectable({
  providedIn: 'root',
})
export class SharedServiceService {
  private _teacherMessageSource = new Subject<string>();
  teacherMessage$ = this._teacherMessageSource.asObservable();

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  public onImgUserError(event) {
    event.target.src = 'assets/images/user.png';
  }

  public onImgCompanyError(event) {
    event.target.src = 'assets/images/company.png';
  }

  // Substarct Days
  public substractDays(dayOne, dayTwo) {
    return differenceInDays(new Date(dayOne), dayTwo);
  }

  // Current Day
  public currentDate() {
    return new Date();
  }

  // Format Days
  formatDays(dayOne) {
    // console.log(dayOne);
    return format(new Date(dayOne), 'dd-MMM-yyyy');
  }

  // KINDLY DON'T REMOVE IT'S USED IN RP PROFILE
  showSuccess(text) {
    Swal.fire({
      text: text,
    });
  }

  uploadFile(file: File, fileType: FileType): Promise<AWSResponse> {
    const fileData = new FormData();
    fileData.append(fileType, file);
    return this.http.post<AWSResponse>(`${environment.apiUrl}v1/uploadDocument`, fileData).toPromise();
  }

  public showToastMessage(type, message, duration = 3000) {
    switch (type) {
      case 'SUCCESS':
        this.toastr.success(message, 'Success', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
        });
        break;
      case 'ERROR':
        this.toastr.error(message, 'Error', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
        });
        break;
      case 'INFO':
        this.toastr.info(message, 'Info', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
        });
        break;
    }
  }

  sendMessage(message: string) {
    this._teacherMessageSource.next(message);
  }
}
