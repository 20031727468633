// import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-all-opportunities',
  templateUrl: './all-opportunities.component.html',
  styleUrls: ['./all-opportunities.component.scss'],
})
export class AllOpportunitiesComponent implements OnInit {
  p: any = 1;

  countPerPageText: string = '10 Per Page';
  quartersFilText: string = 'Period';
  isvFilText: string = 'ISV';
  rpFilText: string = 'High Level Connector';
  accFilText: string = 'Account';
  geographyFilText: string = 'Geography';
  domainFilText: string = 'Domain';
  companySizeFilText: string = 'Company Size';
  stageFilText: string = 'Stage';
  industryFilText: string = 'Industry';

  geographyArr: any = [];
  domainArr: any = [];
  isvArr: any = [];
  rpArr: any = [];
  accArr: any = [];
  industryArr: any = [];
  companySizeArr: any = [];
  stageArr: any = [];
  quartersArr: any = [];

  filterTerm: FormControl = new FormControl();

  filters = {
    filterTerm: '',
    filterCompanySize: '',
    filterCountry: '',
    filterIndustry: '',
    filterStage: '',
    filterRpId: '',
    filterIsvId: '',
    filterPeriod: '',
    days: '',
    filterAccount: '',
    limit: '10',
    page: '0',
  };
  resArr: any[];
  totalPage: number;
  totalPageArr: any[];

  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService
  ) {
    this.common.showHeader.next(true);
  }

  ngOnInit(): void {
    this.getAllAccountDropdown();
    this.getComSize();
    this.getIndustry();
    this.getDomain();
    this.refPartner();
    this.getISVDropDown();
    this.getGeography();
    this.getStages();
    this.quarterDropdown();

    this.fetchOpportunities();

    this.filterTerm.valueChanges
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe(
        (term) => {
          if (term) {
            this.filters.filterTerm = term;
            this.filters.limit = this.filters.limit ? this.filters.limit : '6';
            this.filters.page = '0';

            this.fetchOpportunities();
          } else if (term === '') {
            this.filters.filterTerm = '';
            this.fetchOpportunities();
          }
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;
          this.totalPageArr = [];
          this.common.showToastMessage(`ERROR`, `Something Went Wrong`);
        }
      );
  }

  fetchOpportunities() {
    this.getData.getAllOpportunities(this.filters).subscribe(
      (res) => {
        if (res?.details?.opportunities?.metadata[0].total === 0) {
          return this.common.showToastMessage(
            `ERROR`,
            `No Records Found For Selected Filter`
          );
        }
        this.resArr = res?.details?.opportunities?.data;
        this.totalPage = res?.details?.opportunities?.metadata[0].total
          ? res?.details?.opportunities?.metadata[0].total
          : 0;

        this.totalPageArr = [];
      },
      (err) => {
        this.resArr = [];
        this.totalPage = 0;
        this.totalPageArr = [];
        this.common.showToastMessage(`ERROR`, 'No Records Found', 10000);
      }
    );
  }

  checkVal(opt, e, acVal?) {
    if (opt === 'PERIOD') {
      if (e === 'EXIT') {
        return;
      }
      const periodValue = acVal.toString().replace(' ', '_');
      this.quartersFilText = acVal;
      this.filters.filterPeriod = periodValue;
    }
    if (opt === 'ISV') {
      this.isvFilText = acVal;
      this.filters.filterIsvId = e;
    }
    if (opt === 'RP') {
      this.rpFilText = acVal;
      this.filters.filterRpId = e;
    }
    if (opt === 'ACCOUNT') {
      this.accFilText = acVal;
      this.filters.filterAccount = e;
    }
    if (opt === 'GEO') {
      this.geographyFilText = acVal;
      this.filters.filterCountry = e;
    }
    if (opt === 'INDUSTRY') {
      this.industryFilText = acVal;
      this.filters.filterIndustry = e;
    }
    // if (opt === 'DOMAIN') {
    //   this.domainFilText = acVal;
    //   console.log(e);
    //   // this.filters.filter
    // }
    if (opt === 'COMSIZE') {
      this.companySizeFilText = acVal;
      this.filters.filterCompanySize = e;
    }
    if (opt === 'STAGE') {
      this.stageFilText = acVal;
      this.filters.filterStage = e;
    }
    if (opt === '10PERPAGE') {
      this.countPerPageText = acVal;
      this.filters.limit = e;
    }
    if (opt === '25PERPAGE') {
      this.countPerPageText = acVal;
      this.filters.limit = e;
    }
    if (opt === '50PERPAGE') {
      this.countPerPageText = acVal;
      this.filters.limit = e;
    }

    this.p = 1;
    this.filters.limit = this.filters.limit ? this.filters.limit : '6';
    this.filters.page = '0';

    this.fetchOpportunities();
  }

  pageChange(page) {
    console.log(page);

    this.p = page;
    // const tempPageVar = (page - 1) * parseInt(this.filters.limit);
    const tempPageVar = page;
    this.filters.page = `${tempPageVar}`;

    const searchBar: any = document.getElementById('searchBar');
    if (searchBar.value === '') {
      this.filters.filterTerm = '';
    }

    this.fetchOpportunities();
  }

  async getGeography() {
    const res = await this.dropdown.getGeography().toPromise();
    this.geographyArr = res?.details?.geography;
  }

  async getISVDropDown() {
    const res = await this.dropdown.getISVsDropDown().toPromise();
    this.isvArr = res?.details?.isv;
    this.isvArr = this.isvArr.sort((a, b) => {
      const nameA = a.companyName.toUpperCase();
      const nameB = b.companyName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  async refPartner() {
    const res = await this.dropdown.getRpsList().toPromise();
    this.rpArr = res?.details?.data;
    this.rpArr = this.rpArr.sort((a, b) => {
      const nameA = a?.firstName.toUpperCase().trim();
      const nameB = b?.firstName.toUpperCase().trim();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  async getAllAccountDropdown() {
    const res = await this.dropdown.getAllAccountDropdown().toPromise();
    this.accArr = res?.details?.accounts;
    // console.log(this.accArr);
  }

  async getDomain() {
    const res = await this.dropdown.getDomains().toPromise();
    this.domainArr = res?.details?.domain;
  }

  async getIndustry() {
    const res = await this.dropdown.getIndustry().toPromise();
    this.industryArr = res?.details?.industry;
  }

  async getComSize() {
    const res = await this.dropdown.getCompanySize().toPromise();
    this.companySizeArr = res?.details?.companySize;
  }

  async getStages() {
    const res = await this.dropdown.opportunityDropdown().toPromise();
    this.stageArr = res?.details?.stage;
  }

  async quarterDropdown() {
    const res = await this.dropdown.qtrDropdown().toPromise();
    this.quartersArr = res?.details?.quarters;
    const curDate = new Date();

    this.quartersArr = this.quartersArr.map((el) => {
      el.value = `${el.value} ${curDate.getFullYear()}`;
      return el;
    });

    let tempArrForNextYear = [];

    for (let el of this.quartersArr) {
      const newEl = {
        _id: el._id,
        value: el.value.split(' ')[0] + ' ' + (curDate.getFullYear() + 1),
      };
      tempArrForNextYear.push(newEl);
    }

    this.quartersArr.push({ _id: '', value: `FY ` + curDate.getFullYear() });
    this.quartersArr.push({ _id: 'EXIT', value: '' });

    for (let i of tempArrForNextYear) {
      this.quartersArr.push(i);
    }

    this.quartersArr.unshift({ value: `YtD ` + curDate.getFullYear() });
    this.quartersArr.push({ value: `FY ` + (curDate.getFullYear() + 1) });
  }

  goToIntroProposal(item) {
    this.router.navigate(['/admin/introporposal-isv'], {
      queryParams: { proposal: item?.proposalId },
    });
  }
}
