import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(public http: HttpClient) {}

  getGeography() {
    return this.http.get<any>(`${environment.apiUrl}geographyDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getMonth() {
    return this.http.get<any>(`${environment.apiUrl}monthDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getCountry() {
    return this.http.get<any>(`${environment.apiUrl}countryDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getDomains() {
    return this.http.get<any>(`${environment.apiUrl}domainDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getRPDomains() {
    return this.http.get<any>(`${environment.apiUrl}rpDomainDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getsubDomain(id: any) {
    return this.http
      .get<any>(`${environment.apiUrl}subDomainDropdown/` + id)
      .pipe(
        map((el) => {
          // console.log(el);
          return el;
        })
      );
  }

  getIndustry() {
    return this.http.get<any>(`${environment.apiUrl}industryDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getjobTitle() {
    // jobTitleDropdown
    return this.http.get<any>(`${environment.apiUrl}jobTitleDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getsellerStatus() {
    return this.http.get<any>(`${environment.apiUrl}sellerStatusDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getdocumentType() {
    return this.http.get<any>(`${environment.apiUrl}documentTypeDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getdocumentStatus() {
    return this.http.get<any>(`${environment.apiUrl}docStatusDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getTierStatus() {
    return this.http.get<any>(`${environment.apiUrl}tierDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getISVDropdown() {
    return this.http.get<any>(`${environment.apiUrl}isvDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getProductStatus() {
    return this.http
      .get<any>(`${environment.apiUrl}productStatusDropdown`)
      .pipe(
        map((el) => {
          //   console.log(el);
          return el;
        })
      );
  }

  getAccountStatus() {
    return this.http
      .get<any>(`${environment.apiUrl}accountStatusDropdown`)
      .pipe(
        map((el) => {
          //   console.log(el);
          return el;
        })
      );
  }

  getCompanySize() {
    return this.http.get<any>(`${environment.apiUrl}companySizeDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getcharity() {
    return this.http.get<any>(`${environment.apiUrl}charityDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getRpStatus() {
    return this.http.get<any>(`${environment.apiUrl}agentStatusDropdown`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getlinkedinIndustryDropdown() {
    return this.http
      .get<any>(`${environment.apiUrl}linkedinIndustryDropdown`)
      .pipe(
        map((el) => {
          // console.log(el);
          return el;
        })
      );
  }

  getlinkedinCompanySizeDropdown() {
    return this.http
      .get<any>(`${environment.apiUrl}linkedinCompanySizeDropdown`)
      .pipe(
        map((el) => {
          // console.log(el);
          return el;
        })
      );
  }

  getCompetitor() {
    return this.http.get<any>(`${environment.apiUrl}companyDropdown/`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  sponsoredByDropdown() {
    return this.http.get<any>(`${environment.apiUrl}sponsoredByDropdown/`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }
  getRpsList() {
    return this.http.get<any>(`${environment.apiUrl}getRpList/`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }
  getISVsDropDown() {
    return this.http.get<any>(`${environment.apiUrl}isvDropdown/`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  // Intro Status
  introProposalsStatus() {
    return this.http.get<any>(`${environment.apiUrl}introProposalStatus/`).pipe(
      map((el) => {
        return el;
      })
    );
  }

  introRequestsStatus() {
    return this.http.get<any>(`${environment.apiUrl}introRequestStatus/`).pipe(
      map((el) => {
        return el;
      })
    );
  }

  stageDropdown() {
    return this.http.get<any>(`${environment.apiUrl}stages/`).pipe(
      map((el) => {
        return el;
      })
    );
  }

  opportunityDropdown() {
    return this.http.get<any>(`${environment.apiUrl}opportunityStage/`).pipe(
      map((el) => {
        return el;
      })
    );
  }
  qtrDropdown() {
    return this.http.get<any>(`${environment.apiUrl}quarterDropdown/`).pipe(
      map((el) => {
        return el;
      })
    );
  }

  introStatusDropdownForIntroProposals() {
    return this.http.get<any>(`${environment.apiUrl}introStatusDropdown/`).pipe(
      map((el) => {
        return el;
      })
    );
  }

  getAllAccountDropdown() {
    return this.http.get<any>(`${environment.apiUrl}accountDropDown/`).pipe(
      map((el) => {
        return el;
      })
    );
  }

  getNature() {
    return this.http.get<any>(`${environment.apiUrl}nature`);
  }

  getStrength() {
    return this.http.get<any>(`${environment.apiUrl}strength`);
  }

  accountDropdown() {
    return this.http.get<any>(`${environment.apiUrl}accountDropdown`);
  }

  getRpList() {
    return this.http.get<any>(`${environment.apiUrl}getRpList`);
  }

  addTargetToNetwork(payload) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token,
      }),
    };
    return this.http.post<any>(
      `${environment.apiUrl}addTarget`,
      payload,
      httpOptions
    );
  }

  editConnection(token, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token,
      }),
    };
    return this.http.patch<any>(
      `${environment.apiUrl}connections/` + token,
      data,
      httpOptions
    );
  }

  getConnections(count, page, skip, days, account, searchKey, strength, rpId) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).token,
      }),
    };
    if (rpId != undefined && rpId != 'None') {
      return this.http.get<any>(
        `${environment.apiUrl}getWsmNetwork` +
          `?count=${count}&page=${page}&skip=${skip}&days=${days}&rpId=${rpId}`,
        httpOptions
      );
    }
    if (
      account != undefined &&
      account != 'None' &&
      (strength == undefined || strength == 'None' || strength == '')
    ) {
      return this.http.get<any>(
        `${environment.apiUrl}getWsmNetwork` +
          `?count=${count}&page=${page}&skip=${skip}&days=${days}&account=${account}`,
        httpOptions
      );
    }
    if (searchKey != undefined) {
      return this.http.get<any>(
        `${environment.apiUrl}getWsmNetwork` +
          `?count=${count}&page=${page}&skip=${skip}&days=${days}&term=${searchKey}`,
        httpOptions
      );
    }
    if (
      strength != undefined &&
      (account == undefined || account == 'None' || account == '')
    ) {
      return this.http.get<any>(
        `${environment.apiUrl}getWsmNetwork` +
          `?count=${count}&page=${page}&skip=${skip}&days=${days}&strength=${strength}`,
        httpOptions
      );
    }
    return this.http.get<any>(
      `${environment.apiUrl}getWsmNetwork` +
        `?count=${count}&page=${page}&skip=${skip}&days=${days}`,
      httpOptions
    );
  }
  getpaymentStatus() {
    return this.http.get<any>(`${environment.apiUrl}paymentStatusDropdown`);
  }

  getSponsoredRP() {
    return this.http.get<any>(`${environment.apiUrl}sponsoredUsers?admin=true`);
  }
  getAllCharity() {
    return this.http.get<any>(`${environment.apiUrl}charityDropdown`);
  }
}
