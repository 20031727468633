import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Dropdown } from 'src/app/interface/dropdown';
import { AddDataService } from 'src/app/services/add-data.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utils/common.service';
import { FileType, SharedServiceService } from 'src/app/services/shared-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  isSubmitted: boolean = false;
  isvArr: any;
  productStatusArr: any;
  documentTypeArr: any;
  documentStatusArr: any;
  productDoc: any;
  holdDocArr: any = [];
  filePath: string = null;
  addNewProduct: FormGroup;
  emailError: string;
  universalError: any;
  sellerID: string;
  disableOnboardingButton: boolean = false;
  productID: string;
  prodDetails: any;
  docUrl:any;
  filteredStatusArr: Dropdown[];
  fileName: any;
  // initialStatus: string;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private dropdown: DropdownService,
    private getData: GetDataService,
    private addData: AddDataService,
    private commonService: CommonService,
    private actRoute: ActivatedRoute,
    private sharedService:SharedServiceService
  ) {
    this.commonService.showHeader.next(true);
    this.productID = actRoute.snapshot.params[`id`];
    // console.log(this.productID);
  }

  ngOnInit(): void {
    this.getisvDropdown();
    this.getProductStatus();
    this.getDocumentTypes();
    this.getDocumentStatuss();

    // this.stepService.currentStep$.next(this.stepService.steps$.value[0]);

    this.addNewProduct = this.fb.group({
      firstDealSize: [''],
      repeatDealSize: [''],
      firstDealDuration: [''],
      durationBeforeFirstRepeatDeal: [''],
      targetCompanyCharacteristic: [''],
      targetPeopleCharacteristic: [''],
      details: [''],
      valueProposition: [''],
      benefits: [''],
      roiPayback: [''],
      differentiators: [''],
      usecase: [''],
      reference: [''],
      status: [''],

      createdBy: [''],
      createdDate: [''],

      isvID: ['', [Validators.required]],

      productId: ['', [Validators.required]], // TO BE SET AFTER PRODUCT ONBOARDING IS DONE AND TO BE PASSED IN THE ADD-PRODUCT SERVICE

      documentStatus: [''],
      documentType: [''],
      description: [''],
      documentName: ['']
    });

    this.initialStatusFunc();
  }

  async initialStatusFunc() {
    const res = await this.dropdown.getProductStatus().toPromise();
    const arr: any = res?.details?.productStatus;

    const ss = arr.find((status: any) => {
      return status.value === 'PENDING GSA';
    });

    // this.initialStatus = ss[0]._id;
    // console.log("ngOnInit k neeche", this.initialStatus);
    // this.addNewProduct.patchValue({ status: ss[0]._id });
    this.addNewProduct.get('status').setValue(ss._id);
  }

  saveSellerID() {
    this.commonService.showHeader.next(false);
    localStorage.setItem('sellerId', this.addNewProduct.value.isvID);
    this.router.navigate(['/admin/product-onboarding']);
  }

  async getInitialData() {
    if (this.productID) {
      const res = await this.getData
        .getProductDetailsById(this.productID)
        .toPromise();

      this.docUrl = res.details.docUrl;
      this.prodDetails = res.details.product;

      // Fields to be disabled when editing
      this.addNewProduct.get('createdBy').disable();
      this.addNewProduct.get('createdDate').disable();

      this.dropDownContent();

      this.setFormData(this.prodDetails);
    }
  }

  setFormData(prodData) {
    var datePipe = new DatePipe('en-IN');
    const setDate = datePipe.transform(prodData.createdAt, 'dd/MM/yyyy');

    this.addNewProduct.patchValue({
      createdBy:
        prodData.createdBy.firstName + ' ' + prodData.createdBy.lastName,
      createdDate: setDate,
      status: prodData?.status?._id ? prodData?.status?._id : '',
      isvID: prodData?.sellerId?._id,
      productId: prodData._id,
      firstDealSize: prodData?.firstDealSize,
      repeatDealSize: prodData?.repeatDealSize,
      firstDealDuration: prodData?.firstDealDuration,
      durationBeforeFirstRepeatDeal: prodData?.durationBeforeFirstRepeatDeal,
      targetCompanyCharacteristic: prodData?.targetCompanyCharacteristic,
      targetPeopleCharacteristic: prodData?.targetPeopleCharacteristic,
      details: prodData?.gsaResult?.details,
      valueProposition: prodData?.gsaResult?.valueProposition,
      benefits: prodData?.gsaResult?.benefits,
      roiPayback: prodData?.gsaResult?.roiPayback,
      differentiators: prodData?.gsaResult?.differentiators,
      usecase: prodData?.gsaResult?.usecase,
      reference: prodData?.gsaResult?.reference
    });
   
    prodData?.productDocument.map((data) => {
      this.holdDocArr.push({
        documentStatus: data.documentStatus._id,
        documentType: data.documentType._id,
        description: data.description,
        documentName: data.documentName
      });
    });
  }

  dropDownContent() {
    // console.log("in dropdown Content");
    if (!this.productID || this.productID === '') {
      this.filteredStatusArr = this.productStatusArr.filter((el) => {
        return el.value === 'PENDING GSA';
      });
    } else if (
      this.productID &&
      this.prodDetails.status.value === 'PENDING GSA'
    ) {
      this.filteredStatusArr = this.productStatusArr.filter(
        (el) => el.value === 'PENDING GSA'
      );
    } else if (this.productID && this.prodDetails.status.value === 'GSA 1') {
      this.filteredStatusArr = this.productStatusArr.filter(
        (el) => el.value === 'GSA 1'
      );
    } else if (this.productID && this.prodDetails.status.value === 'GSA 2') {
      this.filteredStatusArr = this.productStatusArr.filter(
        (el) => el.value === 'GSA 2'
      );
    } else if (this.productID && this.prodDetails.status.value === 'GSA 3') {
      this.filteredStatusArr = this.productStatusArr.filter(
        (el) =>
          el.value === 'GSA 3' ||
          el.value === 'Active' ||
          el.value === 'Disabled'
      );
    } else if (this.productID && this.prodDetails.status.value === 'Active') {
      this.filteredStatusArr = this.productStatusArr.filter(
        (el) =>
          el.value === 'Disabled' ||
          el.value === 'Archived' ||
          el.value === 'Active'
      );
    } else if (this.productID && this.prodDetails.status.value === 'Disabled') {
      this.filteredStatusArr = this.productStatusArr.filter(
        (el) =>
          el.value === 'Active' ||
          el.value === 'Disabled' ||
          el.value === 'Archived'
      );
    } else if (this.productID && this.prodDetails.status.value === 'Inactive') {
      this.filteredStatusArr = this.productStatusArr.filter(
        (el) =>
          el.value === 'Inactive' ||
          el.value === 'Active' ||
          el.value === 'Archived'
      );
    } else if (this.productID && this.prodDetails.status.value === 'Archived') {
      this.addNewProduct.get('status').disable();
      this.filteredStatusArr = this.productStatusArr.filter(
        (el) => el.value === 'Archived'
      );
    } else {
      // console.log("Entering ELSE Block Bitch");
      this.filteredStatusArr = this.productStatusArr;
    }
    // console.log("Filtered Status array", this.filteredStatusArr);
  }

  onChangeISV() {
    localStorage.setItem('sellerId', this.addNewProduct.value.isvID);
  }

  // Get List Document Type for Dropdown
  getDocumentTypes() {
    this.dropdown.getdocumentType().subscribe((el) => {
      this.documentTypeArr = el?.details?.documentTypes;
    });
  }

  // Get List Document Status for Dropdown
  getDocumentStatuss() {
    this.dropdown.getdocumentStatus().subscribe((el) => {
      this.documentStatusArr = el?.details?.docStatus;
    });
  }

  // Get isv List for Dropdown
  getisvDropdown() {
    this.dropdown.getISVDropdown().subscribe((el) => {
      this.isvArr = el?.details?.isv;
    });
  }

  // Get isv List for Dropdown
  async getProductStatus() {
    const res = await this.dropdown.getProductStatus().toPromise();
    this.productStatusArr = res?.details?.productStatus;

    // const ss = this.productStatusArr.filter((status) => {
    //   return status.value === "PENDING GSA"
    // })

    // this.initialStatus = ss[0]._id
    // console.log(this.initialStatus)
    // console.log(this.productStatusArr)

    await this.getInitialData();

    this.dropDownContent();
  }

  getStatusValue(id) {
    if (id) {
      const res = this.documentStatusArr.find((el: any) => el._id === id);
      return res.value;
    }
    return;
  }

  getDocumentTypeValue(id) {
    if (id) {
      const res = this.documentTypeArr.find((el: any) => el._id === id);
      return res.value;
    }
    return;
  }
 
  addProductDoc(fileInput:any):void {
    if(fileInput.target.files.length > 0) {
      let file = fileInput.target.files[0] as File;
      this.fileName = file.name;

      if(file.size <= 20000000) {
        this.uploadProductDoc(file);
      } else {
        this.addNewProduct.get('documentName').setErrors({filesize: true});
      }
    } else {
      this.addNewProduct.patchValue({
        documentName: ''
      });
    }
  }

  uploadProductDoc(file : File): void {
    this.sharedService.uploadFile(file, FileType.IsvDocument)
      .then((res) => {
        const fileName = res.awsFileInfo.isv_documents[0]?.key.split('/').pop();

        this.addNewProduct.patchValue({
          documentName: fileName
        });

      })
      .catch((e) => {
        this.fileName = null;
      });
  }
  
  addDoc() {
    // const fileData = new FormData();

    // fileData.append('document', this.productDoc);

    // const filePathData = await this.addData.fileUpload(fileData).toPromise();

    const docObj = {
      documentStatus: this.addNewProduct.value.documentStatus,
      documentType: this.addNewProduct.value.documentType,
      description: this.addNewProduct.value.description,
      documentName: this.addNewProduct.value.documentName
    };

    this.holdDocArr.push(docObj);

    this.addNewProduct.get('documentStatus').reset();
    this.addNewProduct.get('documentType').reset();
    this.addNewProduct.get('description').reset();
    this.addNewProduct.get('documentName').reset();
  }

 
  addProduct() {
    const GSAResult = {
      details: this.addNewProduct.value.details,
      valueProposition: this.addNewProduct.value.valueProposition,
      benefits: this.addNewProduct.value.benefits,
      roiPayback: this.addNewProduct.value.roiPayback,
      differentiators: this.addNewProduct.value.differentiators,
      usecase: this.addNewProduct.value.usecase,
      reference: this.addNewProduct.value.reference,
    };

    const finalObj: any = {
      firstDealSize: this.addNewProduct.value.firstDealSize,
      repeatDealSize: this.addNewProduct.value.repeatDealSize,
      firstDealDuration: this.addNewProduct.value.firstDealDuration,
      durationBeforeFirstRepeatDeal: this.addNewProduct.value
        .durationBeforeFirstRepeatDeal,
      targetCompanyCharacteristic: this.addNewProduct.value
        .targetCompanyCharacteristic,
      targetPeopleCharacteristic: this.addNewProduct.value
        .targetPeopleCharacteristic,
      gsaResult: GSAResult,
      productDocument: this.holdDocArr,
      status: this.addNewProduct.value.status,
    };

    this.addData
      .addProductService(
        finalObj,
        this.addNewProduct.value.isvID,
        this.addNewProduct.value.productId
      )
      .subscribe(
        (data: any) => {
          this.commonService.showSuccess('Product added successfully');
          this.resetForm();
          this.getInitialData();
        },
        (err) => {
          err.error.message === 'Email already exist on platform'
            ? (this.emailError = 'Email already exist on platform')
            : null;
        }
      );
  }

//  async showDoc(i) {
//     console.log("Show Doc", i);
//     // const link = document.createElement('a');
//     // link.setAttribute('target', '_blank');
//     // link.setAttribute('href', `${environment.apiUrl}${i.documentName}`);
//     // link.setAttribute('download', `file`);
//     // document.body.appendChild(link);
//     // link.click();
//     // link.remove();
//     if (this.productID) {
//       const res = await this.getData
//         .getProductDetailsById(this.productID)
//         .toPromise();

//       this.prodDetails = res.details.product;
//       console.log("Product details",this.prodDetails);
//     }
//   }

  resetForm() {
    this.addNewProduct.reset();
    this.holdDocArr = [];
    this.router.navigate(['/admin/all-product']);
  }

  get f() {
    return this.addNewProduct.controls;
  }
}
