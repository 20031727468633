<div class="panel panel-primary setup-content" id="step-1">
  <section class="mail-verify lets">
    <div *ngIf="hint && !onBoardingStatus" class="col-12 p-0">
      <div class="alert-box lets-alert">
        <div class="alert alert-warning" role="alert">
          <p>
            <img src="../assets/images/idea.png" class="mr-1" alt="" />Our Go to
            market Strategy Analysis (GSA) framework consists of a qualification
            questionnaire and a couple of interactive meetings with you. Our B2B
            Geo Sales experts thoroughly go through, along with you and your
            exec team members, your product’s value proposition, targeted
            audience, differentiators, benefits, sales process, demos and other
            important aspects of selling in a foreign geography. The objective
            is to swiftly build a dedicated value proposition supporting a pitch
            deck, most likely with a scripted demo, that we can efficiently take
            to a targeted geography's audience, focused on a Minimum Viable
            Segment for maximizing your chance of quick success.
          </p>
          <p>
            We start by analyzing your initial responses to the questionnaire
            and will contact you very soon for a first meeting, to start the
            refinement process. We now invite you to continue the process at
            your own pace, starting with some generic aspects relating to your
            product(s)
          </p>
        </div>
      </div>
    </div>
    <main *ngIf="productList" class="main-left">
      <section class="mail-verify lets padding-40">
        <div class="container-fluid">
          <!-- <div class="save-exit text-right">
            
            <button class="btn upper-case">Save and Exit</button>
          </div> -->
        </div>
      </section>
    </main>
    <div class="max-width680">
      <div class="condition-para mt-40">
        <div class="sign-head text-center">
          <h5 *ngIf="!onBoardingStatus">Let’s onboard your first product</h5>
          <h5 *ngIf="onBoardingStatus">Let's add a new product</h5>
        </div>
        <div class="form-field sign-form">
          <form>
            <div class="form-sec normal domain mb-15">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="exampleInputPassword1" class="d-flex"
                      >What’s the name of your product?<span class="ml-0"
                        ><button
                          type="button"
                          class="info"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="The best entry point person is NOT the eventual buyer or
                                            decision maker;  The BEST ENTRY POINT person could well
                                            become your eventual main Champion"
                        >
                          <img src="../assets/images/info.svg" /></button></span
                    ></label>
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="productName"
                      (input)="hint = false"
                      [ngModelOptions]="{ standalone: true }"
                      placeholder=""
                    />
                    <div class="text-danger" *ngIf="err">{{ err }}</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="theme-btn dom width-fit text-right">
          <button
            [disabled]="productName === ''"
            (click)="AddProductName()"
            [class.active]="productName"
            class="btn nextBtn"
            type="submit"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
