import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-sponsored-rps',
  templateUrl: './sponsored-rps.component.html',
  styleUrls: ['./sponsored-rps.component.scss'],
})
export class SponsoredRpsComponent implements OnInit {
  sponsors: any;
  page = 1;
  pageSize = 10;
  pageSizes = [10, 25, 50];
  totalRecords: any;
  sponsoredByList: any;
  payload: any = {};
  status: any;
  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    private dataSer: GetDataService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
  }

  ngOnInit(): void {
    this.payload.skip = 0;
    this.payload.count = this.pageSize;
    this.getSponsoredRP(this.payload);
    this.getAllSponsoredBy();
    this.getRPStatusDropdown();
  }
  getSponsoredRP(payload) {
    this.dataSer.getSponsoredPartners(payload).subscribe(
      (data) => {
        this.sponsors = data.details.sponsoredRPs[0].data;
        this.totalRecords = data.details.sponsoredRPs[0].total[0].count;
        // console.log(this.sponsors, this.totalRecords);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getAllSponsoredBy() {
    this.dropdown.getRpList().subscribe(
      (data) => {
        this.sponsoredByList = data.details.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handlePageChange(event): void {
    this.page = event;
    const sk = (event - 1) * this.pageSize;
    this.payload = { count: this.pageSize, skip: sk };
    this.getSponsoredRP(this.payload);
  }
  handlePageSizeChange(event): void {
    this.pageSize = event;
    this.page = 1;
    this.payload = { count: event };
    this.getSponsoredRP(this.payload);
  }
  //

  getRPStatusDropdown() {
    this.dropdown.getRpStatus().subscribe(
      (data) => {
        this.status = data.details.rpStatus;
        // console.log(status);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // filters
  filterBySponsor(id: any) {
    this.page = 1;
    if (id) {
      this.payload = { rpId: id, count: this.pageSize };
      this.getSponsoredRP(this.payload);
    }
  }

  filterBySponsorDate(val) {
    this.page = 1;
    if (val) {
      this.payload = { days: val, count: this.pageSize };
      this.getSponsoredRP(this.payload);
    }
  }

  filterByStatus(val) {
    this.page = 1;
    if (val) {
      this.payload = { status: val, count: this.pageSize };
      this.getSponsoredRP(this.payload);
    }
  }

  search(e) {
    this.page = 1;
    this.payload = { searchPattern: e.target.value, count: this.pageSize };
    if (
      this.payload.searchPattern != '' &&
      this.payload.searchPattern.length > 2
    ) {
      this.getSponsoredRP(this.payload);
    } else if (this.payload.searchPattern === '') {
      this.payload.count = this.pageSize;
      this.getSponsoredRP(this.payload);
    }
  }
}
