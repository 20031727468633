import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ActivatedRoute } from '@angular/router';

import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'app-target-roles',
  templateUrl: './target-roles.component.html',
  styleUrls: ['./target-roles.component.scss'],
})
export class TargetRolesComponent implements OnInit {
  @Input() step: StepModel;

  public payload: any;
  public productId: any;
  public targetRolesForm: FormGroup;
  public productName: any;
  public errMsg: string;
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public roles: any = [];
  public secondEntry: any = [];
  public selectable1 = true;
  public removable1 = true;
  public addOnBlur1 = true;
  public thirdEntry: any = [];
  public selectable2 = true;
  public removable2 = true;
  public addOnBlur2 = true;
  public toEdit: string;
  public productDetails: any;

  constructor(
    private stepService: StepsService,
    public isv: IsvService,
    private fb: FormBuilder,
    public sharedService: CommonService,
    public route: ActivatedRoute,
    private dropdown: DropdownService
  ) {
    this.productId = localStorage.getItem('productId');
    this.productName = localStorage.getItem('productName');
  }

  ngOnInit(): void {
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');

    if (this.toEdit) {
      this.getProductDetail();
    }

    this.targetRolesForm = this.fb.group({
      bestEntryPoint: ['', Validators.required],
      secondaryPriorityRole: [''],
      thirdPriorityRole: [''],
    });
  }

  getProductDetail() {
    this.isv.getSellerProductRegData(this.productId, 1).subscribe(
      (data) => {
        this.productDetails = data?.details?.product;
        this.setFormValues();
      },
      (err) => {
        console.log(err);
        this.sharedService.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  AddTargetRoles() {
    if (
      !this.targetRolesForm.value.secondaryPriorityRole &&
      !this.targetRolesForm.value.thirdPriorityRole
    ) {
      this.payload = {
        bestEntryPoint: this.targetRolesForm.value.bestEntryPoint,
      };
    } else if (
      this.targetRolesForm.value.secondaryPriorityRole &&
      this.targetRolesForm.value.bestEntryPoint &&
      !this.targetRolesForm.value.thirdPriorityRole
    ) {
      this.payload = {
        bestEntryPoint: this.targetRolesForm.value.bestEntryPoint,
        secondaryPriorityRole: this.targetRolesForm.value.secondaryPriorityRole,
      };
    } else if (
      this.targetRolesForm.value.thirdPriorityRole &&
      this.targetRolesForm.value.bestEntryPoint &&
      !this.targetRolesForm.value.secondaryPriorityRole
    ) {
      this.payload = {
        bestEntryPoint: this.targetRolesForm.value.bestEntryPoint,
        thirdPriorityRole: this.targetRolesForm.value.thirdPriorityRole,
      };
    } else {
      this.payload = {
        bestEntryPoint: this.targetRolesForm.value.bestEntryPoint,
        secondaryPriorityRole: this.targetRolesForm.value.secondaryPriorityRole,
        thirdPriorityRole: this.targetRolesForm.value.thirdPriorityRole,
      };
    }
    if (this.targetRolesForm.invalid || this.checkSimilarValues()) {
      return;
    }
    this.isv.addRole(this.payload, this.productId).subscribe(
      (res) => {
        if (res) {
          this.stepService.onNextStep();
        }
      },
      (err) => {
        console.log(err);
        this.sharedService.showToastMessage('ERROR', err.error.message, 5000);
        setTimeout(() => {
          this.errMsg = err.error.message;
        }, 2000);
        this.errMsg = '';
      }
    );
  }

  checkSimilarValues() {
    let result;
    if (
      this.targetRolesForm.get('bestEntryPoint').value &&
      this.targetRolesForm.get('secondaryPriorityRole').value
    ) {
      result = this.targetRolesForm
        .get('secondaryPriorityRole')
        .value.some((item) =>
          this.targetRolesForm.get('bestEntryPoint').value.includes(item)
        );
      if (result) {
        this.sharedService.showToastMessage(
          'ERROR',
          'Best Entry Point & Secondary Priority Role cannot be same',
          5000
        );
        return true;
      }
    }
    if (
      this.targetRolesForm.get('bestEntryPoint').value &&
      this.targetRolesForm.get('thirdPriorityRole').value
    ) {
      result = this.targetRolesForm
        .get('thirdPriorityRole')
        .value.some((item) =>
          this.targetRolesForm.get('bestEntryPoint').value.includes(item)
        );
      if (result) {
        this.sharedService.showToastMessage(
          'ERROR',
          'Best Entry Point & Third Priority Role cannot be same',
          5000
        );
        return true;
      }
    }
    if (
      this.targetRolesForm.get('thirdPriorityRole').value &&
      this.targetRolesForm.get('secondaryPriorityRole').value
    ) {
      result = this.targetRolesForm
        .get('thirdPriorityRole')
        .value.some((item) =>
          this.targetRolesForm.get('secondaryPriorityRole').value.includes(item)
        );
      if (result) {
        this.sharedService.showToastMessage(
          'ERROR',
          'Secondary Priority Role & Third Priority Role cannot be same',
          5000
        );
        return true;
      }
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.roles.push(value.trim());
      this.targetRolesForm.patchValue({ bestEntryPoint: this.roles });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(role): void {
    const index = this.roles.indexOf(role);

    if (index >= 0) {
      this.roles.splice(index, 1);
      this.targetRolesForm
        .get('bestEntryPoint')
        .setValidators([Validators.required]);
      this.targetRolesForm.get('bestEntryPoint').updateValueAndValidity();
    }
  }

  addSecond(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.secondEntry.push(value.trim());
      this.targetRolesForm.patchValue({
        secondaryPriorityRole: this.secondEntry,
      });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  addThird(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.thirdEntry.push(value.trim());
      this.targetRolesForm.patchValue({ thirdPriorityRole: this.thirdEntry });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeSecondEntry(second): void {
    const index = this.secondEntry.indexOf(second);

    if (index >= 0) {
      this.secondEntry.splice(index, 1);
    }
  }

  removeThirddEntry(second): void {
    const index = this.thirdEntry.indexOf(second);

    if (index >= 0) {
      this.thirdEntry.splice(index, 1);
    }
  }

  setFormValues() {
    this.roles = this.productDetails.bestEntryPoint;
    this.secondEntry = this.productDetails.secondaryPriorityRole;
    this.thirdEntry = this.productDetails.thirdPriorityRole;
    this.targetRolesForm.setValue({
      bestEntryPoint: this.productDetails.bestEntryPoint,
      secondaryPriorityRole: this.productDetails.secondaryPriorityRole,
      thirdPriorityRole: this.productDetails.thirdPriorityRole,
    });
  }
}
