<div class="dialog-container">
    <div class="title">
        Once checked, you cannot uncheck Agreement. Are you sure you want to go ahead?
    </div>
    <div class="buttons">
        <button 
            class="white-button"
            mat-raised-button
            (click)="onCancel()"
        >Cancel</button>
        <button 
            class="blue-button"
            mat-raised-button color="primary"
            (click)="onConfirm()"
        >OK</button>
    </div>
</div>