import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AddAccountComponent } from './add/add-account/add-account.component';
import { AddIsvComponent } from './add/add-isv/add-isv.component';
import { AddRpComponent } from './add/add-rp/add-rp.component';
import { AddProductComponent } from './add/add-product/add-product.component';
import { AllRpComponent } from './list/all-rp/all-rp.component';
import { AllIsvComponent } from './list/all-isv/all-isv.component';
import { AllAccountComponent } from './list/all-account/all-account.component';
import { AllProductComponent } from './list/all-product/all-product.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { WsmNetworksComponent } from './add/wsm-networks/wsm-networks.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { IntroRequestsComponent } from './list/intro-requests/intro-requests.component';
import { IntroproposalRpComponent } from './list/introproposal-rp/introproposal-rp.component';
import { IntroproposalIsvComponent } from './list/introproposal-isv/introproposal-isv.component';
import { AllOpportunitiesComponent } from './list/all-opportunities/all-opportunities.component';
import { AllDealsComponent } from './list/all-deals/all-deals.component';
import { ViewCommissionsComponent } from './list/view-commissions/view-commissions.component';
import { CharityRewardComponent } from './list/charity-reward/charity-reward.component';
import { RpCommissionsComponent } from './list/rp-commissions/rp-commissions.component';
import { SponsoredRpsComponent } from './list/sponsored-rps/sponsored-rps.component';
import { ReferralRewardsComponent } from './list/referral-rewards/referral-rewards.component';

// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// ONBoarding

import { IsvOnboardingComponent } from './add/Onboarding/isv-onboarding/isv-onboarding.component';
import { GettingStartedComponent } from './add/Onboarding/GS-STEP1/getting-started/getting-started.component';
import { TargetDomainSubdomainComponent } from './add/Onboarding/GS-STEP1/target-domain-subdomain/target-domain-subdomain.component';
import { TargetGeographiesComponent } from './add/Onboarding/GS-STEP1/target-geographies/target-geographies.component';
import { TargetIndustriesComponent } from './add/Onboarding/GS-STEP1/target-industries/target-industries.component';
import { TargetRolesComponent } from './add/Onboarding/GS-STEP1/target-roles/target-roles.component';
import { CustomerRefComponent } from './add/Onboarding/GS-STEP2/customer-ref/customer-ref.component';
import { PrevoiusUsExpComponent } from './add/Onboarding/GS-STEP2/prevoius-us-exp/prevoius-us-exp.component';
import { ProductDetailsComponent } from './add/Onboarding/GS-STEP2/product-details/product-details.component';
import { RoiPaybackComponent } from './add/Onboarding/GS-STEP2/roi-payback/roi-payback.component';
import { TargetSizeCompComponent } from './add/Onboarding/GS-STEP2/target-size-comp/target-size-comp.component';
import { SalesGetStartedComponent } from './add/Onboarding/GS-STEP3/sales-get-started/sales-get-started.component';
import { IsvOnboardingProgressTrackerComponent } from './add/Onboarding/isv-onboarding-progress-tracker/isv-onboarding-progress-tracker.component';
import { CustomBrowserAlertComponent } from './components/custom-browser-alert/custom-browser-alert.component';
import { SidebarComponent } from './add/Onboarding/sidebar/sidebar.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { SponsorARpComponent } from './list/sponsor-a-rp/sponsor-a-rp.component';
import { ConversionPipe } from './conversion.pipe';

@NgModule({
  declarations: [
    AddAccountComponent,
    AddIsvComponent,
    AddRpComponent,
    AddProductComponent,
    AllRpComponent,
    AllIsvComponent,
    AllAccountComponent,
    AllProductComponent,
    HeaderComponent,
    FooterComponent,
    WsmNetworksComponent,
    IntroRequestsComponent,
    IntroproposalRpComponent,
    IntroproposalIsvComponent,
    AllOpportunitiesComponent,
    AllDealsComponent,
    ViewCommissionsComponent,
    CharityRewardComponent,
    RpCommissionsComponent,
    SponsoredRpsComponent,
    ReferralRewardsComponent,
    // ONBOARDING
    IsvOnboardingComponent,
    GettingStartedComponent,
    TargetDomainSubdomainComponent,
    TargetGeographiesComponent,
    TargetIndustriesComponent,
    TargetRolesComponent,
    CustomerRefComponent,
    PrevoiusUsExpComponent,
    ProductDetailsComponent,
    RoiPaybackComponent,
    TargetSizeCompComponent,
    SalesGetStartedComponent,
    IsvOnboardingProgressTrackerComponent,
    SidebarComponent,
    SponsorARpComponent,
    ConversionPipe,
    CustomBrowserAlertComponent,
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    SharedModule,
    NgxPaginationModule,
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    // InfiniteScrollModule,
    // NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [HeaderComponent],
})
export class LayoutModule {}
