<!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
  <div class="example-box" *ngFor="let movie of movies" cdkDrag>{{movie}}</div>
</div> -->
<div class="panel panel-primary setup-content" id="step-8">
  <section class="mail-verify lets">
    <div class="alert-box lets-alert">
      <div class="alert alert-warning" role="alert">
        <p>
          <img src="../assets/images/idea.png" class="mr-1" alt="" /> Let's now
          finish up the whole GSA framework by understanding more about your
          overall sales process. This will give us a target horizon for the
          closing of your first deals, make the interactions with prospects more
          fluid, as WholeSoft Market team handholds you during the first cycles
        </p>
      </div>
    </div>

    <div class="max-width680">
      <div class="condition-para mt-40">
        <div class="sign-head text-center">
          <h5>
            Help us understand your overall sales process with a prospect
            <span class="ml-0">
              <button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or
                        decision maker;  The BEST ENTRY POINT person could well
                        become your eventual main Champion"
              >
                <img src="../assets/images/info.svg" />
              </button>
            </span>
          </h5>
        </div>
        <div class="alert-box lets-alert trans">
          <div class="alert alert-warning" role="alert">
            <p class="mb-2">
              Describe your typical sales process until decision is taken to
              introduce you to procurement or vendor registration.
            </p>
            <p>
              <img src="../assets/images/idea.png" class="mr-1" alt="" />You can
              add new stages, delete stages or move them to change their
              sequence to fit your organizations sales process
            </p>
          </div>
        </div>
        <form [formGroup]="salesProcessForm">
          <div class="add-box">
            <div class="box-table upper">
              <table>
                <thead>
                  <tr>
                    <th class="first">STAGE #</th>
                    <th class="second">Stage Name</th>
                    <th class="thired">Involved Stakeholder</th>
                  </tr>
                </thead>

                <tbody
                  formArrayName="salesProcess"
                  cdkDropList
                  (cdkDropListDropped)="drop($event)"
                >
                  <tr
                    *ngFor="
                      let ref of createSalesControl().controls;
                      let i = index
                    "
                    [formGroupName]="i"
                    cdkDrag
                  >
                    <td>
                      <div style="cursor: move" class="form-group d-flex">
                        <div class="points drag_icon">
                          <div class="point-box">
                            <span></span>
                          </div>
                          <div class="point-box">
                            <span></span>
                          </div>
                          <div class="point-box">
                            <span></span>
                          </div>
                          <div class="point-box">
                            <span></span>
                          </div>
                          <div class="point-box">
                            <span></span>
                          </div>
                          <div class="point-box">
                            <span></span>
                          </div>
                          <div class="point-box">
                            <span></span>
                          </div>
                          <div class="point-box">
                            <span></span>
                          </div>
                        </div>
                        <div class="form-control text-center drag_1">{{i+1}}</div>
                        <!-- <input
                          type="text" disabled
                          formControlName="stage"
                          class="form-control text-center drag_1"
                          placeholder=""
                        /> -->
                      </div>
                    </td>
                    <td>
                      <div class="form-group">
                        <input
                          type="text"
                          formControlName="stageName"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group select-fa sal d-flex fa-sale">
                        <select
                          required=""
                          formControlName="stakeHolder"
                          class="form-control"
                        >
                          <option
                            *ngFor="let item of stakeHolder"
                            value="{{ item._id }}"
                          >
                            {{ item.value }}
                          </option>
                        </select>
                        <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
                        <i *ngIf="!(i > 0)"
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i
                        >
                        <i
                          *ngIf="i > 0"
                          (click)="removeStages(i)"
                          class="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="add-new">
                      <button (click)="addStages()" class="btn">
                        + Add New
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-field sign-form">
            <div class="form-sec sales-pro domain mb-15">
              <div class="row">
                <div class="col-12">
                  <div class="form-group mb-1">
                    <p class="formp">
                      What are the typical timeframes for a Free Trial / POC /
                      Pilot (as appropriate) in weeks?
                    </p>
                    <label for="exampleInputPassword1">Free Trial</label>
                    <input
                      type="number"
                      formControlName="freeTrial"
                      class="form-control"
                      placeholder=""
                    />
                    <div
                      *ngIf="
                        salesProcessForm.get('freeTrial')?.errors &&
                        salesProcessForm.get('freeTrial')?.invalid &&
                        (salesProcessForm.get('freeTrial')?.touched ||
                          salesProcessForm.get('freeTrial')?.dirty)
                      "
                      class="text-danger mt-2"
                    ></div>
                  </div>
                  <div class="form-group mb-1">
                    <label for="exampleInputPassword1">POC</label>
                    <input
                      type="number"
                      formControlName="poc"
                      class="form-control"
                      placeholder=""
                    />
                    <div
                      *ngIf="
                        salesProcessForm.get('poc')?.errors &&
                        salesProcessForm.get('poc')?.invalid &&
                        (salesProcessForm.get('poc')?.touched ||
                          salesProcessForm.get('poc')?.dirty)
                      "
                      class="text-danger mt-2"
                    ></div>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Pilot</label>
                    <input
                      type="number"
                      formControlName="pilot"
                      class="form-control"
                      placeholder=""
                    />
                    <p
                      *ngIf="
                        (f.pilot.touched ||
                          f.poc.touched ||
                          f.freeTrial.touched) &&
                        !(f.pilot.value || f.poc.value || f.freeTrial.value)
                      "
                      class="text-danger mt-2"
                    >
                      *One Out of Pilot /POC/ FreeTrial is required
                    </p>
                    <div
                      *ngIf="
                        salesProcessForm.get('pilot')?.errors &&
                        salesProcessForm.get('pilot')?.invalid &&
                        (salesProcessForm.get('pilot')?.touched ||
                          salesProcessForm.get('pilot')?.dirty)
                      "
                      class="text-danger mt-2"
                    ></div>
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      If training is required, what is the standard training
                      duration in weeks?
                    </p>
                    <label for="exampleInputPassword1"
                      >Standard training duration
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="training"
                      placeholder=""
                    />
                  </div>
                  <div class="form-group mb-1">
                    <p class="formp">
                      What are the typical timeframe & resources needed for
                      product implementation (excluding training)?
                    </p>
                    <label for="exampleInputPassword1"
                      >Implementation Duration</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="implementationDuration"
                      placeholder="E.g. 3 weeks"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1"
                      >Implementation Resources</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="implementationResource"
                      placeholder="E.g. 1 FTE - Internal resources"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <p *ngIf="errMsg" class="text-danger">{{ errMsg }}</p>
        <div class="theme-btn dom width-fit text-right">
          <button
            (click)="addSalesProcess()"
            class="btn nextBtn"
            [disabled]="
              salesProcessForm.invalid ||
              ((f.pilot.touched || f.poc.touched || f.freeTrial.touched) &&
                !(f.pilot.value || f.poc.value || f.freeTrial.value))
            "
            [class.active]="
              salesProcessForm.valid ||
              ((f.pilot.touched || f.poc.touched || f.freeTrial.touched) &&
                !(f.pilot.value || f.poc.value || f.freeTrial.value))
            "
            type="button"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- fields -->

<!-- working -->

<!-- <tr *ngFor="let stage of stages;let i=index" cdkDrag>
  <td>
    <div style="cursor: move;" class="form-group d-flex">
      <div class="points">
        <div class="point-box">
          <span></span>
        </div>
        <div class="point-box">
          <span></span>
        </div>
        <div class="point-box">
          <span></span>
        </div>
        <div class="point-box">
          <span></span>
        </div>
        <div class="point-box">
          <span></span>
        </div>
        <div class="point-box">
          <span></span>
        </div>
        <div class="point-box">
          <span></span>
        </div>
        <div class="point-box">
          <span></span>
        </div>
      </div>
      <input type="text" value="{{i+1}}" formControlName="stage" class="form-control text-center"
        placeholder="" />
    </div>
  </td>
  <td>
    <div class="form-group">
      <input type="text" value="{{stage.stageName}}" class="form-control" placeholder="" />
    </div>
  </td>
  <td>

    <div class="form-group select-fa sal d-flex fa-sale">
      <select required="" (change)="selected($event.target.value)" [(ngModel)]="stage.stakeHolder"
        [ngModelOptions]="{standalone: true}" class="form-control">
        <option selected="stage.stakeHolder==item._id" value="{{item._id}}"
          *ngFor="let item of stakeHolder;let i=index">
          {{item.value}}
        </option>
      </select>
      <i class="fa fa-angle-down" aria-hidden="true"></i>
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>
  </td>
</tr> -->

<!-- <tr>
                    <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="2" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="Value prop pitch" class="form-control" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">
                          Technical Validator
                        </option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="3" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="Demo" class="form-control" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">
                          Technical Validator
                        </option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="4" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="Free Trial / POC Go forward with exec sponsorship" class="form-control"
                        placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">
                          Decision maker / Technical validat…
                        </option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="5" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="POC Execution" class="form-control" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">
                          Technical Validator
                        </option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="6" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="Free Trial / POC outcome discussion" class="form-control"
                        placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">
                          Technical ValidatorDecision maker / Technical validat…
                        </option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="7" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="Business case and Proposal creation" class="form-control"
                        placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">
                          Technical Validator
                        </option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="8" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="Go-forward decision " class="form-control" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">Decision Maker</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="9" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="Legal discussion" class="form-control" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">Legal Team</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="10" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="Final negotiation with procurement" class="form-control"
                        placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">
                          Procurement/ Sourcing Team
                        </option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group d-flex">
                      <div class="points">
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                        <div class="point-box">
                          <span></span>
                        </div>
                      </div>
                      <input type="text" value="11" class="form-control text-center" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" value="PO Received/ Sales cycle closed" class="form-control" placeholder="" />
                    </div>
                  </td>
                  <td>
                    <div class="form-group select-fa sal d-flex fa-sale">
                      <select required="" class="form-control" id="exampleFormControlSelect1">
                        <option value="Decision Maker">
                          Procurement/ Sourcing Team
                        </option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="add-new">
                    <button class="btn">+ Add New</button>
                  </td>
                </tr> -->
