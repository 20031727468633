import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DropdownService } from 'src/app/services/dropdown.service';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-target-industries',
  templateUrl: './target-industries.component.html',
  styleUrls: ['./target-industries.component.scss'],
})
export class TargetIndustriesComponent implements OnInit {
  @Input() step: StepModel;

  public productId: any;
  public targetIndustryForm: FormGroup;
  public verticals: any;
  public productName: string;
  public payload: any;
  public toEdit: string;
  public productDetails: any;
  public isTargetAllIndustry: string;

  constructor(
    private stepService: StepsService,
    private shared: CommonService,
    private dropdown: DropdownService,
    private fb: FormBuilder,
    public isv: IsvService,
    public route: ActivatedRoute
  ) {
    this.productId = localStorage.getItem('productId');
    this.productName = localStorage.getItem('productName');
  }

  ngOnInit(): void {
    this.getVerticals();
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');

    this.targetIndustryForm = this.fb.group({
      targetIndustry1: ['', Validators.required],
      targetIndustry2: [''],
      targetIndustry3: [''],
      isTargetAllIndustry: ['', Validators.required],
    });
    if (this.toEdit) {
      this.getProductDetail();
    }
  }

  getProductDetail() {
    this.isv.getSellerProductRegData(this.productId, 1).subscribe(
      (data) => {
        this.productDetails = data?.details?.product;
        // console.log(this.productDetails);

        this.setFormValues();
      },
      (err) => {
        console.log(err);
        this.shared.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  AddTargetIndustries() {
    if (this.targetIndustryForm.invalid) {
      return;
    } else {
      let industryObj = [];
      if (this.targetIndustryForm.value.targetIndustry1 !== '') {
        industryObj.push(this.targetIndustryForm.value.targetIndustry1);
      }
      if (this.targetIndustryForm.value.targetIndustry2 !== '') {
        industryObj.push(this.targetIndustryForm.value.targetIndustry2);
      }
      if (this.targetIndustryForm.value.targetIndustry3 !== '') {
        industryObj.push(this.targetIndustryForm.value.targetIndustry3);
      }
      this.payload = {
        industries: industryObj,
        isTargetAllIndustry: this.targetIndustryForm.value.isTargetAllIndustry,
      };
      this.isv.addTargetIndustry(this.payload, this.productId).subscribe(
        (res) => {
          this.stepService.onNextStep();
        },
        (err) => {
          console.log(err);
          this.shared.showToastMessage(
            'ERROR',
            'All Verticals should be different',
            5000
          );
        }
      );
    }
  }

  setValue(val) {
    if (val) {
      this.isTargetAllIndustry = val;
      this.targetIndustryForm.patchValue({
        isTargetAllIndustry: JSON.parse(val),
      });
    }
  }

  getVerticals() {
    this.dropdown.getIndustry().subscribe(
      (res) => {
        this.verticals = res.details.industry;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // setTarget() {
  //   this.targetIndustryForm.value.isTargetAllIndustry = this.isTargetAllIndustry;
  // }

  get f() {
    return this.targetIndustryForm.controls;
  }

  setFormValues() {
    this.targetIndustryForm.setValue({
      targetIndustry1: this.productDetails.industries[0]?._id
        ? this.productDetails.industries[0]._id
        : '',
      targetIndustry2: this.productDetails.industries[1]?._id
        ? this.productDetails.industries[1]._id
        : '',
      targetIndustry3: this.productDetails.industries[2]?._id
        ? this.productDetails.industries[2]._id
        : '',
      isTargetAllIndustry: this.productDetails.isTargetAllIndustry,
    });
    this.isTargetAllIndustry = JSON.stringify(
      this.productDetails.isTargetAllIndustry
    );
  }
}
