<main class="background-color">
  <section class="edit-profile padding-40 pb-0">
    <form [formGroup]="addNewAccount" (ngSubmit)="addAccount()">
      <div class="container-fluid">
        <div class="profile-sec">
          <div *ngIf="!accountID" class="col-12">
            <div class="profile-p">Add New Account</div>
          </div>
          <div *ngIf="accountID" class="col-12">
            <div class="profile-p">Edit and View Account</div>
          </div>
        </div>
        <div class="profile-details">
          <div class="col-12 top">
            <div class="row">
              <div class="col-lg-7 col-md-6">
                <div class="profile-box">
                  <div class="form-sec mb-0">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row flex-dir">
                          <div class="col-10 is-first">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="accountName"
                                    >Company Name
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <input
                                    type="text"
                                    nbInput
                                    fullWidth
                                    formControlName="accountName"
                                    placeholder="Enter Company Name"
                                    name="accountName"
                                    class="form-control"
                                    id="accountName"
                                    [class.is-invalid]="
                                      f.accountName.invalid &&
                                      f.accountName.touched
                                    "
                                  />
                                  <div
                                    class="text-danger"
                                    *ngIf="
                                      f.accountName.touched &&
                                      f.accountName.errors
                                    "
                                  >
                                    <small
                                      *ngIf="f.accountName.errors.required"
                                    >
                                      *Company Name is required
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="region">Place/Region</label>
                                  <input
                                    type="text"
                                    formControlName="region"
                                    placeholder="Enter Place / Region"
                                    name="region"
                                    class="form-control"
                                    id="region"
                                    [class.is-invalid]="
                                      f.region.invalid && f.region.touched
                                    "
                                  />

                                  <div
                                    class="mb-3"
                                    *ngIf="
                                      addNewAccount.get('region').invalid &&
                                      addNewAccount.get('region').touched
                                    "
                                  >
                                    <small
                                      class="error text-danger"
                                      *ngIf="
                                        addNewAccount.get('region').errors
                                          ?.required
                                      "
                                    >
                                      *Region is required
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <!-- Start -->

                                <div class="form-group select-fa">
                                  <label for="country"
                                    >Country
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <select
                                    class="form-control"
                                    name="country"
                                    id="country"
                                    formControlName="country"
                                    [class.is-invalid]="
                                      f.country.invalid && f.country.touched
                                    "
                                  >
                                    <option value selected disabled hidden>
                                      Select Country
                                    </option>
                                    <option
                                      *ngFor="let i of countryArr"
                                      value="{{ i.value }}"
                                    >
                                      {{ i.value }}
                                    </option>
                                  </select>
                                  <i
                                    class="fa fa-angle-down"
                                    aria-hidden="true"
                                  ></i>
                                  <div
                                    class="mb-3"
                                    *ngIf="
                                      addNewAccount.get('country').invalid &&
                                      addNewAccount.get('country').touched
                                    "
                                  >
                                    <small
                                      class="error text-danger"
                                      *ngIf="
                                        addNewAccount.get('country').errors
                                          ?.required
                                      "
                                    >
                                      * Country is required
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-2 is-second">
                            <div class="profile d-block">
                              <p class="head-box-p">Add Photo</p>
                              <div class="profile-img-top squre-top">
                                <div *ngIf="!imgCompURL">
                                  <div *ngIf="!imgCompCon">
                                    <div class="profile-img squre ad-isv mb-2">
                                      <img
                                        src="../assets/images/company.png"
                                        alt="Company Image"
                                        class="image"
                                      />
                                    </div>
                                  </div>
                                  <div *ngIf="imgCompCon">
                                    <div class="profile-img squre ad-isv mb-2">
                                      <img
                                        src="{{ staticDataUrl }}{{
                                          accountDetails?.company?.logo
                                        }}"
                                        alt="Company Image"
                                        class="image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="imgCompURL">
                                  <div class="profile-img squre ad-isv mb-2">
                                    <img
                                      *ngIf="imgCompURL"
                                      [src]="imgCompURL"
                                      alt="Company Image"
                                      class="image"
                                    />
                                  </div>
                                </div>
                                <div class="fa-penci isv-fa">
                                  <i class="fa fa-pencil"></i>
                                  <input
                                    #fille
                                    type="file"
                                    formControlName="companyImage"
                                    class="file"
                                    (change)="selectCompanyFile($event)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="website"
                            >Website <sup class="text-danger">*</sup></label
                          >
                          <input
                            type="text"
                            formControlName="website"
                            name="website"
                            class="form-control"
                            id="website"
                            placeholder="https://www.example.com"
                            [class.is-invalid]="
                              f.website.invalid && f.website.touched
                            "
                          />
                          <div
                            class="mb-3"
                            *ngIf="
                              addNewAccount.get('website').invalid &&
                              addNewAccount.get('website').touched
                            "
                          >
                            <small
                              class="error text-danger"
                              *ngIf="
                                addNewAccount.get('website').errors?.required
                              "
                            >
                              * Website is required
                            </small>
                            <small
                              class="error text-danger"
                              *ngIf="
                                addNewAccount.get('website').errors?.pattern
                              "
                            >
                              *Please Provide a valid website
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="linkedinUrl"
                            >LinkedIn Profile
                            <sup class="text-danger">*</sup>
                          </label>
                          <input
                            type="text"
                            formControlName="linkedinUrl"
                            class="form-control"
                            id="linkedinUrl"
                            value=""
                            [class.is-invalid]="
                              f.linkedinUrl.invalid && f.linkedinUrl.touched
                            "
                             (blur)="checkLinkedin('COMPANY_LINKEDIN', $event)"
                          />
                          <div
                            class="mb-3"
                            *ngIf="
                              addNewAccount.get('linkedinUrl').invalid &&
                              addNewAccount.get('linkedinUrl').touched
                            "
                          >
                            <small
                              class="error text-danger"
                              *ngIf="
                                addNewAccount.get('linkedinUrl').errors
                                  ?.required
                              "
                            >
                              * Linkedin Profile is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <!-- Company Status -->
                      <div class="col-12">
                        <div class="form-group select-fa">
                          <label for="companyStatus"
                            >Company Status<sup class="text-danger"
                              >*</sup
                            ></label
                          >
                          <select
                            class="form-control"
                            id="companyStatus"
                            formControlName="companyStatus"
                            name="companyStatus"
                            [class.is-invalid]="
                              f.companyStatus.invalid && f.companyStatus.touched
                            "
                          >
                            <!-- <option value="" selected disabled hidden>
                              Select Status of Company
                            </option> -->
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                          <div
                            class="text-danger"
                            *ngIf="
                              f.companyStatus.touched && f.companyStatus.errors
                            "
                          >
                            <small *ngIf="f.companyStatus.errors.required">
                              *Company Status is required
                            </small>
                            <small *ngIf="f.companyStatus.errors.pattern">
                              *Company Status is Not Valid
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group select-fa">
                          <label for="industry1"
                            >Industry <sup class="text-danger">*</sup></label
                          >
                          <select
                            class="form-control"
                            id="industry1"
                            formControlName="industriesOne"
                            name="industriesOne"
                            [class.is-invalid]="
                              f.industriesOne.invalid && f.industriesOne.touched
                            "
                          >
                            <option value selected disabled hidden>
                              Select Industry
                            </option>

                            <option
                              *ngFor="let i of industryArr"
                              value="{{ i._id }}"
                            >
                              {{ i.value }}
                            </option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                          <div
                            class="text-danger"
                            *ngIf="
                              f.industriesOne.touched && f.industriesOne.errors
                            "
                          >
                            <small *ngIf="f.industriesOne.errors.required">
                              *Industry is required
                            </small>
                            <small *ngIf="f.industriesOne.errors.pattern">
                              *Industry is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group select-fa">
                          <label for="size"
                            >Company Size
                            <sup class="text-danger">*</sup></label
                          >
                          <select
                            class="form-control"
                            formControlName="size"
                            name="size"
                            id="size"
                            [class.is-invalid]="
                              f.size.invalid && f.size.touched
                            "
                          >
                            <option value selected disabled hidden>
                              Select Company Size
                            </option>
                            <option
                              *ngFor="let i of companySizeArr"
                              value="{{ i._id }}"
                            >
                              {{ i.value }}
                            </option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>

                          <div
                            class="text-danger"
                            *ngIf="f.size.touched && f.size.errors"
                          >
                            <small *ngIf="f.size.errors.required">
                              *Company Size is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="founded">Founded</label>
                          <input
                            type="text"
                            name="founded"
                            formControlName="founded"
                            class="form-control"
                            placeholder="Founded in"
                            id="founded"
                            [class.is-invalid]="
                              f.founded.invalid && f.founded.touched
                            "
                          />
                          <div
                            class="text-danger"
                            *ngIf="f.founded.touched && f.founded.errors"
                          >
                            <small *ngIf="f.founded.errors.required">
                              *Founded is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="about">About</label>
                          <textarea
                            type="text"
                            formControlName="about"
                            name="about"
                            class="form-control"
                            placeholder="About company"
                            id="about"
                            [class.is-invalid]="
                              f.about.invalid && f.about.touched
                            "
                          ></textarea>
                          <div
                            class="text-danger"
                            *ngIf="f.about.touched && f.about.errors"
                          >
                            <small *ngIf="f.about.errors.required">
                              *About is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="specialities">Specialities</label>
                          <textarea
                            type="text"
                            formControlName="specialities"
                            class="form-control"
                            id="specialities"
                            placeholder="Enter Specialities"
                            [class.is-invalid]="
                              f.specialities.invalid && f.specialities.touched
                            "
                          ></textarea>
                          <div
                            class="text-danger"
                            *ngIf="
                              f.specialities.touched && f.specialities.errors
                            "
                          >
                            <small *ngIf="f.specialities.errors.required">
                              *Specialities is required
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 col-md-6" *ngIf="accountID">
                <div class="row">
                  <div class="col-12">
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="statusAccount">Status</label>
                              <div class="select-fa">
                                <select
                                  class="form-control"
                                  formControlName="status"
                                  id="statusAccount"
                                  [class.is-invalid]="
                                    f.status.invalid && f.status.touched
                                  "
                                >
                                  <option
                                    *ngFor="let i of filteredStatusArr"
                                    value="{{ i._id }}"
                                  >
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                                <div
                                  class="text-danger"
                                  *ngIf="f.status.touched && f.status.errors"
                                >
                                  <small *ngIf="f.status.errors.required">
                                    *Account Status is required
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="head-box-check pos verify">
                                  <div
                                    class="custom-control p-0 custom-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="emailVerified"
                                      name="emailVerified"
                                      formControlName="emailVerified"
                                      (click)="detectChangeTrue($event)"
                                    />
                                    <label
                                      class="custom-control-label mb-0"
                                      for="emailVerified"
                                      >Email Verified</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12" *ngIf="accountID">
                            <div class="form-group">
                              <label for="createdBy">Created By</label>
                              <input
                                type="text"
                                formControlName="createdBy"
                                class="form-control"
                                id="createdBy"
                                readonly
                                aria-describedby="createdBy"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12" *ngIf="accountID">
                            <div class="form-group">
                              <label for="createdDate">Created Date</label>
                              <input
                                type="date"
                                readonly
                                class="form-control"
                                formControlName="createdDate"
                                id="createdDate"
                                aria-describedby="createdDate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <p class="head-box-p">Financial Year</p>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div class="select-fa">
                                <label for="startFinancialYear">Start</label>

                                <select
                                  formControlName="start"
                                  name="start"
                                  class="form-control"
                                  id="startFinancialYear"
                                  (ngModelChange)="setEndMonth($event)"
                                  [class.is-invalid]="
                                    f.start.invalid && f.start.touched
                                  "
                                >
                                  <option value selected disabled hidden>
                                    Select Start
                                  </option>
                                  <option
                                    *ngFor="let i of monthsArr"
                                    [hidden]="monthsArr.indexOf(i) % 3 != 0"
                                    value="{{ i._id }}"
                                  >
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>

                                <div
                                  class="text-danger"
                                  *ngIf="f.start.touched && f.start.errors"
                                >
                                  <small *ngIf="f.start.errors.required">
                                    *Year Start is required
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div class="select-fa">
                                <label for="end">End</label>

                                <select
                                  formControlName="end"
                                  class="form-control"
                                  id="end"
                                  [class.is-invalid]="
                                    f.end.invalid && f.end.touched
                                  "
                                >
                                  <option value selected disabled hidden>
                                    Select End
                                  </option>
                                  <option
                                    *ngFor="let i of monthsArr"
                                    value="{{ i._id }}"
                                  >
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>

                                <div
                                  class="text-danger"
                                  *ngIf="f.end.touched && f.end.errors"
                                >
                                  <small *ngIf="f.end.errors.required">
                                    *Year End is required
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="row flex-dir ac-dir">
                              <div class="col-10 is-first new">
                                <div class="row">
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label for="firstName">First Name</label>
                                      <input
                                        type="text"
                                        placeholder="Enter First Name"
                                        class="form-control"
                                        id="firstName"
                                        name="firstName"
                                        formControlName="firstName"
                                        aria-describedby="emailHelp"
                                        (change)="checkFormValid($event)"
                                        [class.is-invalid]="
                                          f.firstName.invalid &&
                                          f.firstName.touched
                                        "
                                      />
                                      <div
                                        class="text-danger"
                                        *ngIf="
                                          f.firstName.touched &&
                                          f.firstName.errors
                                        "
                                      >
                                        <small
                                          *ngIf="f.firstName.errors.required"
                                        >
                                          *First Name is required
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label for="lastName">Last Name</label>
                                      <input
                                        type="text"
                                        name="lastName"
                                        placeholder="Enter Last Name"
                                        formControlName="lastName"
                                        class="form-control"
                                        id="lastName"
                                        (change)="checkFormValid($event)"
                                        [class.is-invalid]="
                                          f.lastName.invalid &&
                                          f.lastName.touched
                                        "
                                      />
                                      <div
                                        class="text-danger"
                                        *ngIf="
                                          f.lastName.touched &&
                                          f.lastName.errors
                                        "
                                      >
                                        <small
                                          *ngIf="f.lastName.errors.required"
                                        >
                                          *Last Name is required
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="form-group">
                                      <label for="email">Work Email</label>
                                      <input
                                        type="email"
                                        placeholder="Enter Email"
                                        class="form-control"
                                        formControlName="email"
                                        class="form-control"
                                        id="email"
                                        (change)="checkFormValid($event)"
                                        [class.is-invalid]="
                                          f.email.invalid && f.email.touched
                                        "
                                      />
                                      <div
                                        class="text-danger"
                                        *ngIf="
                                          f.email.touched && f.email.errors
                                        "
                                      >
                                        <small *ngIf="f.email.errors.required">
                                          *Email is required
                                        </small>
                                        <small *ngIf="f.email.errors.pattern">
                                          *Please enter a valid Email
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-2 is-second new">
                                <div class="profile d-block">
                                  <p class="head-box-p">Add Photo</p>
                                  <!-- <div class="profile-img-top squre-top">
                                    <div class="profile-img squre mr-0">
                                      <img
                                        src="../assets/images/testing.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div class="fa-penci isv-fa new">
                                      <i class="fa fa-pencil"></i>
                                      <input type="file" />
                                    </div>
                                  </div> -->
                                  <!-- Primary Contact Image Start Here -->
                                  <div class="profile-img-top">
                                    <div>
                                      <div *ngIf="!imgUserURL">
                                        <div *ngIf="!imgUserCon">
                                          <div class="profile-img">
                                            <img
                                              src="../assets/images/user.png"
                                              class="image"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                        <div *ngIf="imgUserCon">
                                          <div class="profile-img">
                                            <img
                                              src="{{ staticDataUrl }}{{
                                                accountDetails?.userExist
                                                  ?.userId?.userImage
                                              }}"
                                              class="image"
                                              alt="userImage"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div *ngIf="imgUserURL">
                                        <div class="profile-img">
                                          <img
                                            *ngIf="imgUserURL"
                                            [src]="imgUserURL"
                                            class="image"
                                            alt="userImage"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="fa-penci isv-fa new">
                                      <i class="fa fa-pencil"></i>
                                      <input
                                        type="file"
                                        #file
                                        formControlName="userImage"
                                        (change)="
                                          selectUserFile($event);
                                          previewUserImage(file.files)
                                        "
                                      />
                                    </div>
                                  </div>
                                  <!-- Primary Contact Image End Here -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group select-fa">
                              <label for="jobTitle">Job Title</label>
                              <!-- <select
                                class="form-control"
                                id="jobTitle"
                              >
                                <option selected value="" disabled hidden>Select Job Title</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                              <i
                                class="fa fa-angle-down"
                                aria-hidden="true"
                              ></i> -->

                              <input
                                type="text"
                                formControlName="jobTitle"
                                placeholder="Enter Job Title"
                                class="form-control"
                                id="jobTitle"
                                (change)="checkFormValid($event)"
                                [class.is-invalid]="
                                  f.jobTitle.invalid && f.jobTitle.touched
                                "
                              />

                              <div
                                class="text-danger"
                                *ngIf="f.jobTitle.touched && f.jobTitle.errors"
                              >
                                <small *ngIf="f.jobTitle.errors.required">
                                  *Job Title is required
                                </small>
                              </div>

                              <!-- Job TITLE END -->
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="linkedinProfile"
                                >Linkedin Profile</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Linkedin URL"
                                formControlName="userLinkedin"
                                name="linkedinProfile"
                                id="linkedinProfile"
                                [class.is-invalid]="
                                  f.userLinkedin.invalid &&
                                  f.userLinkedin.touched
                                "
                              />
                              <div
                                class="text-danger"
                                *ngIf="
                                  f.userLinkedin.touched &&
                                  f.userLinkedin.errors
                                "
                              >
                                <small *ngIf="f.userLinkedin.errors.required">
                                  *Linkedin is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="mobile">Mobile Number</label>
                              <input
                                type="text"
                                formControlName="mobile"
                                class="form-control"
                                placeholder="Contact Number"
                                name="mobile"
                                id="mobile"
                                [class.is-invalid]="
                                  f.mobile.invalid && f.mobile.touched
                                "
                                minlength="8"
                                maxlength="15"
                              />
                              <div *ngIf="f.mobile.invalid && f.mobile.touched">
                                <small
                                  class="text-danger"
                                  *ngIf="f.mobile.errors?.required"
                                >
                                  *Mobile Number is required
                                </small>

                                <small
                                  class="text-danger"
                                  *ngIf="
                                    addNewAccount.get('mobile').errors?.pattern
                                  "
                                >
                                  *Mobile number can only be numeric
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- :TODO: *ngIf="accountID" -->
        <div class="profile-details ac-sec mt-40" *ngIf="accountID">
          <div class="prof-r-box">
            <div class="para-two add-para">
              <h2>Charity Preferences</h2>
              <p>
                As part of WholeSoft Market commitment to philanthropy,
                WholeSoft will pay to charities an amount in $, corresponding to
                the rewards of
                {{
                  addNewAccount.value.accountName ||
                    addNewAccount.getRawValue().accountName
                }}
                accumulated on the WSM platform
              </p>
            </div>
            <div class="profile-box ac-pr-box">
              <div class="prof-radio one">
                <div class="form-group height">
                  <label class="ac-radio"
                    >Let WholeSoft Market decide which Charity your reward will
                    be paid to
                    <input
                      type="radio"
                      name="isSystemSelectCharity"
                      formControlName="isSystemSelectCharity"
                      [value]="true"
                      (click)="hideCharityOption()"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <div class="prof-radio">
                <div class="form-group height">
                  <label class="ac-radio"
                    >Choose the Charity to which your reward will be paid to
                    <input
                      type="radio"
                      [value]="false"
                      name="isSystemSelectCharity"
                      formControlName="isSystemSelectCharity"
                      (click)="showCharityOption()"
                    />
                    <span class="checkmark" aria-checked="true"></span>
                  </label>
                </div>
                <div class="radio-select-box new" *ngIf="!disableSelectCharity">
                  <div class="form-group">
                    <div class="form-one">
                      <label for="exampleFormControlSelect1"
                        >Charity you would like to give to</label
                      >
                    </div>
                    <div class="form-two">
                      <!-- <div class="select-fa">
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                        >
                          <option value="" disabled="" selected="" hidden="">
                            Make a wish illinios
                          </option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </select>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div> -->

                      <mat-form-field class="width-big" appearance="outline">
                        <mat-label>Select Charity</mat-label>
                        <mat-select formControlName="charity" id="charity">
                          <mat-option
                            *ngFor="let i of charityArr"
                            value="{{ i._id }}"
                            (click)="getCharityValue(i.value)"
                          >
                            <img
                              src="{{ staticDataUrl }}{{ i.logo }}"
                              width="20px"
                              height="20px"
                            />&nbsp;&nbsp; {{ i.value }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="radio-box-sec"
                *ngIf="!disableSelectCharity && afterCharitySelect"
              >
                <div class="profile-box ac-pr-box">
                  <div class="prof-radio one">
                    <div class="form-group height">
                      <label class="ac-radio"
                        >Let WholeSoft Market pay to your chosen Charity
                        directly
                        <input
                          name="isSystemPayDirectly"
                          formControlName="isSystemPayDirectly"
                          [value]="true"
                          (click)="hidePayCharityOption()"
                          type="radio"
                          name="isSystemPayDirectly"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="prof-radio">
                    <div class="form-group height">
                      <label class="ac-radio"
                        >Have WholeSoft Market transfer the money to
                        {{
                          addNewAccount.value.accountName ||
                            addNewAccount.getRawValue().accountName
                        }}
                        so that it pays {{ charityValue }}. <br />
                        [Here {{ charityValue }} will be informed by WholeSoft
                        Market of the amount
                        {{
                          addNewAccount.value.accountName ||
                            addNewAccount.getRawValue().accountName
                        }}
                        has commited to donate to them]

                        <input
                          name="isSystemPayDirectly"
                          formControlName="isSystemPayDirectly"
                          [value]="false"
                          (click)="showPayCharityOption()"
                          type="radio"
                          name="isSystemPayDirectly"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="add-ac-form mt-20" *ngIf="disablePayCharity">
                      <div class="form-sec">
                        <p class="formp">
                          Name & Email address of Person to contact at
                          {{ addNewAccount.value.accountName }} for Donation
                        </p>
                        <div class="col-12 p-0">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="nameNTC">Name</label>
                                <input
                                  formControlName="donationContactName"
                                  name="donationContactName"
                                  type="text"
                                  class="form-control"
                                  id="nameNTC"
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="emailNTC">Email</label>
                                <input
                                  formControlName="donationContactEmail"
                                  name="donationContactEmail"
                                  type="text"
                                  class="form-control"
                                  id="emailNTC"
                                />
                                <div
                                  class="text-danger"
                                  *ngIf="
                                    f.donationContactEmail.touched &&
                                    f.donationContactEmail.errors
                                  "
                                >
                                  <small
                                    *ngIf="
                                      f.donationContactEmail.errors.required
                                    "
                                  >
                                    *Email is required
                                  </small>
                                  <small
                                    *ngIf="
                                      f.donationContactEmail.errors.pattern
                                    "
                                  >
                                    *Please enter a valid Email
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-sec">
                        <p class="formp">
                          Name & Email address of Global Head of Procurement
                        </p>
                        <div class="col-12 p-0">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="nameGH">Name</label>
                                <input
                                  formControlName="globalProcurementHeadName"
                                  name="globalProcurementHeadName"
                                  type="email"
                                  class="form-control"
                                  id="nameGH"
                                />
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label for="emailGH">Email</label>
                                <input
                                  formControlName="globalProcurementHeadEmail"
                                  name="globalProcurementHeadEmail"
                                  type="email"
                                  class="form-control"
                                  id="emailGH"
                                />
                                <div
                                  class="text-danger"
                                  *ngIf="
                                    f.globalProcurementHeadEmail.touched &&
                                    f.globalProcurementHeadEmail.errors
                                  "
                                >
                                  <small
                                    *ngIf="
                                      f.globalProcurementHeadEmail.errors
                                        .required
                                    "
                                  >
                                    *Email is required
                                  </small>
                                  <small
                                    *ngIf="
                                      f.globalProcurementHeadEmail.errors
                                        .pattern
                                    "
                                  >
                                    *Please enter a valid Email
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="theme-btn width-fit mt-40 footer-btn blue text-center">
        <button class="btn mr-1 regu yellow" [disabled]="!addNewAccount.valid">
          SAVE
        </button>
        <button class="btn regu" (click)="cancelBtn()" type="button">
          <!-- [routerLink]="['/admin/all-account']" -->
          CANCEL
        </button>
      </div>
    </form>
  </section>
</main>
