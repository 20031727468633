import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { CommonService } from 'src/app/utils/common.service';
import { DatePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-introproposal-isv',
  templateUrl: './introproposal-isv.component.html',
  styleUrls: ['./introproposal-isv.component.scss'],
})
export class IntroproposalIsvComponent implements OnInit {
  countPerPageText = '10 Per Page';
  p: any = 1;
  propStatusFilText: string = 'Proposal Status';
  isvFilText: string = 'ISV';
  psRecievedFilText: string = 'Proposal Received';
  rpFilText: string = 'High Level Connector';
  accountFilText: string = 'Account';
  introStatusFilText: string = 'Intro Status';

  resArr: any = [];
  totalPage: any;
  totalPageArr: any[] = [];
  rpArr: any = [];
  isvArr: any = [];
  accArr: any = [];
  statusArr: any = [];
  proposalRecArr: any = [];
  introStatusArr: any = [];

  searchTerm: FormControl = new FormControl();
  elementActive: any;

  filters = {
    count: '10',
    days: '',
    skip: '0',
    status: '',
    rp: '',
    isv: '',
    introStatus: '',
    account: '',
    searchPattern: '',
    singleProposal: '',
  };

  introReqArr: any;
  introPropArr: any;

  propsalId: string = null;

  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    public sharedService: SharedServiceService,
    private actRoute: ActivatedRoute
  ) {
    this.common.showHeader.next(true);

    this.propsalId = this.actRoute.snapshot.queryParams['proposal'];
  }

  ngOnInit(): void {
    this.fetchIntroProposalForIsv();
    this.refPartner();
    this.getISVDropDown();
    this.introStatusDropdownForIntroProposals();
    this.introProposalStatus();
    this.getAllAccountDropdown();

    this.searchTerm.valueChanges
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe(
        (term) => {
          if (term) {
            this.filters.searchPattern = term;
            this.filters.count = this.filters.count
              ? `${this.filters.count}`
              : `${0}`;
            this.filters.skip = '0';
            this.fetchIntroProposalForIsv();
          } else if (term === '') {
            this.filters.searchPattern = '';
            this.fetchIntroProposalForIsv();
          }
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;
          this.common.showToastMessage(`INFO`, `Opps... Something went wrong`);
        }
      );
  }

  fetchIntroProposalForIsv() {
    if (!!this.propsalId) {
      this.filters.singleProposal = this.propsalId;
    }

    if (!!this.filters.searchPattern) {
      delete this.filters.singleProposal;
    }

    this.getData.getIntroProposalsForRPAndISV(this.filters).subscribe(
      (res) => {
        this.resArr = res.details.data;
        this.totalPage = res.details.total;
        if (this.totalPage === 0) {
          this.common.showToastMessage('ERROR', 'No Records Found', 6000);
        }
        return;
      },
      (err) => {
        this.resArr = [];
        this.totalPage = 0;
        this.common.showToastMessage(`ERROR`, `No Records Found`, 6000);
        return;
      }
    );
  }

  checkVal(opt, e, accVal) {
    const searchBar: any = document.getElementById('searchBar');
    if (searchBar.value === '') {
      this.filters.searchPattern = '';
    }

    if (opt === '10PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (opt === '25PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (opt === '50PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }

    if (opt === 'RP') {
      this.rpFilText = e ? `${e.firstName} ${e.lastName}` : 'High Level Connector';
      this.filters.rp = e._id;
    }
    if (opt === 'ISV') {
      this.isvFilText = accVal;
      this.filters.isv = e;
    }
    if (opt === 'PROPSTATUS') {
      this.propStatusFilText = accVal;
      this.filters.status = e;
    }
    if (opt === 'INTROSTATUS') {
      this.introStatusFilText = accVal;
      this.filters.introStatus = e;
    }
    if (opt === 'ACCOUNT') {
      this.accountFilText = accVal;
      this.filters.account = e;
    }

    if (opt === 'CLEARDAYS') {
      this.psRecievedFilText = accVal;
      this.filters.days = e;
    }
    if (opt === '7DAYS') {
      this.psRecievedFilText = accVal;
      this.filters.days = e;
    }
    if (opt === '30DAYS') {
      this.psRecievedFilText = accVal;
      this.filters.days = e;
    }
    if (opt === '90DAYS') {
      this.psRecievedFilText = accVal;
      this.filters.days = e;
    }

    this.filters.skip = '0';

    this.fetchIntroProposalForIsv();
  }

  pageChange(page) {
    this.p = page;
    const tempSkipVar = (page - 1) * parseInt(this.filters.count);
    this.filters.skip = `${tempSkipVar}`;

    const searchBar: any = document.getElementById('searchBar');
    if (searchBar.value === '') {
      this.filters.searchPattern = '';
    }

    this.fetchIntroProposalForIsv();
  }

  dateDiffCalc(introReq) {
    // if (introReq?.status?.value.toLowerCase() !== 'pending') {
    //   return;
    // }
    let givenDate: any;
    let currentDate: any = Date.now();
    var datePipe = new DatePipe('en-IN');

    givenDate = new Date(
      datePipe.transform(introReq?.proposalAcceptanceExpiryDate, 'MM/dd/yyyy')
    );
    currentDate = new Date(datePipe.transform(currentDate, 'MM/dd/yyyy'));

    const diffTime = Math.abs(givenDate - currentDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  async refPartner() {
    const res = await this.dropdown.getRpsList().toPromise();
    this.rpArr = res?.details?.data;
  }

  async getISVDropDown() {
    const res = await this.dropdown.getISVsDropDown().toPromise();
    this.isvArr = res?.details?.isv;
  }

  async introStatusDropdownForIntroProposals() {
    const res = await this.dropdown
      .introStatusDropdownForIntroProposals()
      .toPromise();
    this.introReqArr = res?.details?.status;
  }

  async introProposalStatus() {
    const res = await this.dropdown.introProposalsStatus().toPromise();
    this.introPropArr = res?.details?.status;
  }
  async getAllAccountDropdown() {
    const res = await this.dropdown.getAllAccountDropdown().toPromise();
    this.accArr = res?.details?.accounts;
  }
}
