<div class="panel panel-primary setup-content" id="step-3">
  <section class="customer-ref padding-88">
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center mb-40">
          <h5 class="max-width">
            Customer References and Analyst Coverage
            <span class="ml-0"
              ><button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or
                            decision maker;  The BEST ENTRY POINT person could well
                            become your eventual main Champion"
              >
                <img src="../assets/images/info.svg" /></button
            ></span>
          </h5>
        </div>
        <div class="form-field sign-form">
          <form [formGroup]="custRefForm">
            <div class="form-sec product-det">
              <div formArrayName="reference" class="group-box">
                <div
                  *ngFor="let ref of reference().controls; let i = index"
                  [formGroupName]="i"
                  class="group-in merge"
                >
                  <!-- <p class="formp">
                                        Name some of your best References (customers)?
                                    </p> -->
                  <div class="form-group">
                    <label for="" class="d-lab"
                      >Reference {{ i + 1 }}
                      <a
                        *ngIf="i > 0"
                        href="javascript:void(0)"
                        (click)="removeReference(i)"
                        class="remove"
                        >Remove</a
                      >
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="name"
                      placeholder="Name"
                    />
                  </div>
                  <div class="form-group select-fa">
                    <select
                      required=""
                      formControlName="industry"
                      class="form-control"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Industry
                      </option>
                      <option
                        *ngFor="let industry of industries"
                        value="{{ industry._id }}"
                      >
                        {{ industry.value }}
                      </option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                  <div class="form-group select-fa">
                    <select
                      required=""
                      formControlName="country"
                      class="form-control"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Select Country
                      </option>
                      <option
                        *ngFor="let country of countries"
                        value="{{ country._id }}"
                      >
                        {{ country.value }}
                      </option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>

                <button (click)="addreference()" class="btn btn-form">
                  + Add More
                </button>
              </div>

              <div class="group-box">
                <div class="group-in merge">
                  <p class="formp">
                    Share LinkedIn profiles of sponsors and champions at your 3
                    best customers
                  </p>
                  <div class="form-group">
                    <label for="">Sponsors</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="sponsor1"
                      placeholder="Sponsor1"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="sponsor2"
                      placeholder="Sponsor2"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="sponsor3"
                      placeholder="Sponsor3"
                    />
                  </div>
                </div>
                <div class="group-in merge">
                  <div class="form-group-top">
                    <div class="form-group">
                      <label for="">Champions</label>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="champion1"
                        placeholder="Champion1"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="champion2"
                        placeholder="Champion2"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="champion3"
                        placeholder="Champion3"
                      />
                    </div>
                  </div>
                </div>
                <div class="alert-box">
                  <p class="formp">
                    Do you have IT analyst's reports focused on / mentioning
                    you?
                  </p>
                  <div class="theme-btn width-fit blue">
                    <button
                      class="btn mr-1"
                      [class.selectedbutton]="isITAnalystReport == true"
                      (click)="
                        isITAnalystReport = true;
                        custRefForm.value.isAnalystReport = true
                      "
                    >
                      Yes
                    </button>
                    <button
                      [class.selectedbutton]="isITAnalystReport == false"
                      (click)="
                        isITAnalystReport = false;
                        custRefForm.value.isAnalystReport = false
                      "
                      class="btn"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
              <div
                *ngIf="isITAnalystReport"
                formArrayName="itAnalysisReport"
                class="group-box"
              >
                <div
                  *ngFor="
                    let docs of itAnalysisReport().controls;
                    let i = index
                  "
                  [formGroupName]="i"
                  class="group-in merge"
                >
                  <div class="form-group">
                    <label for="">IT Analyst Report Names {{ i + 1 }}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="text"
                      placeholder="Text"
                    />
                  </div>
                  <div class="form-group cross file-input">
                    <div class="custom-file cross">
                      <input
                        type="file"
                        (change)="fileProgress($event); addFiles($event)"
                        formControlName="filePath"
                        class="custom-file-input form-control"
                        id="customFile"
                      />
                      <i
                        *ngIf="i > 0"
                        class="fa fa-times"
                        aria-hidden="true"
                        (click)="removeFile(i)"
                      >
                      </i>
                      <!-- Previous uploaded files

                      <img
                        *ngIf="uploadedDocs.filePath"
                        src="http://wholesoftbackendphase1.n1.iworklab.com:3224/{{
                          uploadedDocs.filePath
                        }}"
                        alt=""
                      /> -->
                      <label
                        *ngIf="!fileName"
                        class="custom-file-label form-control"
                        for="customFile"
                      >
                        Choose file
                      </label>
                      <label
                        *ngIf="fileName"
                        class="custom-file-label form-control"
                        for="customFile"
                      >
                        {{ docs.controls.filePath.value }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="group-in merge">
                  <!-- <div class="form-group">
										<label for="">IT Analyst Report Names 2</label>
										<input type="text" class="form-control" formControlName="document" placeholder="Text" />
									</div>
									<div class="form-group cross file-input">
										<div class="custom-file cross">
										<input type="file" class="custom-file-input form-control" id="customFile" />
										<i class="fa fa-times" aria-hidden="true"></i>

										<label class="custom-file-label form-control" for="customFile">File_106060</label>
										</div>
									</div> -->
                  <button (click)="addDoc()" class="btn btn-form">
                    + Add More
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="theme-btn dom width-fit text-right">
        <button
          (click)="addCustomerRef()"
          class="btn nextBtn"
          [class.active]="custRefForm.valid"
          [disabled]="custRefForm.invalid"
          type="button"
        >
          NEXT
        </button>
      </div>
    </div>
  </section>
</div>
