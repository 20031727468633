<div
  class="modal left fade show"
  id="exampleModal1"
  tabindex=""
  role="dialog"
  aria-labelledby="exampleModalLabel"
  style="display: block; padding-right: 15px"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header-new mb-30">
        <div class="header-blue">
          <h3>Sponsor a High Level Connector</h3>
        </div>
        <span (click)="close()" data-dismiss="modal" class="cross">
          <img src="../assets/images/cross-white.svg" alt=""
        /></span>
      </div>
      <div class="modal-body">
        <div class="connection-side">
          <h3 class="semi-14">
            I have contacted the person named below and explained how WholeSoft
            Market works. He’s agreed to join the platform provided he’s
            accepted as High Level Connector by WSM.
          </h3>
          <div class="conn-form">
            <form [formGroup]="addSponsorForm">
              <div class="form-sec normal mb-0">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1"
                        >Sponsored By</label
                      >
                      <div class="select-fa">
                        <select
                          formControlName="rpId"
                          class="form-control"
                          id="exampleFormControlSelect1"
                        >
                          <option value="" disabled hidden selected>
                            Select
                          </option>
                          <option
                            [value]="sponsor?._id"
                            *ngFor="let sponsor of sponsors; let i = index"
                          >
                            {{ sponsor?.firstName + " " + sponsor?.lastName }}
                          </option>
                        </select>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                      <div
                        class="text-danger"
                        *ngIf="f.rpId.touched && f.rpId.errors"
                      >
                        <p *ngIf="f.rpId.errors.required">
                          *Sponsor is required
                        </p>
                        <p *ngIf="f.rpId.errors.pattern">
                          *Plese enter a valid sponsor
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">First Name</label>
                      <input
                        formControlName="firstName"
                        type="text"
                        class="form-control"
                        value=""
                        placeholder=""
                      />
                      <div
                        class="text-danger"
                        *ngIf="f.firstName.touched && f.firstName.errors"
                      >
                        <p *ngIf="f.firstName.errors.required">
                          *First name is required
                        </p>
                        <p *ngIf="f.firstName.errors.pattern">
                          *Please enter a valid first name
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Last Name</label>
                      <input
                        formControlName="lastName"
                        type="text"
                        class="form-control"
                        value=""
                        placeholder=""
                      />
                      <div
                        class="text-danger"
                        *ngIf="f.lastName.touched && f.lastName.errors"
                      >
                        <p *ngIf="f.lastName.errors.required">
                          *Last name is required
                        </p>
                        <p *ngIf="f.lastName.errors.pattern">
                          *Please enter a valid last name
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Job Title</label>
                      <input
                        formControlName="jobTitle"
                        type="text"
                        class="form-control"
                        value=""
                        placeholder=""
                      />
                      <div
                        class="text-danger"
                        *ngIf="f.jobTitle.touched && f.jobTitle.errors"
                      >
                        <p *ngIf="f.jobTitle.errors.required">
                          *Job title is required
                        </p>
                        <p *ngIf="f.jobTitle.errors.pattern">
                          *Please enter a valid job title
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email</label>
                      <input
                        formControlName="email"
                        type="text"
                        class="form-control"
                        value=""
                        placeholder=""
                      />
                      <div
                        class="text-danger"
                        *ngIf="f.email.touched && f.email.errors"
                      >
                        <p *ngIf="f.email.errors.required">
                          *Email is required
                        </p>
                        <p *ngIf="f.email.errors.pattern">
                          *Please enter a valid email
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Linkedin URL</label>
                      <input
                        formControlName="userLinkedin"
                        type="text"
                        class="form-control"
                        value=""
                        placeholder="www.linkedin.com/"
                      />
                      <div
                        class="text-danger"
                        *ngIf="f.userLinkedin.touched && f.userLinkedin.errors"
                      >
                        <p *ngIf="f.userLinkedin.errors.required">
                          *Linkedin is required
                        </p>
                        <p *ngIf="f.userLinkedin.errors.pattern">
                          *Plese Enter a valid linkedin Url
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="conn-footer">
            <div class="theme-btn footer-btn blue text-center">
              <button
                type="button"
                (click)="addSponsor()"
                class="btn"
                [disabled]="addSponsorForm.invalid"
                [class.active]="addSponsorForm.valid"
              >
                SPONSOR A High Level Connector
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
