<div class="panel panel-primary setup-content" id="step-3">
  <!-- <div class="save-exit text-right mt-20">
        <button class="btn upper-case trans">Save and Exit</button>
    </div> -->
  <section class="mail-verify lets padding-88">
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5>
            Which verticals (or industries) do you target in priority for
            {{ productName || "N / A" }} ?
            <span class="ml-0">
              <button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or decision maker;  The BEST ENTRY POINT person could well become your eventual main Champion"
              >
                <img src="../assets/images/info.svg" />
              </button>
            </span>
          </h5>
        </div>
        <div class="form-field sign-form">
          <form [formGroup]="targetIndustryForm">
            <div class="form-sec domain normal mb-15">
              <div class="row">
                <div class="col-12">
                  <div class="form-group select-fa">
                    <label for="exampleFormControlSelect1"> Vertical 1</label>
                    <select
                      class="form-control"
                      formControlName="targetIndustry1"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Select Vertical 1
                      </option>
                      <option
                        value="{{ item._id }}"
                        *ngFor="let item of verticals"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group select-fa">
                    <label for="exampleFormControlSelect2">Vertical 2</label>
                    <select
                      class="form-control"
                      formControlName="targetIndustry2"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Select Vertical 2
                      </option>
                      <option
                        value="{{ item._id }}"
                        *ngFor="let item of verticals"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group select-fa">
                    <label for="exampleFormControlSelect3">Vertical 3</label>
                    <select
                      class="form-control"
                      formControlName="targetIndustry3"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Select Vertical 3
                      </option>
                      <option
                        value="{{ item._id }}"
                        *ngFor="let item of verticals"
                      >
                        {{ item.value }}
                      </option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-12">
                  <div class="alert-box">
                    <!-- <p
                      *ngIf="
                        targetIndustryForm.invalid &&
                        (f.targetIndustry1.value == f.targetIndustry2.value ||
                          f.targetIndustry1.value == f.targetIndustry3.value ||
                          f.targetIndustry2.value == f.targetIndustry3.value)
                      "
                      class="text-danger"
                    >
                      *All Verticals should be different
                    </p> -->
                    <p class="formp">
                      Beside these priorities, do you in theory target all
                      verticals?
                    </p>
                    <!-- <p *ngIf="f.targetIndustry1.value && !(isTargetAllIndustry == true || isTargetAllIndustry == false)" class="text-danger">
                                            Select Yes/No
                                        </p> -->
                    <div class="theme-btn width-fit blue">
                      <button
                        (click)="setValue('true')"
                        [class.selectedbutton]="isTargetAllIndustry == 'true'"
                        class="btn mr-1"
                      >
                        Yes
                      </button>
                      <button
                        (click)="setValue('false')"
                        [class.selectedbutton]="isTargetAllIndustry == 'false'"
                        class="btn"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- [disabled]="!targetIndustryForm.valid" -->
        <div class="theme-btn dom width-fit text-right">
          <button
            [class.active]="targetIndustryForm.valid"
            (click)="AddTargetIndustries()"
            class="btn nextBtn"
            type="button"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
