<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <p class="tb-head">
        All ISVs {{ totalPage ? "(" + totalPage + ")" : "" }}
      </p>
      <div class="search-box rps">
        <div class="search-in">
          <div class="in-search-sec">
            <input
              type="text"
              id="searchBar"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="searchTerm"
              autofocus
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>

        <!-- id="custom-id" -->

        <div class="search-link">
          <p-multiSelect
            [options]="sellerStatusArr"
            [(ngModel)]="sellerStatusValueOne"
            defaultLabel="Status"
            label="Status"
            optionLabel="value"
            [virtualScroll]="true"
            itemSize="30"
            [maxSelectedLabels]="1"
            (onChange)="checkVal('STATUS', $event)"
          ></p-multiSelect>

          <p-multiSelect
            [options]="countriesArr"
            [(ngModel)]="countriesValueOne"
            defaultLabel="Geography"
            optionLabel="value"
            [maxSelectedLabels]="1"
            itemSize="30"
            (onChange)="checkVal('GEO', $event)"
          ></p-multiSelect>

          <br />

          <div class="col-md-12 mb-4 text-white text-center d-inline">
            <span
              *ngFor="let item of sellerStatusValueOne"
              class="badge customcolor msg"
              >STATUS : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('STATUS', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
          </div>

          <div class="col-md-12 mb-4 text-white p-2 d-inline">
            <span
              *ngFor="let item of countriesValueOne"
              class="badge customcolor msg"
              >COUNTRY : {{ item?.value }} &nbsp; &nbsp;&nbsp;
              <i
                (click)="clearItem('GEO', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i>
            </span>
          </div>
          <!-- <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ statusFilText || "Status"
                    }}<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{
                      'overflow-class': sellerStatusArr.length > 11
                    }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="statusFilText !== 'Status'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('STATUS', '', 'Status')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of sellerStatusArr"
                      (click)="checkVal('STATUS', i._id, i.value)"
                      >{{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ geoFilText || "Geo"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': countriesArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="geoFilText !== 'Geo'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('GEO', '', 'Geo')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of countriesArr"
                      (click)="checkVal('GEO', i._id, i.value)"
                      >{{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="table-order upper">
        <table>
          <tbody>
            <tr>
              <th>ISV</th>
              <th>Proposals Received</th>
              <th>Proposals Accepted</th>
              <th>Intros made</th>
              <th>Accounts reached</th>
              <th>Deals in progress</th>
              <th>deals closed</th>
              <th>Amount closed</th>
              <th>Agreement start date</th>
              <th>Agreement end date</th>
              <th>Status</th>
            </tr>
            <tr
              *ngFor="
                let isv of resArr
                  | paginate
                    : {
                        itemsPerPage: filters.count,
                        currentPage: p,
                        totalItems: totalPage
                      }
              "
            >
              <td class="td-img-box">
                <div
                  class="td-img"
                  *ngIf="
                    isv.companyImage &&
                    isv.hasOwnProperty('companyImage') &&
                    isv.companyImage !== ''
                  "
                >
                  <img
                    class="img-fluid"
                    src="{{ imageUrl }}{{ isv.companyImage }}"
                    alt="img"
                  />
                </div>

                <div
                  class="td-img"
                  *ngIf="
                    !isv.companyImage ||
                    !isv.hasOwnProperty('companyImage') ||
                    isv.companyImage === ''
                  "
                >
                  <img
                    src="../assets/images/company.png"
                    class="img-thumbnail"
                    alt="img"
                  />
                </div>

                <span (click)="goToRequest(isv?._id)" class="pointer">
                  {{ isv?.companyName }}
                </span>
              </td>

              <td>{{ isv?.proposals }}</td>
              <td>{{ isv?.acceptedProposals }}</td>
              <td>{{ isv?.introsMade }}</td>
              <td>{{ isv?.accountReached }}</td>
              <td>{{ isv?.progressDeal }}</td>
              <td>{{ isv?.closedDeal }}</td>
              <td>{{ isv?.finalDealAmt }}</td>
              <td>
                {{
                  isv?.isvAgreement?.startDate
                    ? sharedService.formatDays(isv?.isvAgreement?.startDate)
                    : ""
                }}
                <!-- {{ isv?.isvAgreement?.startDate || "" }} -->
              </td>
              <td>
                {{
                  isv?.isvAgreement?.endDate
                    ? sharedService.formatDays(isv?.isvAgreement?.endDate)
                    : ""
                }}
              </td>
              <td class="blue upper-a">{{ isv?.status.value || "" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-align pagi-page">
        <!-- <div class="pagination"> -->
        <div class="paginationClass">
          <pagination-controls
            (pageChange)="pageChange($event)"
            previousLabel="Prev"
            nextLabel="Next"
            class="float-right"
          ></pagination-controls>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ countPerPageText || "10 Per Page"
              }}<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                (click)="checkVal('10PERPAGE', '10', '10 Per Page')"
                class="dropdown-item"
                type="button"
              >
                10 Per Page
              </button>
              <button
                class="dropdown-item"
                value="25"
                type="button"
                (click)="checkVal('25PERPAGE', '25', '25 Per Page')"
              >
                25 Per Page
              </button>
              <button
                class="dropdown-item"
                name="countPerPage"
                value="50"
                type="button"
                (click)="checkVal('50PERPAGE', '50', '50 Per Page')"
              >
                50 Per Page
              </button>
            </div>
          </div>
        </div>

        <!-- </div> -->
      </div>
    </div>
  </section>
</main>
