import { Router } from '@angular/router';
import { AddDataService } from 'src/app/services/add-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { DropdownService } from 'src/app/services/dropdown.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-sponsor-a-rp',
  templateUrl: './sponsor-a-rp.component.html',
  styleUrls: ['./sponsor-a-rp.component.scss'],
})
export class SponsorARpComponent implements OnInit {
  sponsors: any;
  addSponsorForm: FormGroup;
  constructor(
    public dialog: MatDialog,
    private dserv: DropdownService,
    private fb: FormBuilder,
    private addData: AddDataService,
    private router: Router,
    public sharedService: SharedServiceService
  ) {}

  ngOnInit(): void {
    this.getSponsoredBy();
    this.addSponsorForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      jobTitle: ['', Validators.required],
      userLinkedin: ['www.linkedin.com/', Validators.required],
      callFrom: 'Admin',
      rpId: ['', Validators.required],
    });
  }

  close() {
    this.dialog.closeAll();
  }

  getSponsoredBy() {
    this.dserv.getRpList().subscribe(
      (res) => {
        // console.log(res.details.data);
        this.sponsors = res.details.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  addSponsor() {
    console.log(this.addSponsorForm.value);
    if (this.addSponsorForm.invalid) {
      return;
    } else {
      this.addData.addSponsorRp(this.addSponsorForm.value).subscribe(
        (res) => {
          console.log(res);
          if (res) {
            this.close();
            this.router.navigate(['/admin/sponsored-rps']);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  get f() {
    return this.addSponsorForm.controls;
  }
}
