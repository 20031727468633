// import { environment } from 'src/environments/environment';
// import { HttpClient, HttpParams } from '@angular/common/http';
// import { BehaviorSubject, Observable } from 'rxjs';
// import { Injectable } from '@angular/core';
// import { HttpHeaders } from '@angular/common/http';

// @Injectable({
//   providedIn: 'root',
// })
// export class IsvService {
//   public productName = localStorage.getItem('productName');
//   constructor(private http: HttpClient) {}

//   //-------------------------------------Onboarding GSA1
//   addProductName(payload, productId?): Observable<any> {
//     if (productId) {
//       return this.http.patch<any>(
//         `${environment.apiUrl}addProductStep1/start?productId=${productId}`,
//         payload
//       );
//     } else {
//       return this.http.patch<any>(
//         `${environment.apiUrl}addProductStep1/start`,
//         payload
//       );
//     }
//   }

//   addRole(payload, productId): Observable<any> {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep1/role`,
//       payload,
//       { params: params }
//     );
//   }

//   addTargetIndustry(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep1/industry`,
//       payload,
//       { params: params }
//     );
//   }

//   addDomain(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep1/domain`,
//       payload,
//       { params: params }
//     );
//   }

//   addGeography(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep1/geography`,
//       payload,
//       { params: params }
//     );
//   }
//   //-------------------------------------Onboarding GSA1

//   //-------------------------------------Onboarding GSA2

//   addProductDetails(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep2/detail`,
//       payload,
//       { params: params }
//     );
//   }

//   addCustomerRefCoverage(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep2/reference`,
//       payload,
//       { params: params }
//     );
//   }

//   addRoiPayback(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep2/roi`,
//       payload,
//       { params: params }
//     );
//   }

//   addTargetSize(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep2/target`,
//       payload,
//       { params: params }
//     );
//   }

//   addUsExperience(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep2/us`,
//       payload,
//       { params: params }
//     );
//   }
//   //-------------------------------------Onboarding GSA2

//   //-------------------------------------Onboarding GSA3

//   addSalesProcessDetails(payload, productId) {
//     let params = new HttpParams().set('productId', productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}addProductStep3`,
//       payload,
//       { params: params }
//     );
//   }
//   //-------------------------------------Onboarding GSA3

//   //-------------------------------------ProductList--------------------------//

//   getSellerProductList(payload) {
//     return this.http.get<any>(
//       `${environment.apiUrl}productList?skip=${payload.skip}&count=${payload.count}&searchPattern=${payload.searchParam}`
//     );
//   }

//   //-------------------------------------ProductList--------------------------//

//   //-------------------------------------ProductDetailsbyStep--------------------------//
//   getSellerProductRegData(productId, stepIndex) {
//     return this.http.get<any>(
//       `${environment.apiUrl}productByStep/` + productId + '/' + stepIndex
//     );
//   }

//   //-------------------------------------ProductList--------------------------//

//   getRegisteredProdutDetails(productId) {
//     return this.http.get<any>(`${environment.apiUrl}product/` + productId);
//   }
//   //-------------------------------------ProductList--------------------------//

//   getonBoardProductStatus() {
//     return this.http.get<any>(`${environment.apiUrl}onBoardProductStatus`);
//   }

//   getproductStatus(productId) {
//     return this.http.get<any>(
//       `${environment.apiUrl}productStatus/` + productId
//     );
//   }
//   // step3
//   getStakeHolder() {
//     return this.http.get<any>(`${environment.apiUrl}stakeHoldereDropdown/`);
//   }

//   getStages() {
//     return this.http.get<any>(`${environment.apiUrl}stages`);
//   }
//   // step3
//   getProductStatus(productId, stepIndex) {
//     return this.http.get<any>(
//       `${environment.apiUrl}productByStep/` + productId + '/' + stepIndex
//     );
//   }

//   uploaddoc(file) {
//     return this.http.post<any>(`${environment.apiUrl}uploadDocument`, file);
//   }

//   // addedon dec/10

//   accountRequestStatus() {
//     return this.http.get<any>(`${environment.apiUrl}originDropdown`);
//   }
//   accountRequestStatusNew() {
//     return this.http.get<any>(`${environment.apiUrl}accountRequestStatus`);
//   }
//   geographyDropdown() {
//     return this.http.get<any>(`${environment.apiUrl}geographyDropdown`);
//   }

//   isvIndustryDropdown() {
//     const httpOptions = {
//       headers: new HttpHeaders({
//         Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
//       }),
//     };
//     return this.http.get<any>(`${environment.apiUrl}industryDropdown`);
//   }
//   companyDropdown() {
//     return this.http.get<any>(`${environment.apiUrl}companySizeDropdown`);
//   }
// }

// NEW :TODO:

// import { environment } from "./../../environments/environment";
// import { HttpClient, HttpParams } from "@angular/common/http";
// import { BehaviorSubject, Observable } from "rxjs";
// import { Injectable } from "@angular/core";

// @Injectable({
//   providedIn: "root",
// })
// export class IsvService {
//   public productName = localStorage.getItem("productName");
//   public sellerID = localStorage.getItem("sellerId");

//   constructor(private http: HttpClient) {}
//   //-------------------------------------Onboarding GSA1
//   addProductName(payload): Observable<any> {
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep1/${this.sellerID}/start`,
//       payload
//     );
//   }

//   addRole(payload, productId): Observable<any> {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep1/${this.sellerID}/role`,
//       payload,
//       { params: params }
//     );
//   }
//   addTargetIndustry(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep1/${this.sellerID}/industry`,
//       payload,
//       { params: params }
//     );
//   }

//   addDomain(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep1/${this.sellerID}/domain`,
//       payload,
//       { params: params }
//     );
//   }

//   addGeography(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep1/${this.sellerID}/geography`,
//       payload,
//       { params: params }
//     );
//   }
//   //-------------------------------------Onboarding GSA1

//   //-------------------------------------Onboarding GSA2

//   addProductDetails(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep2/${this.sellerID}/detail`,
//       payload,
//       { params: params }
//     );
//   }

//   addCustomerRefCoverage(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep2/${this.sellerID}/reference`,
//       payload,
//       { params: params }
//     );
//   }

//   addRoiPayback(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep2/${this.sellerID}/roi`,
//       payload,
//       { params: params }
//     );
//   }

//   addTargetSize(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep2/${this.sellerID}/target`,
//       payload,
//       { params: params }
//     );
//   }
//   addUsExperience(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep2/${this.sellerID}/us`,
//       payload,
//       { params: params }
//     );
//   }
//   //-------------------------------------Onboarding GSA2

//   //-------------------------------------Onboarding GSA3

//   addSalesProcessDetails(payload, productId) {
//     let params = new HttpParams().set("productId", productId);
//     return this.http.patch<any>(
//       `${environment.apiUrl}admin/addProductStep3/${this.sellerID}`,
//       payload,
//       { params: params }
//     );
//   }
//   //-------------------------------------Onboarding GSA3

//   //-------------------------------------ProductList--------------------------//

//   getSellerProductList() {
//     return this.http.get<any>(`${environment.apiUrl}productList`);
//   }

//   //-------------------------------------ProductList--------------------------//

//   //-------------------------------------ProductDetailsbyStep--------------------------//
//   getSellerProductRegData(productId, stepIndex) {
//     return this.http.get<any>(
//       `${environment.apiUrl}productByStep/` + productId + "/" + stepIndex
//     );
//   }

//   //-------------------------------------ProductList--------------------------//

//   getRegisteredProdutDetails(productId) {
//     return this.http.get<any>(`${environment.apiUrl}product/` + productId);
//   }
//   //-------------------------------------ProductList--------------------------//

//   getonBoardProductStatus() {
//     return this.http.get<any>(`${environment.apiUrl}onBoardProductStatus`);
//   }

//   getproductStatus(productId) {
//     return this.http.get<any>(
//       `${environment.apiUrl}productStatus/` + productId
//     );
//   }
//   // step3
//   getStakeHolder() {
//     return this.http.get<any>(`${environment.apiUrl}stakeHoldereDropdown/`);
//   }

//   getStages() {
//     return this.http.get<any>(`${environment.apiUrl}stages`);
//   }
//   // step3
//   getStepStatus(productId, stepIndex) {
//     return this.http.get<any>(
//       `${environment.apiUrl}productByStep/` + productId + stepIndex
//     );
//   }

//   uploaddoc(file) {
//     return this.http.post<any>(`${environment.apiUrl}uploadDocument`, file);
//   }

// }

/////////////////////////////////////////////////      CHANGED      ////////////////////////////////////////

import { environment } from './../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IsvService {
  public productName = localStorage.getItem('productName');
  public sellerID = localStorage.getItem('sellerId');
  constructor(private http: HttpClient) {}

  //-------------------------------------Onboarding GSA1
  addProductName(payload, productId?): Observable<any> {
    const sellerIDAddProduct = localStorage.getItem('sellerId');
    if (productId) {
      return this.http.patch<any>(
        `${environment.apiUrl}admin/addProductStep1/${sellerIDAddProduct}/start?productId=${productId}`,
        payload
      );
    } else {
      return this.http.patch<any>(
        `${environment.apiUrl}admin/addProductStep1/${sellerIDAddProduct}/start`,
        payload
      );
    }
  }

  addRole(payload, productId): Observable<any> {
    const sellerIDAddProduct = localStorage.getItem('sellerId');
    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep1/${sellerIDAddProduct}/role`,
      payload,
      { params: params }
    );
  }

  addTargetIndustry(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');
    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep1/${sellerIDAddProduct}/industry`,
      payload,
      { params: params }
    );
  }

  addDomain(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep1/${sellerIDAddProduct}/domain`,
      payload,
      { params: params }
    );
  }

  addGeography(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');
    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep1/${sellerIDAddProduct}/geography`,
      payload,
      { params: params }
    );
  }
  //-------------------------------------Onboarding GSA1

  //-------------------------------------Onboarding GSA2

  addProductDetails(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep2/${sellerIDAddProduct}/detail`,
      payload,
      { params: params }
    );
  }

  addCustomerRefCoverage(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep2/${sellerIDAddProduct}/reference`,
      payload,
      { params: params }
    );
  }

  addRoiPayback(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep2/${sellerIDAddProduct}/roi`,
      payload,
      { params: params }
    );
  }

  addTargetSize(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep2/${sellerIDAddProduct}/target`,
      payload,
      { params: params }
    );
  }

  addUsExperience(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep2/${sellerIDAddProduct}/us`,
      payload,
      { params: params }
    );
  }
  //-------------------------------------Onboarding GSA2

  //-------------------------------------Onboarding GSA3

  addSalesProcessDetails(payload, productId) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    let params = new HttpParams().set('productId', productId);
    return this.http.patch<any>(
      `${environment.apiUrl}admin/addProductStep3/${sellerIDAddProduct}`,
      payload,
      { params: params }
    );
  }
  //-------------------------------------Onboarding GSA3

  //-------------------------------------ProductList--------------------------//

  getSellerProductList(payload) {
    return this.http.get<any>(
      `${environment.apiUrl}productList?skip=${payload.skip}&count=${payload.count}&searchPattern=${payload.searchParam}`
    );
  }

  //-------------------------------------ProductList--------------------------//

  //-------------------------------------ProductDetailsbyStep--------------------------//
  getSellerProductRegData(productId, stepIndex) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    return this.http.get<any>(
      `${environment.apiUrl}admin/productByStep/${sellerIDAddProduct}/${productId}/${stepIndex}`
    );
  }

  //-------------------------------------ProductList--------------------------//

  getRegisteredProdutDetails(productId) {
    return this.http.get<any>(`${environment.apiUrl}product/` + productId);
  }
  //-------------------------------------ProductList--------------------------//

  getonBoardProductStatus() {
    return this.http.get<any>(`${environment.apiUrl}onBoardProductStatus`);
  }

  getproductStatus(productId) {
    return this.http.get<any>(
      `${environment.apiUrl}productStatus/` + productId
    );
  }
  // step3
  getStakeHolder() {
    return this.http.get<any>(`${environment.apiUrl}stakeHoldereDropdown/`);
  }

  getStages() {
    return this.http.get<any>(`${environment.apiUrl}stages`);
  }
  // step3
  getProductStatus(productId, stepIndex) {
    const sellerIDAddProduct = localStorage.getItem('sellerId');

    return this.http.get<any>(
      `${environment.apiUrl}admin/productByStep/${sellerIDAddProduct}/${productId}/${stepIndex}`
    );
  }

  uploaddoc(file) {
    return this.http.post<any>(`${environment.apiUrl}uploadDocument`, file);
  }
}
