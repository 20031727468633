<div class="head-mr"></div>
<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <p
        class="tb-head capitalized"
        data-toggle="modal"
        data-target="#exampleModal1"
      >
        Sponsorship Rewards
      </p>
      <div class="rewards">
        <div class="box">
          <h3>Total Sponsorship Rewards</h3>
          <h2>{{ euro }} {{ getTotalRewards() | conversion }}</h2>
        </div>
      </div>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input type="text" (keyup)="search($event)" placeholder="Search" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div class="search-link">
          <div class="links">
            <ul>
              <!-- <li>
                <div class="dropdown">
                  <a
                    class="drop-tag"
                    (click)="getDefault()"
                    href="javascript:void(0)"
                    >Clear All Filters
                    <span style="font-size: 20px"
                      ><i class="fa fa-close ml-1"></i
                    ></span>
                  </a>
                </div>
              </li> -->
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Sponsored By <i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div
                    style="max-height: 200px; overflow-y: scroll"
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="btn dropdown-toggle"
                      href="javascript:void(0)"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      (click)="filterBySponsor(item._id)"
                      class="dropdown-item"
                      *ngFor="let item of sponsoredByList"
                      href="javascript:void(0)"
                      >{{ item?.firstName + " " + item?.lastName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Sponsored HLC<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div
                    class="dropdown-menu"
                    style="max-height: 200px; overflow-y: scroll"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="btn dropdown-toggle"
                      href="javascript:void(0)"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      *ngFor="let sp of sponsoredBy"
                      class="dropdown-item"
                      (click)="sponsorRPFilter(sp.userId._id)"
                      href="javascript:void(0)"
                      >{{ sp.userId?.firstName + "" + sp.userId?.lastName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Deal Date<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="btn dropdown-toggle"
                      href="javascript:void(0)"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      (click)="dayFilter('7')"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      >Last 7 Days</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="dayFilter('30')"
                      href="javascript:void(0)"
                      >Last 30 Days</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="dayFilter('90')"
                      href="javascript:void(0)"
                      >Last 90 Days</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Status<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="btn dropdown-toggle"
                      href="javascript:void(0)"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      class="dropdown-item"
                      *ngFor="let item of paymentStatus"
                      (click)="filterByStatus(item._id)"
                      href="javascript:void(0)"
                      >{{ item.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="table-order upper">
        <table>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Sponsored by</th>
              <th>DEAL DATE</th>
              <th>Deal Value ({{ euro }})</th>
              <th>RATE %</th>
              <th>COMMISSION ({{ euro }})</th>
              <th>Status</th>
            </tr>
            <tr
              *ngFor="
                let reward of rewards
                  | paginate
                    : {
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: count
                      }
              "
            >
              <td>
                {{ reward?.user.firstName + " " + reward?.user.lastName }}
              </td>
              <td>
                {{
                  reward?.sponsoredBy.firstName +
                    " " +
                    reward?.sponsoredBy.lastName
                }}
              </td>
              <td>
                {{
                  reward?.dealClosedDate
                    ? sharedService.formatDays(reward?.dealClosedDate)
                    : ""
                }}
              </td>
              <td>{{ reward?.finalDealAmount | conversion }}</td>
              <td>{{ reward?.sponsorshipRate }}</td>
              <td>{{ reward.sponsorshipValue | conversion }}</td>
              <td>
                {{ reward?.status.value }}
                <!-- <form>
                                    <div class="form-sec tab-select min-96 mb-0">
                                        <div class="form-group mb-0">
                                            <div class="select-fa">
                                                <select class="form-control btn paid" id="exampleFormControlSelect1">
                                                    <option>PAID</option>
                                                    <option>UNPAID</option>

                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </form> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-align pagi-page">
        <div class="paginationClass">
          <pagination-controls
            previousLabel="Prev"
            nextLabel="Next"
            responsive="true"
            (pageChange)="handlePageChange($event)"
          ></pagination-controls>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ pageSize }} per page<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                class="dropdown-item"
                (click)="handlePageSizeChange(item)"
                *ngFor="let item of pageSizes"
                type="button"
              >
                {{ item }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
