import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { CommonService } from './utils/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'admin-wholesoft';
  showHeader = false;
  constructor(
    private router: Router,
    public common: CommonService,
    private authService: AuthService
  ) {
    // router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event.url.includes('admin')) {
    //       this.showHeader = true;
    //     }
    //   }
    // });

    let user: any = localStorage.getItem('currentUser');
    if (!user) {
      localStorage.clear();
      this.router.navigate(['/']);
      return;
    }

    if (!!user) {
      user = JSON.parse(user);
      if (user?.role !== 'admin' || user?.role !== 'admin') {
        localStorage.clear();
        this.router.navigate(['/']);
        return;
      }
    }
  }
}
