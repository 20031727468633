<router-outlet></router-outlet>
<app-sidebar></app-sidebar>
<main class="main-left">
    <div class="col-12">
        <div class="steps-top">
            <!-- <div class="stepwizard">
        <div class="stepwizard-row setup-panel">
          <div class="stepwizard-step col-xs-3">
            <a href="#step-1" type="button" class="btn btn-success btn-circle"
              >1</a
            >
            <p><small>Shipper</small></p>
          </div>
          <div class="stepwizard-step col-xs-3">
            <a
              href="#step-2"
              type="button"
              class="btn btn-default btn-circle"
              disabled="disabled"
              >2</a
            >
            <p><small>Destination</small></p>
          </div>
          <div class="stepwizard-step col-xs-3">
            <a
              href="#step-3"
              type="button"
              class="btn btn-default btn-circle"
              disabled="disabled"
              >3</a
            >
            <p><small>Schedule</small></p>
          </div>
          <div class="stepwizard-step col-xs-3">
            <a
              href="#step-4"
              type="button"
              class="btn btn-default btn-circle"
              disabled="disabled"
              >4</a
            >
            <p><small>Cargo</small></p>
          </div>
          <div class="stepwizard-step col-xs-3">
            <a
              href="#step-5"
              type="button"
              class="btn btn-default btn-circle"
              disabled="disabled"
              >5</a
            >
            <p><small>Cargo</small></p>
          </div>
        </div>
      </div> -->

            <form role="form">
                <ng-container [ngSwitch]="currentStep?.stepIndex">
                    <ng-container *ngSwitchCase="'1'">
                        <app-getting-started [step]="currentStep"></app-getting-started>
                    </ng-container>
                    <ng-container *ngSwitchCase="'2'">
                        <app-target-roles [step]="currentStep"></app-target-roles>
                    </ng-container>
                    <ng-container *ngSwitchCase="'3'">
                        <app-target-industries [step]="currentStep"></app-target-industries>
                    </ng-container>
                    <ng-container *ngSwitchCase="'4'">
                        <app-target-domain-subdomain [step]="currentStep"></app-target-domain-subdomain>
                    </ng-container>
                    <ng-container *ngSwitchCase="'5'">
                        <app-target-geographies [step]="currentStep"></app-target-geographies>
                    </ng-container>
                    <!-- step2 -->
                    <ng-container *ngSwitchCase="'6'">
                        <app-product-details [step]="currentStep"></app-product-details>
                    </ng-container>
                    <ng-container *ngSwitchCase="'7'">
                        <app-customer-ref [step]="currentStep"></app-customer-ref>
                    </ng-container>
                    <ng-container *ngSwitchCase="'8'">
                        <app-roi-payback [step]="currentStep"></app-roi-payback>
                    </ng-container>
                    <ng-container *ngSwitchCase="'9'">
                        <app-target-size-comp [step]="currentStep"></app-target-size-comp>
                    </ng-container>
                    <ng-container *ngSwitchCase="'10'">
                        <app-prevoius-us-exp [step]="currentStep"></app-prevoius-us-exp>
                    </ng-container>
                    <ng-container *ngSwitchCase="'11'">
                        <app-sales-get-started [step]="currentStep"></app-sales-get-started>
                    </ng-container>
                </ng-container>

            </form>
        </div>
    </div>
</main>