<div class="head-mr"></div>
<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <div class="connec-head">
        <div class="head-one">
          <p class="tb-head capitalized">
            INTRO Requests {{ totalPage ? "(" + totalPage + ")" : "" }}
          </p>
        </div>
        <div class="head-2"></div>
      </div>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input
              autofocus
              type="text"
              id="searchBar"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="searchTerm"
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>

        <div class="search-link">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ rpFilText || "HLC" }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="rpFilText !== 'HLC'"
                      (click)="checkVal('RP', '', 'RP')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of rpArr; let ind = index"
                      (click)="checkVal('RP', i, '')"
                    >
                      <span> {{ i?.firstName ? i?.firstName : "" }} </span
                      >&nbsp;
                      <span>
                        {{ i?.lastName ? i?.lastName : "" }}
                      </span>
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ isvFilText || "ISV"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="isvFilText !== 'ISV'"
                      (click)="checkVal('ISV', '', 'ISV')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let isv of isvArr"
                      (click)="checkVal('ISV', isv._id, isv.companyName)"
                      >{{ isv.companyName }}</a
                    >
                  </div>
                </div>
              </li>
              <!-- <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Account<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="javascript:void(0)"
                      >Brown Brothers Harriman</a
                    >
                    <a class="dropdown-item" href="javascript:void(0)"
                      >Northern Trust Corporation</a
                    >
                    <a class="dropdown-item" href="javascript:void(0)">BNY Mellon</a>
                  </div>
                </div>
              </li> -->
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ dateRecievedFilText || "Date Received"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="dateRecievedFilText !== 'Date Received'"
                      (click)="checkVal('CLEARDAYS', '', 'Date Received')"
                      >{{ "All" }}</a
                    >

                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('7DAYS', '7DAYS', 'Last 7 Days')"
                      >Last 7 Days</a
                    >

                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('30DAYS', '30DAYS', 'Last 30 Days')"
                      >Last 30 Days</a
                    >

                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('90DAYS', '90DAYS', 'Last 90 Days')"
                      >90 Days</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ statusFilText || "Status"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="statusFilText !== 'Status'"
                      (click)="checkVal('STATUS', '', 'Status')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let stat of statusArr"
                      (click)="checkVal('STATUS', stat._id)"
                      >{{ stat?.value || "" }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- End -->
      </div>
      <section>
        <div class="table-order upper">
          <table>
            <thead>
              <tr>
                <th>Date Received</th>
                <th>High Level Connector</th>
                <th>ISV</th>
                <!-- <th>Account</th> -->
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let inqr of resArr
                    | paginate
                      : {
                          itemsPerPage: filters.count,
                          currentPage: p,
                          totalItems: totalPage
                        }
                "
              >
                <td>
                  {{
                    inqr?.receivedDate
                      ? sharedService.formatDays(inqr.receivedDate)
                      : ""
                  }}
                </td>
                <td>{{ inqr?.rp?.firstName + " " + inqr?.rp?.lastName }}</td>
                <td>{{ inqr?.seller?.companyName }}</td>
                <!-- <td>Test</td> -->
                <td>{{ inqr.status?.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-align pagi-page">
          <!-- {{ totalPageArr.toString() }} -->
          <!-- <div
            id="pagination"
            class="pagination"
            *ngFor="let page of totalPageArr; let i = index"
          >
            <a
              *ngIf="totalPageArr[page] === 0"
              href="javascript:void(0)"
              class="prev"
              [ngClass]="
                totalPage % +filters.count < +filters.skip ? '' : 'disable-link'
              "
              (click)="nextVal('PREV')"
              >Prev</a
            >
           
            <a
              *ngIf="i < 5"
              id="number"
              href="javascript:void(0)"
              [ngClass]="i === +filters.skip / +filters.count ? 'active' : ''"
              name="number"
              (click)="skipPageFn(i)"
              >{{ page + 1 | number: "1.0-0" }}
            </a>
            <a
              href="javascript:void(0)"
              *ngIf="totalPageArr[page] === totalPageArr.length - 1"
              [ngClass]="
                totalPage - +filters.skip <= +filters.count
                  ? 'disable-link'
                  : ''
              "
              (click)="nextVal('NEXT')"
              >Next<i class="fa ml-1 fa-angle-right"></i
            ></a>
          </div> -->

          <div class="paginationClass">
            <pagination-controls
              (pageChange)="pageChange($event)"
              class="float-right"
              previousLabel="Prev"
              nextLabel="Next"
            ></pagination-controls>
          </div>

          <div class="user">
            <div class="btn-group">
              <button
                type="button"
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {{ countPerPageText || "10 per page"
                }}<i class="fa fa-angle-down ml-1"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                x-placement="bottom-end"
                style="
                  position: absolute;
                  will-change: transform;
                  top: 0px;
                  left: 0px;
                  transform: translate3d(-69px, 42px, 0px);
                "
              >
                <button
                  (click)="checkVal('10PERPAGE', '10', '10 per page')"
                  class="dropdown-item"
                  type="button"
                >
                  10 Per Page
                </button>
                <button
                  class="dropdown-item"
                  value="25"
                  type="button"
                  (click)="checkVal('25PERPAGE', '25', '25 per page')"
                >
                  25 Per Page
                </button>
                <button
                  class="dropdown-item"
                  name="countPerPage"
                  value="50"
                  type="button"
                  (click)="checkVal('50PERPAGE', '50', '50 per page')"
                >
                  50 Per Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</main>
