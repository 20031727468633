import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'app-isv-onboarding-progress-tracker',
  templateUrl: './isv-onboarding-progress-tracker.component.html',
  styleUrls: ['./isv-onboarding-progress-tracker.component.scss'],
})
export class IsvOnboardingProgressTrackerComponent implements OnInit {
  public gsaProgress: any;
  public step: any = '';
  completedStep: any;
  onBoarded: boolean;
  constructor(
    private isv: IsvService,
    public stepService: StepsService,
    public router: Router,
    private auth: AuthService,
    public common: CommonService
  ) {}

  ngOnInit(): void {
    this.getProductStatus();
    console.log('auth', this.auth.currentUserValue);
    const st = localStorage.getItem('onBoard');
    if (localStorage.getItem('onBoard') == 'true') {
      this.auth.currentUserValue.onBoard = true;
    }

    this.onBoarded = this.auth.currentUserValue.onBoard;
  }

  getProductStatus() {
    const productId = localStorage.getItem('productId');
    this.isv.getproductStatus(productId).subscribe((data) => {
      this.gsaProgress = data.details.product.status.value;
      this.step = data.details.product.lastStep;
      // console.log(data.details.product.status.value);
      this.completedStep = data.details.product.status.value;
      if (this.step == '3') {
        this.clearStorage();
      }
    });
  }

  editProduct(step) {
    this.stepService.setCurrentStep(step);
    localStorage.setItem('currentStep', step);

    this.router.navigate(['product-onboarding'], {
      queryParams: { toEdit: true },
    });
  }

  continueProduct(step) {
    this.stepService.setCurrentStep(step);
    localStorage.setItem('currentStep', step);
    this.router.navigate(['product-onboarding']);
  }

  clearStorage() {
    localStorage.removeItem('currentStep');
  }

  redirectToProducts() {
    this.common.showHeader.next(true);
    const productID = localStorage.getItem('productId');
    this.router.navigate([`/admin/add-product/${productID}`]);
  }
}
