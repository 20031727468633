import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AddDataService {
  constructor(public http: HttpClient) {}

  fileUpload(file) {
    return this.http.post<any>(`${environment.apiUrl}uploadDocument`, file);
  }

  updateISVService(id, rpData) {
    return this.http
      .put<any>(`${environment.apiUrl}updateIsv/${id}`, rpData)
      .pipe(
        map((el) => {
          // console.log(el);
          return el;
        })
      );
  }

  addIsvService(isvData) {
    return this.http.post<any>(`${environment.apiUrl}addIsv`, isvData);
  }

  addRpService(rpData) {
    return this.http.post<any>(`${environment.apiUrl}addAgent`, rpData);
  }

  addAccountService(accData) {
    return this.http.post<any>(`${environment.apiUrl}addAccount`, accData);
  }

  addProductService(productData, sellerId, productId) {
    return this.http.patch<any>(
      `${environment.apiUrl}updateProductDetail/${sellerId}/${productId}`,
      productData
    );
  }

  updateAgentDetails(agentId, rpData) {
    return this.http.put<any>(
      `${environment.apiUrl}updateAgent/${agentId}`,
      rpData
    );
  }

  updateAccountDetails(accountId, accData) {
    return this.http.put<any>(
      `${environment.apiUrl}updateAccount/${accountId}`,
      accData
    );
  }
  addSponsorRp(payload) {
    return this.http.post<any>(`${environment.apiUrl}sponsorRP`, payload);
  }
}
