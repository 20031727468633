import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'app-target-geographies',
  templateUrl: './target-geographies.component.html',
  styleUrls: ['./target-geographies.component.scss'],
})
export class TargetGeographiesComponent implements OnInit {
  @Input() step: StepModel;

  public geoForm: FormGroup;
  public productId: string;
  public toEdit: string;
  public productDetails: any;
  public geographies: any;
  public geographyArr = [];

  constructor(
    private stepService: StepsService,
    private router: Router,
    private shared: CommonService,
    private isv: IsvService,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private dropdown: DropdownService
  ) {
    this.productId = localStorage.getItem('productId');
    // const targetGeography = localStorage.getItem("targetGeography")
    // if (targetGeography) {
    //   this.stepService.onNextStep()
    // }
  }

  ngOnInit(): void {
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');
    this.getGeography();

    this.geoForm = this.fb.group({
      targetGeography: ['', Validators.required],
    });
  }

  getProductDetail() {
    this.isv.getSellerProductRegData(this.productId, 1).subscribe(
      (data) => {
        this.productDetails = data?.details?.product;
        this.setFormValues();
      },
      (err) => {
        console.log(err);
        this.shared.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

  AddTargetGeographies() {
    if (this.geoForm.invalid) {
      return;
    } else {
      console.log(this.geoForm.value.targetGeography);
      // const payload = { targetGeography: this.geoForm.value.targetGeography[0] }
      let geographyObj = {
        targetGeography: [this.geoForm.value.targetGeography],
      };
      this.isv.addGeography(geographyObj, this.productId).subscribe(
        (res) => {
          this.onCompleteStep();
          this.stepService.onNextStep();

          this.router.navigate(['admin/onboarding-progress']);
          if (this.toEdit) {
            localStorage.removeItem('currentStep');
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  getGeography() {
    this.dropdown.getGeography().subscribe(
      (res) => {
        this.geographies = res.details.geography;
        if (this.toEdit) {
          this.getProductDetail();
        }
      },
      (err) => {
        console.log(err);
        this.shared.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  setFormValues(id?) {
    // this.geoForm.setValue({
    //   targetGeography: [this.productDetails.targetGeography._id]
    // })

    this.geographyArr.push(id);
    this.geoForm.setValue({
      targetGeography: this.productDetails.targetGeography[0]
        ? this.productDetails.targetGeography[0]._id
        : '',
    });
  }
}
