<div class="head-mr"></div>
<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <div class="connec-head">
        <div class="head-one">
          <p class="tb-head capitalized">
            Intro Proposals for ISV
            {{ totalPage ? " (" + totalPage + ")" : "" }}
          </p>
        </div>
        <div class="head-2"></div>
      </div>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input
              autofocus
              type="text"
              id="searchBar"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="searchTerm"
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>

        <!-- STart -->

        <div class="search-link">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ propStatusFilText || "Proposal Status" }}
                    <!-- status -->
                    <i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      *ngIf="propStatusFilText !== 'Proposal Status'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('PROPSTATUS', '', 'Proposal Status')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let ps of introPropArr"
                      (click)="checkVal('PROPSTATUS', ps._id, ps.value)"
                      >{{ ps.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ isvFilText || "ISV" }}
                    <!-- isv -->
                    <i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="isvFilText !== 'ISV'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('ISV', '', 'ISV')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let isv of isvArr"
                      (click)="checkVal('ISV', isv._id, isv.companyName)"
                      >{{ isv.companyName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ psRecievedFilText || "Proposal Received" }}
                    <!-- days -->
                    <i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      *ngIf="psRecievedFilText !== 'Proposal Received'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('CLEARDAYS', '', 'Proposal Received')"
                      >Clear Filters</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('7DAYS', '7', 'Last 7 Days')"
                      >Last 7 Days</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('30DAYS', '30', 'Last 30 Days')"
                      >Last 30 Days</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('90DAYS', '90', 'Last 90 Days')"
                      >Last 90 Days</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ rpFilText || "High Level Connector" }}
                    <!-- rp -->
                    <i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="rpFilText !== 'High Level Connector'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('RP', '', 'High Level Connector')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let rp of rpArr"
                      (click)="checkVal('RP', rp)"
                    >
                      <span> {{ rp?.firstName ? rp?.firstName : "" }} </span
                      >&nbsp;<span>
                        {{ rp?.lastName ? rp?.lastName : "" }}
                      </span>
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ accountFilText || "Account" }}
                    <!-- account -->
                    <i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="accountFilText !== 'Account'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('ACCOUNT', '', 'Account')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let acc of accArr"
                      (click)="checkVal('ACCOUNT', acc._id, acc.accountName)"
                      >{{ acc.accountName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ introStatusFilText || "Intro Status" }}
                    <!-- introStatus -->
                    <i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="introStatusFilText !== 'Intro Status'"
                      (click)="checkVal('INTROSTATUS', '', 'Intro Status')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let ins of introReqArr"
                      (click)="checkVal('INTROSTATUS', ins._id, ins.value)"
                      >{{ ins.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- End -->
      </div>
      <section>
        <div class="table-order upper">
          <table>
            <thead>
              <tr>
                <th>DATE RECEIVED</th>
                <th>ISV</th>
                <th>High Level Connector</th>
                <th>ACCOUNT</th>
                <th>TARGET</th>
                <th>PROPOSAL STATUS</th>
                <th>VALID UPTO</th>
                <th>DAYS REMAINING TO RESPOND</th>
                <th>INTRO STATUS</th>
                <!-- <th>VALID UPTO</th> -->
                <th>DAYS REMAINING FOR High Level Connector</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let i of resArr
                    | paginate
                      : {
                          itemsPerPage: filters.count,
                          currentPage: p,
                          totalItems: totalPage
                        }
                "
              >
                <td>
                  {{
                    i?.proposalDate
                      ? sharedService.formatDays(i?.proposalDate)
                      : ""
                  }}
                </td>
                <td>{{ i?.isv?.companyName }}</td>
                <td>{{ i?.rp?.firstName + " " + i?.rp?.lastName }}</td>
                <td>{{ i?.account?.accountName }}</td>
                <td>{{ i.target.firstName + " " + i?.target?.lastName }}</td>
                <td>{{ i?.status?.value }}</td>
                <td>
                  {{
                    i?.proposalAcceptanceExpiryDate
                      ? sharedService.formatDays(
                          i?.proposalAcceptanceExpiryDate
                        )
                      : ""
                  }}
                </td>
                <td>
                  {{
                    i?.status?.value.toLowerCase() === "pending"
                      ? dateDiffCalc(i) + "  Days remaining"
                      : ""
                  }}
                </td>
                <td>{{ i?.introStatus?.value || "" }}</td>
                <!-- <td>Test</td> -->
                <td>
                  {{
                    i?.introStatus?.value.toLowerCase() === "pending" ? "" : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="d-align pagi-page">
          <div class="paginationClass">
            <pagination-controls
              (pageChange)="pageChange($event)"
              class="float-right"
              previousLabel="Prev"
              nextLabel="Next"
            ></pagination-controls>
          </div>
          <div class="user">
            <div class="btn-group">
              <button
                type="button"
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {{ countPerPageText || "10 per page"
                }}<i class="fa fa-angle-down ml-1"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                x-placement="bottom-end"
                style="
                  position: absolute;
                  will-change: transform;
                  top: 0px;
                  left: 0px;
                  transform: translate3d(-69px, 42px, 0px);
                "
              >
                <button
                  (click)="checkVal('10PERPAGE', '10', '10 Per Page')"
                  class="dropdown-item"
                  type="button"
                >
                  10 Per Page
                </button>
                <button
                  class="dropdown-item"
                  value="25"
                  type="button"
                  (click)="checkVal('25PERPAGE', '25', '25 Per Page')"
                >
                  25 Per Page
                </button>
                <button
                  class="dropdown-item"
                  name="countPerPage"
                  value="50"
                  type="button"
                  (click)="checkVal('50PERPAGE', '50', '50 Per Page')"
                >
                  50 Per Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</main>
