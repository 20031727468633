import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-view-commissions',
  templateUrl: './view-commissions.component.html',
  styleUrls: ['./view-commissions.component.scss'],
})
export class ViewCommissionsComponent implements OnInit {
  commissions: any;
  totalRecords: any;
  pageSize: any = 10;
  page: any = 1;
  pageSizes = [10, 25, 50];
  payload: any = {};
  account: any;
  allRps: any;
  isv: any;
  paymentStat: any;
  euro: any;
  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    private dataser: GetDataService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
    this.dataser.currentCurr.subscribe((data) => {
      if (data === 'EUR') {
        this.euro = '€';
      } else {
        this.euro = '$';
      }
    });
  }

  ngOnInit(): void {
    this.getAllIsv();
    this.getRPList();
    this.getAllAccounts();
    this.getPaymentStatus();
    this.payload.count = this.pageSize;
    this.payload.skip = 0;
    this.getComissions(this.payload);
  }
  getComissions(payload: any) {
    this.dataser.getRpCommissions(payload).subscribe(
      (data) => {
        this.commissions = data.details.comissions[0].data;
        this.totalRecords = data.details.comissions[0]?.total[0].count;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  handlePageChange(event) {
    this.page = event;
    const sk = (event - 1) * this.pageSize;
    this.payload = { count: this.pageSize, skip: sk };
    this.getComissions(this.payload);
  }

  handlePageSizeChange(event): void {
    this.pageSize = event;
    this.page = 1;
    this.payload = { count: event };
    this.getComissions(this.payload);
  }

  search(e) {
    this.page = 1;
    this.payload = { searchPattern: e.target.value, count: this.pageSize };
    if (
      this.payload.searchPattern != '' &&
      this.payload.searchPattern.length > 2
    ) {
      this.getComissions(this.payload);
    } else if (this.payload.searchPattern === '') {
      this.payload.count = this.pageSize;
      this.getComissions(this.payload);
    }
  }

  getAllIsv() {
    this.dropdown.getISVDropdown().subscribe(
      (data) => {
        this.isv = data.details.isv;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getRPList() {
    this.dropdown.getRpsList().subscribe(
      (data) => {
        this.allRps = data.details.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllAccounts() {
    this.dropdown.getAllAccountDropdown().subscribe((data) => {
      this.account = data.details.accounts;
    });
  }

  getPaymentStatus() {
    this.dropdown.getpaymentStatus().subscribe((data) => {
      this.paymentStat = data.details.paymentStatus;
    });
  }

  getTotalRewards() {
    let sum = 0;
    if (this.commissions) {
      this.commissions.forEach((element) => {
        sum += element.platformValue;
      });
      return Math.trunc(sum);
    }
  }

  // filters

  filterByAccount(val) {
    this.page = 1;
    this.payload = { account: val, count: this.pageSize };
    this.getComissions(this.payload);
  }
  filterByIsv(val) {
    this.page = 1;
    this.payload = { isv: val, count: this.pageSize };
    this.getComissions(this.payload);
  }
  filterByRP(val) {
    this.page = 1;
    this.payload = { rpId: val, count: this.pageSize };
    this.getComissions(this.payload);
  }
  filterByDays(val) {
    this.page = 1;
    this.payload = { days: val, count: this.pageSize };
    this.getComissions(this.payload);
  }
  filterByPaymentStatus(val) {
    this.page = 1;
    this.payload = { status: val, count: this.pageSize };
    this.getComissions(this.payload);
  }
}
