<div class="head-mr"></div>
<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <div class="connec-head">
        <div class="head-one">
          <p class="tb-head capitalized">
            Intro Proposals for HLC {{ totalPage ? " (" + totalPage + ")" : "" }}
          </p>
        </div>
        <div class="head-2"></div>
      </div>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input
              type="text"
              id="searchBar"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="searchTerm"
              autofocus
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>

        <div class="search-link">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ rpFilText || "High Level Connector"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="rpFilText !== 'High Level Connector'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('RP', '', 'High Level Connector')"
                    >
                      {{ "All" }}
                    </a>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let rp of rpArr"
                      (click)="checkVal('RP', rp, '')"
                    >
                      <span> {{ rp?.firstName ? rp?.firstName : "" }} </span
                      >&nbsp;<span>
                        {{ rp?.lastName ? rp?.lastName : "" }}
                      </span>
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ proposalSentFilText || "Proposal Sent"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      *ngIf="proposalSentFilText !== 'Proposal Sent'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('CLEARDAYS', '', 'Proposal Sent')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('7DAYS', '7', 'Last 7 Days')"
                      >Last 7 Days</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('30DAYS', '30', 'Last 30 Days')"
                      >Last 30 Days</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('90DAYS', '90', 'Last 90 Days')"
                      >90 Days</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ isvFilText || "ISV"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="isvFilText !== 'ISV'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('ISV', '', 'ISV')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of isvArr"
                      (click)="checkVal('ISV', i._id, i.companyName)"
                      >{{ i.companyName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ accFilText || "Account"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="accFilText !== 'Account'"
                      (click)="checkVal('ACCOUNT', '', 'Account')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of accArr"
                      (click)="checkVal('ACCOUNT', i._id, i?.accountName)"
                      >{{ i?.accountName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ proposalStatusFilText || "Proposal Status"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="proposalStatusFilText !== 'Proposal Status'"
                      (click)="checkVal('PROPSTATUS', '', 'Proposal Status')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of introPropArr"
                      (click)="checkVal('PROPSTATUS', i._id, i?.value)"
                      >{{ i?.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ introStatusFilText || "Intro Status"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngIf="introStatusFilText !== 'Intro Status'"
                      (click)="checkVal('INTROSTATUS', '', 'Intro Status')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let ins of introReqArr"
                      (click)="checkVal('INTROSTATUS', ins._id, ins.value)"
                      >{{ ins.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section>
        <div class="table-order upper">
          <table>
            <thead>
              <tr>
                <th>PROPOSAL DATE</th>
                <th>High Level Connector</th>
                <th>ISV</th>
                <th>ACCOUNT</th>
                <th>TARGET</th>
                <th>PROPOSAL STATUS</th>
                <th>INTRO STATUS</th>
                <th>VALID UPTO</th>
                <th>DAYS REMAINING</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let intro of resArr
                    | paginate
                      : {
                          itemsPerPage: filters.count,
                          currentPage: p,
                          totalItems: totalPage
                        }
                "
              >
                <td>
                  {{
                    intro?.proposalDate
                      ? sharedService.formatDays(intro?.proposalDate)
                      : ""
                  }}
                </td>
                <td>{{ intro.rp?.firstName + " " + intro.rp?.lastName }}</td>
                <td>{{ intro.isv?.companyName }}</td>
                <td>{{ intro?.account?.accountName }}</td>
                <td>
                  {{ intro.target?.firstName + " " + intro.target?.lastName }}
                </td>
                <td>{{ intro?.status?.value }}</td>
                <td>{{ intro?.introStatus?.value }}</td>
                <td>
                  {{
                    intro?.proposalAcceptanceExpiryDate
                      ? sharedService.formatDays(
                          intro?.proposalAcceptanceExpiryDate
                        )
                      : ""
                  }}
                </td>
                <td>
                  {{
                    intro?.status?.value.toLowerCase() === "pending"
                      ? dateDiffCalc(intro) + " Days remaining"
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-align pagi-page">
          <div class="paginationClass">
            <pagination-controls
              (pageChange)="pageChange($event)"
              class="float-right"
              previousLabel="Prev"
              nextLabel="Next"
            ></pagination-controls>
          </div>

          <div class="user">
            <div class="btn-group">
              <button
                type="button"
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                {{ countPerPageText || "10 per page"
                }}<i class="fa fa-angle-down ml-1"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                x-placement="bottom-end"
                style="
                  position: absolute;
                  will-change: transform;
                  top: 0px;
                  left: 0px;
                  transform: translate3d(-69px, 42px, 0px);
                "
              >
                <button
                  (click)="checkVal('10PERPAGE', '10', '10 per page')"
                  class="dropdown-item"
                  type="button"
                >
                  10 Per Page
                </button>
                <button
                  class="dropdown-item"
                  value="25"
                  type="button"
                  (click)="checkVal('25PERPAGE', '25', '25 per page')"
                >
                  25 Per Page
                </button>
                <button
                  class="dropdown-item"
                  name="countPerPage"
                  value="50"
                  type="button"
                  (click)="checkVal('50PERPAGE', '50', '50 per page')"
                >
                  50 Per Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</main>
