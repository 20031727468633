import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../utils/auth-guard.service';
import { AddAccountComponent } from './add/add-account/add-account.component';
import { AddIsvComponent } from './add/add-isv/add-isv.component';
import { AddProductComponent } from './add/add-product/add-product.component';
import { AddRpComponent } from './add/add-rp/add-rp.component';
import { AllAccountComponent } from './list/all-account/all-account.component';
import { AllIsvComponent } from './list/all-isv/all-isv.component';
import { AllProductComponent } from './list/all-product/all-product.component';
import { AllRpComponent } from './list/all-rp/all-rp.component';
import { WsmNetworksComponent } from './add/wsm-networks/wsm-networks.component';
import { IntroRequestsComponent } from './list/intro-requests/intro-requests.component';
import { IntroproposalRpComponent } from './list/introproposal-rp/introproposal-rp.component';
import { IntroproposalIsvComponent } from './list/introproposal-isv/introproposal-isv.component';
import { AllOpportunitiesComponent } from './list/all-opportunities/all-opportunities.component';
import { AllDealsComponent } from './list/all-deals/all-deals.component';
import { ViewCommissionsComponent } from './list/view-commissions/view-commissions.component';
import { CharityRewardComponent } from './list/charity-reward/charity-reward.component';
import { RpCommissionsComponent } from './list/rp-commissions/rp-commissions.component';
import { SponsoredRpsComponent } from './list/sponsored-rps/sponsored-rps.component';
import { ReferralRewardsComponent } from './list/referral-rewards/referral-rewards.component';
import { IsvOnboardingComponent } from './add/Onboarding/isv-onboarding/isv-onboarding.component';
import { IsvOnboardingProgressTrackerComponent } from './add/Onboarding/isv-onboarding-progress-tracker/isv-onboarding-progress-tracker.component';
import { GettingStartedComponent } from './add/Onboarding/GS-STEP1/getting-started/getting-started.component';
import { TargetRolesComponent } from './add/Onboarding/GS-STEP1/target-roles/target-roles.component';
import { TargetIndustriesComponent } from './add/Onboarding/GS-STEP1/target-industries/target-industries.component';
import { TargetGeographiesComponent } from './add/Onboarding/GS-STEP1/target-geographies/target-geographies.component';
import { TargetDomainSubdomainComponent } from './add/Onboarding/GS-STEP1/target-domain-subdomain/target-domain-subdomain.component';
import { CustomerRefComponent } from './add/Onboarding/GS-STEP2/customer-ref/customer-ref.component';
import { PrevoiusUsExpComponent } from './add/Onboarding/GS-STEP2/prevoius-us-exp/prevoius-us-exp.component';
import { ProductDetailsComponent } from './add/Onboarding/GS-STEP2/product-details/product-details.component';
import { RoiPaybackComponent } from './add/Onboarding/GS-STEP2/roi-payback/roi-payback.component';
import { TargetSizeCompComponent } from './add/Onboarding/GS-STEP2/target-size-comp/target-size-comp.component';
import { SalesGetStartedComponent } from './add/Onboarding/GS-STEP3/sales-get-started/sales-get-started.component';
const routes: Routes = [
  {
    path: 'add-isv',
    component: AddIsvComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-rp',
    component: AddRpComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-account',
    component: AddAccountComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-product',
    component: AddProductComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-isv/:id',
    component: AddIsvComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-rp/:id',
    component: AddRpComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-account/:id',
    component: AddAccountComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-product/:id',
    component: AddProductComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'all-isv',
    component: AllIsvComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: 'all-rp',
    component: AllRpComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'all-product',
    component: AllProductComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'all-account',
    component: AllAccountComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'wsm-network',
    component: WsmNetworksComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'intro-requests',
    component: IntroRequestsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'introporposal-rp',
    component: IntroproposalRpComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'introporposal-isv',
    component: IntroproposalIsvComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'all-opportunities',
    component: AllOpportunitiesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'all-deals',
    component: AllDealsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'view-commissions',
    component: ViewCommissionsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'charity-reward',
    component: CharityRewardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'rp-commissions',
    component: RpCommissionsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'sponsored-rps',
    component: SponsoredRpsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'referral-rewards',
    component: ReferralRewardsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'product-onboarding',
    component: IsvOnboardingComponent,
  },
  {
    path: 'onboarding-progress',
    component: IsvOnboardingProgressTrackerComponent,
  },
  {
    path: 'product-onboarding/step1/get-started',
    component: GettingStartedComponent,
  },
  {
    path: 'product-onboarding/step1/target-roles',
    component: TargetRolesComponent,
  },
  {
    path: 'product-onboarding/step1/target-industries',
    component: TargetIndustriesComponent,
  },
  {
    path: 'product-onboarding/step1/target-geographies',
    component: TargetGeographiesComponent,
  },
  {
    path: 'product-onboarding/step1/target-domain-subdomain',
    component: TargetDomainSubdomainComponent,
  },
  {
    path: 'product-onboarding/step2/customer-ref',
    component: CustomerRefComponent,
  },
  {
    path: 'product-onboarding/step2/previous-us-exp',
    component: PrevoiusUsExpComponent,
  },
  {
    path: 'product-onboarding/step2/product-details',
    component: ProductDetailsComponent,
  },
  {
    path: 'product-onboarding/step2/roi-payback',
    component: RoiPaybackComponent,
  },
  {
    path: 'product-onboarding/step2/target-size-comp',
    component: TargetSizeCompComponent,
  },
  {
    path: 'product-onboarding/step3/sales-process',
    component: SalesGetStartedComponent,
  },
  // {
  //   path: 'product-onboarding',
  //   component: IsvOnboardingComponent,
  // },
  // {
  //   path: 'onboarding-progress',
  //   component: IsvOnboardingProgressTrackerComponent,
  // },
  // {
  //   path: 'product-onboarding/step1/get-started',
  //   component: GettingStartedComponent,
  // },
  // {
  //   path: 'product-onboarding/step1/target-roles',
  //   component: TargetRolesComponent,
  // },
  // {
  //   path: 'product-onboarding/step1/target-industries',
  //   component: TargetIndustriesComponent,
  // },
  // {
  //   path: 'product-onboarding/step1/target-geographies',
  //   component: TargetGeographiesComponent,
  // },
  // {
  //   path: 'product-onboarding/step1/target-domain-subdomain',
  //   component: TargetDomainSubdomainComponent,
  // },
  // {
  //   path: 'product-onboarding/step2/customer-ref',
  //   component: CustomerRefComponent,
  // },
  // {
  //   path: 'product-onboarding/step2/previous-us-exp',
  //   component: PrevoiusUsExpComponent,
  // },
  // {
  //   path: 'product-onboarding/step2/product-details',
  //   component: ProductDetailsComponent,
  // },
  // {
  //   path: 'product-onboarding/step2/roi-payback',
  //   component: RoiPaybackComponent,
  // },
  // {
  //   path: 'product-onboarding/step2/target-size-comp',
  //   component: TargetSizeCompComponent,
  // },
  // {
  //   path: 'product-onboarding/step3/sales-process',
  //   component: SalesGetStartedComponent,
  // },

  // {
  //   path: "",
  //   redirectTo: "add-isv",
  //   pathMatch: "full",
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
