import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GetDataService } from 'src/app/services/get-data.service';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { DropdownService } from 'src/app/services/dropdown.service';
import { CommonService } from 'src/app/utils/common.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-all-account',
  templateUrl: './all-account.component.html',
  styleUrls: ['./all-account.component.scss'],
})
export class AllAccountComponent implements OnInit {
  countPerPageText = '10 per page';
  // indutryFilText: string = 'Industry';
  // companySizeFilText: string = 'Company Size';
  // countryFilText: string = 'Country';
  // statusFilText: string = 'Status';

  indutryFilArr: any[] = [];
  companySizeFilArr: any[] = [];
  countryFilArr: any[] = [];
  statusFilArr: any[] = [];

  p: any = 1;
  public imageUrl = environment.apiUrl;

  accountStatusArr: any = [];
  industryArr: any = [];

  industryValue: any = [];
  companyValue: any = [];
  countryValue: any = [];
  statusValue: any = [];

  countriesArr: any = [];
  companySizeArr: any = [];
  resArr: any = [];
  totalPage: any;
  loader: boolean = true;
  public filters = {
    searchQuery: {
      accountName: '',
      industry: '',
      size: '',
      country: '',
      status: '',
    },
    count: 10,
    skip: 0,
  };

  accountName: FormControl = new FormControl(); // SearchTerm
  industry: FormControl = new FormControl();
  size: FormControl = new FormControl();
  country: FormControl = new FormControl();
  status: FormControl = new FormControl();

  // NGX-MULTI SELECER
  // dropdownSettings: any = {};

  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
  }

  ngOnInit(): void {
    this.getaccountStatus();
    this.getIndustry();
    this.getcompanySize();
    this.getCountries();

    this.fetchAccount();

    // // NGX-MULTI SELECER
    // this.dropdownSettings = {
    //   idField: '_id',
    //   textField: 'value',
    //   itemsShowLimit: 2,
    //   allowSearchFilter: true,
    //   enableCheckAll: false,
    // };
    // // #163079

    this.accountName.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(
        (term) => {
          this.filters.count = this.filters.count ? this.filters.count : 0;
          this.filters.skip = 0;
          this.p = 1;
          if (term) {
            this.filters.searchQuery.accountName = term;
            this.fetchAccount();
          } else if (term === '') {
            this.filters.searchQuery.accountName = '';
            this.fetchAccount();
          }
          return;
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;
          this.common.showToastMessage(`ERROR`, `Opps... Something went wrong`);
          return;
        }
      );
  }

  fetchAccount() {
    this.getData
      .getAllAccount(this.filters)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe(
        (res) => {
          this.resArr = res.details.accounts;
          this.totalPage = res.details.totalPage;
          if (this.resArr.length <= 0 && this.totalPage === 0) {
            this.common.showToastMessage(`ERROR`, `No Records Found`);
            return;
          }
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;
          this.common.showToastMessage(`ERROR`, `No Records Found`);
          return;
        }
      );
  }

  checkVal(opt: string, e: any, acVal?: any) {
    this.loader = true;

    if (this.accountName.value === '') {
      this.filters.searchQuery.accountName = '';
    }

    if (opt === '10PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = acVal;
    }
    if (opt === '25PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = '25 Per Page';
    }
    if (opt === '50PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = '50 Per Page';
    }

    if (opt === 'INDUSTRY') {
      let tempIndustry = '';
      this.industryValue.forEach(
        (a, b) =>
          (tempIndustry = (tempIndustry ? `${tempIndustry},` : '') + a._id)
      );

      this.filters.searchQuery.industry = tempIndustry;
    }

    if (opt === 'COMPANYSIZE') {
      let tempCompanySize = '';
      this.companyValue.forEach(
        (a, b) =>
          (tempCompanySize =
            (tempCompanySize ? `${tempCompanySize},` : '') + a._id)
      );

      this.filters.searchQuery.size = tempCompanySize;
    }

    if (opt === 'COUNTRY') {
      let tempCountry = '';
      this.countryValue.forEach(
        (a, b) =>
          (tempCountry = (tempCountry ? `${tempCountry},` : '') + a.value)
      );

      this.filters.searchQuery.country = tempCountry;
    }

    if (opt === 'STATUS') {
      let tempStatus = '';
      this.statusValue.forEach(
        (a, b) => (tempStatus = (tempStatus ? `${tempStatus},` : '') + a._id)
      );

      this.filters.searchQuery.status = tempStatus;
    }

    this.filters.skip = 0;
    this.p = 1;

    this.fetchAccount();
  }

  clearItem(option, item) {
    this.loader = true;

    if (this.accountName.value === '') {
      this.filters.searchQuery.accountName = '';
    }

    if (option === 'INDUSTRY') {
      let tempIndustry = '';

      this.industryValue = this.industryValue.filter(
        (el) => el._id !== item._id
      );

      this.industryValue.forEach(
        (a, b) =>
          (tempIndustry = (tempIndustry ? `${tempIndustry},` : '') + a._id)
      );
      this.filters.searchQuery.industry = tempIndustry;
    }

    if (option === 'COMPANYSIZE') {
      let tempCompanySize = '';

      this.companyValue = this.companyValue.filter((el) => el._id !== item._id);

      this.companyValue.forEach(
        (a, b) =>
          (tempCompanySize =
            (tempCompanySize ? `${tempCompanySize},` : '') + a._id)
      );
      this.filters.searchQuery.size = tempCompanySize;
    }

    if (option === 'COUNTRY') {
      let tempCountry = '';

      this.countryValue = this.countryValue.filter((el) => el._id !== item._id);

      this.countryValue.forEach(
        (a, b) =>
          (tempCountry = (tempCountry ? `${tempCountry},` : '') + a.value)
      );
      this.filters.searchQuery.country = tempCountry;
    }

    if (option === 'STATUS') {
      let tempStatus = '';

      this.statusValue = this.statusValue.filter((el) => el._id !== item._id);

      this.statusValue.forEach(
        (a, b) => (tempStatus = (tempStatus ? `${tempStatus},` : '') + a._id)
      );
      this.filters.searchQuery.status = tempStatus;
    }

    this.filters.skip = 0;
    this.p = 1;

    this.fetchAccount();
  }

  pageChange(page) {
    this.loader = true;
    this.p = page;
    this.filters.skip = (page - 1) * this.filters.count;
    if (this.accountName.value === '') {
      this.filters.searchQuery.accountName = '';
    }

    this.fetchAccount();
  }

  /**
   *
   *  this.filters.searchQuery.industry = this.filters.searchQuery.industry
   *    ? this.filters.searchQuery.industry + `,${event?._id}`
   *     : event?._id;
   *  console.log(this.filters);
   *  this.filters.searchQuery.industry = this.filters.searchQuery.industry
   *    ? this.filters.searchQuery.industry + `,${event?._id}`
   *    : event?._id;
   *  console.log(this.filters);
   */

  onItemSelect(option, event) {
    if (option === 'INDUSTRY') {
      this.indutryFilArr.push(event?._id);
      this.filters.searchQuery.industry = this.indutryFilArr.join();
    }
  }
  onItemDeselect(option, event) {
    console.log(option, event);
    if (option === 'INDUSTRY') {
      const indexOfItem = this.indutryFilArr.findIndex(
        (el) => el === event._id
      );
      this.indutryFilArr.splice(indexOfItem, 1);

      this.filters.searchQuery.industry = this.indutryFilArr.join();
    }
  }

  resetFilters() {
    this.industry.reset();
    this.size.reset();
    this.country.reset();
    this.status.reset();

    this.filters = {
      searchQuery: {
        accountName: this.accountName.value ? this.accountName.value : '',
        industry: '',
        size: '',
        country: '',
        status: '',
      },
      skip: 0,
      count: this.filters.count ? this.filters.count : 6,
    };

    this.fetchAccount();
  }

  goToRequest(id) {
    this.router.navigate([`/admin/add-account/${id}`]);
  }

  async getIndustry() {
    const res = await this.dropdown.getlinkedinIndustryDropdown().toPromise();
    this.industryArr = res?.details?.industry;
    // console.log(this.industryArr);
  }

  async getcompanySize() {
    const res = await this.dropdown
      .getlinkedinCompanySizeDropdown()
      .toPromise();
    this.companySizeArr = res?.details?.size;
  }

  async getCountries() {
    const res = await this.dropdown.getCountry().toPromise();
    this.countriesArr = res?.details?.countries;
  }
  async getaccountStatus() {
    const res = await this.dropdown.getAccountStatus().toPromise();
    this.accountStatusArr = res?.details?.accountStatus;
  }
}
