<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <p class="tb-head">
        All Accounts {{ totalPage ? "(" + totalPage + ")" : "" }}
      </p>
      <div class="search-box rps">
        <div class="search-in">
          <div class="in-search-sec search-bar">
            <input
              type="text"
              id="searchBar"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="accountName"
              autofocus
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>

          <!-- <mat-form-field class="searchBar-rp" appearance="outline">
            <mat-label>Search</mat-label>
            <input
              matInput
              autofocus
              placeholder="Search"
              [formControl]="accountName"
            />
          </mat-form-field> -->
        </div>

        <!-- <ng-multiselect-dropdown
          name="city"
          class="cutom-dropdown"
          [placeholder]="'Select Industry'"
          [data]="industryArr"
          [settings]="dropdownSettings"
          (onSelect)="onItemSelect('INDUSTRY', $event)"
          (onDeSelect)="onItemDeselect('INDUSTRY', $event)"
        >
        </ng-multiselect-dropdown> -->

        <div class="search-link">
          <p-multiSelect
            [options]="industryArr"
            [(ngModel)]="industryValue"
            defaultLabel="Industry"
            optionLabel="value"
            [maxSelectedLabels]="1"
            [virtualScroll]="true"
            itemSize="30"
            scrollHeight="250px"
            (onChange)="checkVal('INDUSTRY', $event)"
          ></p-multiSelect>
          <p-multiSelect
            [options]="companySizeArr"
            [(ngModel)]="companyValue"
            defaultLabel="Company Size"
            optionLabel="value"
            [maxSelectedLabels]="1"
            scrollHeight="250px"
            (onChange)="checkVal('COMPANYSIZE', $event)"
          ></p-multiSelect>
          <p-multiSelect
            [options]="countriesArr"
            [(ngModel)]="countryValue"
            defaultLabel="Country"
            optionLabel="value"
            [maxSelectedLabels]="1"
            [virtualScroll]="true"
            itemSize="30"
            scrollHeight="250px"
            (onChange)="checkVal('COUNTRY', $event)"
          ></p-multiSelect>
          <p-multiSelect
            [options]="accountStatusArr"
            [(ngModel)]="statusValue"
            defaultLabel="Status"
            optionLabel="value"
            [maxSelectedLabels]="1"
            scrollHeight="250px"
            (onChange)="checkVal('STATUS', $event)"
          ></p-multiSelect>

          <br />
          <div class="col-md-12 mb-4 text-white text-center d-inline">
            <span
              *ngFor="let item of industryValue"
              class="badge customcolor msg"
              >{{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('INDUSTRY', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span
              *ngFor="let item of companyValue"
              class="badge customcolor msg"
              >{{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('COMPANYSIZE', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span
              *ngFor="let item of countryValue"
              class="badge customcolor msg"
              >{{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('COUNTRY', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span *ngFor="let item of statusValue" class="badge customcolor msg"
              >{{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('STATUS', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
          </div>
          <!-- <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ indutryFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="indutryFilText !== 'Industry'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('INDUSTRY', '', 'Industry')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of industryArr"
                      (click)="checkVal('INDUSTRY', i._id, i.value)"
                      >{{ i.value }}</a
                    >
                  </div>
                </div>
              </li>

             

              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ companySizeFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': companySizeArr.length > 12 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="companySizeFilText !== 'Company Size'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('COMPANYSIZE', '', 'Company Size')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of companySizeArr"
                      (click)="checkVal('COMPANYSIZE', i._id, i.value)"
                    >
                      {{ i.value }}</a
                    >
                  </div>
                </div>
              </li>

              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ countryFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="countryFilText !== 'Country'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('COUNTRY', '', 'Country')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of countriesArr"
                      (click)="checkVal('COUNTRY', i.value, i.value)"
                    >
                      {{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ statusFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      *ngIf="statusFilText !== 'Status'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('STATUS', '', 'Status')"
                      >{{ "Clear Filter" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let i of accountStatusArr"
                      (click)="checkVal('STATUS', i._id, i.value)"
                    >
                      {{ i.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="spinner-border custom_loaders" role="status" *ngIf="loader">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="table-order upper" *ngIf="!loader">
        <table>
          <tbody>
            <tr>
              <th>Account</th>
              <th>Primary contact</th>
              <th>Country</th>
              <th>industry</th>
              <th>Company size</th>
              <th>Deals closed</th>
              <th>Amount closed</th>
              <th>Status</th>
            </tr>

            <tr
              *ngFor="
                let account of resArr
                  | paginate
                    : {
                        itemsPerPage: filters.count,
                        currentPage: p,
                        totalItems: totalPage
                      }
              "
            >
              <td class="td-img-box">
                <div
                  class="td-img"
                  *ngIf="
                    account?.logo &&
                    account?.hasOwnProperty('logo') &&
                    account?.logo !== ''
                  "
                >
                  <img src="{{ imageUrl }}{{ account?.logo }}" alt="img" />
                </div>

                <div
                  class="td-img"
                  *ngIf="
                    !account?.logo ||
                    !account.hasOwnProperty('logo') ||
                    account?.logo === ''
                  "
                >
                  <img src="../assets/images/user.png" alt="img" />
                </div>
                <span (click)="goToRequest(account?._id)" class="pointer">
                  {{ account?.accountName }}
                </span>
              </td>
              <td>
                <span>
                  {{
                    account?.user?.firstName ? account?.user?.firstName : ""
                  }} </span
                ><span>
                  {{ account?.user?.lastName ? account?.user?.lastName : "" }}
                </span>
              </td>
              <td>{{ account?.country }}</td>
              <td>{{ account?.industry?.value }}</td>
              <td>{{ account?.size?.value }}</td>
              <td>{{ account?.dealsClosed || "" }}</td>
              <td>{{ account?.amountClosed || "" }}</td>
              <td class="blue upper-a">{{ account?.status?.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div *ngIf="resArr.length <= 0" class="lead adjustText">
        No Records Found
      </div> -->
      <div class="d-align pagi-page" *ngIf="!loader">
        <div class="paginationClass">
          <pagination-controls
            (pageChange)="pageChange($event)"
            previousLabel="Prev"
            nextLabel="Next"
            class="float-right"
          ></pagination-controls>
        </div>

        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ countPerPageText || "10 Per Page"
              }}<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                (click)="checkVal('10PERPAGE', '10', '10 Per Page')"
                class="dropdown-item"
                type="button"
              >
                10 Per Page
              </button>
              <button
                class="dropdown-item"
                value="25"
                type="button"
                (click)="checkVal('25PERPAGE', '25', '25 Per Page')"
              >
                25 Per Page
              </button>
              <button
                class="dropdown-item"
                name="countPerPage"
                value="50"
                type="button"
                (click)="checkVal('50PERPAGE', '50', '50 Per Page')"
              >
                50 Per Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
