<div class="panel panel-primary setup-content" id="step-2">
  <section class="mail-verify lets padding-88">
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5>
            Which role/title do you target first as your best entry point in a
            company for {{ productName }} ?
            <span class="ml-0"
              ><button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or
                                        decision maker;  The BEST ENTRY POINT person could well
                                        become your eventual main Champion"
              >
                <img src="../assets/images/info.svg" />
              </button>
            </span>
          </h5>
        </div>
        <div class="form-field sign-form">
          <form [formGroup]="targetRolesForm">
            <div class="form-sec domain mb-15">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <p class="formp">
                      <img
                        src="../assets/images/idea.png"
                        class="mr-1"
                        alt=""
                      />You can define multiple keywords as tags to describe
                      best the relevant roles and titles
                    </p>
                    <mat-label>Best entry point</mat-label>
                    <!--	<input
											type="text"
											class="form-control"
											formControlName="bestEntryPoint"
											placeholder="E.g. Global Head , Quality Management"
											/> -->
                    <mat-form-field
                      class="matFieldNoLabel example-chip-list"
                      appearance="outline"
                    >
                      <mat-chip-list
                        #chipList
                        aria-label="Role selection"
                        formControlName="bestEntryPoint"
                      >
                        <mat-chip
                          *ngFor="let role of roles"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="remove(role)"
                        >
                          {{ role }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >close</mat-icon
                          >
                        </mat-chip>

                        <input
                          placeholder="E.g. Global Head , Quality Management"
                          [matChipInputFor]="chipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur"
                          (matChipInputTokenEnd)="add($event)"
                        />
                      </mat-chip-list>
                    </mat-form-field>
                    <div
                      *ngIf="
                        targetRolesForm.get('bestEntryPoint').touched &&
                        targetRolesForm.value.bestEntryPoint == ''
                      "
                      class="text-danger"
                    >
                      *Best entry point is required
                    </div>
                  </div>
                  <div class="form-group">
                    <mat-label>Second Priority Role</mat-label>
                    <!--<input
										type="text"
										class="form-control"
										formControlName="secondaryPriorityRole"
										placeholder="E.g. Quality Engineering, Testing Head"
										/> -->
                    <mat-form-field
                      class="matFieldNoLabel example-chip-list form-group"
                      appearance="outline"
                    >
                      <mat-chip-list
                        #chipList1
                        aria-label="Role selection"
                        formControlName="secondaryPriorityRole"
                      >
                        <mat-chip
                          *ngFor="let entry of secondEntry"
                          [selectable]="selectable1"
                          [removable]="removable1"
                          (removed)="removeSecondEntry(entry)"
                        >
                          {{ entry }}
                          <mat-icon matChipRemove *ngIf="removable1"
                            >close</mat-icon
                          >
                        </mat-chip>

                        <input
                          placeholder="E.g. Global Head , Quality Management"
                          [matChipInputFor]="chipList1"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur1"
                          (matChipInputTokenEnd)="addSecond($event)"
                        />
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <mat-label>Third Priority Role</mat-label>
                    <!-- <input
											type="text"
											class="form-control"
											formControlName="thirdPriorityRole"
											placeholder="E.g. Jira Management"
											/> -->
                    <mat-form-field
                      class="matFieldNoLabel example-chip-list form-group"
                      appearance="outline"
                    >
                      <mat-chip-list
                        #chipList2
                        aria-label="Role selection"
                        formControlName="thirdPriorityRole"
                      >
                        <mat-chip
                          *ngFor="let entry of thirdEntry"
                          [selectable]="selectable2"
                          [removable]="removable2"
                          (removed)="removeThirddEntry(entry)"
                        >
                          {{ entry }}
                          <mat-icon matChipRemove *ngIf="removable2"
                            >close</mat-icon
                          >
                        </mat-chip>

                        <input
                          placeholder="E.g. Global Head , Quality Management"
                          [matChipInputFor]="chipList2"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur2"
                          (matChipInputTokenEnd)="addThird($event)"
                        />
                      </mat-chip-list>
                    </mat-form-field>
                    <p *ngIf="errMsg" class="text-danger">{{ errMsg }}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="theme-btn dom width-fit text-right">
          <button
            class="btn nextBtn"
            [class.active]="targetRolesForm.valid"
            [disabled]="targetRolesForm.invalid"
            (click)="AddTargetRoles()"
            type="submit"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
