<div class="panel panel-primary setup-content" id="step-6">
  <section class="mail-verify lets padding-88">
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5>
            Have you had any experience chasing US based customers?
            <span class="ml-0">
              <button type="button" class="info" data-toggle="tooltip" data-placement="bottom" title=""
                data-original-title="The best entry point person is NOT the eventual buyer or decision maker;  The BEST ENTRY POINT person could well become your eventual main Champion">
                <img src="../assets/images/info.svg" />
              </button>
            </span>
          </h5>
        </div>

        <div class="rqi-btn">
          <div class="theme-btn regu width-fit blue text-center">
            <button (click)="showExpForm = true; updateValue('true')" class="btn mr-1"
              [class.selectedbutton]="showExpForm == true">
              Yes
            </button>
            <button (click)="showExpForm = false; updateValue('false')" class="btn ml-1"
              [class.selectedbutton]="showExpForm == false">
              No
            </button>
          </div>
        </div>

        <div *ngIf="showExpForm" class="form-field sign-form">
          <form [formGroup]="experienceForm">
            <div formArrayName="account" class="form-sec domain mb-15">
              <p class="formp">
                Which accounts did you chase and what were the outcomes?
              </p>
              <div *ngFor="let item of account().controls; let i = index" [formGroupName]="i" class="group-box">
                <div class="group-in merge">
                  <div class="form-group">
                    <label for="exampleInputPassword1" class="d-lab">
                      Account {{ i + 1 }}
                      <a *ngIf="i > 0" href="javascript:void(0)" (click)="removeAccount(i)" class="remove">Remove</a>
                    </label>
                    <input type="text" class="form-control" formControlName="name" placeholder="Account Name" />
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="outcome" placeholder="Outcome" />
                  </div>
                  <div>
                    <select required="" formControlName="geography" class="form-control" placeholder='geography'>
                      <option value="" disabled="" selected="" hidden="">
                        Select geographies
                      </option>
                      <option *ngFor="let item of geographies" value="{{ item._id }}">
                        {{ item.value }}
                      </option>
                    </select>
                    <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
                  </div>
                </div>
              </div>
              <button (click)="addAcc()" type="button" class="btn btn-form">
                + Add More
              </button>
            </div>
          </form>
        </div>
        <div class="theme-btn dom width-fit text-right">
          <button (click)="addPreviousUsExp()" class="btn nextBtn" [class.active]="experienceForm.valid"
            [disabled]="experienceForm.invalid" type="button">
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>