<div class="panel panel-primary setup-content" id="step-5">
  <section class="mail-verify lets padding-88">
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5>
            Do you target all sizes of companies for <br />
            {{ productName || "N/A" }}?<span class="ml-0"><button type="button" class="info" data-toggle="tooltip"
                data-placement="bottom" title="" data-original-title="The best entry point person is NOT the eventual buyer or
                            decision maker;  The BEST ENTRY POINT person could well
                            become your eventual main Champion">
                <img src="../assets/images/info.svg" /></button></span>
          </h5>
        </div>

        <div class="rqi-btn">
          <div class="theme-btn regu width-fit blue text-center">
            <button (click)="isTargetSizeMeasured('true'); showTargetSizeForm = false" class="btn mr-1"
              [class.selectedbutton]="showTargetSizeForm == false">
              Yes
            </button>
            <button (click)="isTargetSizeMeasured('false'); showTargetSizeForm = true" class="btn ml-1"
              [class.selectedbutton]="showTargetSizeForm == true">
              No
            </button>
          </div>
        </div>

        <div *ngIf="showTargetSizeForm" class="form-field sign-form">
          <form [formGroup]="targetSizeForm">
            <div class="form-sec domain normal mb-15">
              <div class="row">
                <div class="col-12">
                  <div class="form-group select-fa">
                    <label for="exampleFormControlSelect1">Business size</label>
                    <select required class="form-control" formControlName="businessSize">
                      <option value="" disabled="" selected="" hidden="">
                        Select size of business
                      </option>
                      <option *ngFor="let item of businessSize" value="{{ item._id }}">
                        {{ item.value }}
                      </option>
                    </select>
                    <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
                  </div>
                </div>
                <!-- <div class="col-12">
                  <div class="form-group">
                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Large accounts" />
                    <input type="text" class="form-control border-top-0" id="exampleInputPassword1"
                      placeholder="Small &amp; Medium size Businesses" />
                  </div>
                </div> -->
              </div>
            </div>
          </form>
        </div>
        <div class="theme-btn dom width-fit text-right">
          <button (click)="addtargetSizeDetails()" [disabled]="targetSizeForm.invalid"
            [class.active]="targetSizeForm.valid" class="btn nextBtn" type="button">
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>