<div class="head-mr"></div>
<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <p class="tb-head capitalized">Platform Commissions</p>
      <div class="rewards">
        <div class="box">
          <h3>Total Commissions</h3>
          <h2>
            <span>{{ this.euro }}</span
            >{{ getTotalRewards() | conversion }}
          </h2>
        </div>
      </div>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input (keyup)="search($event)" type="text" placeholder="Search" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div class="search-link">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    ISV<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div
                    style="max-height: 200px; overflow-y: scroll"
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="btn dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      href="javascript:void(0)"
                      (click)="filterByIsv(item._id)"
                      class="dropdown-item"
                      *ngFor="let item of isv"
                      >{{ item?.companyName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                  High Level Connector<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div
                    class="dropdown-menu"
                    style="max-height: 200px; overflow-y: scroll"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="btn dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      (click)="filterByRP(rp._id)"
                      class="dropdown-item"
                      *ngFor="let rp of allRps"
                      href="javascript:void(0)"
                      >{{ rp?.firstName + " " + rp?.lastName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Account<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div
                    style="
                      max-height: 200px;
                      overflow-y: scroll;
                      max-width: -webkit-fill-available;
                    "
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="btn dropdown-toggle"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      href="javascript:void(0)"
                      (click)="filterByAccount(item._id)"
                      class="dropdown-item"
                      *ngFor="let item of account"
                      >{{ item?.accountName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Date<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="btn dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      (click)="filterByDays(7)"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      >Last 7 Days</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="filterByDays(30)"
                      href="javascript:void(0)"
                      >Last 30 Days</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="filterByDays(90)"
                      href="javascript:void(0)"
                      >Last 90 Days</a
                    >
                  </div>
                </div>
              </li>

              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Status<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="btn dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      class="dropdown-item"
                      (click)="filterByPaymentStatus(pay._id)"
                      *ngFor="let pay of paymentStat"
                      href="javascript:void(0)"
                      >{{ pay?.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="table-order upper">
        <table>
          <tbody>
            <tr>
              <th>ISV</th>
              <th>High Level Connector</th>
              <th>Account</th>
              <th>DEAL DATE</th>
              <th>Deal Value ({{ this.euro }})</th>
              <th>RATE</th>
              <th>COMMISSION ({{ this.euro }})</th>
              <th>Status</th>
            </tr>
            <tr
              *ngFor="
                let com of commissions
                  | paginate
                    : {
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: totalRecords
                      }
              "
            >
              <td>{{ com?.seller.companyName }}</td>
              <td>
                {{ com.rpProfile?.firstName + " " + com.rpProfile?.lastName }}
              </td>

              <td>{{ com.account?.accountName }}</td>
              <td>
                {{
                  com?.newCompletionDate
                    ? sharedService.formatDays(com?.newCompletionDate)
                    : ""
                }}
              </td>
              <td>{{ com?.finalDealAmount | conversion }}</td>
              <td>{{ com?.platformRate }}</td>
              <td>{{ com?.platformValue | conversion }}</td>
              <td>
                {{ com?.status.value }}
                <!-- <form>
                                    <div class="form-sec tab-select min-96 mb-0">
                                        <div class="form-group mb-0">
                                            <div class="select-fa">
                                                <select class="form-control btn paid">
                                                    <option>PAID</option>
                                                    <option>UNPAID</option>

                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </form> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-align pagi-page">
        <div class="paginationClass">
          <pagination-controls
            (pageChange)="handlePageChange($event)"
            previousLabel="Prev"
            nextLabel="Next"
            responsive="true"
          ></pagination-controls>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ pageSize }} per page<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                *ngFor="let page of pageSizes"
                class="dropdown-item"
                (click)="handlePageSizeChange(page)"
                type="button"
              >
                {{ page }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
