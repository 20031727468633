import { GetDataService } from 'src/app/services/get-data.service';
import { SponsorARpComponent } from './../../list/sponsor-a-rp/sponsor-a-rp.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/utils/common.service';
import Swal from 'sweetalert2';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userDetails: any;
  selected: any;
  constructor(
    public auth: AuthService,
    public common: CommonService,
    public dialog: MatDialog,
    private dataser: GetDataService,
    public sharedService: SharedServiceService
  ) {
    this.userDetails = localStorage.getItem('currentUser');
    this.userDetails = JSON.parse(this.userDetails);
  }

  ngOnInit(): void {
    if (localStorage.getItem('currency')) {
      this.selected = localStorage.getItem('currency');
    } else {
      this.selected = 'USD';
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(SponsorARpComponent, {
      panelClass: 'my-custom-dialog-class',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  saveStatus(type) {
    localStorage.setItem('status', type);
    this.sharedService.sendMessage('Event Calling');
  }

  isShowDivIf = false;

  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  logoutUser() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    this.auth.logout();

    Toast.fire({
      icon: 'success',
      title: 'User logged out',
    });
  }

  conversionCurrency(val) {
    if (val) {
      this.dataser.currentCurr.next(val);
      localStorage.setItem('currency', val);
    }
  }
}
