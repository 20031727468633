<!-- <div class="panel panel-primary setup-content" id="step-2">
  <section class="mail-verify lets">
    <div class="col-12 p-0">
      <div class="alert-box lets-alert">
        <div class="alert alert-warning" role="alert">
          <p>
            <img src="../assets/images/idea.png" class="mr-1" alt="" />Now that
            your Product is on-boarded, let's get deeper in the understanding of
            the marketing aspects (differentiators, positioning, targeted
            sub-market, target audience, references, ...) that will eventually
            help us build the right materials for the targeted territory.
          </p>
        </div>
      </div>
    </div>
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5 class="max-width">
            Help us understand more about <br />
            {{ productName }}
            <span class="ml-0"
              ><button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or decision maker;  The BEST ENTRY POINT person could well become your eventual main Champion"
              >
                <img src="../assets/images/info.svg" />
              </button>
            </span>
          </h5>
        </div>
        <div class="form-field sign-form">
          <form [formGroup]="productDetailForm">
            <div class="form-sec product-det">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="group-in">
                      <p class="formp">
                        What are the main differentiators or Unique Selling
                        Points (USP) of {{ productName }} ?
                      </p>
                    </div>

                    
                    <div formArrayName="usp">
                      <div
                        *ngFor="
                          let u of usp().controls;
                          let i = index;
                          let last = last
                        "
                        [formGroupName]="i"
                        class="group-in pb-3 uspInputWrapper"
                      >
                        <label class="d-lab" for="exampleInputPassword1"
                          >Differentiator or USP {{ i + 1 }}
                          <a
                            *ngIf="i > 0"
                            href="javascript:void(0)"
                            (click)="removeUsp(i)"
                            class="remove"
                            >Remove</a
                          >
                        </label>
                        <input
                          type="text"
                          placeholder="USP"
                          class="form-control"
                          [ngClass]="{ active: !last }"
                          formControlName="usp"
                          placeholder=""
                        />
                      </div>
                    </div>
                    
                    <button
                      type="button"
                      (click)="addUsp()"
                      class="btn btn-form"
                    >
                      + Add More
                    </button>
                  </div>
                  <div class="form-group">
                    <div class="select-form select-fa">
                      <p class="formp">
                        What, according to you, represents your main
                        competition? (it can be false or adjacent or direct
                        competition).
                      </p>
                    </div>

                    
                    <div formArrayName="competitor">
                      <div
                        *ngFor="
                          let comp of competitor().controls;
                          let j = index
                        "
                        [formGroupName]="j"
                        class="select-form"
                      >
                        <div class="select-fa">
                          <label style="width: 100%" for="select"
                            ><span style="float: left"
                              >Competitor {{ j + 1 }}</span
                            >
                            <a
                              *ngIf="j > 0"
                              href="javascript:void(0)"
                              (click)="removeComp(j)"
                              style="float: right"
                              class="remove"
                              >Remove</a
                            >
                          </label>
                        </div>

                        <div class="select-fa">
                          <select
                            required=""
                            class="form-control"
                            formControlName="competitor"
                          >
                            <option value="" disabled="" selected="" hidden="">
                              Select
                            </option>
                            <option value="null">Other</option>
                            <option
                              *ngFor="let item of competitorComp"
                              value="{{ item._id }}"
                            >
                              {{ item.accountName }}
                            </option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </div>

                        <input
                          type="text"
                          *ngIf="comp.get('competitor').value == 'null'"
                          class="form-control"
                          formControlName="competitorName"
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      (click)="addcompetitor()"
                      class="btn btn-form"
                    >
                      + Add More
                    </button>
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      What are some keywords to characterize and differentiate
                      {{ productName }} (think about competition) ?
                    </p>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="productCharacteristic"
                      placeholder="E.g. Brings more efficiency and productivity, Easy to implement"
                    />
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      Within Automated Design , what further categories or
                      specialty areas would you say {{ productName }} plays in
                      (if any)? (can be more than one)
                    </p>
                    
                    <mat-form-field
                      class="matFieldNoLabel bg-blue"
                      appearance="outline"
                    >
                      <mat-chip-list
                        #chipList
                        formControlName="productCategory"
                      >
                        <mat-chip
                          *ngFor="let c of categories"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="removeCategory(c)"
                        >
                          {{ c }}
                          <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                        </mat-chip>

                        <input
                          [matChipInputFor]="chipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur"
                          (matChipInputTokenEnd)="addCategory($event)"
                        />
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      Mention names of tools, toolchains, toolkits that
                      {{ productName }} integrates with.
                    </p>

                    <mat-form-field
                      class="matFieldNoLabel bg-blue"
                      appearance="outline"
                    >
                      <mat-chip-list
                        #toolField
                        formControlName="toolIntegration"
                      >
                        <mat-chip
                          *ngFor="let t of tools"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="removeTool(t)"
                        >
                          {{ t }}
                          <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                        </mat-chip>

                        <input
                          placeholder="E.g. Atlassian Jira, SalesForce, Cognos"
                          [matChipInputFor]="toolField"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur"
                          (matChipInputTokenEnd)="addTool($event)"
                        />
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      Quickly describe the unit of measure of your pricing model
                    </p>
                    <textarea
                      type="text"
                      class="form-control"
                      formControlName="unitOfMeasure"
                      placeholder="E.g. Unit counts or T-Shirt size type of model / Seats or Users of Impacted # of FTE, etc"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="theme-btn dom width-fit text-right">
          <button
            (click)="addProductDetails()"
            [class.active]="productDetailForm.valid"
            [disabled]="!productDetailForm.valid"
            class="btn nextBtn"
            type="button"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div> -->

<div class="panel panel-primary setup-content" id="step-2">
  <section class="mail-verify lets">
    <div class="col-12 p-0">
      <div class="alert-box lets-alert">
        <div class="alert alert-warning" role="alert">
          <p>
            <img src="../assets/images/idea.png" class="mr-1" alt="" />Now that
            your Product is on-boarded, let's get deeper in the understanding of
            the marketing aspects (differentiators, positioning, targeted
            sub-market, target audience, references, ...) that will eventually
            help us build the right materials for the targeted territory.
          </p>
        </div>
      </div>
    </div>
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5 class="max-width">
            Help us understand more about <br />
            {{ productName }}
            <span class="ml-0"
              ><button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or decision maker;  The BEST ENTRY POINT person could well become your eventual main Champion"
              ></button>
            </span>
          </h5>
        </div>
        <div class="form-field sign-form">
          <form [formGroup]="productDetailForm">
            <div class="form-sec product-det">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="group-in">
                      <p class="formp">
                        What are the main differentiators or Unique Selling
                        Points (USP) of {{ productName }} ?
                      </p>
                    </div>

                    <!-- USP ARRAY -->
                    <div formArrayName="usp">
                      <div
                        *ngFor="
                          let u of usp().controls;
                          let i = index;
                          let last = last
                        "
                        [formGroupName]="i"
                        class="group-in pb-3 uspInputWrapper"
                      >
                        <label class="d-lab" for="exampleInputPassword1"
                          >Differentiator or USP {{ i + 1 }} *
                          <a
                            *ngIf="i > 0"
                            href="javascript:void(0)"
                            (click)="removeUsp(i)"
                            class="remove"
                            >Remove</a
                          >
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          [ngClass]="{ active: !last }"
                          formControlName="usp"
                          placeholder="E.g. Quick time to value, Seamlessly integrates with existing ecosystem"
                        />
                      </div>
                    </div>
                    <!-- USP ARRAY -->
                    <button
                      type="button"
                      (click)="addUsp()"
                      class="btn btn-form"
                    >
                      + Add More
                    </button>
                  </div>
                  <div class="form-group">
                    <div class="select-form select-fa">
                      <p class="formp">
                        What, according to you, represents your main
                        competition? (it can be false or adjacent or direct
                        competition).
                      </p>
                    </div>

                    <!-- competitor ARRAY -->
                    <div formArrayName="competitor">
                      <div
                        *ngFor="
                          let comp of competitor().controls;
                          let j = index
                        "
                        [formGroupName]="j"
                        class="select-form"
                      >
                        <div class="select-fa">
                          <label style="width: 100%" for="select"
                            ><span style="float: left"
                              >Competitor {{ j + 1 }} *</span
                            >
                            <a
                              *ngIf="j > 0"
                              href="javascript:void(0)"
                              (click)="removeComp(j)"
                              style="float: right"
                              class="remove"
                              >Remove</a
                            >
                          </label>
                        </div>

                        <div class="select-fa">
                          <!-- <select
                            required=""
                            class="form-control"
                            formControlName="competitor"
                          >
                            <option value="" disabled="" selected="" hidden="">
                              Select
                            </option>
                            <option value="null">Other</option>
                            <option
                              *ngFor="let item of competitorComp"
                              value="{{ item._id }}"
                            >
                              {{ item.accountName }}
                            </option>
                          </select> -->
                          <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->

                          <input
                            type="text"
                            class="form-control"
                            formControlName="competitor"
                          />
                        </div>

                        <!-- <input
                          type="text"
                          *ngIf="comp.get('competitor').value == 'null'"
                          class="form-control"
                          formControlName="competitorName"
                        /> -->
                      </div>
                    </div>
                    <button
                      type="button"
                      (click)="addcompetitor()"
                      class="btn btn-form"
                    >
                      + Add More
                    </button>
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      What are some keywords to characterize and differentiate
                      {{ productName }} (think about competition) ?
                    </p>

                    <mat-form-field
                      class="matFieldNoLabel"
                      appearance="outline"
                    >
                      <mat-chip-list
                        formControlName="productCharacteristic"
                        #productArr
                      >
                        <mat-chip
                          *ngFor="let product of productCharacteristicArr"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="removeChar(product)"
                        >
                          {{ product }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >cancel</mat-icon
                          >
                        </mat-chip>
                        <input
                          placeholder="E.g. Brings more efficiency and productivity, Easy to implement"
                          [matChipInputFor]="productArr"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur"
                          (matChipInputTokenEnd)="addChar($event)"
                        />
                      </mat-chip-list>
                    </mat-form-field>
                    <!-- <input
                      type="text"
                      class="form-control"
                      formControlName="productCharacteristic"
                      placeholder="E.g. Brings more efficiency and productivity, Easy to implement"
                    /> -->
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      Within {{ subdomain }}, what further categories or
                      specialty areas would you say {{ productName }} plays in
                      (if any)? (can be more than one)
                    </p>
                    <!-- <input type="text" class="form-control" formControlName="productCategory" placeholder="" /> -->
                    <mat-form-field
                      class="matFieldNoLabel"
                      appearance="outline"
                    >
                      <mat-chip-list
                        #chipList
                        formControlName="productCategory"
                      >
                        <mat-chip
                          *ngFor="let c of categories"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="removeCategory(c)"
                        >
                          {{ c }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >close</mat-icon
                          >
                        </mat-chip>

                        <input
                          placeholder="E.g. Test Design Automation, Agile Testing"
                          [matChipInputFor]="chipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur"
                          (matChipInputTokenEnd)="addCategory($event)"
                        />
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      Mention names of tools, toolchains, toolkits that
                      {{ productName }} integrates with.
                    </p>
                    <!-- <input type="text" class="form-control" formControlName="toolIntegration" placeholder="E.g. Atlassian Jira, SalesForce, Cognos" /> -->
                    <mat-form-field
                      class="matFieldNoLabel"
                      appearance="outline"
                    >
                      <mat-chip-list
                        #toolField
                        formControlName="toolIntegration"
                      >
                        <mat-chip
                          *ngFor="let t of tools"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="removeTool(t)"
                        >
                          {{ t }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >close</mat-icon
                          >
                        </mat-chip>

                        <input
                          placeholder="E.g. Atlassian Jira, SalesForce, Cognos"
                          [matChipInputFor]="toolField"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="addOnBlur"
                          (matChipInputTokenEnd)="addTool($event)"
                        />
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                  <div class="form-group">
                    <p class="formp">
                      Quickly describe the unit of measure of your pricing model
                    </p>
                    <textarea
                      type="text"
                      class="form-control"
                      formControlName="unitOfMeasure"
                      placeholder="E.g. Unit counts or T-Shirt size type of model / Seats or Users of Impacted # of FTE, etc"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="theme-btn dom width-fit text-right">
          <button
            (click)="addProductDetails()"
            [class.active]="productDetailForm.valid"
            [disabled]="!productDetailForm.valid"
            class="btn nextBtn"
            type="button"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
