import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { CommonService } from 'src/app/utils/common.service';
import { getIntroReq } from 'src/app/interface/searchQuery';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-intro-requests',
  templateUrl: './intro-requests.component.html',
  styleUrls: ['./intro-requests.component.scss'],
})
export class IntroRequestsComponent implements OnInit {
  p: any = 1;
  countPerPageText = '10 Per Page';
  rpFilText = 'HLC';
  isvFilText = 'ISV';
  accountFilText = 'Account';
  dateRecievedFilText = 'Date Received';
  statusFilText = 'Status';

  resArr: any = [];
  totalPage: any;
  totalPageArr: any[] = [];

  searchTerm: FormControl = new FormControl();

  filters: getIntroReq = {
    count: '10',
    days: '',
    skip: '0',
    status: '',
    rpId: '',
    isvId: '',
    searchPattern: '',
  };

  rpArr: any = [];
  isvArr: any = [];
  statusArr: any = [];
  elementActive: any;

  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
  }

  ngOnInit(): void {
    this.fetchIntroReqs();
    this.getRpDropDown();
    this.getISVDropDown();
    this.introRequestStatus();

    // console.log(this.searchTerm);
    this.searchTerm.valueChanges
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe(
        (term) => {
          this.filters.count = this.filters.count
            ? `${this.filters.count}`
            : `${0}`;
          this.filters.skip = '0';
          if (term) {
            this.filters.searchPattern = term;
            this.fetchIntroReqs();
          } else if (term === '') {
            this.filters.searchPattern = '';
            this.fetchIntroReqs();
          }
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;
          this.totalPageArr = [];
          this.common.showToastMessage(`ERROR`, `Opps... Something Went Wrong`);
        }
      );
  }

  fetchIntroReqs() {
    this.getData.getIntroRequestsAdmin(this.filters).subscribe(
      (res) => {
        this.resArr = res.details?.data;
        this.totalPage = res.details?.total ? res.details.total : 0;
        if (this.resArr.length <= 0 && this.totalPage === 0) {
          this.common.showToastMessage(`ERROR`, 'No Records Found', 7000);
          return;
        }
      },
      (err) => {
        this.resArr = [];
        this.totalPage = 0;
        this.common.showToastMessage(`ERROR`, `No Records Found `);
      }
    );
  }

  pageChange(page) {
    this.p = page;
    const tempSkipVar = (page - 1) * parseInt(this.filters.count);

    this.filters.skip = `${tempSkipVar}`;

    const searchBar: any = document.getElementById('searchBar');
    if (searchBar.value === '') {
      this.filters.searchPattern = '';
    }

    this.fetchIntroReqs();
  }

  checkVal(option, e, accVal) {
    const searchBar: any = document.getElementById('searchBar');
    if (searchBar.value === '') {
      this.filters.searchPattern = '';
    }

    if (option === 'RP') {
      this.rpFilText = e ? `${e.firstName} ${e.lastName}` : 'HLC';
      this.filters.rpId = e._id ? e._id : '';
    }
    if (option === 'ISV') {
      this.isvFilText = accVal;
      this.filters.isvId = e;
    }
    if (option === 'STATUS') {
      this.statusFilText = accVal;
      this.filters.status = e;
    }

    if (option === '7DAYS') {
      this.dateRecievedFilText = accVal;
      this.filters.days = e;
    }
    if (option === '30DAYS') {
      this.dateRecievedFilText = accVal;
      this.filters.days = e;
    }
    if (option === '90DAYS') {
      this.dateRecievedFilText = accVal;
      this.filters.days = e;
    }

    if (option === 'CLEARDAYS') {
      this.dateRecievedFilText = accVal;
      this.filters.days = e;
    }

    if (option === '10PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (option === '25PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (option === '50PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }

    this.filters.count = this.filters.count ? this.filters.count : '6';
    this.filters.skip = '0';
    this.p = 1;

    this.fetchIntroReqs();
  }

  async getRpDropDown() {
    const res = await this.dropdown.getRpsList().toPromise();
    this.rpArr = res?.details?.data;
  }

  async getISVDropDown() {
    const res = await this.dropdown.getISVsDropDown().toPromise();
    this.isvArr = res?.details?.isv;
  }

  async introRequestStatus() {
    const res = await this.dropdown.introRequestsStatus().toPromise();
    this.statusArr = res?.details?.status;
  }
}
