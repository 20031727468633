import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/interface/user';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CommonService } from '../utils/common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    public http: HttpClient,
    private router: Router,
    private actRoute: ActivatedRoute,
    private common: CommonService
  ) {
    // this.currentUserSubject = new BehaviorSubject<User>(
    //   JSON.parse(localStorage.getItem('userinfo'))
    // );
    // this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(payload) {
    return this.http.post<any>(`${environment.apiUrl}v1/login`, payload).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user.details));
        this.currentUserSubject.next(user.details);
        return user;
      })
    );
  }

  public logout() {
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.common.showHeader.next(false);
    this.router.navigate(['/']);
    // window.location.href = `${window.location.protocol}//${window.location.host}`;
  }
}
