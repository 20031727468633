import { Validators, NgControl } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IsvService } from 'src/app/services/isv.service';
import { Component, Input, OnInit } from '@angular/core';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'app-target-domain-subdomain',
  templateUrl: './target-domain-subdomain.component.html',
  styleUrls: ['./target-domain-subdomain.component.scss'],
})
export class TargetDomainSubdomainComponent implements OnInit {
  @Input() step: StepModel;

  public domain: any;
  public subdomain;
  public domainForm: FormGroup;
  public productId: any;
  public productName: string;
  public toEdit: string;
  public productDetails: any;

  constructor(
    private stepService: StepsService,
    private shared: CommonService,
    public isv: IsvService,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private dropdown: DropdownService
  ) {
    this.productId = localStorage.getItem('productId');
    this.productName = localStorage.getItem('productName');
  }

  ngOnInit(): void {
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');
    this.getDomains();

    this.domainForm = this.fb.group({
      domain: ['', Validators.required],
      subDomain: ['', Validators.required],
    });
    this.domainForm.controls.subDomain.disable();
    if (this.toEdit) {
      this.getProductDetail();
    }
  }

  getProductDetail() {
    this.isv.getSellerProductRegData(this.productId, 1).subscribe(
      (data) => {
        this.productDetails = data.details.product;
        this.setFormValues();
      },
      (err) => {
        console.log(err);
        this.shared.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  onCompleteStep() {
    this.step.isComplete = true;
    if (this.step.isComplete) {
      document.getElementById('targetDomain').className = 'check mr-0 success';
    }
  }

  AddtargetDomain() {
    if (this.domainForm.invalid) {
      return;
    } else {
      this.isv.addDomain(this.domainForm.value, this.productId).subscribe(
        (data) => {
          this.onCompleteStep();
          this.stepService.onNextStep();
        },
        (err) => {
          console.log(err);
          this.shared.showToastMessage('ERROR', err.error.message, 5000);
        }
      );
    }
  }

  getDomains() {
    this.dropdown.getDomains().subscribe((res) => {
      this.domain = res.details.domain;
    });
  }

  getSubdomain(val) {
    if (val) {
      this.dropdown.getsubDomain(val).subscribe(
        (data) => {
          this.subdomain = data.details.subDomain;
          if (this.subdomain) {
            this.domainForm.controls.subDomain.enable();
          }
        },
        (err) => {
          console.log(err);
          this.shared.showToastMessage('ERROR', err.error.message, 5000);
          this.domainForm.controls.subDomain.disable();
        }
      );
    }
  }

  setFormValues() {
    this.getSubdomain(this.productDetails.domain._id);
    this.domainForm.patchValue({
      domain: this.productDetails.domain._id,
      subDomain: this.productDetails.subDomain._id,
    });
  }
}
