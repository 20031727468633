<main class="p-4 mt-3">
  <section class="table-board">
    <div class="0container-fluid">
      <p class="tb-head capitalized">
        All Opportunities {{ totalPage ? "- (" + totalPage + ")" : "" }}
      </p>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input
              autofocus
              type="text"
              id="searchBar"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="filterTerm"
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div class="search-link">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ quartersFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      *ngIf="quartersFilText !== 'Period'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('PERIOD', 'CLEAR', 'Period')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let qtr of quartersArr"
                      (click)="
                        checkVal(
                          'PERIOD',
                          qtr._id ? qtr._id : qtr.value,
                          qtr?.value
                        )
                      "
                      >{{ qtr?.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ isvFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    [ngClass]="{ 'overflow-class': isvArr.length > 12 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="isvFilText !== 'ISV'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('ISV', '', 'ISV')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let isv of isvArr; let i = index"
                      (click)="checkVal('ISV', isv._id, isv.companyName)"
                      >{{ isv?.companyName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ rpFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu overflow-class"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="rpFilText !== 'High Level Connector'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('RP', '', 'High Level Connector')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let rp of rpArr; let i = index"
                      (click)="
                        checkVal('RP', rp._id, rp.firstName + ' ' + rp.lastName)
                      "
                    >
                      <span> {{ rp?.firstName ? rp?.firstName : "" }} </span
                      ><span>
                        {{ rp?.lastName ? rp?.lastName : "" }}
                      </span>
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ accFilText || "Account"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="accArr.length > 12 ? 'overflow-class' : ''"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="accFilText !== 'Account'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('ACCOUNT', '', 'Account')"
                      >All</a
                    >

                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let acc of accArr; let i = index"
                      (click)="checkVal('ACCOUNT', acc._id, acc?.accountName)"
                      >{{ acc?.accountName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ geographyFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>

                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      *ngIf="geographyFilText !== 'Geography'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('GEO', '', 'Geography')"
                      >{{ "Clear Filetr" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let geo of geographyArr"
                      (click)="checkVal('GEO', geo._id, geo.value)"
                      >{{ geo?.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ industryFilText }}<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': industryArr.length > 12 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="industryFilText !== 'Industry'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('INDUSTRY', '', 'Industry')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let ind of industryArr"
                      (click)="checkVal('INDUSTRY', '', ind?.value, ind.value)"
                      >{{ ind?.value }}</a
                    >
                  </div>
                </div>
              </li>
              <!-- <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ domainFilText }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': domainArr.length > 12 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('DOMAIN', '', 'Domain')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let domain of domainArr"
                      (click)="checkVal('DOMAIN', domain._id, domain?.value)"
                      >{{ domain?.value }}</a
                    >
                  </div>
                </div>
              </li> -->
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ companySizeFilText || "Company Size"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{
                      'overflow-class': companySizeArr.length > 12
                    }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="companySizeFilText !== 'Company Size'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('COMSIZE', '', 'Company Size')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let cs of companySizeArr"
                      (click)="checkVal('COMSIZE', cs._id, cs?.value)"
                      >{{ cs?.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <!-- <i class="fa fa-filter"></i>  -->
                    {{ "Stage" }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{
                      'overflow-class': stageArr.length > 12
                    }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="stageFilText !== 'Stage'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('STAGE', '', 'Stage')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let stage of stageArr"
                      (click)="checkVal('STAGE', stage._id, stage?.name)"
                      >{{ stage?.name }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="table-order upper">
        <table>
          <tbody>
            <tr>
              <th class="text-center">ISV</th>
              <th class="text-center">High Level Connector</th>
              <th class="text-center">Account</th>
              <th class="text-center">target</th>
              <th class="text-center">Opportunity</th>
              <th class="text-center">Stage in progress</th>
              <th class="text-center">Expected closing quarter</th>
              <th class="text-center">Expected Deal amount</th>
              <th class="text-center">Link to PROPOSAL</th>
            </tr>
            <tr
              *ngFor="
                let opp of resArr
                  | paginate
                    : {
                        itemsPerPage: filters.limit,
                        currentPage: p,
                        totalItems: totalPage
                      }
              "
            >
              <td class="text-center">{{ opp?.vendorName }}</td>
              <td class="text-center">
                {{ opp?.rpfirstName + " " + opp?.rplastName }}
              </td>
              <td class="text-center">{{ opp?.accountName }}</td>
              <td class="text-center">
                {{ opp?.targetfirstName + " " + opp?.targetlastName }}
              </td>
              <td class="text-center">{{ opp?.opportunityName }}</td>
              <td class="text-center">{{ opp?.stage }}</td>
              <td class="text-center">
                <span>
                  {{ opp?.closingQuarter ? opp?.closingQuarter : "" }}
                </span>
                {{ " " }}
                <span>
                  {{ opp?.closingQuarterYear ? opp?.closingQuarterYear : "" }}
                </span>
              </td>
              <td class="text-center">{{ opp?.newExpectedDealAmount }}</td>
              <td class="text-center cursor" (click)="goToIntroProposal(opp)">
                <img src="../assets/images/external_Link.svg" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="d-align pagi-page">
        <div class="pagination">
          <a href="javascript:void(0)">Prev</a>
          <a href="javascript:void(0)" class="active">1</a>

          <a href="">Next <i class="fa ml-1 fa-angle-right"></i></a>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              6 per page<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button class="dropdown-item" type="button">Action</button>
              <button class="dropdown-item" type="button">
                Another action
              </button>
              <button class="dropdown-item" type="button">
                Something else here
              </button>
            </div>
          </div>
        </div>
      </div> -->

      <div class="d-align pagi-page">
        <!-- <div
          id="pagination"
          class="pagination"
          *ngFor="let page of totalPageArr; let i = index"
        >
          <a
            *ngIf="totalPageArr[page] === 0"
            (click)="nextVal('PREV')"
            [ngClass]="
              totalPage % +filters.limit < +filters.page ? '' : 'disable-link'
            "
            href="javascript:void(0)"
            >Prev</a
          >
          <a
            id="number"
            *ngIf="i < 5"
            href="javascript:void(0)"
            [ngClass]="i === +filters.page / +filters.limit ? 'active' : ''"
            name="number"
            (click)="skipPageFn(i)"
            >{{ page + 1 | number: "1.0-0" }}</a
          >
          <a
            (click)="nextVal('NEXT')"
            href="javascript:void(0)"
            *ngIf="totalPageArr[page] === totalPageArr.length - 1"
            [ngClass]="
              totalPage - +filters.page <= +filters.limit ? 'disable-link' : ''
            "
            >Next<i class="fa ml-1 fa-angle-right"></i
          ></a>
        </div> -->

        <!-- <div class="pagination"> -->
        <div class="paginationClass">
          <pagination-controls
            (pageChange)="pageChange($event)"
            class="float-right"
            previousLabel="Prev"
            nextLabel="Next"
          ></pagination-controls>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ countPerPageText || "10 Per Page"
              }}<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                (click)="checkVal('10PERPAGE', '10', '10 Per Page')"
                class="dropdown-item"
                type="button"
              >
                10 Per Page
              </button>
              <button
                class="dropdown-item"
                value="25"
                type="button"
                (click)="checkVal('25PERPAGE', '25', '25 Per Page')"
              >
                25 Per Page
              </button>
              <button
                class="dropdown-item"
                name="countPerPage"
                value="50"
                type="button"
                (click)="checkVal('50PERPAGE', '50', '50 Per Page')"
              >
                50 Per Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
