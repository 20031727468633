import { Validators } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

//  import { SharedService } from 'src/app/services/shared.service';

// @Component({
//   selector: 'app-product-details',
//   templateUrl: './product-details.component.html',
//   styleUrls: ['./product-details.component.scss'],
// })
// export class ProductDetailsComponent implements OnInit {
//   @Input() step: StepModel;

//   public productDetailForm: FormGroup;
//   public productName: string;
//   public toEdit: string;
//   public uspPayload = [];
//   public competitorPayload = [];
//   public competitorComp: any;

//   public selectable = true;
//   public removable = true;
//   public addOnBlur = true;
//   readonly separatorKeysCodes: number[] = [ENTER, COMMA];
//   public categories: any = [];
//   public tools: any = [];
//   productDetails: any;
//   subdomain: any;

//   constructor(
//     private fb: FormBuilder,
//     private stepService: StepsService,
//     private shared: CommonService,
//     private isv: IsvService,
//     private dropdown: DropdownService,
//     public route: ActivatedRoute
//   ) {}

//   ngOnInit(): void {
//     this.getSubDomain();
//     this.getCompetitors();
//     this.getProductDetails();
//     this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');
//     this.productName = localStorage.getItem('productName');

//     this.productDetailForm = this.fb.group({
//       usp: this.fb.array([this.newUsp()]),
//       competitor: this.fb.array([this.newcompetitorarr()]),
//       productCharacteristic: [''],
//       productCategory: [''],
//       toolIntegration: [''],
//       unitOfMeasure: [''],
//     });
//   }

//   onCompleteStep() {
//     this.step.isComplete = true;
//   }

//   // USP---------------------------------------
//   usp(): FormArray {
//     return this.productDetailForm.get('usp') as FormArray;
//   }

//   newUsp(): FormGroup {
//     return this.fb.group({
//       usp: '',
//     });
//   }

//   addUsp() {
//     this.usp().push(this.newUsp());
//   }

//   removeUsp(i: number) {
//     this.usp().removeAt(i);
//   }
//   // USP---------------------------------------

//   // Competitor---------------------------------------

//   competitor(): FormArray {
//     return this.productDetailForm.get('competitor') as FormArray;
//   }

//   newcompetitorarr(): FormGroup {
//     return this.fb.group({
//       competitor: '',
//       competitorName: '',
//     });
//   }

//   addcompetitor() {
//     this.competitor().push(this.newcompetitorarr());
//   }
//   removeComp(i: number) {
//     this.competitor().removeAt(i);
//   }

//   addProductDetails() {
//     let competitorNameArr = [];

//     this.productDetailForm.value.usp.forEach((element) => {
//       this.uspPayload.push(element.usp);
//     });

//     this.productDetailForm.value.competitor.forEach((element) => {
//       if (element.competitor != 'null') {
//         this.competitorPayload.push(element.competitor);
//       } else {
//         competitorNameArr.push(element.competitorName);
//       }
//     });
//     // this.productDetailForm.value.productCategory.split(',')
//     if (this.productDetailForm.invalid) {
//       return;
//     } else {
//       const productId = localStorage.getItem('productId');
//       const payload = {
//         usp: this.uspPayload,
//         competitor: this.competitorPayload,
//         competitorName: competitorNameArr,
//         productCategory: this.productDetailForm.value.productCategory,
//         toolIntegration: this.productDetailForm.value.toolIntegration,
//         unitOfMeasure: this.productDetailForm.value.unitOfMeasure,
//         productCharacteristic: this.productDetailForm.value
//           .productCharacteristic,
//       };
//       // console.log(payload);

//       this.isv.addProductDetails(payload, productId).subscribe(
//         (data) => {
//           this.onCompleteStep();
//           this.stepService.onNextStep();
//           // localStorage.setItem("productDetails", JSON.stringify(data));
//         },
//         (error) => {
//           console.log(error);
//         }
//       );
//     }
//   }

//   getCompetitors() {
//     this.dropdown.getCompetitor().subscribe(
//       (data) => {
//         this.competitorComp = data.details.companies;
//       },

//       (err) => {
//         console.log(err);
//         this.shared.showToastMessage('ERROR', err.error.message, 5000);
//       }
//     );
//   }

//   addCategory(event: MatChipInputEvent): void {
//     const input = event.input;
//     const value = event.value;

//     // Add our fruit
//     if ((value || '').trim()) {
//       this.categories.push(value.trim());
//       this.productDetailForm.patchValue({ productCategory: this.categories });
//     }

//     // Reset the input value
//     if (input) {
//       input.value = '';
//     }
//   }

//   removeCategory(role): void {
//     const index = this.categories.indexOf(role);
//     if (index >= 0) {
//       this.categories.splice(index, 1);
//     }
//   }

//   addTool(event: MatChipInputEvent): void {
//     const input = event.input;
//     const value = event.value;

//     // Add our fruit
//     if ((value || '').trim()) {
//       this.tools.push(value.trim());
//       this.productDetailForm.patchValue({ toolIntegration: this.tools });
//     }

//     // Reset the input value
//     if (input) {
//       input.value = '';
//     }
//   }

//   removeTool(tool): void {
//     const index = this.tools.indexOf(tool);

//     if (index >= 0) {
//       this.tools.splice(index, 1);
//     }
//   }
//   getProductDetails() {
//     const productId = localStorage.getItem('productId');
//     this.isv.getProductStatus(productId, 2).subscribe(
//       (data) => {
//         // console.log(data.details.product);
//         this.productDetails = data.details.product;

//         this.setProductDetails();
//       },
//       (err) => {
//         console.log(err);
//         this.shared.showToastMessage('ERROR', err.error.message, 5000);
//       }
//     );
//   }
//   getSubDomain() {
//     const productId = localStorage.getItem('productId');
//     this.isv.getProductStatus(productId, 1).subscribe((prod) => {
//       // console.log(prod.details.product.subDomain.value);
//       this.subdomain = prod.details.product.subDomain.value;
//     });
//   }
//   setProductDetails() {
//     this.categories = this.productDetails.productCategory;
//     this.tools = this.productDetails.toolIntegration;
//     this.productDetailForm.patchValue({
//       productCharacteristic: this.productDetails.productCharacteristic,
//       unitOfMeasure: this.productDetails.unitOfMeasure,
//       productCategory: this.categories,
//       toolIntegration: this.tools,
//     });
//     this.setUsp();
//     this.setCompetitor();
//     this.setCompetitorName();
//   }

//   setUsp() {
//     if (this.productDetails.usp && this.productDetails.usp.length) {
//       this.usp().clear();
//       this.productDetails.usp.forEach((element) => {
//         this.usp().push(
//           this.fb.group({
//             usp: [element, Validators.required],
//           })
//         );
//       });
//     }
//   }

//   setCompetitor() {
//     if (
//       this.productDetails.competitor &&
//       this.productDetails.competitor.length
//     ) {
//       this.competitor().clear();
//       this.productDetails.competitor.forEach((element) => {
//         // console.log(element, "sadsad");
//         this.competitor().push(
//           this.fb.group({
//             competitor: [element, Validators.required],
//           })
//         );
//       });
//     }
//   }
//   setCompetitorName() {
//     if (
//       this.productDetails.competitorName &&
//       this.productDetails.competitorName.length
//     ) {
//       if (
//         this.productDetails.competitorName.length &&
//         !this.productDetails.competitor.length
//       ) {
//         this.competitor().removeAt(0);
//       }
//       this.productDetails.competitorName.forEach((element) => {
//         this.competitor().push(
//           this.fb.group({
//             competitor: 'null',
//             competitorName: [element, Validators.required],
//           })
//         );
//       });
//     }
//   }
// }

/**
 * STRAT EDIT
 */

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  @Input() step: StepModel;

  public productDetailForm: FormGroup;
  public productName: string;
  public toEdit: string;
  public uspPayload = [];
  public competitorPayload = [];
  public competitorComp: any;

  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public categories: any = [];
  public tools: any = [];
  productDetails: any;
  subdomain: any;

  productCharacteristicArr: any = [];

  constructor(
    private fb: FormBuilder,
    private stepService: StepsService,
    private shared: CommonService,
    private isv: IsvService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getSubDomain();
    // this.getCompetitors();
    this.getProductDetails();
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');
    this.productName = localStorage.getItem('productName');

    this.productDetailForm = this.fb.group({
      usp: this.fb.array([this.newUsp()]),
      competitor: this.fb.array([this.newcompetitorarr()]),
      productCharacteristic: [''],
      productCategory: [''],
      toolIntegration: [''],
      unitOfMeasure: [''],
    });
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

  // USP---------------------------------------
  usp(): FormArray {
    return this.productDetailForm.get('usp') as FormArray;
  }

  newUsp(): FormGroup {
    return this.fb.group({
      usp: '',
    });
  }

  addUsp() {
    this.usp().push(this.newUsp());
  }

  removeUsp(i: number) {
    this.usp().removeAt(i);
  }
  // USP---------------------------------------

  // Competitor---------------------------------------

  competitor(): FormArray {
    return this.productDetailForm.get('competitor') as FormArray;
  }

  newcompetitorarr(): FormGroup {
    return this.fb.group({
      competitor: '',
      competitorName: '',
    });
  }

  addcompetitor() {
    this.competitor().push(this.newcompetitorarr());
  }
  removeComp(i: number) {
    this.competitor().removeAt(i);
  }

  addProductDetails() {
    let competitorNameArr = [];

    this.productDetailForm.value.usp.forEach((element) => {
      this.uspPayload.push(element.usp);
    });

    this.productDetailForm.value.competitor.forEach((element) => {
      if (element.competitor != 'null') {
        this.competitorPayload.push(element.competitor);
      } else {
        competitorNameArr.push(element.competitorName);
      }
    });
    // this.productDetailForm.value.productCategory.split(',')
    if (this.productDetailForm.invalid) {
      return;
    } else {
      const productId = localStorage.getItem('productId');
      const payload = {
        usp: this.uspPayload,
        competitor: this.competitorPayload,
        competitorName: competitorNameArr,
        productCategory: this.productDetailForm.value.productCategory,
        toolIntegration: this.productDetailForm.value.toolIntegration,
        unitOfMeasure: this.productDetailForm.value.unitOfMeasure,
        productCharacteristic: this.productDetailForm.value
          .productCharacteristic,
      };
      // console.log(payload);

      this.isv.addProductDetails(payload, productId).subscribe(
        (data) => {
          this.onCompleteStep();
          this.stepService.onNextStep();
          // localStorage.setItem("productDetails", JSON.stringify(data));
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  //  getCompetitors() {
  //  this.dropdown.getCompetitor().subscribe((data) => {
  //      this.competitorComp = data.details.companies;
  //    });
  //  }

  addCategory(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.categories.push(value.trim());
      this.productDetailForm.patchValue({ productCategory: this.categories });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeCategory(role): void {
    const index = this.categories.indexOf(role);
    if (index >= 0) {
      this.categories.splice(index, 1);
    }
  }

  addTool(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tools.push(value.trim());
      this.productDetailForm.patchValue({ toolIntegration: this.tools });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeTool(tool): void {
    const index = this.tools.indexOf(tool);

    if (index >= 0) {
      this.tools.splice(index, 1);
    }
  }

  addChar(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    // Add product characteristic
    if ((value || '').trim()) {
      this.productCharacteristicArr.push(value.trim());
      this.productDetailForm.patchValue({
        productCharacteristic: this.productCharacteristicArr,
      });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeChar(character): void {
    const index = this.productCharacteristicArr.indexOf(character);

    if (index >= 0) {
      this.productCharacteristicArr.splice(index, 1);
    }
  }

  getProductDetails() {
    const productId = localStorage.getItem('productId');
    this.isv.getProductStatus(productId, 2).subscribe(
      (data) => {
        // console.log(data.details.product);
        this.productDetails = data.details.product;

        this.setProductDetails();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getSubDomain() {
    const productId = localStorage.getItem('productId');
    this.isv.getProductStatus(productId, 1).subscribe((prod) => {
      // console.log(prod.details.product.subDomain.value);
      this.subdomain = prod.details.product.subDomain.value;
    });
  }
  setProductDetails() {
    this.categories = this.productDetails.productCategory;
    this.tools = this.productDetails.toolIntegration;
    this.productDetailForm.patchValue({
      unitOfMeasure: this.productDetails.unitOfMeasure,
      productCategory: this.categories,
      toolIntegration: this.tools,
    });
    this.productCharacteristicArr = this.productDetails.productCharacteristic;

    this.productDetailForm.patchValue({
      productCharacteristic: this.productCharacteristicArr,
    });
    this.setUsp();
    this.setCompetitor();
    this.setCompetitorName();
  }

  setUsp() {
    if (this.productDetails.usp && this.productDetails.usp.length) {
      this.usp().clear();
      this.productDetails.usp.forEach((element) => {
        this.usp().push(
          this.fb.group({
            usp: [element, Validators.required],
          })
        );
      });
    }
  }

  setCompetitor() {
    if (
      this.productDetails.competitor &&
      this.productDetails.competitor.length
    ) {
      this.competitor().clear();
      this.productDetails.competitor.forEach((element) => {
        // console.log(element, "sadsad");
        this.competitor().push(
          this.fb.group({
            competitor: [element, Validators.required],
          })
        );
      });
    }
  }
  setCompetitorName() {
    if (
      this.productDetails.competitorName &&
      this.productDetails.competitorName.length
    ) {
      if (
        this.productDetails.competitorName.length &&
        !this.productDetails.competitor.length
      ) {
        this.competitor().removeAt(0);
      }
      this.productDetails.competitorName.forEach((element) => {
        this.competitor().push(
          this.fb.group({
            competitor: 'null',
            competitorName: [element, Validators.required],
          })
        );
      });
    }
  }
}
