import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-referral-rewards',
  templateUrl: './referral-rewards.component.html',
  styleUrls: ['./referral-rewards.component.scss'],
})
export class ReferralRewardsComponent implements OnInit {
  rewards: any;
  page = 1;
  count: any;
  pageSize = 10;
  pageSizes = [10, 25, 50];
  payload: any = {};
  sponsoredByList: any;
  paymentStatus: any;
  sponsoredBy: any;
  euro: string;
  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    private data: GetDataService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
    this.data.currentCurr.subscribe((data) => {
      if (data === 'EUR') {
        this.euro = '€';
      } else {
        this.euro = '$';
      }
    });
  }

  ngOnInit(): void {
    this.payload.count = this.pageSize;
    this.payload.skip = 0;
    this.getSponsorshipRewards(this.payload);
    this.paymentStatusDropdown();
    this.getAllSponsoredBy();
    this.getSponsredRP();
  }

  getSponsorshipRewards(payload) {
    this.data.getallSponsorReferalRewards(this.payload).subscribe(
      (res) => {
        this.rewards = res.details.rewards[0].data;
        this.count = res.details.rewards[0].total[0]?.count;
      },
      (err) => {
        console.log(err);
        this.rewards = [];
      }
    );
  }
  search(e) {
    this.payload.searchPattern = e.target.value;
    if (
      this.payload.searchPattern != '' &&
      this.payload.searchPattern.length > 2
    ) {
      this.getSponsorshipRewards(this.payload);
    } else if (this.payload.searchPattern === '') {
      this.getSponsorshipRewards(this.payload);
    }
  }
  // Dropdowns
  getAllSponsoredBy() {
    this.dropdown.getRpList().subscribe(
      (data) => {
        this.sponsoredByList = data.details.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  paymentStatusDropdown() {
    this.dropdown.getpaymentStatus().subscribe((data) => {
      this.paymentStatus = data.details.paymentStatus;
    });
  }
  // Filters
  filterBySponsor(id: any) {
    this.page = 1;
    if (id) {
      this.payload = { sponsor: id, count: this.pageSize };
      this.getSponsorshipRewards(this.payload);
    }
  }
  filterByStatus(stat: any) {
    this.page = 1;
    // this.payload = { status: stat };
    this.payload = { status: stat, count: this.pageSize };
    this.getSponsorshipRewards(this.payload);
  }
  dayFilter(day: any) {
    this.page = 1;
    this.payload = { days: day, count: this.pageSize };
    this.getSponsorshipRewards(this.payload);
  }
  getDefault() {
    this.payload = {};
    this.pageSize = 2;
    this.getSponsorshipRewards(this.payload);
  }
  getTotalRewards() {
    let sum = 0;
    if (this.rewards) {
      this.rewards.forEach((element) => {
        sum += element.sponsorshipValue;
      });
      return Math.trunc(sum);
    }
  }

  handlePageChange(event): void {
    this.page = event;
    const sk = (event - 1) * this.pageSize;
    this.payload = { count: this.pageSize, skip: sk };
    this.getSponsorshipRewards(this.payload);
  }
  handlePageSizeChange(event): void {
    this.pageSize = event;
    this.page = 1;
    this.payload = { count: event };
    this.getSponsorshipRewards(this.payload);
  }

  getSponsredRP() {
    this.dropdown.getSponsoredRP().subscribe(
      (data) => {
        this.sponsoredBy = data.details.sponsoredUsers;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  sponsorRPFilter(id: any) {
    this.page = 1;
    if (id) {
      this.payload = { name: id, count: this.pageSize };
      this.getSponsorshipRewards(this.payload);
    }
  }
}
