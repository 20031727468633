<header class="set-paddi">
  <div class="container-fluid">
    <div class="col-sm-12 p-0 header-menu">
      <div class="row">
        <div class="col-sm-12">
          <div class="top-menu-bar d-flex">
            <div class="logo">
              <img src="/assets/images/wholesoft_white.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="head-mr"></div>

<main class="main-left-new">
  <div class="col-12">
    <section class="mail-verify padding-70">
      <div class="max-width710">
        <div class="set-up">
          <div class="up-head text-center">
            <p *ngIf="step == 1.5">
              Congratulations on onboarding your first product!
            </p>
            <!-- <p>Congratulations on adding a new product!</p> -->
          </div>
          <div class="box">
            <div class="box-main max-mob">
              <div class="setup-head">
                <h4>Get up and running with WholeSoft Market</h4>
                <p *ngIf="step == 1.5">
                  Complete these tasks at your own pace. The WholeSoft Market
                  team will help you with this process
                </p>
                <p *ngIf="step == 2.5">
                  Congratulations! You are one step closer to receiving warm
                  intros from our High Level Connectors to key Execs in Accounts
                </p>
                <p *ngIf="step == 3">
                  Congratulations! You have completed the GSA process and are
                  now ready to penetrate the desired foreign market. Our
                  algorithms will match relevant High Level Connectors and encourage
                  them to share Intro Proposals with you. You can also select
                  the Accounts that you would like to penetrate in the Reachable
                  Accounts section of your Dashboard
                </p>
              </div>
              <div class="progress-bar-main">
                <label *ngIf="completedStep === 'GSA 1'">34% completed</label>
                <label *ngIf="completedStep === 'GSA 2'">82% completed</label>
                <label *ngIf="completedStep === 'GSA 3'">100% completed</label>
                <div class="progress">
                  <div
                    *ngIf="completedStep === 'GSA 1'"
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 34%"
                    aria-valuenow="82"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    *ngIf="completedStep === 'GSA 2'"
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 82%"
                    aria-valuenow="82"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    *ngIf="completedStep === 'GSA 3'"
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 100%"
                    aria-valuenow="82"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <!-- <div class="progress-bar-main">
                                    <label *ngIf="step == 1.5 && step < 2">34% completed</label>
                                    <label *ngIf="step == 2.5 && step < 3">82% completed</label>
                                    <label *ngIf="step == 3">100% completed</label>
                                    <div class="progress">
                                        <div *ngIf="step == 1.5" class="progress-bar bg-success" role="progressbar"
                                            style="width: 34%" aria-valuenow="82" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                        <div *ngIf="step == 2.5" class="progress-bar bg-success" role="progressbar"
                                            style="width: 82%" aria-valuenow="82" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                        <div *ngIf="step == 3" class="progress-bar bg-success" role="progressbar"
                                            style="width: 100%" aria-valuenow="82" aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                </div> -->
            </div>
          </div>
          <div class="box">
            <div class="box-main">
              <div class="setup-head">
                <h4>Go-to Market Strategy Analysis</h4>
                <p>Consistent, Focused, Swift</p>
              </div>
            </div>
            <div class="progress-bar-main set-up-ic">
              <div class="box-step box-main">
                <div class="box-select">
                  <div class="check-icon">
                    <span [class.success]="step > 1" class="check mr-0"></span>
                  </div>
                  <div class="link-one">
                    <h4>GSA Framework - Step 1</h4>
                    <p>Onboard your product</p>
                  </div>
                </div>
                <div class="button-box">
                  <div class="theme-btn width-fit text-right">
                    <button
                      *ngIf="step == 1.5 || step > 1.5"
                      class="btn active"
                      (click)="editProduct(0)"
                    >
                      EDIT
                    </button>
                    <button *ngIf="step < 1.5"><a>Continue</a></button>
                  </div>
                </div>
              </div>
              <div class="box-step box-main">
                <div class="box-select">
                  <div class="check-icon">
                    <span
                      [class.success]="
                        completedStep === 'GSA 2' || completedStep === 'GSA 3'
                      "
                      class="check mr-0"
                    ></span>
                  </div>
                  <div class="link-one">
                    <h4>GSA Framework - Step 2</h4>
                    <p>Provide additional product details</p>
                  </div>
                </div>
                <div class="button-box">
                  <div class="theme-btn width-fit text-right">
                    <button
                      *ngIf="completedStep === 'GSA 1'"
                      (click)="continueProduct(5)"
                      [class.active]="step > 1.5 || step == 1.5"
                      [disabled]="step < 1.5"
                      class="btn"
                    >
                      <a style="color: inherit">CONTINUE</a>
                    </button>
                    <button
                      class="btn active"
                      *ngIf="
                        completedStep === 'GSA 2' || completedStep === 'GSA 3'
                      "
                      (click)="editProduct(5)"
                    >
                      Edit
                    </button>
                  </div>
                  <!-- <div class="theme-btn width-fit text-right">
                                            <button *ngIf="step < 2" (click)="continueProduct(5)"
                                                [class.active]="step > 1.5 || step == 1.5" [disabled]="step < 1.5"
                                                class="btn">
                                                <a style="color: inherit">CONTINUE</a>
                                            </button>
                                            <button class="btn active" *ngIf="step == 2.5 || step > 2.5"
                                                (click)="editProduct(5)">
                                                Edit
                                            </button>
                                        </div> -->
                </div>
              </div>
              <div class="box-step box-main">
                <div class="box-select">
                  <div class="check-icon">
                    <span
                      [class.success]="completedStep === 'GSA 3'"
                      class="check mr-0"
                    ></span>
                  </div>
                  <div class="link-one">
                    <h4>GSA Framework - Step 3</h4>
                    <p>Provide sales process details</p>
                  </div>
                </div>
                <div class="button-box">
                  <div class="theme-btn width-fit text-right">
                    <button
                      *ngIf="
                        completedStep === 'GSA 2' || completedStep === 'GSA 1'
                      "
                      (click)="continueProduct(10)"
                      [class.active]="completedStep === 'GSA 2'"
                      [disabled]="!(completedStep === 'GSA 2')"
                      class="btn"
                    >
                      <a style="color: inherit">CONTINUE</a>
                    </button>
                    <button
                      *ngIf="completedStep === 'GSA 3'"
                      class="btn active"
                      (click)="editProduct(10)"
                    >
                      Edit
                    </button>
                  </div>
                  <!-- <div class="theme-btn width-fit text-right">
                                            <button *ngIf="step < 3" (click)="continueProduct(10)"
                                                [class.active]="step == 2.5" [disabled]="step < 2.5" class="btn">
                                                <a style="color: inherit">CONTINUE</a>
                                            </button>
                                            <button *ngIf="step == 3" class="btn active" (click)="editProduct(10)">
                                                Edit
                                            </button>
                                        </div> -->
                </div>
              </div>
              <div
                *ngIf="completedStep === 'GSA 3' || onBoarded"
                class="box-step link-a box-main"
              >
                <p>
                  <a
                    routerLink="/admin/add-product"
                    (click)="redirectToProducts($event)"
                    >Click here
                  </a>
                  to skip setup and go to dashboard
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="box">
                            <div class="box-main">
                                <div class="setup-head que">
                                    <div class="check-icon">
                                        <img src="../assets/images/que.png" alt="" class="mr-2" />
                                    </div>
                                    <div class="head-sec">
                                        <h4>Help</h4>
                                        <p>Lorem Ipsum placeholder text for</p>
                                    </div>
                                </div>
                                <div class="progress-bar-main">
                                    <div class="box-step border-0">
                                        <div class="box-select pr-0">
                                            <div class="link-one">
                                                <p>
                                                    Generate Lorem Ipsum placeholder text for use in your graphic, print
                                                    and web layouts, and discover plugins for your favorite writing,
                                                    design and blogging tools.
                                                </p>
                                            </div>
                                            <div class="button-box">
                                                <div class="theme-btn width-fit text-right">
                                                    <button class="btn active"
                                                        onclick="location.href='./product-deatail.html'">
                                                        Read More
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
        </div>
      </div>
    </section>
  </div>
</main>
