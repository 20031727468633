// @ts-nocheck
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Dropdown } from 'src/app/interface/dropdown';
import { AddDataService } from 'src/app/services/add-data.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { CommonService } from 'src/app/utils/common.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { subDays, addYears } from 'date-fns';
import { CustomBrowserAlertComponent } from 'src/app/layout/components/custom-browser-alert/custom-browser-alert.component';

@Component({
  selector: 'app-add-isv',
  templateUrl: './add-isv.component.html',
  styleUrls: ['./add-isv.component.scss'],
})
export class AddIsvComponent implements OnInit {
  staticDataUrl: string = environment.apiUrl;

  startDate = new Date();
  isSubmitted: boolean = false;
  id: string;
  isvUser: any;
  userImageFile: File;
  companyFile: File;
  isvDoc: any;

  countriesArr: Dropdown[];
  monthsArr: Dropdown[];
  statusArr: Dropdown[];
  jobTitleArr: any[];
  sellerStatusArr: Dropdown[];
  documentTypeArr: Dropdown[];
  documentStatusArr: Dropdown[];
  filteredStatusArr: Dropdown[];

  holdDocArr: any = [];
  filePath: string = null;
  emailError: string;
  previewUrl: any;

  public imagePath;
  companyImgUrl: any = null;
  userImgUrl: any = null;
  message: string;

  showCreate: boolean = false;
  checkBox: boolean;
  endDateValue: any;

  fileName: string;

  companyImgCon: boolean = false;
  userImgCon: boolean = false;
  idOfNew: Dropdown;
  idOfApproved: Dropdown;
  idOfActive: Dropdown;
  idOfArchived: Dropdown;
  checkBoxEmail: boolean;
  checkBoxSigned: boolean;
  emailDisable: boolean = false;

  addNewIsv: FormGroup = this.fb.group({
    companyName: ['', Validators.required],
    companyUrl: [''],
    companyLogo: [''],
    userImage: [''],
    companyLinkedin: [
      'https://www.linkedin.com/company/',
      [
        Validators.pattern(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        ),
      ],
    ],
    // [Validators.minLength(28)]
    userLinkedin: [
      'https://www.linkedin.com/in/',
      [
        Validators.pattern(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        ),
      ],
    ],
    // [Validators.minLength(28)]
    isApprovedStatus: [''],
    createdBy: [''],
    createdDate: [''],
    emailVerified: [false],
    address: [''],
    city: [''],
    state: [''],
    country: [''],
    zip: [''],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ],
    ],
    jobTitle: ['', Validators.required],

    mobile: ['', [Validators.pattern(/^[0-9]*$/)]],
    year1: ['', Validators.compose([Validators.min(0), Validators.max(100)])],
    year2: ['', Validators.compose([Validators.min(0), Validators.max(100)])],
    year3: ['', Validators.compose([Validators.min(0), Validators.max(100)])],
    year4: ['', Validators.compose([Validators.min(0), Validators.max(100)])],
    year5: ['', Validators.compose([Validators.min(0), Validators.max(100)])],
    feeOngoing: [
      '',
      Validators.compose([Validators.min(0), Validators.max(100)]),
    ],
    startDate: [''],
    endDate: [''],

    isAgreementSigned: [false],
    start: [''],
    end: [''],
    documentStatus: [''],
    documentType: [''],
    description: [''],
    documentName: [''],
  });
  validURlforLinkedin: any = true;
  errorCompanyLinkedin: boolean = false;
  errorUserLinkedin: boolean = false;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private dropDown: DropdownService,
    private actRoute: ActivatedRoute,
    private getData: GetDataService,
    private addData: AddDataService,
    private alertService: CommonService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.alertService.showHeader.next(true);
    this.id = actRoute.snapshot.params['id'];

    !this.id || this.id === ''
      ? (this.showCreate = true)
      : (this.showCreate = false);
  }

  ngOnInit(): void {
    this.getCountries();
    this.getMonths();
    this.getJobTitle();
    this.getSellerStatus();
    this.getDocumentTypes();
    this.getDocumentStatuss();

    this.getInitialData();

    this.addNewIsv.get('end').disable();

    if (this.showCreate) {
      this.addNewIsv.get('year1').disable();
      this.addNewIsv.get('year2').disable();
      this.addNewIsv.get('year3').disable();
      this.addNewIsv.get('year4').disable();
      this.addNewIsv.get('year5').disable();
      this.addNewIsv.get('feeOngoing').disable();
      this.addNewIsv.get('startDate').disable();
      this.addNewIsv.get('endDate').disable();
      this.addNewIsv.get('isApprovedStatus').disable();
      this.addNewIsv.get('isAgreementSigned').disable();
    }
  }

  validationOFFeeSch(condition: boolean) {
    if (condition) {
      this.addNewIsv.get('year1').enable();
      this.addNewIsv.get('year2').enable();
      this.addNewIsv.get('year3').enable();
      this.addNewIsv.get('year4').enable();
      this.addNewIsv.get('year5').enable();
      this.addNewIsv.get('feeOngoing').enable();
      this.addNewIsv.get('startDate').enable();
      this.addNewIsv.get('endDate').enable();

      this.addNewIsv.controls.year1.setValidators([Validators.required]);
      this.addNewIsv.controls.year2.setValidators([Validators.required]);
      this.addNewIsv.controls.year3.setValidators([Validators.required]);
      this.addNewIsv.controls.year4.setValidators([Validators.required]);
      this.addNewIsv.controls.year5.setValidators([Validators.required]);
      this.addNewIsv.controls.feeOngoing.setValidators([Validators.required]);
      this.addNewIsv.controls.startDate.setValidators([Validators.required]);
      this.addNewIsv.controls.endDate.setValidators([Validators.required]);
    } else {
      this.addNewIsv.controls.startDate.reset();
      this.addNewIsv.controls.endDate.reset();
      this.addNewIsv.controls.year1.reset();
      this.addNewIsv.controls.year2.reset();
      this.addNewIsv.controls.year3.reset();
      this.addNewIsv.controls.year4.reset();
      this.addNewIsv.controls.year5.reset();
      this.addNewIsv.controls.feeOngoing.reset();

      this.addNewIsv.controls.startDate.clearValidators();
      this.addNewIsv.controls.endDate.clearValidators();
      this.addNewIsv.controls.year1.clearValidators();
      this.addNewIsv.controls.year2.clearValidators();
      this.addNewIsv.controls.year3.clearValidators();
      this.addNewIsv.controls.year4.clearValidators();
      this.addNewIsv.controls.year5.clearValidators();
      this.addNewIsv.controls.feeOngoing.clearValidators();

      this.addNewIsv.get('year1').disable();
      this.addNewIsv.get('year2').disable();
      this.addNewIsv.get('year3').disable();
      this.addNewIsv.get('year4').disable();
      this.addNewIsv.get('year5').disable();
      this.addNewIsv.get('feeOngoing').disable();
      this.addNewIsv.get('startDate').disable();
      this.addNewIsv.get('endDate').disable();
    }
    this.addNewIsv.controls['year1'].updateValueAndValidity();
    this.addNewIsv.controls['year2'].updateValueAndValidity();
    this.addNewIsv.controls['year3'].updateValueAndValidity();
    this.addNewIsv.controls['year4'].updateValueAndValidity();
    this.addNewIsv.controls['year5'].updateValueAndValidity();
    this.addNewIsv.controls['feeOngoing'].updateValueAndValidity();
    this.addNewIsv.controls['startDate'].updateValueAndValidity();
    this.addNewIsv.controls['endDate'].updateValueAndValidity();
  }

  dateFunc(e) {
    const datePipePro = new DatePipe('en-IN');
    const stratDateVal = datePipePro.transform(new Date(), 'yyyy-MM-dd');

    let resultDate = addYears(new Date(), 1);
    resultDate = subDays(resultDate, 1);
    const toDateVal = datePipePro.transform(resultDate, 'yyyy-MM-dd');

    if (
      this.addNewIsv.value.isAgreementSigned === true &&
      this.addNewIsv.value.isApprovedStatus === this.idOfApproved._id
    ) {
      this.addNewIsv.get('year1').enable();
      this.addNewIsv.get('year2').enable();
      this.addNewIsv.get('year3').enable();
      this.addNewIsv.get('year4').enable();
      this.addNewIsv.get('year5').enable();
      this.addNewIsv.get('feeOngoing').enable();
      this.addNewIsv.get('startDate').enable();
      this.addNewIsv.get('endDate').enable();
      this.addNewIsv.controls.startDate.setValue(stratDateVal);
      this.addNewIsv.controls.endDate.setValue(toDateVal);

      this.addNewIsv.controls.startDate.setValidators([Validators.required]);
      this.addNewIsv.controls.endDate.setValidators([Validators.required]);

      this.addNewIsv.controls.year1.setValidators([Validators.required]);
      this.addNewIsv.controls.year2.setValidators([Validators.required]);
      this.addNewIsv.controls.year3.setValidators([Validators.required]);
      this.addNewIsv.controls.year4.setValidators([Validators.required]);
      this.addNewIsv.controls.year5.setValidators([Validators.required]);
      this.addNewIsv.controls.feeOngoing.setValidators([Validators.required]);
    } else if (this.addNewIsv.value.isAgreementSigned === true) {
      this.addNewIsv.get('startDate').enable();
      this.addNewIsv.get('endDate').enable();
      this.addNewIsv.controls.startDate.setValue(stratDateVal);
      this.addNewIsv.controls.endDate.setValue(toDateVal);

      this.addNewIsv.controls.startDate.setValidators([Validators.required]);
      this.addNewIsv.controls.endDate.setValidators([Validators.required]);
    } else {
      this.addNewIsv.controls.startDate.clearValidators();
      this.addNewIsv.controls.endDate.clearValidators();
      this.addNewIsv.controls.year1.clearValidators();
      this.addNewIsv.controls.year2.clearValidators();
      this.addNewIsv.controls.year3.clearValidators();
      this.addNewIsv.controls.year4.clearValidators();
      this.addNewIsv.controls.year5.clearValidators();
      this.addNewIsv.controls.feeOngoing.clearValidators();

      this.addNewIsv.get('year1').disable();
      this.addNewIsv.get('year2').disable();
      this.addNewIsv.get('year3').disable();
      this.addNewIsv.get('year4').disable();
      this.addNewIsv.get('year5').disable();
      this.addNewIsv.get('feeOngoing').disable();
      this.addNewIsv.get('startDate').disable();
      this.addNewIsv.get('endDate').disable();
    }
    this.addNewIsv.controls['year1'].updateValueAndValidity();
    this.addNewIsv.controls['year2'].updateValueAndValidity();
    this.addNewIsv.controls['year3'].updateValueAndValidity();
    this.addNewIsv.controls['year4'].updateValueAndValidity();
    this.addNewIsv.controls['year5'].updateValueAndValidity();
    this.addNewIsv.controls['feeOngoing'].updateValueAndValidity();
    this.addNewIsv.controls['startDate'].updateValueAndValidity();
    this.addNewIsv.controls['endDate'].updateValueAndValidity();
  }

  // For Confirmation message of Email Verified
  detectChange(e) {
    if (!this.showCreate && this.addNewIsv.value.emailVerified) {
      e.preventDefault();
      return;
    }

    if (this.emailDisable) {
      e.preventDefault();
      return;
    }
    if (
      this.addNewIsv.value.emailVerified === false ||
      this.isvUser?.userId?.emailVerified === false
    ) {
      return this.openDialog('CONFIRM', e);
    }
  }
  openDialog(type: 'CONFIRM' | 'AGREE', e): void {
    const dialogRef = this.dialog.open(CustomBrowserAlertComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (type === 'CONFIRM') {
        this.alertConfirmation(confirm, e);
      } else {
        this.agreementConformation(confirm, e);
      }
    });
  }

  alertConfirmation(confirmed: boolean, e): void {
    if (confirmed) {
      this.addNewIsv.value.emailVerified = true;
      this.emailDisable = true;
      this.addNewIsv.controls.emailVerified.setValue(true);
      this.addNewIsv.get('isApprovedStatus').enable();
      this.dateFunc(e);

      Swal.fire({
        title: 'Email Verified Checked',
        text: 'User\'s Email is Verified',
        icon: 'success',
      });
    } else {
      e.preventDefault();
      this.addNewIsv.value.emailVerified = false;
      this.addNewIsv.value.isApprovedStatus = false;
      this.addNewIsv.controls.emailVerified.setValue(false);
      this.checkBoxEmail = false;
      Swal.fire({
        title: 'Email Verified Not Checked',
        text: 'User\'s Email is not Verified',
        icon: 'warning',
      });
    }
  }

  afterAgreement(e): void {
    if (this.addNewIsv.controls.isAgreementSigned.status !== 'DISABLED') {
      if (this.addNewIsv.value.isAgreementSigned) {
        e.preventDefault();
        return;
      }

      if (
        this.addNewIsv.value.isAgreementSigned === false ||
        this.isvUser?.isAgreementSigned === false
      ) {
        return this.openDialog('AGREE', e);
      }
    }
  }

  agreementConformation(confirmed: boolean, e): void {
    if (confirmed) {
      this.addNewIsv.value.isAgreementSigned = true;
      this.checkBoxSigned = true;
      this.addNewIsv.controls.isAgreementSigned.setValue(true);

      Swal.fire({
        title: 'Agreement Signed',
        text: 'User has Signed Agreement',
        icon: 'success',
      });
      this.dateFunc(e);
    } else {
      e.preventDefault();
      this.checkBoxSigned = false;
      this.addNewIsv.controls.isAgreementSigned.setValue(false);
      this.addNewIsv.get('isAgreementSigned').value = false;
      this.addNewIsv.get('isApprovedStatus').enable();
      this.addNewIsv.get('isAgreementSigned').enable();
      Swal.fire({
        title: 'Email Verified Not Checked',
        text: 'User\'s Email is not Verified',
        icon: 'warning',
      });
    }
  }

  isApprovedStatusPathValue(): void {
    this.idOfNew = this.filteredStatusArr.find((el) =>
      el.value === 'New' ? el : ''
    );

    this.idOfNew &&
      this.addNewIsv.patchValue({
        isApprovedStatus: this.idOfNew?._id,
      });

    if (this.addNewIsv.value.isApprovedStatus === this.idOfNew?._id) {
      this.addNewIsv.controls.jobTitle.setValidators([Validators.required]);
    }
    this.addNewIsv.controls['jobTitle'].updateValueAndValidity();
  }

  // getInitial Seller Data
  async getInitialData() {
    // console.log('Coming Here getInitialData()', Date.now());

    if (this.showCreate === false) {
      const res = await this.getData.getIsvById(this.id).toPromise();

      // console.log(res);
      this.isvUser = res?.details?.seller;

      // console.log(this.isvUser);

      if (this.isvUser?.userId?.emailVerified === false) {
        this.addNewIsv.get('isApprovedStatus').disable();
      }

      this.addNewIsv.value.emailVerified =
        this.isvUser?.userId?.emailVerified || false;

      this.userImgCon =
        this.isvUser?.userId?.userImage &&
        this.isvUser?.userId.hasOwnProperty('userImage');
      this.companyImgCon =
        this.isvUser.companyImage &&
        this.isvUser.hasOwnProperty('companyImage');

      if (!this.showCreate) {
        // console.log('Coming 123');
        this.addNewIsv.get('createdBy').disable();
        this.addNewIsv.get('createdDate').disable();
      }

      if (!this.isvUser?.isAgreementSigned) {
        this.validationOFFeeSch(this.isvUser?.isAgreementSigned);
      }

      if (this.isvUser?.status.value === 'New') {
        this.addNewIsv.get('isAgreementSigned').disable();
      }

      this.setFormData(this.isvUser);
    }
    return;
  }

  setFormData(userData) {
    this.addNewIsv.patchValue({
      companyName: userData?.companyName ? userData?.companyName : '',
      companyUrl: userData?.companyUrl ? userData?.companyUrl : '',
      companyLinkedin: userData?.companyLinkedin
        ? userData?.companyLinkedin
        : '',
      createdDate: userData?.createdAt
        ? userData?.createdAt.substr(0, 10).toString().split('-').join('-')
        : '',
      createdBy:
        userData.createdBy?.firstName || userData.createdBy?.lastName
          ? `${userData.createdBy?.firstName} ${userData.createdBy.lastName}`
          : '',
      isApprovedStatus: userData?.status._id ? userData?.status._id : '',

      mobile: userData?.userId?.mobile ? userData?.userId?.mobile : '',

      emailVerified: userData?.userId?.emailVerified
        ? userData?.userId?.emailVerified
        : '',
      address: userData?.officeAddress?.address
        ? userData?.officeAddress?.address
        : '',
      city: userData?.officeAddress?.city ? userData?.officeAddress.city : '',
      state: userData?.officeAddress?.state
        ? userData?.officeAddress.state
        : '',
      zip: userData?.officeAddress?.zip ? userData?.officeAddress.zip : '',
      country: userData?.officeAddress?.country?._id
        ? userData?.officeAddress?.country?._id
        : '',
      firstName: userData?.userId.firstName ? userData?.userId?.firstName : '',
      lastName: userData?.userId.lastName ? userData?.userId?.lastName : '',
      email: userData?.userId.email ? userData?.userId?.email : '',
      jobTitle: userData?.jobTitle?._id ? userData?.jobTitle._id : '',
      userLinkedin: userData?.userId.userLinkedin
        ? userData?.userId.userLinkedin
        : '',
      year1: `${userData?.isvAgreement?.year1}`
        ? `${userData?.isvAgreement?.year1}`
        : '',
      year2: `${userData?.isvAgreement?.year2}`
        ? `${userData?.isvAgreement?.year2}`
        : '',
      year3: `${userData?.isvAgreement?.year3}`
        ? `${userData?.isvAgreement?.year3}`
        : '',
      year4: `${userData?.isvAgreement?.year4}`
        ? `${userData?.isvAgreement?.year4}`
        : '',
      year5: `${userData?.isvAgreement?.year5}`
        ? `${userData?.isvAgreement?.year5}`
        : '',
      feeOngoing: `${userData?.isvAgreement?.feeOngoing}`
        ? `${userData?.isvAgreement?.feeOngoing}`
        : '',
      isAgreementSigned: userData?.isAgreementSigned
        ? userData?.isAgreementSigned
        : '',
      startDate: userData?.isvAgreement?.startDate
        ? userData?.isvAgreement?.startDate
            .substr(0, 10)
            .toString()
            .split('-')
            .join('-')
        : '',
      endDate: userData?.isvAgreement?.endDate
        ? userData?.isvAgreement?.endDate
            .substr(0, 10)
            .toString()
            .split('-')
            .join('-')
        : '',
      start: userData?.financialYear?.start
        ? userData?.financialYear?.start?._id
        : '',
      end: userData?.financialYear?.end
        ? userData?.financialYear?.end?._id
        : '',
    });

    if (Array.isArray(this.isvUser?.isvDocument)) {
      this.holdDocArr = [];
      this.isvUser?.isvDocument.forEach((el) => {
        this.holdDocArr.push({
          documentStatus: el.documentStatus._id,
          documentType: el.documentType._id,
          description: el.description,
          documentName: el.documentName,
        });
      });
    }
  }

  checkLinkedin(option: string, e: any) {
    if (e.target.value == '') {
      this.errorUserLinkedin = false;
      this.errorCompanyLinkedin = false;
      return;
    }
    const linkedURLarr = e.target.value.toLowerCase().split('/');

    if (option === 'COMPANY_LINKEDIN') {
      this.validURlforLinkedin =
        (linkedURLarr.includes('www.linkedin.com') ||
          linkedURLarr.includes('linkedin.com')) &&
        linkedURLarr.includes('company');

      this.errorCompanyLinkedin = !this.validURlforLinkedin;

      if (!!this.addNewIsv.controls.companyLinkedin.errors) {
        return;
      } else {
        if (e.target.value.endsWith('/')) {
          return;
        } else {
          this.addNewIsv.patchValue({
            companyLinkedin: `${e.target.value}/`,
          });
        }
      }
    }

    if (option === 'USER_LINKEDIN') {
      this.validURlforLinkedin =
        (linkedURLarr.includes('www.linkedin.com') ||
          linkedURLarr.includes('linkedin.com')) &&
        linkedURLarr.includes('in');

      this.errorUserLinkedin = !this.validURlforLinkedin;
      if (!!this.addNewIsv.controls.userLinkedin.errors) {
        return;
      } else {
        if (e.target.value.endsWith('/')) {
          return;
        } else {
          this.addNewIsv.patchValue({
            userLinkedin: `${e.target.value}/`,
          });
        }
      }
    }
  }

  // Get List Seller Status for Dropdown
  async getSellerStatus() {
    const res = await this.dropDown.getsellerStatus().toPromise();
    this.sellerStatusArr = res?.details?.sellerStatus;

    await this.getInitialData();
    this.dropDownContent();
    this.isApprovedStatusPathValue();

    // console.log(this.sellerStatusArr);
    this.idOfNew = this.sellerStatusArr.find((el) =>
      el.value === 'New' ? el : ''
    );
    this.idOfApproved = this.sellerStatusArr.find((el) =>
      el.value === 'Approved' ? el : ''
    );
    this.idOfActive = this.sellerStatusArr.find((el) =>
      el.value === 'Active' ? el : ''
    );

    this.idOfArchived = this.sellerStatusArr.find((el) =>
      el.value === 'Archived' ? el : ''
    );
    // console.log(this.idOfActive);
  }

  dropDownContent() {
    if (this.showCreate) {
      this.filteredStatusArr = this.sellerStatusArr.filter((el) => {
        return (
          el.value === 'New' ||
          el.value === 'Approved' ||
          el.value === 'Rejected'
        );
      });
    } else if (!this.showCreate && this.isvUser?.status?.value === 'New') {
      this.filteredStatusArr = this.sellerStatusArr.filter((el) => {
        this.addNewIsv.get('year1').disable();
        this.addNewIsv.get('year2').disable();
        this.addNewIsv.get('year3').disable();
        this.addNewIsv.get('year4').disable();
        this.addNewIsv.get('year5').disable();
        this.addNewIsv.get('feeOngoing').disable();
        this.addNewIsv.get('startDate').disable();
        this.addNewIsv.get('endDate').disable();

        return (
          el.value === 'New' ||
          el.value === 'Approved' ||
          el.value === 'Rejected'
        );
      });
    } else if (!this.showCreate && this.isvUser?.status?.value === 'Approved') {
      this.filteredStatusArr = this.sellerStatusArr.filter(
        (el) => el.value === 'Active' || el.value === 'Approved'
      );
    } else if (!this.showCreate && this.isvUser?.status?.value === 'Rejected') {
      this.addNewIsv.get('isApprovedStatus').disable();
      this.filteredStatusArr = this.sellerStatusArr.filter(
        (el) => el.value === 'Rejected'
      );
    } else if (!this.showCreate && this.isvUser?.status?.value === 'Active') {
      this.filteredStatusArr = this.sellerStatusArr.filter(
        (el) =>
          el.value === 'Active' ||
          el.value === 'Disabled' ||
          el.value === 'Archived'
      );
    } else if (!this.showCreate && this.isvUser?.status?.value === 'Disabled') {
      this.filteredStatusArr = this.sellerStatusArr.filter(
        (el) => el.value === 'Disabled' || el.value === 'Active'
      );
    } else if (!this.showCreate && this.isvUser?.status?.value === 'Archived') {
      this.addNewIsv.get('isApprovedStatus').disable();
      this.filteredStatusArr = this.sellerStatusArr.filter(
        (el) => el.value === 'Archived'
      );
    } else {
      this.filteredStatusArr = this.sellerStatusArr;
    }
  }

  selectUserFile(e) {
    if (e.target.files.length > 0) {
      for (let i of e.target.files) {
        this.userImageFile = i;
      }

      let mimeType = this.userImageFile.type;
      if (mimeType.match(/image\/*/) == null) {
        this.message = 'Only images are supported.';
        return;
      }
      let reader = new FileReader();
      this.imagePath = this.userImageFile;
      reader.readAsDataURL(this.userImageFile);
      reader.onload = (_event) => {
        this.userImgUrl = reader.result;
      };
    }
    return;
  }

  selectCompanyImage(e) {
    if (e.target.files.length > 0) {
      for (let i of e.target.files) {
        this.companyFile = i;
      }
    }
    return;
  }

  getStatusValue(id) {
    if (id) {
      const res: Dropdown = this.documentStatusArr.find(
        (el: any) => el._id === id
      );
      return res.value;
    }
    return;
  }

  getDocumentTypeValue(id) {
    if (id) {
      const res: Dropdown = this.documentTypeArr.find(
        (el: any) => el._id === id
      );
      return res.value;
    }
    return;
  }

  enableEndDateFn(e) {
    const datePipePro = new DatePipe('en-IN');
    let resultDate = addYears(new Date(e.target.value), 1);
    resultDate = subDays(resultDate, 1);
    const finaldate = datePipePro.transform(resultDate, 'yyyy-MM-dd');

    this.addNewIsv.patchValue({
      endDate: finaldate,
    });
  }

  chnageEmailVerfied(e) {
    if (e.target.value === this.idOfApproved?._id) {
      this.addNewIsv.controls.isAgreementSigned.enable();
      this.addNewIsv.controls.emailVerified.setValidators([
        Validators.required,
      ]);
      this.checkBox = true;
      this.dateFunc(e);
    } else {
      this.addNewIsv.controls.emailVerified.clearValidators();

      this.checkBox = false;
    }

    if (this.addNewIsv.value.isApprovedStatus === this.idOfNew?._id) {
      this.addNewIsv.controls.isAgreementSigned.reset();
      this.addNewIsv.controls.isAgreementSigned.disable();

      this.addNewIsv.controls.jobTitle.setValidators([Validators.required]);
    }
    this.addNewIsv.controls['jobTitle'].updateValueAndValidity();
    this.addNewIsv.controls['emailVerified'].updateValueAndValidity();
  }

  previewCompanyImage(files) {
    if (files.length === 0) {
      return;
    }
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    let reader = new FileReader();
    this.imagePath = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.companyImgUrl = reader.result;
    };
  }

  addIsvDoc(e) {
    if (e.target.files.length > 0) {
      this.fileName = e.target.files[0].name;
      for (let i of e.target.files) {
        this.isvDoc = i;
      }
    }
    return;
  }

  async addDoc() {
    const fileData = new FormData();

    fileData.append('document', this.isvDoc);

    const filePathData = await this.addData.fileUpload(fileData).toPromise();
    const docObj = {
      documentStatus: this.addNewIsv.value.documentStatus,
      documentType: this.addNewIsv.value.documentType,
      description: this.addNewIsv.value.description,
      documentName: filePathData.details?.path,
    };

    this.holdDocArr.push(docObj);
    // console.log("DOCArray ---", this.holdDocArr);

    this.addNewIsv.get('documentStatus').reset();
    this.addNewIsv.get('documentType').reset();
    this.addNewIsv.get('description').reset();
    this.addNewIsv.get('documentName').reset();
    this.fileName = null;
  }

  showDoc(i) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', `${environment.apiUrl}${i.documentName}`);
    link.setAttribute('download', `file`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  addIsv() {
    const addressOfOffice = {
      address: this.addNewIsv.value.address,
      city: this.addNewIsv.value.city,
      state: this.addNewIsv.value.state,
      country: this.addNewIsv.value.country,
      zip: this.addNewIsv.value.zip ? this.addNewIsv.value.zip.toString() : '',
    };
    console.log(this.addNewIsv)

    const filtered = Object.values(addressOfOffice).filter((el) => el.trim());

    const finYear = {
      start: this.addNewIsv.value.start,
      end: this.addNewIsv.getRawValue().end,
    };

    const agrremt = {
      year1: parseFloat(this.addNewIsv.getRawValue().year1),
      year2: parseFloat(this.addNewIsv.getRawValue().year2),
      year3: parseFloat(this.addNewIsv.getRawValue().year3),
      year4: parseFloat(this.addNewIsv.getRawValue().year4),
      year5: parseFloat(this.addNewIsv.getRawValue().year5),
      feeOngoing: parseFloat(this.addNewIsv.getRawValue().feeOngoing),
      startDate: this.addNewIsv.getRawValue().startDate || '',
      endDate: this.addNewIsv.getRawValue().endDate || ''
    };

    const finalObj = {
      officeAddress: addressOfOffice,
      financialYear: finYear,
      companyName: this.addNewIsv.value.companyName,
      companyUrl: this.addNewIsv.value.companyUrl,
      companyLinkedin: this.addNewIsv.value.companyLinkedin,
      status: this.addNewIsv.value.isApprovedStatus,
      isvAgreement: agrremt,
      isAgreementSigned: this.addNewIsv.value.isAgreementSigned,
      sellerDocument: [...new Set(this.holdDocArr)],
    };

    const formData = new FormData();

    if (this.userImageFile) formData.append('userImage', this.userImageFile);
    if (this.companyFile) formData.append('companyImage', this.companyFile);
    filtered.length >= 3 &&
      formData.append('officeAddress', JSON.stringify(addressOfOffice));
    formData.append('companyName', this.addNewIsv.value.companyName);
    formData.append('companyUrl', this.addNewIsv.value.companyUrl);
    formData.append('companyLinkedin', this.addNewIsv.value.companyLinkedin);
    // filteredAgreement.length === 8 &&

    if (this.showCreate) {
      for (const key in agrremt) {
        if (!agrremt[key]) {
          delete agrremt[key];
        }
      }
    }

    if (Object.keys(agrremt).length > 4) {
      formData.append('isvAgreement', JSON.stringify(agrremt));
    }

    formData.append(
      'isAgreementSigned',
      this.addNewIsv.value.isAgreementSigned || false
    );
    finYear.start && formData.append('financialYear', JSON.stringify(finYear));

    formData.append('isvDocument', JSON.stringify(finalObj.sellerDocument));

    this.addNewIsv.value.jobTitle &&
      formData.append('jobTitle', this.addNewIsv.value.jobTitle);

    formData.append('userLinkedin', this.addNewIsv.value.userLinkedin);

    formData.append('firstName', this.addNewIsv.value.firstName);

    formData.append('lastName', this.addNewIsv.value.lastName);

    this.addNewIsv.value.mobile &&
      formData.append('mobile', this.addNewIsv.value.mobile);

    formData.append('email', this.addNewIsv.value.email);

    formData.append(
      'status',
      this.addNewIsv.value.isApprovedStatus ||
        this.addNewIsv.getRawValue().isApprovedStatus
        ? this.addNewIsv.value.isApprovedStatus ||
            this.addNewIsv.getRawValue().isApprovedStatus
        : this.idOfNew?._id
    );
    formData.append(
      'emailVerified',
      this.addNewIsv.value.emailVerified
        ? this.addNewIsv.value.emailVerified
        : false
    );
    // console.log(this.addNewIsv.value.emailVerified);
    if (!this.showCreate && this.isvUser) {
      this.addData
        .updateISVService(this.isvUser.userId._id, formData)
        .subscribe(
          (el) => {
            // this.getInitialData();

            this.alertService.showSuccess('ISV Updated Successfully');
            this.resetForm();
          },
          (err) => {
            if (err.error.message === 'Email already exist on platform') {
              Swal.fire({
                title: 'Something went Wrong',
                text: err.error.message,
                icon: 'error',
              });
              this.emailError = 'Email already exist on platform';
            } else {
              console.log(err.error);
              Swal.fire({
                title: 'Something went Wrong',
                text: err.error.message || err.error.error,
                icon: 'error',
              });
            }
          }
        );
    } else {
      this.addData.addIsvService(formData).subscribe(
        (el) => {
          // console.log(el);
          this.alertService.showSuccess('ISV Added Successfully');
          this.resetForm();
        },
        (err) => {
          if (err.error.message === 'Email already exist on platform') {
            Swal.fire({
              title: 'Something went Wrong',
              text: err.error.message,
              icon: 'error',
            });
            this.emailError = 'Email already exist on platform';
          } else {
            Swal.fire({
              title: 'Something went Wrong',
              text: err.error.message || err.error.error,
              icon: 'error',
            });
          }
        }
      );
    }
  }

  resetForm() {
    this.addNewIsv.reset();
    this.holdDocArr = [];
    this.fileName = null;
    this.router.navigate(['/admin/all-isv']);
  }

  get f() {
    return this.addNewIsv.controls;
  }

  setEndMonth(e) {
    if (e != '' && this.monthsArr.length > 0) {
      const findObjOFMonth = this.monthsArr.filter((el) => el._id === e);
      let tempIndex = this.monthsArr.indexOf(findObjOFMonth[0]);
      let tempObject;
      if (tempIndex == 0) {
        tempObject = this.monthsArr[11];
      } else {
        tempObject = this.monthsArr[tempIndex - 1];
      }

      this.endDateValue = tempObject ? tempObject._id : '';

      this.addNewIsv.get('end').setValue(tempObject ? tempObject._id : '');
    }
  }

  async getDocumentTypes() {
    const res = await this.dropDown.getdocumentType().toPromise();
    this.documentTypeArr = res?.details?.documentTypes;
  }

  async getDocumentStatuss() {
    const res = await this.dropDown.getdocumentStatus().toPromise();
    this.documentStatusArr = res?.details?.docStatus;
  }

  // Get List Months for Dropdown
  async getMonths() {
    const res = await this.dropDown.getMonth().toPromise();
    this.monthsArr = res?.details?.months;
  }

  // Get List Job Title for Dropdown
  async getJobTitle() {
    const res = await this.dropDown.getjobTitle().toPromise();
    this.jobTitleArr = res?.details?.jobTitle;

    const otherJobTitle = this.jobTitleArr.find((el) => el.value === 'Others');

    const indexofOther = this.jobTitleArr.findIndex(
      (item) => item == otherJobTitle
    );

    this.jobTitleArr.splice(indexofOther, 1);
    this.jobTitleArr.push(otherJobTitle);
  }

  // Get Countries Dropdown;
  async getCountries() {
    const countries = await this.dropDown.getCountry().toPromise();
    this.countriesArr = countries?.details?.countries.sort();
  }
}
