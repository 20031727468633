import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService {
  constructor(private authenticationService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl =
      request.url.startsWith('127.0.0.1') ||
      request.url.startsWith('localhost') ||
      request.url.startsWith(environment.apiUrl);

    // console.log('LINE 25 - currentUser ==>', currentUser.token);
    // console.log("LINE 26 - isLoggedIn ==>", currentUser);
    // console.log('LINE 27 - isApiUrl ==>', isApiUrl);

    if (isLoggedIn && isApiUrl) {
      // console.log(currentUser.token);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
    }

    return next.handle(request);
  }
}
