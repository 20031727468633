<div class="panel panel-primary setup-content" id="step-4">
  <section class="mail-verify lets padding-88">
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5>
            What domain and sub-domain is
            {{ productName || "N/A" }} targeting?<span class="ml-0"
              ><button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or
                                            decision maker;  The BEST ENTRY POINT person could well
                                            become your eventual main Champion"
              >
                <img src="../assets/images/info.svg" /></button
            ></span>
          </h5>
        </div>
        <div class="form-field sign-form">
          <form [formGroup]="domainForm">
            <div class="form-sec domain normal mb-15">
              <div class="row">
                <div class="col-12">
                  <div class="form-group select-fa">
                    <label for="exampleFormControlSelect1"
                      >What's the generic domain {{ productName || "N/A" }} is
                      targeting?</label
                    >
                    <select
                      formControlName="domain"
                      (change)="getSubdomain($event.target.value)"
                      required=""
                      class="form-control"
                    >
                      <option value="" disabled="" selected="" hidden="">
                        Select Domain
                      </option>
                      <option
                        *ngFor="let data of domain"
                        value="{{ data._id }}"
                      >
                        {{ data.value }}
                      </option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group select-fa">
                    <label for="exampleFormControlSelect1"
                      >What’s, according to you, the sub-domain
                      {{ productName || "N/A" }} is targeting ?</label
                    >
                    <select formControlName="subDomain" class="form-control">
                      <option value="" disabled="" selected="" hidden="">
                        Select Sub-Domain
                      </option>
                      <option
                        *ngFor="let data of subdomain"
                        value="{{ data._id }}"
                      >
                        {{ data.value }}
                      </option>
                    </select>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="theme-btn dom width-fit text-right">
          <button
            [disabled]="domainForm.invalid"
            (click)="AddtargetDomain()"
            [class.active]="domainForm.valid"
            class="btn nextBtn"
            type="button"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
