<main class="background-color">
  <section class="edit-profile padding-40 pb-0">
    <form [formGroup]="addNewRp" (ngSubmit)="addRp()">
      <div class="container-fluid">
        <div class="profile-sec">
          <div class="col-12" *ngIf="showCreate">
            <div class="profile-p">Add New HLC</div>
          </div>
          <div class="col-12" *ngIf="!showCreate">
            <div class="profile-p">Edit and View HLC</div>
          </div>
        </div>
        <div class="profile-details">
          <div class="col-12 top">
            <div class="row">
              <div class="col-lg-7 col-md-6">
                <div class="profile-box">
                  <div class="form-sec mb-0">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row flex-dir">
                          <div class="col-10 is-first">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="firstName"
                                    >First Name <sup class="text-danger">*</sup>
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="firstName"
                                    aria-describedby="firstName"
                                    formControlName="firstName"
                                    placeholder="Enter First name"
                                    [class.is-invalid]="
                                      f.firstName.invalid && f.firstName.touched
                                    "
                                  />
                                  <div
                                    class="text-danger"
                                    *ngIf="
                                      f.firstName.touched && f.firstName.errors
                                    "
                                  >
                                    <small *ngIf="f.firstName.errors.required">
                                      *Firstname is required
                                    </small>
                                    <small *ngIf="f.firstName.errors.pattern">
                                      *Firstname is required
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="lastName"
                                    >Last Name
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="lastName"
                                    placeholder="Enter Last name"
                                    id="lastName"
                                    [class.is-invalid]="
                                      f.lastName.invalid && f.lastName.touched
                                    "
                                  />
                                  <div
                                    class="text-danger"
                                    *ngIf="
                                      f.lastName.touched && f.lastName.errors
                                    "
                                  >
                                    <small *ngIf="f.lastName.errors.required">
                                      *Lastname is required
                                    </small>
                                    <small *ngIf="f.lastName.errors.pattern">
                                      *Lastame is required
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="form-group">
                              <label for="email"
                                >Work Email<sup class="text-danger"
                                  >*</sup
                                ></label
                              >
                              <input
                                type="email"
                                class="form-control"
                                id="email"
                                aria-describedby="email"
                                formControlName="email"
                                name="email"
                                placeholder="Enter your Email"
                                [class.is-invalid]="
                                  f.email.invalid && f.email.touched
                                "
                              />
                              <small class="text-danger" *ngIf="emailError">
                                {{ emailError }}
                              </small>

                              <div
                                class="text-danger"
                                *ngIf="f.email.touched && f.email.errors"
                              >
                                <small *ngIf="f.email.errors.required">
                                  *Email is required
                                </small>
                                <small *ngIf="f.email.errors.pattern">
                                  *Please enter a valid Email
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-2 is-second">
                            <div class="profile d-block">
                              <p class="head-box-p">Add Photo</p>
                              <div class="profile-img-top">
                                <div *ngIf="!rpImg">
                                  <div *ngIf="!rpUserImgCon">
                                    <div class="profile-img">
                                      <img
                                        src="../assets/images/user.png"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div *ngIf="rpUserImgCon">
                                    <div class="profile-img">
                                      <img
                                        src="{{ imgUrl }}{{
                                          agentDetails.userId?.userImage
                                        }}"
                                        height="100"
                                        width="150"
                                        class="image"
                                        alt="Profile Image"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="rpImg">
                                  <div class="profile-img">
                                    <img
                                      [src]="rpImg"
                                      height="100"
                                      width="150"
                                      class="image"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div class="fa-penci isv-fa new">
                                  <i class="fa fa-pencil"></i>
                                  <input
                                    type="file"
                                    #file
                                    formControlName="rpImage"
                                    name="rpImage"
                                    (change)="
                                      selectFile($event);
                                      previewFile(file.files)
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group select-fa">
                          <label for="jobTitle"
                            >Job Title <sup class="text-danger">*</sup></label
                          >
                          <input
                            type="text"
                            formControlName="jobTitle"
                            class="form-control"
                            id="jobTitle"
                            placeholder="Job Title"
                            [class.is-invalid]="
                              f.jobTitle.invalid && f.jobTitle.touched
                            "
                          />

                          <div
                            class="text-danger"
                            *ngIf="f.jobTitle.touched && f.jobTitle.errors"
                          >
                            <small *ngIf="f.jobTitle.errors.required">
                              *Jobtitle is required
                            </small>
                            <small *ngIf="f.jobTitle.errors.pattern">
                              *Jobtitle is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="userLinkedin"
                            >Linkedin Profile
                            <sup class="text-danger">*</sup></label
                          >
                          <input
                            type="text"
                            formControlName="userLinkedin"
                            class="form-control"
                            id="userLinkedin"
                            name="userLinkedin"
                            placeholder="https://www.linkedin.com/in/"
                            [class.is-invalid]="
                              f.userLinkedin.invalid && f.userLinkedin.touched
                            "
                          />

                          <div
                            class="text-danger"
                            *ngIf="
                              f.userLinkedin.touched && f.userLinkedin.errors
                            "
                          >
                            <small *ngIf="f.userLinkedin.errors.required">
                              *User linkedin is required
                            </small>
                            <small *ngIf="f.userLinkedin.errors.pattern">
                              *User linkedin is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="mobile">Mobile Number</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="mobile"
                            class="form-control"
                            name="mobile"
                            id="mobile"
                            maxlength="15"
                            [class.is-invalid]="
                              f.mobile.invalid && f.mobile.touched
                            "
                          />
                          <div
                            class="text-danger"
                            *ngIf="f.mobile.touched && f.mobile.errors"
                          >
                            <small *ngIf="f.mobile.errors.pattern">
                              *Please provide a valid mobile number.
                            </small>
                            <small *ngIf="f.mobile.errors.maxlength">
                              *Please provide a valid mobile number.
                            </small>
                            <small *ngIf="f.mobile.errors.minlength">
                              *Please provide a valid mobile number.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="profile-box">
                  <div class="form-sec mb-0">
                    <div class="head-box-check pos">
                      <p class="head-box-p">WholeSoft Market Agreement</p>

                      <div class="custom-control p-0 custom-checkbox mb-3">
                        <!-- :SIGNED: :TODO: -->
                        <mat-checkbox
                          class="matCheckBox checkBoxText"
                          required
                          name="isAgreementSigned"
                          aria-label="isAgreementSigned"
                          formControlName="isAgreementSigned"
                          id="isAgreementSigned"
                          (click)="afterAgreement($event)"
                        >
                          Signed?
                        </mat-checkbox>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="startDate">Start Date</label>
                          <input
                            type="date"
                            class="form-control"
                            formControlName="startDate"
                            name="startDate"
                            id="startDate"
                            (change)="enableEndDateFn($event)"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="endDate">End Date</label>
                          <input
                            type="date"
                            class="form-control"
                            id="endDate"
                            [min]="addNewRp.value.startDate"
                            aria-describedby="endDate"
                            formControlName="endDate"
                            [readonly]="!addNewRp.value.startDate"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="referralFees">Referral Fee %</label>
                          <input
                            type="number"
                            formControlName="referralFees"
                            class="form-control"
                            id="referralFees"
                            placeholder="Referal Fees"
                            value="5"
                            [class.is-invalid]="
                              f.referralFees.invalid && f.referralFees.touched
                            "
                          />

                          <div
                            class="text-danger"
                            *ngIf="
                              f.referralFees.touched && f.referralFees.errors
                            "
                          >
                            <small *ngIf="f.referralFees.errors.required">
                              *Referal Fee is required
                            </small>
                            <small *ngIf="f.referralFees.errors.pattern">
                              Referal Fee is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <!-- <input
                            type="text"
                            formControlName="sponsoredBy"
                            class="form-control"
                            id="sponsoredBy"
                            name="sponsoredBy"
                            [class.is-invalid]="
                              f.sponsoredBy.invalid && f.sponsoredBy.touched
                            "
                          /> -->
                          <div class="select-fa">
                            <label for="sponsoredBy">Sponsored By</label>
                            <select
                              class="form-control"
                              formControlName="sponsoredBy"
                              for="sponsoredBy"
                              id="sponsoredBy"
                              name="sponsoredBy"
                              [class.is-invalid]="
                                f.sponsoredBy.invalid && f.sponsoredBy.touched
                              "
                            >
                              <option selected value="" disabled hidden>
                                Select Sponsee
                              </option>

                              <option
                                *ngFor="let i of sponsoredByArr"
                                value="{{ i._id }}"
                              >
                                {{ i.firstName + " " + i.lastName }}
                              </option>
                            </select>
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </div>

                          <div
                            class="text-danger"
                            *ngIf="
                              f.sponsoredBy.touched && f.sponsoredBy.errors
                            "
                          >
                            <small *ngIf="f.sponsoredBy.errors.required">
                              *Sponsored by is required
                            </small>
                            <small *ngIf="f.sponsoredBy.errors.pattern">
                              Sponsored by is required
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="status">Status</label>
                              <div class="select-fa">
                                <select
                                  class="form-control"
                                  formControlName="status"
                                  id="status"
                                  (change)="chnageEmailVerfied($event)"
                                  [class.is-invalid]="
                                    f.status.invalid && f.status.touched
                                  "
                                >
                                  <option selected value="" disabled hidden>
                                    Select Status
                                  </option>

                                  <option
                                    *ngFor="let i of filteredStatusArr"
                                    value="{{ i._id }}"
                                    [disabled]="
                                      i._id === idOfActive._id &&
                                      (addNewRp.value.isAgreementSigned ===
                                        false ||
                                        addNewRp.value.geography == '' ||
                                        addNewRp.value.industriesOne == '' ||
                                        addNewRp.value.domainOne == '' ||
                                        addNewRp.value.startDate == '' ||
                                        addNewRp.value.endDate == '')
                                    "
                                  >
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>

                              <!-- <mat-form-field
                                appearance="outline"
                                class="material-input width-100"
                              >
                                <mat-label>Select Status</mat-label>
                                <mat-select
                                  formControlName="status"
                                  id="status"
                                  [class.is-invalid]="
                                    f.status.invalid && f.status.touched
                                  "
                                >
                                  <mat-option
                                    *ngFor="let i of filteredStatusArr"
                                    [value]="i._id"
                                  >
                                    {{ i.value }}</mat-option
                                  >
                                </mat-select>
                              </mat-form-field> -->
                              <div
                                class="text-danger"
                                *ngIf="f.status.touched && f.status.errors"
                              >
                                <small *ngIf="f.status.errors.required">
                                  *Status is required
                                </small>
                                <small *ngIf="f.status.errors.pattern">
                                  *Status is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="row">
                              <div class="col-sm-8">
                                <div class="form-group">
                                  <label for="tier">Tier Status</label>
                                  <div class="select-fa">
                                    <select
                                      formControlName="tier"
                                      class="form-control"
                                      [class.is-invalid]="
                                        f.tier.invalid && f.tier.touched
                                      "
                                      name="tier"
                                      id="tier"
                                    >
                                      <option selected disabled value="" hidden>
                                        Select Tier Status
                                      </option>
                                      <option value="">Other</option>
                                      <option
                                        *ngFor="let i of tierStatusArr"
                                        [value]="i._id"
                                      >
                                        {{ i.value }}
                                      </option>
                                    </select>
                                    <i
                                      class="fa fa-angle-down"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <!-- <mat-form-field
                                    appearance="outline"
                                    class="material-input width-100"
                                  >
                                    <mat-label>Tier Status</mat-label>
                                    <mat-select
                                      formControlName="tier"
                                      [class.is-invalid]="
                                        f.tier.invalid && f.tier.touched
                                      "
                                      id="tier"
                                      [class.is-invalid]="
                                        f.status.invalid && f.status.touched
                                      "
                                    >
                                      <mat-option
                                        *ngFor="let i of tierStatusArr"
                                        [value]="i._id"
                                      >
                                        {{ i.value }}</mat-option
                                      >
                                    </mat-select>
                                  </mat-form-field> -->
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <!-- <div class="head-box-check new pos"> -->
                                <div class="pushMiddle">
                                  <!-- <div
                                    class="custom-control p-0 custom-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="customCheck"
                                      name="example1"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="customCheck"
                                      >Email Verified</label
                                    >
                                  </div> -->
                                  <mat-checkbox
                                    required
                                    name="emailVerified"
                                    aria-label="emailVerified"
                                    formControlName="emailVerified"
                                    (click)="detectChangeTrue($event)"
                                    status="success"
                                    formControlName="emailVerified"
                                    class="checkBoxText"
                                  >
                                    Email Verified
                                    <sup class="text-danger" *ngIf="checkBox"
                                      >*</sup
                                    >
                                  </mat-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="!showCreate" class="col-sm-12">
                            <div class="form-group">
                              <label for="createdDate">Created Date</label>
                              <input
                                type="date"
                                class="form-control"
                                readonly
                                id="createdDate"
                                formControlName="createdDate"
                                name="createdDate"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12" *ngIf="!showCreate">
                            <div class="form-group">
                              <label for="createdBy">Created By</label>
                              <input
                                type="text"
                                class="form-control"
                                readonly
                                id="createdBy"
                                name="createdBy"
                                formControlName="createdBy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <p class="head-box-p">Bank Details</p>

                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="bankName">Bank Name</label>
                              <input
                                type="text"
                                formControlName="bankName"
                                class="form-control"
                                id="bankName"
                                placeholder="Bank Name"
                                (change)="checkFormValid($event)"
                                [class.is-invalid]="
                                  f.bankName.invalid && f.bankName.touched
                                "
                              />
                              <div
                                class="text-danger"
                                *ngIf="f.bankName.touched && f.bankName.errors"
                              >
                                <small *ngIf="f.bankName.errors.required">
                                  *Bank name is required
                                </small>
                                <small *ngIf="f.bankName.errors.pattern">
                                  *Bank name is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="IBAN">IBAN</label>
                              <input
                                type="text"
                                formControlName="iban"
                                (change)="checkFormValid($event)"
                                class="form-control"
                                id="IBAN"
                                name="IBAN"
                                aria-describedby="IBAN"
                                placeholder="IBAN"
                                [class.is-invalid]="
                                  f.iban.invalid && f.iban.touched
                                "
                              />
                              <div
                                class="text-danger"
                                *ngIf="f.iban.touched && f.iban.errors"
                              >
                                <small *ngIf="f.iban.errors.required">
                                  *IBAN is required
                                </small>
                                <small *ngIf="f.iban.errors.pattern">
                                  *IBAN is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="SWIFT">BIC/SWIFT</label>
                              <input
                                type="text"
                                formControlName="bicSwift"
                                class="form-control"
                                id="SWIFT"
                                (change)="checkFormValid($event)"
                                aria-describedby="SWIFT"
                                name="SWIFT"
                                placeholder="BIC/SWIFT"
                                [class.is-invalid]="
                                  f.bicSwift.invalid && f.bicSwift.touched
                                "
                              />

                              <div
                                class="text-danger"
                                *ngIf="f.bicSwift.touched && f.bicSwift.errors"
                              >
                                <small *ngIf="f.bicSwift.errors.required">
                                  *Bic-Swift number is required
                                </small>
                                <small *ngIf="f.bicSwift.errors.pattern">
                                  *Bic-Swift number is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="beneficiary">Beneficiary Name</label>
                              <input
                                type="text"
                                formControlName="benificiaryName"
                                class="form-control"
                                id="beneficiary"
                                name="beneficiary"
                                (change)="checkFormValid($event)"
                                placeholder="Benificiary Name"
                                aria-describedby="beneficiary"
                                [class.is-invalid]="
                                  f.benificiaryName.invalid &&
                                  f.benificiaryName.touched
                                "
                              />

                              <div
                                class="text-danger"
                                *ngIf="
                                  f.benificiaryName.touched &&
                                  f.benificiaryName.errors
                                "
                              >
                                <small
                                  *ngIf="f.benificiaryName.errors.required"
                                >
                                  *Benificiary name is required
                                </small>
                                <small *ngIf="f.benificiaryName.errors.pattern">
                                  *Benificiary name is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="benificiaryName"
                                >Beneficiary Address</label
                              >
                              <input
                                type="text"
                                formControlName="benificiaryAddress"
                                class="form-control"
                                id="benificiaryAddress"
                                name="benificiaryAddress"
                                placeholder="Benificiary Address"
                                aria-describedby="benificiaryAddress"
                                (change)="checkFormValid($event)"
                                [class.is-invalid]="
                                  f.benificiaryAddress.invalid &&
                                  f.benificiaryAddress.touched
                                "
                              />

                              <div
                                class="text-danger"
                                *ngIf="
                                  f.benificiaryAddress.touched &&
                                  f.benificiaryAddress.errors
                                "
                              >
                                <small
                                  *ngIf="f.benificiaryAddress.errors.required"
                                >
                                  Benificiary address is required
                                </small>
                                <small
                                  *ngIf="f.benificiaryAddress.errors.pattern"
                                >
                                  Benificiary address is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="beneficiaryAC"
                                >Beneficiary Account Number</label
                              >
                              <input
                                type="text"
                                formControlName="benificiaryAccount"
                                class="form-control"
                                id="beneficiaryAC"
                                aria-describedby="beneficiaryAC"
                                name="beneficiaryAC"
                                placeholder="Beneficiary Account Number"
                                (change)="checkFormValid($event)"
                                [class.is-invalid]="
                                  f.benificiaryAccount.invalid &&
                                  f.benificiaryAccount.touched
                                "
                              />

                              <div
                                class="text-danger"
                                *ngIf="
                                  f.benificiaryAccount.touched &&
                                  f.benificiaryAccount.errors
                                "
                              >
                                <small
                                  *ngIf="f.benificiaryAccount.errors.required"
                                >
                                  Benificiary account number is required
                                </small>
                                <small
                                  *ngIf="f.benificiaryAccount.errors.pattern"
                                >
                                  Benificiary account number is required
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-box">
                      <div class="form-field sign-form">
                        <div class="form-sec domain mb-15">
                          <!-- <div class="row">
                            <div class="col-12">
                              <p class="formp">
                                Which geography can you help software vendors
                                penetrate?
                              </p>
                              <div class="form-group select-fa">
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option
                                    value=""
                                    disabled=""
                                    selected=""
                                    hidden=""
                                  >
                                    United States
                                  </option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group select-fa">
                                <p class="formp">
                                  Which Industries and Domains, in priority, can
                                  you help software vendors penetrate?
                                </p>
                                <label for="exampleFormControlSelect1"
                                  >Industries</label
                                >
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option>BFSI</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div class="form-group select-fa">
                                <select
                                  required
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option
                                    value=""
                                    disabled=""
                                    selected=""
                                    hidden=""
                                  >
                                    Industry 1
                                  </option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group select-fa">
                                <label for="exampleFormControlSelect1"
                                  >Domain</label
                                >
                                <select
                                  required
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option
                                    value=""
                                    disabled=""
                                    selected=""
                                    hidden=""
                                  >
                                    Domain 1
                                  </option>
                                  <option>Domain 2</option>
                                  <option>Domain 3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div class="form-group select-fa">
                                <select
                                  required
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option
                                    value=""
                                    disabled=""
                                    selected=""
                                    hidden=""
                                  >
                                    Domain 1
                                  </option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div class="form-group select-fa">
                                <select
                                  required
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                >
                                  <option
                                    value=""
                                    disabled=""
                                    selected=""
                                    hidden=""
                                  >
                                    Domain 1
                                  </option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="head-box-check left-check">
                                <div
                                  class="custom-control p-0 custom-checkbox mb-3"
                                >
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customCheck-new2"
                                    name="example1"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customCheck-new2"
                                    >Do you permit WholeSoft Market to extract
                                    your Linkedin network?</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div> -->
                          <div class="row">
                            <div class="col-12">
                              <p class="formp">
                                Which geography can you help software vendors
                                penetrate?
                              </p>
                              <div class="form-group select-fa">
                                <select
                                  class="form-control"
                                  id="geography-software"
                                  formControlName="geography"
                                  name="geography"
                                  [class.is-invalid]="
                                    f.geography.invalid && f.geography.touched
                                  "
                                >
                                  <option value selected disabled hidden>
                                    Select Your Geography
                                  </option>
                                  <option
                                    *ngFor="let i of geographyArr"
                                    value="{{ i._id }}"
                                  >
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  *ngIf="!f.geography.errors"
                                  class="fas fa-check"
                                ></i>
                                <div
                                  class="text-danger"
                                  *ngIf="submitted && f.geography.errors"
                                >
                                  <div *ngIf="f.geography.errors.required">
                                    *Geography region is required
                                  </div>
                                  <div *ngIf="f.geography.errors.pattern">
                                    *Please enter geography region
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group select-fa">
                                <p class="formp">
                                  Which Industries and Domains, in priority, can
                                  you help software vendors penetrate?
                                </p>
                                <label for="industriesOne">Industries</label>
                                <select
                                  class="form-control"
                                  id="industriesOne"
                                  formControlName="industriesOne"
                                  name="industriesOne"
                                  (click)="
                                    checkDupIndOneToTwo($event);
                                    additionalFunInd($event)
                                  "
                                  [class.is-invalid]="
                                    f.industriesOne.invalid &&
                                    f.industriesOne.touched
                                  "
                                >
                                  <option value selected disabled hidden>
                                    Select Industry 1
                                  </option>
                                  <option *ngFor="let i of industryArr">
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  *ngIf="!f.industriesOne.errors"
                                  class="fas fa-check"
                                ></i>
                                <small
                                  class="text-danger"
                                  *ngIf="
                                    msgIndustryOneToTwo || msgIndustryOneToTwo
                                  "
                                >
                                  {{
                                    msgIndustryOneToTwo || msgIndustryOneToTwo
                                  }}
                                </small>

                                <!-- <small class="text-danger" *ngIf="oneToThree">
                          {{ oneToThree }}
                        </small> -->

                                <!-- <small class="text-danger" *ngIf="f.industriesOne.errors">
                        *Atleast one industry is required
                      </small> -->
                              </div>
                              <div class="form-group select-fa">
                                <select
                                  class="form-control"
                                  id="industry-two"
                                  formControlName="industriesTwo"
                                  name="industriesTwo"
                                  (click)="
                                    checkDupInd($event);
                                    additionalFunInd($event)
                                  "
                                >
                                  <!--  disabled hidden -->
                                  <option value selected>
                                    <!-- [hidden]="hideIndustryOne" -->
                                    Select Industry 2
                                  </option>
                                  <option *ngFor="let i of industryArr">
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>

                                <small
                                  class="text-danger"
                                  *ngIf="msgIndustry && msgIndustry !== null"
                                >
                                  {{ msgIndustry }}
                                </small>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group select-fa">
                                <label for="domainOne">Domain</label>
                                <select
                                  class="form-control"
                                  id="domainOne"
                                  formControlName="domainOne"
                                  name="domainOne"
                                  (click)="
                                    checkDupDomainOneToTwo($event);
                                    checkDupDomainOneToThree($event);
                                    additionalFunDomain($event)
                                  "
                                  [class.is-invalid]="
                                    f.domainOne.invalid && f.domainOne.touched
                                  "
                                >
                                  <option value selected disabled hidden>
                                    Select Domain 1
                                  </option>
                                  <option *ngFor="let i of domainArr">
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>

                                <small
                                  class="text-danger"
                                  *ngIf="
                                    msgDomainOtherToOne ||
                                    oneToThree ||
                                    msgDomainOtherToTwo
                                  "
                                >
                                  {{
                                    msgDomainOtherToOne ||
                                      oneToThree ||
                                      msgDomainOtherToTwo
                                  }}
                                </small>
                                <!-- <small class="text-danger" *ngIf="oneToThree">
                        {{ oneToThree }}
                      </small> -->
                                <div
                                  class="text-danger"
                                  *ngIf="submitted && f.domainOne.errors"
                                >
                                  <small *ngIf="f.domainOne.errors.required">
                                    *Atleast one domain is required
                                  </small>
                                  <small *ngIf="f.domainOne.errors.pattern">
                                    *Please enter atleast one domain
                                  </small>
                                </div>
                              </div>
                              <div class="form-group select-fa">
                                <select
                                  class="form-control"
                                  id="exampleFormControlSelect1"
                                  formControlName="domainTwo"
                                  name="domainTwo"
                                  (click)="
                                    checkDupDomainTwotoOne($event);
                                    checkDupDomainTwotoThree($event);
                                    additionalFunDomain($event)
                                  "
                                >
                                  <option value selected>
                                    <!-- [hidden]="hideDomainOne" -->
                                    Select Domain 2
                                  </option>
                                  <option *ngFor="let i of domainArr">
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                                <!-- <i
                        *ngIf="msgDomain && msgDomain !== null"
                        class="fas fa-check"
                      ></i> -->
                                <small
                                  class="text-danger"
                                  *ngIf="msgDomainOne || msgDomainOneToThree"
                                >
                                  {{ msgDomainOne || msgDomainOneToThree }}
                                </small>
                                <!-- <small class="text-danger" *ngIf="msgDomainOneToThree">
                        {{ msgDomainOneToThree }}
                      </small> -->
                              </div>
                              <div class="form-group select-fa">
                                <select
                                  class="form-control"
                                  id="domainThree"
                                  formControlName="domainThree"
                                  name="domainThree"
                                  (click)="
                                    checkDupDomainThreeTotwo($event);
                                    checkDupDomainThreeToOne($event);
                                    additionalFunDomain($event)
                                  "
                                >
                                  <option value selected>
                                    <!-- [hidden]="hideDomainTwo" -->
                                    Select Domain 3
                                  </option>
                                  <option *ngFor="let i of domainArr">
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>

                                <small
                                  class="text-danger"
                                  *ngIf="
                                    msgDomainThreeToOne || msgDomainThreeToTwo
                                  "
                                >
                                  {{
                                    msgDomainThreeToOne || msgDomainThreeToTwo
                                  }}
                                </small>
                                <!-- <small
                        class="text-danger"
                        *ngIf="
                          msgDomainThreeToTwo && msgDomainThreeToTwo !== null
                        "
                      >
                        {{ msgDomainThreeToTwo }}
                      </small> -->
                              </div>
                            </div>
                            <div class="col-12">
                              <!-- <div class="alert-box">
                                <p class="formp">
                                  Do you permit WholeSoft Market to extract your
                                  LinkedIn network?
                                </p>
                                <div class="alert alert-warning" role="alert">
                                  <p>
                                    <img
                                      src="assets/images/idea.png"
                                      class="mr-1"
                                      alt=""
                                    />This will automate advanced searches and
                                    create more opportunities for you
                                  </p>
                                </div>
                              </div> -->
                              <mat-checkbox
                                name="isExtractLinkedin"
                                aria-label="isExtractLinkedin"
                                formControlName="isExtractLinkedin"
                                class="isExtractLinkedin"
                                id="isExtractLinkedin"
                              >
                                Do you permit WholeSoft Market to extract your
                                LinkedIn network?
                              </mat-checkbox>
                              <!-- <div class="alert alert-warning" role="alert">
                                <p>
                                  <img
                                    src="assets/images/idea.png"
                                    class="mr-1"
                                    alt=""
                                  />This will automate advanced searches and
                                  create more opportunities for you
                                </p>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 
      <h1 class="bool">
        {{
          showCreate &&
            addNewRp.value.isAgreementSigned === true &&
            addNewRp.controls.startDate.status === "VALID" &&
            addNewRp.controls.endDate.status === "VALID" &&
            addNewRp.controls.referralFees.status === "VALID"
        }}
      </h1> -->

      <div class="theme-btn width-fit mt-40 footer-btn blue text-center">
        <button
          *ngIf="showCreate"
          class="btn mr-1 regu yellow"
          [disabled]="
            showCreate && addNewRp.value.isAgreementSigned === true
              ? !(
                  addNewRp.controls.startDate.status === 'VALID' &&
                  addNewRp.controls.endDate.status === 'VALID' &&
                  addNewRp.controls.referralFees.status === 'VALID'
                )
              : false
          "
          [disabled]="
            !(
              showCreate &&
              addNewRp.value.firstName &&
              addNewRp.value.lastName &&
              addNewRp.value.jobTitle
            )
          "
        >
          SAVE
        </button>
        <button
          *ngIf="!showCreate"
          class="btn mr-1 regu yellow"
          [disabled]="
            f.bankName.errors ||
            f.benificiaryAccount.errors ||
            f.benificiaryAddress.errors ||
            f.benificiaryName.errors ||
            f.bicSwift.errors ||
            f.domainOne.errors ||
            f.domainThree.errors ||
            f.domainTwo.errors ||
            f.email.errors ||
            f.emailVerified.errors ||
            f.endDate.errors ||
            f.firstName.errors ||
            f.geography.errors ||
            f.iban.errors ||
            f.industriesOne.errors ||
            f.industriesTwo.errors ||
            f.isExtractLinkedin.errors ||
            f.jobTitle.errors ||
            f.lastName.errors ||
            f.mobile.errors ||
            f.referralFees.errors ||
            f.rpImage.errors ||
            f.sponsoredBy.errors ||
            f.startDate.errors ||
            f.status.errors ||
            f.tier.errors ||
            f.userLinkedin.errors
          "
        >
          <!-- [disabled]="!addNewRp.valid" -->
          SAVE
        </button>
        <button type="button" class="btn regu" (click)="resetForm()">
          CANCEL
        </button>
      </div>
    </form>
  </section>
</main>
<!-- 
  [disabled]="
            addNewRp.controls.bankName.status !== 'VALID' ||
            addNewRp.controls.benificiaryAccount.status !== 'VALID' ||
            addNewRp.controls.benificiaryAddress.status !== 'VALID' ||
            addNewRp.controls.benificiaryName.status !== 'VALID' ||
            addNewRp.controls.bicSwift.status !== 'VALID' ||
            addNewRp.controls.domainOne.status !== 'VALID' ||
            addNewRp.controls.domainThree.status !== 'VALID' ||
            addNewRp.controls.domainTwo.status !== 'VALID' ||
            addNewRp.controls.email.status !== 'VALID' ||
            addNewRp.controls.emailVerified.status !== 'VALID' ||
            addNewRp.controls.endDate.status !== 'VALID' ||
            addNewRp.controls.firstName.status !== 'VALID' ||
            addNewRp.controls.geography.status !== 'VALID' ||
            addNewRp.controls.iban.status !== 'VALID' ||
            addNewRp.controls.industriesOne.status !== 'VALID' ||
            addNewRp.controls.industriesTwo.status !== 'VALID' ||
            addNewRp.controls.isExtractLinkedin.status !== 'VALID' ||
            addNewRp.controls.jobTitle.status !== 'VALID' ||
            addNewRp.controls.lastName.status !== 'VALID' ||
            addNewRp.controls.mobile.status !== 'VALID' ||
            addNewRp.controls.referralFees.status !== 'VALID' ||
            addNewRp.controls.rpImage.status !== 'VALID' ||
            addNewRp.controls.sponsoredBy.status !== 'VALID' ||
            addNewRp.controls.startDate.status !== 'VALID' ||
            addNewRp.controls.status.status !== 'VALID' ||
            addNewRp.controls.tier.status !== 'VALID' ||
            addNewRp.controls.userLinkedin.status !== 'VALID'
          "
 -->
