import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { DropdownService } from '../../../services/dropdown.service';
import { CommonService } from 'src/app/utils/common.service';
import { environment } from '../../../../environments/environment';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { SharedServiceService } from 'src/app/services/shared-service.service';
declare var $: any;
@Component({
  selector: 'app-wsm-networks',
  templateUrl: './wsm-networks.component.html',
  styleUrls: ['./wsm-networks.component.scss'],
})
export class WsmNetworksComponent implements OnInit {
  rpId: any;
  newNature: any = '<target>';
  dynamicName = '';
  initialArr: any = [];
  totalPage;
  totalPageArr: any = [];
  bkUptotalPage: any;
  public filters: any = {
    searchQuery: {
      name: '',
      status: '',
      geography: '',
      industry: '',
      domain: '',
    },

    skip: 0,
  };
  elementActive: any;
  count: number = 10;
  updateId;
  searchKey;
  days;
  account;
  strengthFilterId;
  imageFile;
  p: Number = 1;
  skip: number = 0;
  totalItems;
  imageUrl: any = 'https://i.ibb.co/fDWsn3G/buck.jpg';
  imageBaseUrl: any = environment.apiUrl;
  connectionDetails = {
    name: '',
    image: '',
    title: '',
    linkedInUrl: 'https://www.linkedin.com/in/',
    strength: '',
    strengthId: '',
    nature: '',
    natureId: '',
    jobTitle: '',
    companyName: '',
  };
  rpData: any = [];
  filterRpData: any = [];
  accountName: any = [];
  filterAccountName: any = [];
  strength: any = [];
  filterStrength: any = [];
  relationship: any = [];
  newRelationship: any = [];
  filterRelationship: any = [];
  connectionData: any = [];
  filterConnectionData: any = [];

  countPerPageText: string = '10 Per Page';
  rpFilText: string = 'High Level Connector';
  accFilText: string = 'Account';
  dateFiltext: string = 'Date Added';
  strengthFilText: string = 'Strength of connection';
  natureRelationFilText: string = 'Nature of Relationship';
  loader: boolean = true;

  connectionForm: FormGroup;
  editFile: boolean = true;
  removeUpload: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private auth: DropdownService,
    public sharedService: CommonService,
    public shared: SharedServiceService
  ) {
    this.sharedService.showHeader.next(true);
    this.connectionForm = this.fb.group({
      rpId: ['', [Validators.required]],
      account_name: ['', [Validators.required]],
      first_name: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z\\s]+$/)],
      ],
      last_name: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z\\s]+$/)],
      ],
      job_title: ['', [Validators.required]],
      // linkedin_url : ['', [Validators.required]],
      strength: [''],
      relationship: [''],
    });
  }
  user: any = '';
  ngOnInit(): void {
    let status = localStorage.getItem('status');
    if (JSON.parse(status) == 1) {
      $('#exampleModal1').modal('show');
    } else {
    }
    this.getAllNature();
    this.getAllStrength();
    this.accountDropdown();
    this.getConnections();
    this.getFilterStrength();
    this.filteraccountDropdown();
    this.getAllNatureNew();
    this.getRpData();
    this.getFilterRpData();
  }

  getRpData() {
    this.auth.getRpList().subscribe((res) => {
      let data = res['details']['data'];
      var filtered = data.filter(function (el) {
        return el != null;
      });
      this.rpData = filtered;
    });
  }

  getFilterRpData() {
    this.auth.getRpList().subscribe((res) => {
      let data = res['details']['data'];
      var filtered = data.filter(function (el) {
        return el != null;
      });
      this.filterRpData = filtered.sort((a, b) => {
        const nameA = a?.firstName.toUpperCase().trim();
        // ignore upper and lowercase
        const nameB = b?.firstName.toUpperCase().trim();
        // ignore upper and lowercase

        if (nameA < nameB) {
          return -1; //nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0; // names must be equal
      });
      // this.filterRpData.unshift({
      //   _id: 'None',
      //   firstName: 'Referral',
      //   lastName: 'Partner',
      // });
    });
  }
  uploadFile(event) {
    let reader = new FileReader();
    let file = event.target.files[0];
    this.imageFile = event.target.files[0];

    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageUrl = reader.result;
        this.connectionDetails.image = this.imageUrl;
        this.editFile = false;
        this.removeUpload = true;
      };
      this.cd.markForCheck();
    }
  }
  addConnection() {
    this.loader = true;
    let data = {
      rpId: this.connectionForm.value.rpId,
      firstName: this.connectionForm.value.first_name,
      lastName: this.connectionForm.value.last_name,
      linkedinUrl: $('#linkedInUrlId').val(),
      title: this.connectionForm.value.job_title,
      companyName: this.connectionForm.value.account_name,
      strengthOfConnection: this.connectionForm.value.strength,
      natureOfRelationship: this.connectionForm.value.relationship,
    };

    this.auth.addTargetToNetwork(data).subscribe((res) => {
      this.getConnections();
    });

    this.sharedService.showToastMessage(
      'SUCCESS',
      'Connection Added Successfully!',
      5000
    );
    $('#exampleModal1').modal('hide');
  }

  closeDialog(val) {
    console.log('closed');
    localStorage.removeItem('status');
    this.shared.sendMessage('Event Calling');
  }

  viewProfile(id) {
    this.updateId = id;

    let data = this.connectionData.filter((e) => e._id == id);

    let strengthId = data[0]['rpProfileMapped']['strengthOfConnection'];

    let natureOfRelationship =
      data[0]['rpProfileMapped']['natureOfRelationship'];

    let strengthName = this.strength.filter((ele) => ele._id == strengthId);

    let natureName = this.relationship.filter(
      (ele) => ele._id == natureOfRelationship
    );

    if (data[0].photo == null) {
      this.imageUrl = '';
    }

    this.newRelationship.forEach((res: any) => {
      res.value = res.value
        .toLowerCase()
        .replace(this.newNature, data[0].firstName);
    });
    this.newNature = data[0].firstName.toLowerCase();

    this.connectionDetails = {
      name: data[0].firstName + ' ' + data[0].lastName,
      image: data[0].photo == null ? this.imageUrl : data[0].photo,
      title: data[0].currentTitle,
      linkedInUrl: data[0].linkedInUrl,
      strength: !strengthName[0] ? null : strengthName[0].value,
      strengthId: !strengthName[0] ? null : strengthName[0]._id,
      nature: !natureName[0] ? null : natureName[0].value,
      natureId: !natureName[0] ? null : natureName[0]._id,
      jobTitle: data[0].currentTitle,
      companyName: data[0].currentCompanyName,
    };

    $('#exampleModal').modal('show');
  }

  getAllNature() {
    this.auth.getNature().subscribe((res) => {
      this.relationship = res['details']['nature'];
      this.relationship.forEach((res: any) => {
        res.value = res.value
          .toLowerCase()
          .replace('<target>', this.dynamicName);
      });
    });
  }

  getAllNatureNew() {
    this.auth.getNature().subscribe((res) => {
      this.newRelationship = res['details']['nature'];
    });
  }

  getAllStrength() {
    this.auth.getStrength().subscribe((res) => {
      this.strength = res['details']['strength'];
    });
  }
  getFilterStrength() {
    this.auth.getStrength().subscribe((res) => {
      this.filterStrength = res['details']['strength'];
      // this.filterStrength.unshift({ _id: 'None', value: 'None' });
    });
  }
  accountDropdown() {
    this.auth.accountDropdown().subscribe((res) => {
      this.accountName = res['details']['accounts'];
    });
  }

  filteraccountDropdown() {
    this.auth.accountDropdown().subscribe((res) => {
      this.filterAccountName = res['details']['accounts'];
      this.filterAccountName = this.filterAccountName.sort((a, b) => {
        const nameA = a?.accountName.toUpperCase().trim();
        // ignore upper and lowercase
        const nameB = b?.accountName.toUpperCase().trim();
        // ignore upper and lowercase

        if (nameA < nameB) {
          return -1; //nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0; // names must be equal
      });
      // this.filterAccountName.unshift({ _id: 'None', accountName: 'None' });
    });
  }

  getConnections() {
    this.auth
      .getConnections(
        this.count,
        this.p,
        this.skip,
        this.days,
        this.account,
        this.searchKey,
        this.strengthFilterId,
        this.rpId
      )
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe(
        (res) => {
          if (res['details']['profiles']['data'].length == 0) {
            this.connectionData = [];
            this.totalPageArr = [];
            this.totalItems = 0;
            return;
          }
          this.totalItems = res['details']['profiles']['metadata'][0].total;
          this.connectionData = res['details']['profiles']['data'];
          this.connectionData.forEach((element) => {
            if (
              element.photo == '' ||
              element.photo == null ||
              element.photo == undefined
            ) {
              element['photo'] = '';
            } else {
              element['photo'] = this.imageBaseUrl + element.photo;
            }
            if (
              element.company == '' ||
              element.company == null ||
              element.company == undefined
            ) {
              element['accountName'] = 'N/A';
            } else {
              element['accountName'] = element.company.accountName;
            }
          });

          this.initialArr = this.connectionData;
          this.totalPage = this.bkUptotalPage =
            res['details']['profiles']['metadata'][0].total;
        },
        (err) => {
          this.sharedService.showToastMessage(
            'ERROR',
            'Opps.. Something Went Wrong'
          );
        },
        () => {
          this.loader = false;
        }
      );
  }
  pageChange(page) {
    this.loader = true;
    this.skip = (page - 1) * this.count;

    this.p = page;
    this.getConnections();
  }

  dayFilter(day) {
    this.loader = true;
    this.p = 1;
    this.skip = 0;
    this.days = day;
    this.getConnections();
  }

  accountFilter(id) {
    this.loader = true;
    if (id == 'NONE') {
      this.account = '';
    } else {
      this.account = id;
    }
    this.p = 1;
    this.skip = 0;
    this.getConnections();
  }

  strengthFilter(id) {
    this.loader = true;
    if (id == 'NONE') {
      this.strengthFilterId = '';
    } else {
      this.strengthFilterId = id;
    }
    this.p = 1;
    this.skip = 0;
    this.getConnections();
  }

  rpFilter(id) {
    this.loader = true;
    if (id == 'NONE') {
      this.rpId = '';
    } else {
      this.rpId = id;
    }
    this.p = 1;
    this.skip = 0;
    this.getConnections();
  }

  search() {
    this.p = 1;
    this.skip = 0;
    this.searchKey = $('#search').val();
    this.getConnections();
  }

  addTargetReset() {
    this.connectionForm.reset();
    this.connectionDetails.linkedInUrl = 'https://www.linkedin.com/in/';
  }

  saveChange() {
    this.loader = true;

    let formData = new FormData();

    formData.append('linkedInUrl', this.connectionDetails.linkedInUrl);
    formData.append('title', this.connectionDetails.jobTitle);
    formData.append('strengthOfConnection', this.connectionDetails.strengthId);
    formData.append('natureOfRelationship', this.connectionDetails.natureId);
    if (this.imageFile != undefined && this.imageFile != null) {
      formData.append('userImage', this.imageFile);
    }

    this.auth.editConnection(this.updateId, formData).subscribe(
      (res) => {
        this.sharedService.showToastMessage(
          'SUCCESS',
          'Connection Updated Successfully!',
          3000
        );
        $('#exampleModal').modal('hide');
        this.getConnections();
      },
      (err) => {
        this.sharedService.showToastMessage(
          'ERROR',
          'Opps... Something went wrong...',
          3000
        );
        console.error('error', err);
      }
    );
  }

  getDataCount(value) {
    this.loader = true;

    this.filters.count = value;
    this.p = 1;
    this.skip = 0;
    this.count = value;
    this.getConnections();
  }

  myFunction() {
    this.dynamicName = this.connectionForm.value.first_name;
    this.getAllNature();
  }

  checkVal(opt, e, accVal) {
    this.loader = true;

    const searchBar: any = document.getElementById('search');
    if (searchBar.value === '') {
      this.filters.searchPattern = '';
    }

    if (opt === 'RP') {
      if (!accVal) {
        this.rpFilText = (e.firstName ?? '') + ' ' + (e.lastName ?? '');
      } else {
        this.rpFilText = accVal;
      }
      return;
    }

    if (opt === 'ACCOUNT') {
      this.accFilText =
        accVal && accVal.length > 19 ? accVal.slice(0, 16) + '...' : accVal;
      return;
    }

    if (opt === 'DATE') {
      this.dateFiltext = accVal;
      return;
    }

    if (opt === 'STRENGTH') {
      this.strengthFilText = accVal;
      return;
    }

    if (opt === 'NATURE') {
      this.natureRelationFilText =
        accVal && accVal.length > 19 ? accVal.slice(0, 16) + '...' : accVal;
      return;
    }

    if (opt === '10PERPAGE') {
      this.countPerPageText = accVal;
      this.count = e;
    }
    if (opt === '25PERPAGE') {
      this.countPerPageText = accVal;
      this.count = e;
    }
    if (opt === '50PERPAGE') {
      this.countPerPageText = accVal;
      this.count = e;
    }
    this.getConnections();
  }
}
