<main class="background-color">
  <section class="edit-profile padding-40 pb-0">
    <form [formGroup]="addNewProduct" (ngSubmit)="addProduct()">
      <div class="container-fluid">
        <div class="profile-sec">
          <div class="col-12" *ngIf="!productID">
            <div class="profile-p">Add New Product</div>
          </div>
          <div class="col-12" *ngIf="productID">
            <div class="profile-p">Edit and View Product</div>
          </div>
        </div>
        <div class="profile-details">
          <div class="col-12 top">
            <div class="row">
              <div class="col-lg-7 col-md-6">
                <div class="profile-box">
                  <div class="form-sec mb-0">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="isvID">ISV</label>
                          <div class="select-fa">
                            <select
                              class="form-control"
                              id="isvID"
                              formControlName="isvID"
                              id="isvID"
                              (change)="onChangeISV()"
                            >
                              <option selected value="" disabled hidden>
                                Select ISV
                              </option>
                              <option
                                *ngFor="let i of isvArr"
                                value="{{ i._id }}"
                              >
                                {{ i.companyName }}
                              </option>
                            </select>
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="form-group input-btn">
                          <label for="exampleInputEmail1"></label>
                          <input
                            value="start product onboarding"
                            disabled
                            type="button"
                            [ngClass]="{
                              'inactive-class': !addNewProduct.value.isvID
                            }"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            (click)="saveSellerID()"
                            [disabled]="!addNewProduct.value.isvID"
                          />
                        </div>

                        <!-- <button
                            class="btn btn-warning custom-btn"
                          >
                            Start Product Onboarding
                          </button> -->
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-0">
                          <p class="head-box-p mt-20">GSA Internal Inputs</p>
                          <p class="formp">
                            Typical Size of deals (on average) in K$
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="firstDealSize">First Deal</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="firstDealSize"
                            name="firstDealSize"
                            id="firstDealSize"
                            [class.is-invalid]="
                              f.firstDealSize.invalid && f.firstDealSize.touched
                            "
                            aria-describedby="emailHelp"
                            placeholder=""
                          />
                          <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="repeatDealSize">Repeat Deal</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="repeatDealSize"
                            name="repeatDealSize"
                            id="repeatDealSize"
                            [class.is-invalid]="
                              f.repeatDealSize.invalid &&
                              f.repeatDealSize.touched
                            "
                          />
                          <div class="col-6">
                            <div
                              *ngIf="
                                addNewProduct.get('firstDealSize').invalid &&
                                addNewProduct.get('firstDealSize').touched
                              "
                            >
                              <small
                                class="error text-danger"
                                *ngIf="
                                  addNewProduct.get('firstDealSize').errors
                                    ?.required
                                "
                              >
                                * First Deal is required
                              </small>
                            </div>
                          </div>
                          <div class="col-6">
                            <div
                              *ngIf="
                                addNewProduct.get('repeatDealSize').invalid &&
                                addNewProduct.get('repeatDealSize').touched
                              "
                            >
                              <small
                                class="error text-danger"
                                *ngIf="
                                  addNewProduct.get('repeatDealSize').errors
                                    ?.required
                                "
                              >
                                * Repeat Deal is required
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-0">
                          <label for="firstDealSalesCycle" class="formp mb-0">
                            First deal sales cycle duration & lapse time before
                            first repeat (in months)
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="firstDealSalesCycle"
                            >Typical overall First deal sales cycle
                            duration</label
                          >
                          <input
                            type="number"
                            class="form-control"
                            formControlName="firstDealDuration"
                            name="firstDealDuration"
                            id="firstDealSalesCycle"
                            [class.is-invalid]="
                              f.firstDealDuration.invalid &&
                              f.firstDealDuration.touched
                            "
                          />
                          <div
                            *ngIf="
                              addNewProduct.get('firstDealDuration').invalid &&
                              addNewProduct.get('firstDealDuration').touched
                            "
                          >
                            <small
                              class="error text-danger"
                              *ngIf="
                                addNewProduct.get('firstDealDuration').errors
                                  ?.required
                              "
                            >
                              * Required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="durationBeforeFirstCycle"
                            >Typical duration before first repeat deal</label
                          >
                          <input
                            type="number"
                            class="form-control"
                            formControlName="durationBeforeFirstRepeatDeal"
                            class="form-control"
                            id="durationBeforeFirstCycle"
                            [class.is-invalid]="
                              f.durationBeforeFirstRepeatDeal.invalid &&
                              f.durationBeforeFirstRepeatDeal.touched
                            "
                            aria-describedby="durationBeforeFirstRepeatDeal"
                          />
                          <div
                            *ngIf="
                              addNewProduct.get('durationBeforeFirstRepeatDeal')
                                .invalid &&
                              addNewProduct.get('durationBeforeFirstRepeatDeal')
                                .touched
                            "
                          >
                            <small
                              class="error text-danger"
                              *ngIf="
                                addNewProduct.get(
                                  'durationBeforeFirstRepeatDeal'
                                ).errors?.required
                              "
                            >
                              * Required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="specificCharacter1" class="formp mb-0">
                            Specific characterstics of targeted companies that
                            can help narrow down on them
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="targetCompanyCharacteristic"
                            name="targetCompanyCharacteristic"
                            id="specificCharacter1"
                            [class.is-invalid]="
                              f.targetCompanyCharacteristic.invalid &&
                              f.targetCompanyCharacteristic.touched
                            "
                          />
                          <div
                            *ngIf="
                              addNewProduct.get('targetCompanyCharacteristic')
                                .invalid &&
                              addNewProduct.get('targetCompanyCharacteristic')
                                .touched
                            "
                          >
                            <small
                              class="error text-danger"
                              *ngIf="
                                addNewProduct.get('targetCompanyCharacteristic')
                                  .errors?.required
                              "
                            >
                              * Required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label
                            for="targetPeopleCharacteristic"
                            class="formp mb-0"
                          >
                            Specific characterstics of targeted people that can
                            help narrow down on them (This can be be a previous
                            role,a specific education background, the name of a
                            tool, etc.)
                          </label>
                          <input
                            type="text"
                            formControlName="targetPeopleCharacteristic"
                            class="form-control"
                            id="targetPeopleCharacteristic"
                            [class.is-invalid]="
                              f.targetPeopleCharacteristic.invalid &&
                              f.targetPeopleCharacteristic.touched
                            "
                          />
                          <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="profile-box">
                  <div class="form-sec mb-0">
                    <div class="row">
                      <div class="col-sm-12">
                        <p class="head-box-p mt-20">Add Product Documents</p>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group select-fa">
                          <label for="documentType">Document Type</label>
                          <select
                            class="form-control"
                            formControlName="documentType"
                            id="documentType"
                          >
                            <option selected value="" disabled hidden>
                              Select Document Type
                            </option>
                            <option
                              *ngFor="let i of documentTypeArr"
                              value="{{ i._id }}"
                            >
                              {{ i.value }}
                            </option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group select-fa">
                          <label for="documentStatus">Status</label>
                          <select
                            class="form-control"
                            formControlName="documentStatus"
                            id="documentStatus"
                          >
                            <option value selected disabled hidden>
                              Select Document Status
                            </option>
                            <option
                              *ngFor="let i of documentStatusArr"
                              value="{{ i._id }}"
                            >
                              {{ i.value }}
                            </option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div class="col-12">
                        <div>
                          <div>
                            <div class="form-group">
                              <label for="uploadFile">Upload File</label>
                              <input
                                type="file"
                                id="documentName"
                                class="form-control mb-3"
                                (change)="addProductDoc($event)"
                                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="description">Description</label>
                          <textarea
                            type="text "
                            formControlName="description"
                            name="description"
                            class="form-control"
                            id="description"
                          ></textarea>
                          <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="exampleInputEmail1"
                            >Demo(Screenshot, live, recorded video) for 1 or 2
                            use cases.</label
                          >
                          <div class="theme-btn mb-3">
                            <button
                              class="btn active d-block"
                              type="button"
                              (click)="addDoc()"
                              [disabled]="
                                !addNewProduct.value.documentType ||
                                !addNewProduct.value.description ||
                                !addNewProduct.value.documentStatus ||
                                !addNewProduct.value.documentName
                              "
                            >
                              <i class="fa fa-upload mr-1"></i>Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="profile-box add-pa p-0">
                  <p class="head-box-p">Attached Documents</p>

                  <div class="table-board p-0">
                    <div class="table-order ad-is-tb">
                      <table>
                        <tbody>
                          <tr>
                            <th>Document Type</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Document</th>
                          </tr>
                          <tr *ngFor="let i of holdDocArr;let ind=index">
                            <td>{{ getDocumentTypeValue(i.documentType) }}</td>
                            <td>{{ i.description }}</td>
                            <td>{{ getStatusValue(i.documentStatus) }}</td>
                            <td class="blue">
                              <a
                                class="btn view-btn"
                                type="button"
                                href="{{docUrl[ind]}}"
                                target="_blank"
                              >
                                <i
                                  class="fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                                View
                            </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-5 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div class="select-fa">
                                <label for="statusProduct">Status</label>
                                <select
                                  class="form-control"
                                  id="statusProduct"
                                  formControlName="status"
                                >
                                  <!-- <option>Active</option> -->
                                  <option
                                    *ngFor="let i of filteredStatusArr"
                                    value="{{ i._id }}"
                                  >
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="productID" class="col-sm-12">
                            <div class="form-group">
                              <label for="createdBy">Created By</label>
                              <input
                                type="text"
                                formControlName="createdBy"
                                readonly
                                class="form-control"
                                id="createdBy"
                              />
                              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                            </div>
                          </div>
                          <div *ngIf="productID" class="col-sm-12">
                            <div class="form-group">
                              <label for="createdDate">Created Date</label>
                              <input
                                type="text"
                                formControlName="createdDate"
                                readonly
                                class="form-control"
                                id="createdDate"
                              />
                              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <div class="row">
                          <div class="col-sm-12">
                            <p class="head-box-p mt-20">GSA Results</p>
                          </div>

                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="backgroundDetails"
                                >Background details to illustrate the problem
                                statement</label
                              >
                              <textarea
                                type="text"
                                formControlName="details"
                                nbInput
                                fullWidth
                                class="form-control"
                                id="backgroundDetails"
                                [class.is-invalid]="
                                  f.details.invalid && f.details.touched
                                "
                              ></textarea>
                              <div
                                *ngIf="
                                  addNewProduct.get('details').invalid &&
                                  addNewProduct.get('details').touched
                                "
                              >
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewProduct.get('details').errors
                                      ?.required
                                  "
                                >
                                  * Background Details are required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="valueProposition"
                                >Value Proposition</label
                              >
                              <textarea
                                type="text"
                                formControlName="valueProposition"
                                nbInput
                                fullWidth
                                formControlName="valueProposition"
                                class="form-control"
                                id="valueProposition"
                                [class.is-invalid]="
                                  f.valueProposition.invalid &&
                                  f.valueProposition.touched
                                "
                              ></textarea>
                              <div
                                *ngIf="
                                  addNewProduct.get('valueProposition')
                                    .invalid &&
                                  addNewProduct.get('valueProposition').touched
                                "
                              >
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewProduct.get('valueProposition').errors
                                      ?.required
                                  "
                                >
                                  * Value Proposition is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Benefits</label>
                              <textarea
                                type="password"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder=""
                              ></textarea>
                              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="roiPayback">ROI/ Payback</label>
                              <textarea
                                type="text"
                                formControlName="roiPayback"
                                name="roiPayback"
                                class="form-control"
                                id="roiPayback"
                                [class.is-invalid]="
                                  f.roiPayback.invalid && f.roiPayback.touched
                                "
                              ></textarea>
                              <div
                                *ngIf="
                                  addNewProduct.get('roiPayback').invalid &&
                                  addNewProduct.get('roiPayback').touched
                                "
                              >
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewProduct.get('roiPayback').errors
                                      ?.required
                                  "
                                >
                                  * ROI/ Payback is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="differentiators"
                                >Differentiators</label
                              >
                              <textarea
                                type="text"
                                formControlName="differentiators"
                                nbInput
                                fullWidth
                                formControlName="differentiators"
                                class="form-control"
                                id="differentiators"
                                [class.is-invalid]="
                                  f.differentiators.invalid &&
                                  f.differentiators.touched
                                "
                              ></textarea>
                              <div
                                *ngIf="
                                  addNewProduct.get('differentiators')
                                    .invalid &&
                                  addNewProduct.get('differentiators').touched
                                "
                              >
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewProduct.get('differentiators').errors
                                      ?.required
                                  "
                                >
                                  * Differentiators are required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="useCases">Use Cases</label>
                              <textarea
                                type="text"
                                formControlName="usecase"
                                class="form-control"
                                id="useCases"
                                [class.is-invalid]="
                                  f.usecase.invalid && f.usecase.touched
                                "
                              ></textarea>
                              <div
                                *ngIf="
                                  addNewProduct.get('usecase').invalid &&
                                  addNewProduct.get('usecase').touched
                                "
                              >
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewProduct.get('usecase').errors
                                      ?.required
                                  "
                                >
                                  * Use cases are required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="customReferences"
                                >Customer references</label
                              >
                              <textarea
                                type="text"
                                formControlName="reference"
                                name="reference"
                                class="form-control"
                                id="customReferences"
                                [class.is-invalid]="
                                  f.reference.invalid && f.reference.touched
                                "
                              ></textarea>
                              <div
                                *ngIf="
                                  addNewProduct.get('reference').invalid &&
                                  addNewProduct.get('reference').touched
                                "
                              >
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewProduct.get('reference').errors
                                      ?.required
                                  "
                                >
                                  * References are required
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="theme-btn width-fit mt-40 footer-btn blue text-center">
        <button class="btn mr-1 regu yellow" [disabled]="!addNewProduct.valid">
          SAVE
        </button>
        <button class="btn regu" type="button" (click)="resetForm()">
          CANCEL
        </button>
      </div>
    </form>
  </section>
</main>
