import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-all-deals',
  templateUrl: './all-deals.component.html',
  styleUrls: ['./all-deals.component.scss'],
})
export class AllDealsComponent implements OnInit {
  domainArr: any = [];
  geographyArr: any = [];
  isvArr: any = [];
  rpArr: any = [];
  accArr: any = [];
  industryArr: any = [];
  companySizeArr: any = [];
  stageArr: any = [];
  quartersArr: any = [];

  domainValue: any[] = [];
  geographyValue: any[] = [];
  isvValue: any[] = [];
  rpValue: any[] = [];
  accValue: any[] = [];
  industryValue: any[] = [];
  companySizeValue: any[] = [];
  stageValue: any[] = [];
  quarterValue: any[] = [];

  filterTerm: FormControl = new FormControl();

  p: any = 1;
  countPerPageText: string = '10 Per Page';
  periodFilText: string = 'Period';
  isvFilText: string = 'ISV';
  rpFilText: string = 'High Level Connector';
  accountFilText: string = 'Account';
  geoFilText: string = 'Geo';
  industryFilText: string = 'Industry';
  domainFilText: string = 'Doamin';
  companySizeFilText: string = 'Company Size';

  resArr: any[] = [];
  totalPage: number;

  filters = {
    filterTerm: '',
    filterCompanySize: '',
    filterCountry: '',
    filterIndustry: '',
    filterStage: '',
    filterRpId: '',
    filterIsvId: '',
    filterPeriod: '',
    days: '',
    filterAccount: '',
    limit: '10',
    page: '0',
  };

  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
  }
  ngOnInit(): void {
    this.getAllAccountDropdown();
    this.getComSize();
    this.getIndustry();
    this.getDomain();
    this.refPartner();
    this.getISVDropDown();
    this.getGeography();
    this.getStages();
    this.quarterDropdown();
    this.fetchDeals();

    this.filterTerm.valueChanges
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe(
        (term) => {
          this.filters.limit = this.filters.limit ? this.filters.limit : '6';
          this.filters.page = '0';
          this.p = 1;

          if (term) {
            this.filters.filterTerm = term;

            this.fetchDeals();
          } else if (term === '') {
            this.filters.filterTerm = '';

            this.fetchDeals();
          }
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;
          this.common.showToastMessage(
            `ERROR`,
            `Oppss... Something Went Wrong`
          );
        }
      );
  }

  fetchDeals() {
    this.getData.getAllDeals(this.filters).subscribe(
      (res) => {
        if (res?.details?.deals?.metadata[0].total === 0) {
          return this.common.showToastMessage(`ERROR`, `No Records Found `);
        }
        this.resArr = res?.details?.deals?.data;
        this.totalPage = res?.details?.deals?.metadata[0].total
          ? res?.details?.deals?.metadata[0].total
          : 0;
      },
      (err) => {
        this.resArr = [];
        this.totalPage = 0;
        this.common.showToastMessage(`ERROR`, 'No Records Found', 10000);
      }
    );
  }

  checkVal(option: string, val: any, accVal?: any) {
    if (this.filterTerm.value === '') {
      this.filters.filterTerm = '';
    }

    if (option === '10PERPAGE') {
      this.filters.limit = val;
      this.countPerPageText = accVal;
    }
    if (option === '25PERPAGE') {
      this.filters.limit = val;
      this.countPerPageText = accVal;
    }
    if (option === '50PERPAGE') {
      this.filters.limit = val;
      this.countPerPageText = accVal;
    }

    if (option === 'PERIOD') {
      const periodValue = val?.itemValue?.value.toString().replace(' ', '_');

      let periodArr = this.filters?.filterPeriod
        ? this.filters?.filterPeriod.split(',')
        : [];

      if (periodArr.includes(periodValue)) {
        periodArr = periodArr.filter((el) => el !== periodValue);
        this.filters.filterPeriod = periodArr.join(',');
      } else {
        this.filters.filterPeriod = this.filters.filterPeriod
          ? `${this.filters.filterPeriod},${periodValue}`
          : periodValue;
      }
    }

    if (option === 'ISV') {
      const selectedIsvValue = val.itemValue._id.toString();

      let isvArr = this.filters?.filterIsvId
        ? this.filters?.filterIsvId.split(',')
        : [];

      if (isvArr.includes(selectedIsvValue)) {
        isvArr = isvArr.filter((el) => el !== selectedIsvValue);
        this.filters.filterIsvId = isvArr.join(',');
      } else {
        this.filters.filterIsvId = this.filters.filterIsvId
          ? `${this.filters.filterIsvId},${selectedIsvValue}`
          : selectedIsvValue;
      }
    }

    if (option === 'RP') {
      let tempRP = '';

      this.rpValue.forEach((a, b) => {
        tempRP = (tempRP ? `${tempRP},` : '') + a._id;
      });

      this.filters.filterRpId = tempRP;
    }

    if (option === 'ACCOUNT') {
      let tempAccount = '';

      console.log(this.accValue);

      this.accValue.forEach((a, b) => {
        tempAccount = (tempAccount ? `${tempAccount},` : '') + a._id;
      });

      this.filters.filterAccount = tempAccount;
    }

    if (option === 'INDUSTRY') {
      let tempIndustry = '';

      this.industryValue.forEach((a, b) => {
        tempIndustry = (tempIndustry ? `${tempIndustry},` : '') + a._id;
      });

      this.filters.filterIndustry = tempIndustry;
    }

    if (option === 'COMPANYSIZE') {
      let tempcomSize = '';

      this.companySizeValue.forEach((a, b) => {
        tempcomSize = (tempcomSize ? `${tempcomSize},` : '') + a._id;
      });

      this.filters.filterIndustry = tempcomSize;
    }

    if (option === 'GEO') {
      let tempGeo = '';
      this.geographyValue.forEach((a, b) => {
        tempGeo = (tempGeo ? `${tempGeo},` : '') + a._id;
      });

      this.filters.filterCountry = tempGeo;
    }

    this.p = 1;
    this.filters.limit = this.filters.limit ? this.filters.limit : '6';
    this.filters.page = '0';

    this.fetchDeals();
  }

  clearItem(option, item) {
    if (option === 'PERIOD') {
      let tempPeriod = '';
      this.quarterValue = this.quarterValue.filter((el) => el !== item);
      this.quarterValue.forEach((a, b) => {
        tempPeriod =
          (tempPeriod ? `${tempPeriod},` : '') +
          a.value.toString().replace(' ', '_');
      });

      this.filters.filterPeriod = tempPeriod;
    }

    if (option === 'ISV') {
      let tempIsv = '';
      this.isvValue = this.isvValue.filter((el) => el !== item);

      this.isvValue.forEach((a, b) => {
        tempIsv = (tempIsv ? `${tempIsv},` : '') + a._id;
      });

      this.filters.filterIsvId = tempIsv;
    }

    if (option === 'RP') {
      let tempRp = '';
      this.rpValue = this.rpValue.filter((el) => el !== item);

      this.rpValue.forEach((a, b) => {
        tempRp = (tempRp ? `${tempRp},` : '') + a._id;
      });

      this.filters.filterRpId = tempRp;
    }

    if (option === 'ACCOUNT') {
      let tempAcc = '';
      this.accValue = this.accValue.filter((el) => el !== item);

      this.accValue.forEach((a, b) => {
        tempAcc = (tempAcc ? `${tempAcc},` : '') + a._id;
      });

      this.filters.filterAccount = tempAcc;
    }

    if (option === 'INDUSTRY') {
      let tempIndustry = '';
      this.industryValue = this.industryValue.filter((el) => el !== item);
      this.industryValue.forEach((a, b) => {
        tempIndustry = (tempIndustry ? `${tempIndustry},` : '') + a._id;
      });
      this.filters.filterIndustry = tempIndustry;
    }

    if (option === 'COMPANYSIZE') {
      let tempcomSize = '';
      this.companySizeValue = this.companySizeValue.filter((el) => el !== item);
      this.companySizeValue.forEach((a, b) => {
        tempcomSize = (tempcomSize ? `${tempcomSize},` : '') + a._id;
      });
      this.filters.filterCompanySize = tempcomSize;
    }

    if (option === 'GEO') {
      let tempGeo = '';
      this.geographyValue = this.geographyValue.filter((el) => el !== item);
      this.geographyValue.forEach((a, b) => {
        tempGeo = (tempGeo ? `${tempGeo},` : '') + a._id;
      });
      this.filters.filterCountry = tempGeo;
    }

    // console.log(this.filters);

    this.p = 1;
    this.filters.limit = this.filters.limit ? this.filters.limit : '6';
    this.filters.page = '0';

    this.fetchDeals();
  }

  pageChange(page) {
    this.p = page;
    this.filters.limit = this.filters.limit ? this.filters.limit : '6';
    const tempPageVar = page;
    this.filters.page = `${tempPageVar}`;

    if (this.filterTerm.value === '') {
      this.filters.filterTerm = '';
    }

    this.fetchDeals();
  }

  /**
   * DropDown Start
   */

  async getGeography() {
    const res = await this.dropdown.getGeography().toPromise();
    this.geographyArr = res?.details?.geography;
  }

  async getISVDropDown() {
    const res = await this.dropdown.getISVsDropDown().toPromise();
    this.isvArr = res?.details?.isv;
    this.isvArr = this.isvArr.sort((a, b) => {
      const nameA = a?.companyName.toUpperCase();
      const nameB = b?.companyName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  async refPartner() {
    const res = await this.dropdown.getRpsList().toPromise();
    this.rpArr = res?.details?.data;

    this.rpArr = this.rpArr.filter((a) => a?.firstName || a?.lastName);

    this.rpArr = this.rpArr.map((a) => {
      const fullName = `${a?.firstName} ${a?.lastName}`;
      Object.assign(a, { fullName: fullName });
      return a;
    });

    this.rpArr = this.rpArr.sort((a, b) => {
      const nameA = a?.firstName.toUpperCase().trim();
      const nameB = b?.firstName.toUpperCase().trim();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  async getAllAccountDropdown() {
    const res = await this.dropdown.getAllAccountDropdown().toPromise();
    this.accArr = res?.details?.accounts;
    // console.log(this.accArr);
  }

  async getDomain() {
    const res = await this.dropdown.getDomains().toPromise();
    this.domainArr = res?.details?.domain;
  }

  async getIndustry() {
    const res = await this.dropdown.getIndustry().toPromise();
    this.industryArr = res?.details?.industry;
  }

  async getComSize() {
    const res = await this.dropdown.getCompanySize().toPromise();
    this.companySizeArr = res?.details?.companySize;
  }

  async getStages() {
    const res = await this.dropdown.stageDropdown().toPromise();
    this.stageArr = res?.details?.stages;
  }

  async quarterDropdown() {
    const res = await this.dropdown.qtrDropdown().toPromise();
    this.quartersArr = res?.details?.quarters;
    const curDate = new Date();

    this.quartersArr = this.quartersArr.map((el) => {
      el.value = `${el.value} ${curDate.getFullYear()}`;
      return el;
    });

    let tempArrForNextYear = [];

    for (let el of this.quartersArr) {
      const newEl = {
        _id: el._id,
        value: el.value.split(' ')[0] + ' ' + (curDate.getFullYear() + 1),
      };
      tempArrForNextYear.push(newEl);
    }

    this.quartersArr.push({ _id: '', value: `FY ` + curDate.getFullYear() });
    // this.quartersArr.push({ _id: 'EXIT', value: '' });

    for (let i of tempArrForNextYear) {
      this.quartersArr.push(i);
    }

    this.quartersArr.unshift({ value: `YtD ` + curDate.getFullYear() });
    this.quartersArr.push({ value: `FY ` + (curDate.getFullYear() + 1) });

    // console.log(this.quartersArr);
  }
}
