import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Dropdown } from 'src/app/interface/dropdown';
import { AddDataService } from 'src/app/services/add-data.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/utils/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss'],
})
export class AddAccountComponent implements OnInit {
  staticDataUrl: string = environment.apiUrl;

  addNewAccount: FormGroup;
  accountStatusArr: any;
  industryArr: any;
  companySizeArr: any;
  monthsArr: any;
  charityArr: any;
  countryArr: [];
  filteredStatusArr: Dropdown[];

  msgIndustryOneToTwo: string = null;
  msgIndustry: string = null;
  userImageFile: any;
  companyImageFile: any;
  emailError: string;
  universalError: any;

  charityValue: string;
  disableSelectCharity: boolean = true;
  disablePayCharity: boolean = true;
  afterCharitySelect: boolean = false;
  accountID: string;
  accountDetails: any;
  initialStatus: string;

  imgUserCon: boolean = false;
  imgCompCon: boolean = false;
  message: string;
  imagePath: any;
  imgUserURL: string | ArrayBuffer;
  imgCompURL: string | ArrayBuffer;

  endDateValue: any;
  emailDisable: boolean = false;
  errorUserLinkedin: boolean;
  errorCompanyLinkedin: boolean;
  validURlforLinkedin: any;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private dropdown: DropdownService,
    private getData: GetDataService,
    private addData: AddDataService,
    private actRoute: ActivatedRoute,
    private commonService: CommonService
  ) {
    this.commonService.showHeader.next(true);
    this.accountID = actRoute.snapshot.params[`id`];
  }

  ngOnInit(): void {
    this.getaccountStatus();
    this.getIndustry();
    this.getcompanySize();
    this.getMonths();
    this.getCharity();
    this.getCountries();

    // this.getInitialData();

    this.addNewAccount = this.fb.group({
      userImage: [''],
      companyImage: [''],
      region: [''],
      country: ['', [Validators.required]],
      website: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
          ),
        ],
      ],
      // Company
      linkedinUrl: [
        'https://www.linkedin.com/company/',
        [
          Validators.required,
          Validators.pattern(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
          ),
        ],
      ],
      industriesOne: ['', [Validators.required]],
      size: ['', [Validators.required]],
      founded: [''],
      about: [''],
      specialities: [''],
      status: [''],
      emailVerified: [false],
      createdBy: [''],
      createdDate: [''],
      start: [''],
      end: [''],
      firstName: [''],
      lastName: [''],
      email: [
        '',
        [
          Validators.email,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      jobTitle: [''],
      userLinkedin: [''], //for User userLinkedin
      mobile: ['', [Validators.pattern(/^[0-9]*$/)]],
      donationContactName: [''],
      globalProcurementHeadName: [''],
      donationContactEmail: [
        '',
        [
          Validators.email,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      globalProcurementHeadEmail: [
        '',
        [
          Validators.email,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      charity: [''],
      accountName: ['', [Validators.required]],
      isSystemSelectCharity: [],
      isSystemPayDirectly: [],
      companyStatus: ['', [Validators.required]],
    });

    this.addNewAccount.patchValue({
      companyStatus: 'active',
    });

    this.initialStatusFunc();
  }

  async initialStatusFunc() {
    const res = await this.dropdown.getAccountStatus().toPromise();
    const arr: any = res?.details?.accountStatus;

    // console.log("initialStatusFunc",arr)

    const ss = arr.filter((status: any) => {
      return status.value === 'New';
    });

    this.initialStatus = ss[0]._id;
    this.addNewAccount.get('status').setValue(ss[0]._id);
  }

  async getInitialData() {
    if (this.accountID) {
      const res = await this.getData
        .getAccountDetailsById(this.accountID)
        .toPromise();

      // console.log(res);
      this.accountDetails = res?.details;

      console.log('Account Details', this.accountDetails);
      if (this.accountDetails.userExist?.userId?.emailVerified) {
        this.emailDisable = true;
      }

      this.imgUserCon =
        this.accountDetails?.userExist?.userId?.userImage &&
        this.accountDetails?.userExist?.userId.hasOwnProperty('userImage');
      this.imgCompCon =
        this.accountDetails?.company?.logo &&
        this.accountDetails?.company?.hasOwnProperty('logo');

      // Fields to be disabled when editing
      this.addNewAccount.get('accountName').disable();
      this.addNewAccount.get('createdBy').disable();
      this.addNewAccount.get('createdDate').disable();
      this.addNewAccount.get('end').disable();

      if (
        this.accountDetails?.userExist?.userId?.firstName &&
        this.accountDetails?.userExist?.userId?.lastName &&
        this.accountDetails?.userExist?.userId?.email &&
        this.accountDetails?.userExist?.jobTitle
      ) {
        this.addNewAccount.get('firstName').disable();
        this.addNewAccount.get('lastName').disable();
        this.addNewAccount.get('email').disable();
        this.addNewAccount.get('jobTitle').disable();
      }

      await this.dropDownContent();

      this.setFormData(this.accountDetails);
    }
  }

  setFormData(accData) {
    const datePipe = new DatePipe('en-IN');

    const setDate = datePipe.transform(accData.company.createdAt, 'yyyy-MM-dd');

    if (accData.userExist.isSystemSelectCharity == true) {
      this.disableSelectCharity = true;
    } else {
      this.disableSelectCharity = false;
      this.afterCharitySelect = true;
    }

    if (accData?.userExist?.isSystemPayDirectly == true) {
      this.disablePayCharity = true;
      this.disablePayCharity = false;
    }

    if (accData.userExist.charity) {
      this.charityValue = this.getCharityName(accData.userExist.charity);
    }

    this.addNewAccount.patchValue({
      about: accData?.company?.about ? accData?.company?.about : '',

      accountName: accData?.company?.accountName
        ? accData?.company?.accountName
        : '',

      country: accData?.company?.country ? accData?.company?.country : '',

      founded: accData?.company?.founded ? accData?.company?.founded : '',

      industriesOne: accData?.company?.industry?._id
        ? accData?.company?.industry?._id
        : '',

      region: accData?.company?.region ? accData?.company?.region : '',

      companyStatus: accData?.company?.companyStatus
        ? accData?.company?.companyStatus
        : '',

      website: accData?.company?.website ? accData?.company?.website : '',

      linkedinUrl: accData?.company?.linkedinUrl
        ? accData?.company?.linkedinUrl
        : '',

      specialities: accData?.company?.specialities
        ? accData?.company?.specialities
        : '',

      size: accData?.company?.size?._id ? accData?.company?.size?._id : '',

      status: accData?.userExist?.status?._id
        ? accData?.userExist?.status?._id
        : this.initialStatus,

      start: accData?.userExist?.financialYear?.start
        ? accData?.userExist?.financialYear?.start
        : '',

      end: accData?.userExist?.financialYear?.end
        ? accData?.userExist?.financialYear?.end
        : '',

      firstName: accData?.userExist?.userId?.firstName
        ? accData?.userExist?.userId?.firstName
        : '',

      lastName: accData?.userExist?.userId?.lastName
        ? accData?.userExist?.userId?.lastName
        : '',

      email: accData?.userExist?.userId?.email
        ? accData?.userExist?.userId?.email
        : '',

      jobTitle: accData?.userExist?.jobTitle
        ? accData?.userExist?.jobTitle
        : '',

      userLinkedin: accData?.userExist?.userId?.userLinkedin
        ? accData?.userExist?.userId?.userLinkedin
        : '',

      mobile: accData?.userExist?.userId?.mobile
        ? accData?.userExist?.userId?.mobile
        : '',

      isSystemSelectCharity: accData?.userExist?.isSystemSelectCharity,
      charity: accData?.userExist?.charity ? accData?.userExist?.charity : '',
      isSystemPayDirectly: accData?.userExist?.isSystemPayDirectly,

      donationContactName: accData?.userExist?.donationContactPerson?.name
        ? accData?.userExist?.donationContactPerson?.name
        : '',

      donationContactEmail: accData?.userExist?.donationContactPerson?.email
        ? accData?.userExist?.donationContactPerson?.email
        : '',

      globalProcurementHeadName: accData?.userExist?.globalProcurementHead?.name
        ? accData?.userExist?.globalProcurementHead?.name
        : '',
      globalProcurementHeadEmail: accData?.userExist?.globalProcurementHead
        ?.email
        ? accData?.userExist?.globalProcurementHead?.email
        : '',
      createdBy: accData?.userExist?.createdBy?.firstName,
      createdDate: setDate,

      emailVerified: accData?.userExist?.userId?.emailVerified
        ? accData?.userExist?.userId?.emailVerified
        : false,
    });

    if (!accData.userExist.hasOwnProperty('isSystemSelectCharity')) {
      this.addNewAccount.patchValue({
        isSystemSelectCharity: true,
        isSystemPayDirectly: true,
      });
      this.disableSelectCharity = true;
      this.disablePayCharity = false;
    }
  }

  dropDownContent() {
    if (!this.accountID || this.accountID === '') {
      this.filteredStatusArr = this.accountStatusArr.filter((el) => {
        return el.value === 'New';
      });
    } else if (this.accountID && !this.accountDetails?.userExist?.status) {
      this.filteredStatusArr = this.accountStatusArr.filter(
        (el) => el.value === 'New'
      );
    } else if (
      this.accountID &&
      this.accountDetails?.userExist?.status?.value === 'New'
    ) {
      this.filteredStatusArr = this.accountStatusArr.filter(
        (el) =>
          el.value === 'New' ||
          el.value === 'Approved' ||
          el.value === 'Rejected'
      );
    } else if (
      this.accountID &&
      this.accountDetails?.userExist?.status?.value === 'Approved'
    ) {
      this.filteredStatusArr = this.accountStatusArr.filter(
        (el) =>
          el.value === 'Approved' ||
          el.value === 'Active' ||
          el.value === 'Inactive'
      );
    } else if (
      this.accountID &&
      this.accountDetails?.userExist?.status?.value === 'Active'
    ) {
      this.filteredStatusArr = this.accountStatusArr.filter(
        (el) =>
          el.value === 'Active' ||
          el.value === 'Inactive' ||
          el.value === 'Archived'
      );
    } else if (
      this.accountID &&
      this.accountDetails?.userExist?.status?.value === 'Inactive'
    ) {
      this.filteredStatusArr = this.accountStatusArr.filter(
        (el) =>
          el.value === 'Inactive' ||
          el.value === 'Active' ||
          el.value === 'Archived'
      );
    } else if (
      this.accountID &&
      this.accountDetails?.userExist?.status?.value === 'Archived'
    ) {
      this.addNewAccount.get('status').disable();
      this.filteredStatusArr = this.accountStatusArr.filter(
        (el: any) => el.value === 'Archived'
      );
    } else {
      this.filteredStatusArr = this.accountStatusArr;
    }
  }

  // e.preventDefault();
  detectChangeTrue(e) {
    if (!this.accountID && this.addNewAccount.value.emailVerified) {
      e.preventDefault();
      return;
    }

    if (this.emailDisable) {
      e.preventDefault();
      return;
    }

    if (
      this.addNewAccount.value.emailVerified === false ||
      this.accountDetails?.userId?.emailVerified === false
    ) {
      return this.alertConfirmation(e);
    }
  }

  alertConfirmation(e) {
    if (
      confirm(
        'Once checked, you cannot uncheck Email verified. Are you sure you want to go ahead ?'
      )
    ) {
      this.addNewAccount.value.emailVerified = true;
      this.addNewAccount.get('status').enable();

      Swal.fire({
        title: 'Email Verified Checked',
        text: "User's Email is Verified",
        icon: 'success',
      });

      this.emailDisable = true;
    } else {
      e.preventDefault();

      this.addNewAccount.value.emailVerified = false;
      this.addNewAccount.get('status').disable();

      Swal.fire({
        title: 'Email Verified Not Checked',
        text: "User's Email is not Verified",
        icon: 'warning',
      });
    }
  }

  checkLinkedin(option: string, e: any) {
    if (e.target.value == '') {
      this.errorUserLinkedin = false;
      this.errorCompanyLinkedin = false;
      return;
    }
    const linkedURLarr = e.target.value.toLowerCase().split('/');

    if (option === 'COMPANY_LINKEDIN') {
      this.validURlforLinkedin =
        (linkedURLarr.includes('www.linkedin.com') ||
          linkedURLarr.includes('linkedin.com')) &&
        linkedURLarr.includes('company');

      this.errorCompanyLinkedin = !this.validURlforLinkedin;

      if (!!this.addNewAccount.controls.companyLinkedin.errors) {
        return;
      } else {
        if (e.target.value.endsWith('/')) {
          return;
        } else {
          this.addNewAccount.patchValue({
            companyLinkedin: `${e.target.value}/`,
          });
        }
      }
    }

    if (option === 'USER_LINKEDIN') {
      this.validURlforLinkedin =
        (linkedURLarr.includes('www.linkedin.com') ||
          linkedURLarr.includes('linkedin.com')) &&
        linkedURLarr.includes('in');

      this.errorUserLinkedin = !this.validURlforLinkedin;
      if (!!this.addNewAccount.controls.userLinkedin.errors) {
        return;
      } else {
        if (e.target.value.endsWith('/')) {
          return;
        } else {
          this.addNewAccount.patchValue({
            userLinkedin: `${e.target.value}/`,
          });
        }
      }
    }
  }

  // Get Account Status for Dropdown

  async getaccountStatus() {
    const res = await this.dropdown.getAccountStatus().toPromise();
    this.accountStatusArr = res?.details?.accountStatus;

    await this.getInitialData();
    await this.dropDownContent();
  }

  // Get the list of countries for Dropdown
  async getCountries() {
    const res = await this.dropdown.getCountry().toPromise();
    this.countryArr = res?.details?.countries;
  }

  async getIndustry() {
    const res = await this.dropdown.getlinkedinIndustryDropdown().toPromise();
    this.industryArr = res?.details?.industry;
  }

  async getcompanySize() {
    const res = await this.dropdown
      .getlinkedinCompanySizeDropdown()
      .toPromise();
    this.companySizeArr = res?.details?.size;
  }

  // Get List Months for Dropdown
  async getMonths() {
    const res = await this.dropdown.getMonth().toPromise();
    this.monthsArr = res?.details?.months;
  }

  // Get List Charity for Dropdown
  async getCharity() {
    const res = await this.dropdown.getcharity().toPromise();
    this.charityArr = res?.details?.charity;
  }

  // Extract Industry IDs
  getIndustryId(industryItem) {
    if (industryItem) {
      const res = this.industryArr.find((el) => el.value === `${industryItem}`);
      return res._id;
    }
    return;
  }

  selectUserFile(e) {
    if (e.target.files.length > 0) {
      for (let i of e.target.files) {
        this.userImageFile = i;
      }
    }
  }

  previewUserImage(files) {
    if (files.length === 0) return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    var reader = new FileReader();
    this.imagePath = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgUserURL = reader.result;
    };
  }

  selectCompanyFile(e) {
    if (e.target.files.length > 0) {
      for (let i of e.target.files) {
        this.companyImageFile = i;
      }

      var mimeType = this.companyImageFile.type;
      if (mimeType.match(/image\/*/) == null) {
        this.message = 'Only images are supported.';
        return;
      }
      var reader = new FileReader();
      this.imagePath = this.companyImageFile;
      reader.readAsDataURL(this.companyImageFile);
      reader.onload = (_event) => {
        this.imgCompURL = reader.result;
      };
    }
  }

  checkFormValid(e) {
    if (
      this.addNewAccount.value.firstName ||
      this.addNewAccount.value.lastName ||
      this.addNewAccount.value.email ||
      this.addNewAccount.value.jobTitle
    ) {
      this.addNewAccount.controls.firstName.setValidators([
        Validators.required,
      ]);
      this.addNewAccount.controls.lastName.setValidators([Validators.required]);
      this.addNewAccount.controls.email.setValidators([
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]);
      this.addNewAccount.controls.jobTitle.setValidators([Validators.required]);
    } else {
      !this.addNewAccount.value.firstName &&
        this.addNewAccount.controls.firstName.clearValidators();
      !this.addNewAccount.value.lastName &&
        this.addNewAccount.controls.lastName.clearValidators();
      !this.addNewAccount.value.email &&
        this.addNewAccount.controls.email.clearValidators();
      !this.addNewAccount.value.jobTitle &&
        this.addNewAccount.controls.jobTitle.clearValidators();
    }
    this.addNewAccount.controls['firstName'].updateValueAndValidity();
    this.addNewAccount.controls['lastName'].updateValueAndValidity();
    this.addNewAccount.controls['email'].updateValueAndValidity();
    this.addNewAccount.controls['jobTitle'].updateValueAndValidity();
  }

  getCharityName(charityId: string) {
    const res = this.charityArr.find((data) => data._id === `${charityId}`);
    return res.value;
  }

  getCharityValue(value: any) {
    this.charityValue = value;
    this.afterCharitySelect = true;

    if (this.addNewAccount.value.isSystemPayDirectly)
      this.disablePayCharity = false;
  }

  resetCharitySelect() {
    this.afterCharitySelect = false;
  }

  hideCharityOption() {
    this.disableSelectCharity = true;
  }

  showCharityOption() {
    this.disableSelectCharity = false;
  }

  hidePayCharityOption() {
    this.disablePayCharity = false;
  }

  cancelBtn() {
    this.addNewAccount.reset();
    this.router.navigate(['/admin/all-account']);
  }

  showPayCharityOption() {
    console.log(this.addNewAccount.value.isSystemSelectCharity);
    this.disablePayCharity = true;
  }

  addAccount() {
    // console.log(this.addNewAccount.value);
    // if (this.addNewAccount.value.isSystemSelectCharity == false) {
    //   this.addNewAccount.get('charity').setValue('');
    //   this.addNewAccount.get('isSystemPayDirectly').setValue(false);
    //   this.addNewAccount.get('donationContactName').setValue('');
    //   this.addNewAccount.get('donationContactEmail').setValue('');
    //   this.addNewAccount.get('globalProcurementHeadName').setValue('');
    //   this.addNewAccount.get('globalProcurementHeadEmail').setValue('');
    // }

    // if (this.addNewAccount.value.isSystemPayDirectly == false) {
    //   this.addNewAccount.get('donationContactName').setValue('');
    //   this.addNewAccount.get('donationContactEmail').setValue('');
    //   this.addNewAccount.get('globalProcurementHeadName').setValue('');
    //   this.addNewAccount.get('globalProcurementHeadEmail').setValue('');
    // }

    /* Getting Formatting Data Start */
    const donationContact = {
      name: this.addNewAccount.value.donationContactName,
      email: this.addNewAccount.value.donationContactEmail,
    };
    const globalProcurement = {
      name: this.addNewAccount.value.globalProcurementHeadName,
      email: this.addNewAccount.value.globalProcurementHeadEmail,
    };
    const financialYear = {
      start: this.addNewAccount.value.start,
      end: this.addNewAccount.getRawValue().end,
    };

    let jobTitleVar =
      this.addNewAccount.value.userLinkedin ||
      this.addNewAccount.getRawValue().jobTitle ||
      this.accountDetails?.userExist?.jobTitle ||
      '';

    let firstNameVar =
      this.addNewAccount.value.firstName ||
      this.addNewAccount.getRawValue().firstName ||
      this.accountDetails?.userExist?.userId?.firstName ||
      '';

    let lastNameVar =
      this.addNewAccount.value.lastName ||
      this.addNewAccount.getRawValue().lastName ||
      this.accountDetails?.userExist?.userId?.lastName ||
      '';
    let workEmailVar =
      this.addNewAccount.value.email ||
      this.addNewAccount.getRawValue().email ||
      this.accountDetails?.userExist?.userId?.email ||
      '';

    let isSystemPayDirectlyVar =
      this.addNewAccount.value.isSystemSelectCharity === true
        ? true
        : this.addNewAccount.value.isSystemPayDirectly;

    let globalPVar =
      this.addNewAccount.value.isSystemSelectCharity ||
      this.addNewAccount.value.isSystemPayDirectly
        ? JSON.stringify({ email: '', name: '' })
        : JSON.stringify(globalProcurement);

    let donationContactPersonVar =
      this.addNewAccount.value.isSystemSelectCharity ||
      this.addNewAccount.value.isSystemPayDirectly
        ? JSON.stringify({ email: '', name: '' })
        : JSON.stringify(donationContact);
    /* Getting Formatting Data Start */

    const formData = new FormData();

    /* Form Data Append Start :DON'T TOUCH: */
    this.userImageFile && formData.append('userImage', this.userImageFile);

    this.companyImageFile &&
      formData.append('companyImage', this.companyImageFile);

    this.accountID &&
      financialYear.start &&
      formData.append('financialYear', JSON.stringify(financialYear));

    formData.append('website', this.addNewAccount.value.website);

    formData.append('linkedinUrl', this.addNewAccount.value.linkedinUrl);

    this.accountID &&
      this.addNewAccount.value.userLinkedin &&
      formData.append('userLinkedin', this.addNewAccount.value.userLinkedin);

    this.accountID &&
      formData.append(
        'isSystemSelectCharity',
        this.addNewAccount.value.isSystemSelectCharity
      );

    this.accountID &&
      formData.append('isSystemPayDirectly', isSystemPayDirectlyVar);
    this.accountID && formData.append('jobTitle', jobTitleVar);
    this.accountID && formData.append('firstName', firstNameVar);
    this.accountID && formData.append('lastName', lastNameVar);
    this.accountID && formData.append('email', workEmailVar);
    this.accountID &&
      this.addNewAccount.value.mobile &&
      formData.append('mobile', this.addNewAccount.value.mobile);

    this.accountID &&
      this.addNewAccount.value.isSystemSelectCharity === false &&
      this.addNewAccount.value.charity &&
      formData.append('charity', this.addNewAccount.value.charity);

    formData.append(
      'accountName',
      this.addNewAccount.getRawValue().accountName ||
        this.accountDetails.company.accountName
    );

    this.accountID &&
      formData.append(
        'emailVerified',
        this.addNewAccount.value.emailVerified || false
      );

    this.accountID &&
      donationContactPersonVar &&
      formData.append('donationContactPerson', donationContactPersonVar);

    this.accountID &&
      globalPVar &&
      formData.append('globalProcurementHead', globalPVar);

    this.accountID &&
      formData.append('status', this.addNewAccount.value.status);

    this.addNewAccount.value.about &&
      formData.append('about', this.addNewAccount.value.about);

    formData.append('industry', this.addNewAccount.value.industriesOne);

    this.addNewAccount.value.region &&
      formData.append('region', this.addNewAccount.value.region);

    formData.append('country', this.addNewAccount.value.country);

    formData.append('size', this.addNewAccount.value.size);

    this.addNewAccount.value.founded &&
      formData.append('founded', this.addNewAccount.value.founded);

    this.addNewAccount.value.specialities &&
      formData.append('specialities', this.addNewAccount.value.specialities);

    formData.append('companyStatus', this.addNewAccount.value.companyStatus);

    // Upadte Account OR Add Account
    if (this.accountID) {
      this.addData.updateAccountDetails(this.accountID, formData).subscribe(
        (data: any) => {
          this.commonService.showSuccess('Account updated successfully');
          this.addNewAccount.reset();
          this.imgCompURL = null;
          this.companyImageFile = null;
          this.router.navigate(['/admin/all-account']);
        },
        (err) => {
          if (err.error.message === 'Email already exist on platform') {
            this.emailError = 'Email already exist on platform';
            this.commonService.showSwError(err.error.message);
          } else {
            this.commonService.showSwError(err.error.message);
          }
          return;
        }
      );
    } else {
      this.addData.addAccountService(formData).subscribe(
        (data) => {
          this.commonService.showSuccess('Account added successfully');
          this.addNewAccount.reset();
          this.companyImageFile = null;
          this.imgCompURL = null;
          this.router.navigate(['/admin/all-account']);
        },
        (err) => {
          if (err.error.message === 'Email already exist on platform') {
            this.emailError = 'Email already exist on platform';
            this.commonService.showSwError(err.error.message);
          } else {
            this.commonService.showSwError(err.error.message);
          }
          return;
        }
      );
    }
  }

  resetForm() {
    this.addNewAccount.reset();
    this.disableSelectCharity = true;
    this.disablePayCharity = true;
    this.afterCharitySelect = false;
    this.companyImageFile = null;

    if (this.accountID) {
      this.accountID = '';
      this.router.navigate(['/admin/all-account']);
    }
  }

  setEndMonth(e) {
    if (e != '') {
      const findObjOFMonth = this.monthsArr.filter((el) => el._id === e);
      let tempIndex = this.monthsArr.indexOf(findObjOFMonth[0]);
      let tempObject: any;
      if (tempIndex == 0) {
        tempObject = this.monthsArr[11];
      } else {
        tempObject = this.monthsArr[tempIndex - 1];
      }

      this.endDateValue = tempObject ? tempObject._id : '';

      this.addNewAccount.get('end').setValue(tempObject ? tempObject._id : '');
    }
  }

  get f() {
    return this.addNewAccount.controls;
  }
}
