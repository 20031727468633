<div class="head-mr"></div>
<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <p
        class="tb-head capitalized"
        data-toggle="modal"
        data-target="#exampleModal1"
      >
        Sponsored High Level Connectors
      </p>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input (keyup)="search($event)" type="text" placeholder="Search" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div class="search-link">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Sponsored By<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div
                    style="max-height: 200px; overflow-y: scroll"
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="btn dropdown-toggle"
                      href="javascript:void(0)"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      (click)="filterBySponsor(item._id)"
                      class="dropdown-item"
                      *ngFor="let item of sponsoredByList"
                      href="javascript:void(0)"
                      >{{ item?.firstName + " " + item?.lastName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Sponsored Date<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="btn dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a class="dropdown-item" (click)="filterBySponsorDate(7)"
                      >Last 7 Days</a
                    >
                    <a class="dropdown-item" (click)="filterBySponsorDate(30)"
                      >Last 30 Days</a
                    >
                    <a class="dropdown-item" (click)="filterBySponsorDate(90)"
                      >Last 90 Days</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Status<i class="fa fa-angle-down ml-1"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      class="btn dropdown-toggle"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    </a
                    ><a
                      (click)="filterByStatus(item._id)"
                      *ngFor="let item of status"
                      class="dropdown-item"
                      >{{ item.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="table-order upper">
        <table>
          <tbody>
            <tr>
              <th>Sponsored Date</th>
              <th>Sponsored By</th>
              <th>Sponsored HLC Name</th>
              <th>LinkedIn Profile</th>
              <th>Work Email</th>
              <th>Status</th>
            </tr>
            <tr
              *ngFor="
                let sponsor of sponsors
                  | paginate
                    : {
                        itemsPerPage: pageSize,
                        currentPage: page,
                        totalItems: totalRecords
                      }
              "
            >
              <td>
                {{
                  sponsor.user?.createdAt
                    ? sharedService.formatDays(sponsor.user?.createdAt)
                    : ""
                }}
              </td>

              <td>
                {{ sponsor.rpData?.firstName + " " + sponsor.rpData?.lastName }}
              </td>
              <td>
                {{ sponsor.user?.firstName + " " + sponsor.user?.lastName }}
              </td>
              <td>
                <a
                  target="blank"
                  href="http://{{ sponsor.user?.userLinkedin }}"
                  >{{ sponsor.user?.userLinkedin }}</a
                >
              </td>
              <td>{{ sponsor.user?.email }}</td>
              <td>
                {{ sponsor.status?.value }}
                <!-- <form>
                                    <div class="form-sec tab-select min-96 mb-0">
                                        <div class="form-group mb-0">
                                            <div class="select-fa">
                                                <select class="form-control btn paid">
                                                    <option>ACCEPTED</option>
                                                    <option>PENDING</option>
                                                    <option>REJECTED</option>

                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </form> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-align pagi-page">
        <div class="paginationClass">
          <pagination-controls
            previousLabel="Prev"
            nextLabel="Next"
            responsive="true"
            (pageChange)="handlePageChange($event)"
          ></pagination-controls>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ pageSize }} per page<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                (click)="handlePageSizeChange(page)"
                *ngFor="let page of pageSizes"
                class="dropdown-item"
                type="button"
              >
                {{ page }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
