import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { IsvService } from 'src/app/services/isv.service';
import { StepModel } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { StepsService } from 'src/app/services/isv.stepsService';

@Component({
  selector: 'app-target-size-comp',
  templateUrl: './target-size-comp.component.html',
  styleUrls: ['./target-size-comp.component.scss'],
})
export class TargetSizeCompComponent implements OnInit {
  @Input() step: StepModel;
  targetSizeForm: FormGroup;
  showTargetSizeForm: any;
  businessSize: any;
  productId: any;
  productName: string;
  productDetails: any;
  constructor(
    private fb: FormBuilder,
    public isv: IsvService,
    private shared: CommonService,
    private stepsService: StepsService,
    private dropdown: DropdownService
  ) {
    this.productId = localStorage.getItem('productId');
    this.productName = localStorage.getItem('productName');
  }

  ngOnInit(): void {
    this.getProductDetails();
    this.getBusinessSize();
    this.targetSizeForm = this.fb.group({
      isTargetAllBusinessSize: ['', Validators.required],
      businessSize: [''],
    });
  }

  addtargetSizeDetails() {
    if (!this.targetSizeForm.valid) {
      return;
    } else {
      if (this.targetSizeForm.value.businessSize) {
        this.isv
          .addTargetSize(this.targetSizeForm.value, this.productId)
          .subscribe(
            (data) => {
              this.onCompleteStep();
              this.stepsService.onNextStep();
            },
            (err) => {
              console.log(err);
              this.shared.showToastMessage('ERROR', err.error.error, 5000);
            }
          );
      } else {
        this.isv
          .addTargetSize(
            {
              isTargetAllBusinessSize: this.targetSizeForm.value
                .isTargetAllBusinessSize,
            },
            this.productId
          )
          .subscribe(
            (data) => {
              this.onCompleteStep();
              this.stepsService.onNextStep();
            },
            (err) => {
              console.log(err);
              this.shared.showToastMessage('ERROR', err.error.error, 5000);
            }
          );
      }
    }
  }

  isTargetSizeMeasured(val) {
    if (val == 'true') {
      this.targetSizeForm.patchValue({ isTargetAllBusinessSize: 'true' });
      this.targetSizeForm.get('businessSize').setValidators([]);
      this.targetSizeForm.get('businessSize').updateValueAndValidity();
      // this.targetSizeForm.patchValue({
      //   businessSize: ''
      // })
    } else {
      this.targetSizeForm.patchValue({ isTargetAllBusinessSize: 'false' });
      this.targetSizeForm
        .get('businessSize')
        .setValidators([Validators.required]);
      this.targetSizeForm.get('businessSize').updateValueAndValidity();
    }
  }

  getBusinessSize() {
    this.dropdown.getCompanySize().subscribe((data) => {
      this.businessSize = data.details.companySize;
    });
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

  getProductDetails() {
    const productId = localStorage.getItem('productId');
    this.isv.getProductStatus(productId, 2).subscribe(
      (data) => {
        // console.log(data.details.product);
        this.productDetails = data.details.product;
        this.setTargetSize();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setTargetSize() {
    if (this.productDetails.isTargetAllBusinessSize === true) {
      this.showTargetSizeForm = false;
      this.targetSizeForm.patchValue({
        isTargetAllBusinessSize: this.productDetails.isTargetAllBusinessSize,
      });
    } else {
      if (
        this.productDetails.businessSize &&
        this.productDetails.businessSize.value
      ) {
        this.showTargetSizeForm = true;
        this.targetSizeForm.patchValue({
          isTargetAllBusinessSize: this.productDetails.isTargetAllBusinessSize,
          businessSize: this.productDetails.businessSize._id,
        });
        // console.log(this.targetSizeForm.value);
      }
    }
  }

  get f() {
    return this.targetSizeForm.controls;
  }
}
