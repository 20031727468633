import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conversion',
  pure: false,
})
export class ConversionPipe implements PipeTransform {
  conversionRate: any;
  constructor() {
    this.conversionRate = localStorage.getItem('converstionRate');
  }
  transform(value: any, args?: any): any {
    if (localStorage.getItem('currency') === 'EUR' && value) {
      return Math.trunc(value * this.conversionRate);
    } else {
      return Math.trunc(value);
    }
  }
}
