<main class="background-color">
  <section class="edit-profile padding-40 pb-0">
    <form [formGroup]="addNewIsv" (ngSubmit)="addIsv()">
      <div class="container-fluid">
        <div class="profile-sec">
          <div class="col-12" *ngIf="showCreate">
            <div class="profile-p">Add New ISV</div>
          </div>
          <div class="col-12" *ngIf="!showCreate">
            <div class="profile-p">Edit and View ISV</div>
          </div>
        </div>
        <div class="profile-details">
          <div class="col-12 top">
            <div class="row">
              <div class="col-lg-7 col-md-6">
                <div class="profile-box">
                  <div class="form-sec mb-0">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row flex-dir">
                          <div class="col-10 is-first">
                            <div class="form-group">
                              <label for="companyName"
                                >Company Name
                                <sup class="text-danger">*</sup></label
                              >
                              <input
                                type="text"
                                class="form-control"
                                id="companyName"
                                name="companyName"
                                formControlName="companyName"
                                placeholder="Enter company name"
                                aria-describedby="companyName"
                              />
                              <div
                                class="text-danger"
                                *ngIf="
                                  f.companyName.touched && f.companyName.errors
                                "
                              >
                                <small *ngIf="f.companyName.errors.required">
                                  *Company Name is required
                                </small>
                                <small *ngIf="f.companyName.errors.pattern">
                                  *Company Name is required
                                </small>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="companyUrl">Domain Name</label>
                              <input
                                type="text"
                                class="form-control"
                                id="companyUrl"
                                name="companyUrl"
                                formControlName="companyUrl"
                                placeholder="Enter company domain"
                                aria-describedby="companyUrl"
                              />
                            </div>
                          </div>
                          <div class="col-2 is-second pull-sm-left">
                            <div class="profile d-block">
                              <p class="head-box-p mb-0">Add Logo</p>

                              <div class="profile-img-top">
                                <div *ngIf="!companyImgUrl">
                                  <div *ngIf="!companyImgCon">
                                    <div class="profile-img ad-isv mb-2">
                                      <img
                                        src="../assets/images/company.png"
                                        height="100"
                                        width="150"
                                        class="image"
                                        alt="Company Image"
                                      />
                                    </div>
                                  </div>
                                  <div *ngIf="companyImgCon">
                                    <div class="profile-img squre ad-isv mb-2">
                                      <img
                                        src="{{ staticDataUrl }}{{
                                          isvUser?.companyImage
                                        }}"
                                        height="100"
                                        width="150"
                                        class="image"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div *ngIf="companyImgUrl">
                                  <div class="profile-img squre ad-isv mb-2">
                                    <img
                                      [src]="companyImgUrl"
                                      height="100"
                                      width="150"
                                      class="image"
                                      *ngIf="companyImgUrl"
                                    />
                                  </div>
                                </div>

                                <div class="fa-penci isv-fa new">
                                  <i class="fa fa-pencil"></i>
                                  <input
                                    type="file"
                                    #file
                                    name="companyLogo"
                                    id="companyLogo"
                                    formControlName="companyLogo"
                                    (change)="
                                      selectCompanyImage($event);
                                      previewCompanyImage(file.files)
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="companyLinkedin">LinkedIn Profile</label>
                          <input
                            type="text"
                            class="form-control"
                            id="companyLinkedin"
                            name="companyLinkedin"
                            formControlName="companyLinkedin"
                            aria-describedby="companyLinkedin"
                            [class.is-invalid]="
                              f.companyLinkedin.invalid &&
                              f.companyLinkedin.touched
                            "
                            (blur)="checkLinkedin('COMPANY_LINKEDIN', $event)"
                          />

                          <div
                            class="text-danger"
                            *ngIf="f.companyLinkedin.touched"
                          >
                            <small
                              *ngIf="
                                f.companyLinkedin?.errors?.pattern ||
                                errorCompanyLinkedin
                              "
                            >
                              "Please Provide a valid linkedin URL"
                            </small>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 mt-4">
                        <div class="form-group">
                          <p class="head-box-p mt-20">Office Address</p>
                          <label for="address">Address</label>
                          <input
                            type="text"
                            class="form-control"
                            id="address"
                            name="address"
                            formControlName="address"
                            aria-describedby="address"
                            placeholder="Enter Addfress 1"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="city">City</label>
                          <input
                            type="text"
                            class="form-control"
                            id="city"
                            formControlName="city"
                            name="city"
                            aria-describedby="city"
                            placeholder="Enter City"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="state-region">State / Region</label>
                          <input
                            type="text"
                            class="form-control"
                            id="state-region"
                            formControlName="state"
                            name="state-region"
                            aria-describedby="state-region"
                            placeholder="Enter State / Region"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group select-fa">
                          <label for="country">Country</label>

                          <select
                            class="form-control"
                            id="country"
                            formControlName="country"
                          >
                            <option value="" disabled hidden selected>
                              Select Country
                            </option>

                            <option
                              *ngFor="let i of countriesArr"
                              value="{{ i._id }}"
                            >
                              {{ i.value }}
                            </option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label for="zip-code">Zip / Postal Code</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="zip"
                            id="zip-code"
                            name="zip-code"
                            aria-describedby="emailHelp"
                            placeholder="Enter Zip / Postal Code"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="profile-box">
                  <div class="form-sec mb-0">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row flex-dir">
                          <div class="col-10 is-first">
                            <p class="head-box-p">Primary Contact</p>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="firstName"
                                    >First Name
                                    <sup class="text-danger">*</sup></label
                                  >
                                  <input
                                    type="text"
                                    name="firstName"
                                    aria-describedby="firstName"
                                    placeholder=""
                                    formControlName="firstName"
                                    placeholder="Enter Firstname"
                                    class="form-control"
                                    id="firstName"
                                    [class.is-invalid]="
                                      f.firstName.invalid && f.firstName.touched
                                    "
                                  />
                                  <div
                                    class="text-danger"
                                    *ngIf="
                                      f.firstName.touched && f.firstName.errors
                                    "
                                  >
                                    <small *ngIf="f.firstName.errors.required">
                                      *Firstname is required
                                    </small>
                                    <small *ngIf="f.firstName.errors.pattern">
                                      *Firstname is required
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="lastName"
                                    >Last Name<sup class="text-danger"
                                      >*</sup
                                    ></label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="lastName"
                                    name="lastName"
                                    formControlName="lastName"
                                    placeholder="Enter Lastname"
                                    aria-describedby="emailHelp"
                                  />
                                  <div
                                    class="text-danger"
                                    *ngIf="
                                      f.lastName.touched && f.lastName.errors
                                    "
                                  >
                                    <small *ngIf="f.lastName.errors.required">
                                      *Lastname is required
                                    </small>
                                    <small *ngIf="f.lastName.errors.pattern">
                                      *Lastname is required
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="form-group">
                              <label for="email"
                                >Work Email
                                <sup class="text-danger">*</sup></label
                              >
                              <input
                                type="email"
                                class="form-control"
                                id="email"
                                name="email"
                                aria-describedby="email"
                                formControlName="email"
                                placeholder="Enter Email"
                                [class.is-invalid]="
                                  f.email.invalid && f.email.touched
                                "
                              />
                              <div
                                class="text-danger"
                                *ngIf="f.email.touched && f.email.errors"
                              >
                                <small *ngIf="f.email.errors.required">
                                  *Email is required
                                </small>
                                <small *ngIf="f.email.errors.pattern">
                                  *Email is required
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="col-2 is-second">
                            <div class="profile d-block mt-40">
                              <p class="head-box-p">Add Photo</p>

                              <div class="profile-img-top mb-2">
                                <div *ngIf="!userImgUrl">
                                  <div *ngIf="!userImgCon">
                                    <div class="profile-img">
                                      <img
                                        src="../assets/images/user.png"
                                        height="100"
                                        width="100"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div *ngIf="userImgCon">
                                    <div class="profile-img">
                                      <img
                                        src="{{ staticDataUrl }}{{
                                          isvUser?.userId?.userImage
                                        }}"
                                        height="100"
                                        width="100"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="profile-img" *ngIf="userImgUrl">
                                  <img
                                    [src]="userImgUrl"
                                    alt="userImage"
                                    height="100"
                                    width="150"
                                    class="image"
                                    *ngIf="userImgUrl"
                                  />
                                </div>
                                <div class="fa-penci isv-fa new">
                                  <i class="fa fa-pencil"></i>
                                  <input
                                    type="file"
                                    #file
                                    formControlName="userImage"
                                    (change)="selectUserFile($event)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group select-fa">
                          <label for="jobTitle"
                            >Job Title
                            <!-- *ngIf="
                              addNewIsv.getRawValue().isApprovedStatus ===
                              this.idOfNew?._id
                            " -->
                            <sup class="text-danger">*</sup></label
                          >
                          <select
                            class="form-control"
                            id="jobTitle"
                            formControlName="jobTitle"
                          >
                            <option value="" selected hidden disabled>
                              Select Job Title
                            </option>
                            <option
                              *ngFor="let i of jobTitleArr"
                              value="{{ i?._id }}"
                            >
                              {{ i?.value }}
                            </option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>

                          <div
                            class="text-danger"
                            *ngIf="f.jobTitle.touched && f.jobTitle.errors"
                          >
                            <small *ngIf="f.jobTitle.errors.required">
                              *Jobtitle is required
                            </small>
                            <small *ngIf="f.jobTitle.errors.pattern">
                              *Jobtitle is required
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="userLinkedin">Linkedin Profile</label>
                          <input
                            type="text"
                            class="form-control"
                            id="userLinkedin"
                            name="userLinkedin"
                            aria-describedby="userLinkedin"
                            formControlName="userLinkedin"
                            placeholder="Linkedin Profile"
                            (blur)="checkLinkedin('USER_LINKEDIN', $event)"
                          />

                          <div
                            class="text-danger"
                            *ngIf="f.userLinkedin.touched"
                          >
                            <small
                              *ngIf="
                                f.userLinkedin?.errors?.pattern ||
                                errorUserLinkedin
                              "
                            >
                              *Please Provide Valid Linkedin URL
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="mobile">Mobile Number</label>
                          <input
                            type="text"
                            class="form-control"
                            id="mobile"
                            name="mobile"
                            formControlName="mobile"
                            aria-describedby="mobile"
                            placeholder="Enter Mobile number"
                          />
                          <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="profile-box">
                  <div class="form-sec mb-0">
                    <p class="head-box-p">Referral Fee Schedule</p>
                    <div class="table-box border-box">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <h3>1<sup>st</sup> Year</h3>
                              <p>
                                Starting at the date of first contract with
                                End-Customer
                              </p>
                            </th>
                            <th>
                              <h3>2<sup>nd</sup> Year</h3>
                            </th>
                            <th>
                              <h3>3<sup>rd</sup> Year</h3>
                            </th>
                            <th>
                              <h3>4<sup>th</sup> Year</h3>
                            </th>
                            <th>
                              <h3>5<sup>th</sup> Year</h3>
                            </th>
                            <th>
                              <h3>Following Years</h3>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="form-group input-group">
                                <input
                                  type="number"
                                  class="form-control rfsFee"
                                  min="0"
                                  max="100"
                                  formControlName="year1"
                                  placeholder="0.0"
                                  [class.is-invalid]="
                                    f.year1.invalid && f.year1.touched
                                  "
                                />

                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year1').touched &&
                                    addNewIsv.value.year1 < 0
                                  "
                                >
                                  *Fee can't be less than 0.0
                                </small>
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year1').touched &&
                                    addNewIsv.value.year1 > 100
                                  "
                                >
                                  *Fee can't be grater than 100.0
                                </small>

                                <div
                                  *ngIf="
                                    addNewIsv.get('year1').invalid &&
                                    addNewIsv.get('year1').touched
                                  "
                                >
                                  <small
                                    class="error text-danger"
                                    *ngIf="
                                      addNewIsv.get('year1').errors?.required
                                    "
                                  >
                                    *1st Year fee is required
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group input-group">
                                <input
                                  type="number"
                                  class="form-control rfsFee"
                                  min="0"
                                  max="100"
                                  formControlName="year2"
                                  placeholder="0.0"
                                  [class.is-invalid]="
                                    f.year2.invalid && f.year2.touched
                                  "
                                />

                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year2').touched &&
                                    addNewIsv.value.year2 < 0
                                  "
                                >
                                  *Fee can't be less than 0.0
                                </small>
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year2').touched &&
                                    addNewIsv.value.year2 > 100
                                  "
                                >
                                  *Fee can't be grater than 100.0
                                </small>

                                <div
                                  *ngIf="
                                    addNewIsv.get('year2').invalid &&
                                    (addNewIsv.get('year2').touched ||
                                      addNewIsv.get('year2').dirty)
                                  "
                                >
                                  <small
                                    class="error text-danger"
                                    *ngIf="
                                      addNewIsv.get('year2').errors?.required
                                    "
                                  >
                                    *2nd Year fee is required
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group input-group">
                                <input
                                  type="number"
                                  class="form-control rfsFee"
                                  nbInput
                                  min="0"
                                  max="100"
                                  formControlName="year3"
                                  placeholder="0.0"
                                  [class.is-invalid]="
                                    f.year3.invalid && f.year3.touched
                                  "
                                />

                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year3').touched &&
                                    addNewIsv.value.year3 < 0
                                  "
                                >
                                  *Fee can't be less than 0.0
                                </small>
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year3').touched &&
                                    addNewIsv.value.year3 > 100
                                  "
                                >
                                  *Fee can't be grater than 100.0
                                </small>
                                <div
                                  *ngIf="
                                    addNewIsv.get('year3').invalid &&
                                    addNewIsv.get('year3').touched
                                  "
                                >
                                  <small
                                    class="error text-danger"
                                    *ngIf="
                                      addNewIsv.get('year3').errors?.required
                                    "
                                  >
                                    *3rd Year fee is required
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group input-group">
                                <input
                                  type="number"
                                  class="form-control rfsFee"
                                  formControlName="year4"
                                  placeholder="0.0"
                                  min="0"
                                  max="100"
                                  [class.is-invalid]="
                                    f.year4.invalid && f.year4.touched
                                  "
                                />

                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year4').touched &&
                                    addNewIsv.value.year4 < 0
                                  "
                                >
                                  *Fee can't be less than 0.0
                                </small>
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year4').touched &&
                                    addNewIsv.value.year4 > 100
                                  "
                                >
                                  *Fee can't be grater than 100.0
                                </small>

                                <div
                                  *ngIf="
                                    addNewIsv.get('year4').invalid &&
                                    addNewIsv.get('year4').touched
                                  "
                                >
                                  <small
                                    class="error text-danger"
                                    *ngIf="
                                      addNewIsv.get('year4').errors?.required
                                    "
                                  >
                                    *4th Year fee is required
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group input-group">
                                <input
                                  type="number"
                                  class="form-control rfsFee"
                                  min="0"
                                  max="100"
                                  formControlName="year5"
                                  placeholder="0.0"
                                  [class.is-invalid]="
                                    f.year5.invalid && f.year5.touched
                                  "
                                />
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year5').touched &&
                                    addNewIsv.value.year5 < 0
                                  "
                                >
                                  *Fee can't be less than 0.0
                                </small>
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('year5').touched &&
                                    addNewIsv.value.year5 > 100
                                  "
                                >
                                  *Fee can't be grater than 100.0
                                </small>
                                <div
                                  *ngIf="
                                    addNewIsv.get('year5').invalid &&
                                    addNewIsv.get('year5').touched
                                  "
                                >
                                  <small
                                    class="error text-danger"
                                    *ngIf="
                                      addNewIsv.get('year5').errors?.required
                                    "
                                  >
                                    *5th Year fee is required
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="form-group input-group">
                                <input
                                  type="number"
                                  class="form-control rfsFee"
                                  min="0"
                                  max="50"
                                  placeholder="0.0"
                                  formControlName="feeOngoing"
                                  [class.is-invalid]="
                                    f.feeOngoing.invalid && f.feeOngoing.touched
                                  "
                                />
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('feeOngoing').touched &&
                                    addNewIsv.value.feeOngoing < 0
                                  "
                                >
                                  *Fee can't be less than 0.0
                                </small>
                                <small
                                  class="error text-danger"
                                  *ngIf="
                                    addNewIsv.get('feeOngoing').touched &&
                                    addNewIsv.value.feeOngoing > 100
                                  "
                                >
                                  *Fee can't be grater than 100.0
                                </small>
                                <div
                                  *ngIf="
                                    addNewIsv.get('feeOngoing').invalid &&
                                    addNewIsv.get('feeOngoing').touched
                                  "
                                >
                                  <small
                                    class="error text-danger"
                                    *ngIf="
                                      addNewIsv.get('feeOngoing').errors
                                        ?.required
                                    "
                                  >
                                    *Following Years fee is required
                                  </small>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="profile-box">
                    <div class="row">
                      <!-- custom-shadow -->
                      <div class="col-sm-12">
                        <p class="head-box-p mt-20">Add ISV Documents</p>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group select-fa">
                          <label for="docType">Document Type</label>
                          <select
                            class="form-control"
                            formControlName="documentType"
                            id="docType"
                          >
                            <option selected value="" hidden disabled>
                              Select Document Type
                            </option>
                            <option
                              *ngFor="let i of documentTypeArr"
                              value="{{ i._id }}"
                            >
                              {{ i.value }}
                            </option>
                          </select>
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group select-fa">
                          <label for="documentStatus">Document Status</label>
                          <select
                            class="form-control"
                            formControlName="documentStatus"
                            id="documentStatus"
                          >
                            <option value="" disabled hidden selected>
                              Select Status
                            </option>

                            <option
                              *ngFor="let i of documentStatusArr"
                              value="{{ i._id }}"
                            >
                              {{ i.value }}
                            </option>
                          </select>

                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </div>
                      </div>

                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="description">Description</label>
                          <textarea
                            type="text"
                            class="form-control"
                            id="description"
                            name="description"
                            aria-describedby="description"
                            formControlName="description"
                            placeholder=""
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group">
                          <label for="uploadFile">Upload File</label>
                          <input
                            type="file"
                            name="document"
                            id="documentName"
                            class="form-control mb-3"
                            formControlName="documentName"
                            (change)="addIsvDoc($event)"
                          />

                          <div class="theme-btn width-fit">
                            <button
                              class="btn active"
                              type="button"
                              (click)="addDoc()"
                              [disabled]="
                                !addNewIsv.value.documentType ||
                                !addNewIsv.value.description ||
                                !addNewIsv.value.documentStatus ||
                                !addNewIsv.value.documentName
                              "
                            >
                              <i class="fa fa-upload mr-1"></i>Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="profile-box add-pa p-0">
                    <p class="head-box-p">Attached Documents</p>

                    <div class="table-board p-0">
                      <div class="table-order ad-is-tb">
                        <table>
                          <tbody>
                            <tr>
                              <th>Document Type</th>
                              <th>Description</th>
                              <th>Status</th>
                              <th></th>
                            </tr>

                            <tr *ngFor="let i of holdDocArr">
                              <td class="fontSet">
                                {{ getDocumentTypeValue(i.documentType) }}
                              </td>
                              <td class="fontSet">{{ i.description }}</td>
                              <td class="fontSet">
                                {{ getStatusValue(i.documentStatus) }}
                              </td>
                              <td class="blue fontSet">
                                <button
                                  type="button"
                                  class="btn view-btn"
                                  (click)="showDoc(i)"
                                >
                                  <i
                                    class="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                  View
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-6">
                <div class="row">
                  <div class="col-12">
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="isApprovedStatus">Status</label>
                              <div class="select-fa">
                                <select
                                  class="form-control"
                                  formControlName="isApprovedStatus"
                                  id="isApprovedStatus"
                                  (change)="chnageEmailVerfied($event)"
                                >
                                  <option
                                    *ngFor="let i of filteredStatusArr"
                                    value="{{ i._id }}"
                                    [disabled]="
                                      i._id === idOfActive._id &&
                                      addNewIsv.value.isAgreementSigned !== true
                                    "
                                  >
                                    {{ i.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>

                            <mat-checkbox
                              required
                              class="match-theme"
                              name="emailVerified"
                              aria-label="emailVerified"
                              formControlName="emailVerified"
                              (click)="detectChange($event)"
                              [checked]="checkBoxEmail"
                              data-cy="CyEmailChecked"
                            >
                              Email Verified
                              <sup
                                class="text-danger"
                                *ngIf="
                                  addNewIsv.value.isApprovedStatus ===
                                    idOfApproved?._id ||
                                  addNewIsv.value.isApprovedStatus ===
                                    idOfActive?._id
                                "
                                >*
                              </sup>
                            </mat-checkbox>
                          </div>
                          <div class="col-sm-12" *ngIf="!showCreate">
                            <div class="form-group">
                              <label for="createdBy">Created By</label>
                              <input
                                type="text"
                                class="form-control"
                                id="createdBy"
                                name="createdBy"
                                formControlName="createdBy"
                                aria-describedby="createdBy"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="col-sm-12" *ngIf="!showCreate">
                            <div class="form-group">
                              <label for="createdDate">Created Date</label>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="createdDate"
                                id="createdDate"
                                name="createdDate"
                                aria-describedby="createdDate"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <div class="head-box-check pos">
                          <p class="head-box-p">WholeSoft Market Agreement</p>

                          <div class="custom-control p-0 custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck"
                              formControlName="isAgreementSigned"
                              [checked]="checkBoxSigned"
                              (click)="afterAgreement($event)"
                              name="isAgreementSigned"
                            />

                            <label
                              class="custom-control-label"
                              for="customCheck"
                              data-cy="CyRSigned"
                              >Signed?</label
                            >
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                              <div>
                                <label for="start-date">Start Date</label>
                                <input
                                  (change)="enableEndDateFn($event)"
                                  class="form-control"
                                  formControlName="startDate"
                                  type="date"
                                  id="date"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                              <div>
                                <label for="end-date">End Date</label>

                                <input
                                  type="date"
                                  id="endDate"
                                  class="form-control"
                                  [min]="addNewIsv.value.startDate"
                                  formControlName="endDate"
                                  [readonly]="!addNewIsv.value.startDate"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="profile-box">
                      <div class="form-sec mb-0">
                        <p class="head-box-p">Financial Year</p>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="fin-start-date">Start</label>
                              <div class="select-fa">
                                <select
                                  class="form-control"
                                  id="fin-start-date"
                                  name="fin-start-date"
                                  formControlName="start"
                                  id="startFinancialYear"
                                  (ngModelChange)="setEndMonth($event)"
                                >
                                  <option value selected disabled hidden>
                                    Select Month
                                  </option>
                                  <option
                                    *ngFor="let m of monthsArr"
                                    [hidden]="monthsArr.indexOf(m) % 3 != 0"
                                    value="{{ m._id }}"
                                  >
                                    {{ m.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="end">End</label>
                              <div class="select-fa">
                                <select
                                  class="form-control"
                                  id="end"
                                  formControlName="end"
                                >
                                  <option value selected disabled hidden>
                                    Select Month
                                  </option>
                                  <option
                                    *ngFor="let m of monthsArr"
                                    [hidden]="monthsArr.indexOf(m) % 3 != 0"
                                    value="{{ m._id }}"
                                  >
                                    {{ m.value }}
                                  </option>
                                </select>
                                <i
                                  class="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="theme-btn width-fit mt-40 footer-btn blue text-center">
        <button
          *ngIf="showCreate"
          class="btn mr-1 regu yellow"
          [disabled]="
            showCreate && addNewIsv.value.isAgreementSigned
              ? addNewIsv.controls.year1.status === 'INVALID' ||
                addNewIsv.controls.year2.status === 'INVALID' ||
                addNewIsv.controls.year3.status === 'INVALID' ||
                addNewIsv.controls.year4.status === 'INVALID' ||
                addNewIsv.controls.year5.status === 'INVALID' ||
                addNewIsv.controls.feeOngoing.status === 'INVALID' ||
                addNewIsv.controls.startDate.status === 'INVALID' ||
                addNewIsv.controls.endDate.status === 'INVALID'
              : false
          "
          [disabled]="
            !(
              showCreate &&
              addNewIsv.controls.firstName.status === 'VALID' &&
              addNewIsv.controls.companyName.status === 'VALID' &&
              addNewIsv.controls.lastName.status === 'VALID' &&
              addNewIsv.controls.email.status === 'VALID' &&
              addNewIsv.controls.jobTitle.status === 'VALID'
            ) ||
            (addNewIsv.controls.companyLinkedin && errorCompanyLinkedin) ||
            (addNewIsv.controls.userLinkedin && errorUserLinkedin)
          "
        >
          SAVE
        </button>
        <button
          *ngIf="!showCreate"
          class="btn mr-1 regu yellow"
          [disabled]="!addNewIsv.valid"
          data-cy="CySaveISV"   
        >
          SAVE
        </button>
        <button type="button" class="btn regu" (click)="resetForm()">
          CANCEL
        </button>
      </div>
    </form>
  </section>
</main>
