import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'app-prevoius-us-exp',
  templateUrl: './prevoius-us-exp.component.html',
  styleUrls: ['./prevoius-us-exp.component.scss'],
})
export class PrevoiusUsExpComponent implements OnInit {
  experienceForm: FormGroup;
  showExpForm: any;
  productId: any;
  @Input() step: StepModel;
  productDetails: any;
  public geographies: any;

  constructor(
    private fb: FormBuilder,
    private isv: IsvService,
    private stepSer: StepsService,
    private router: Router,
    public sharedService: CommonService,
    public dropdown: DropdownService
  ) {
    this.productId = localStorage.getItem('productId');
  }

  ngOnInit(): void {
    this.getProductDetails();
    this.getGeography();
    this.experienceForm = this.fb.group({
      experienceWithUSCompany: ['', Validators.required],
      account: this.fb.array([this.createAccDefault()]),
    });
  }

  getGeography() {
    this.dropdown.getGeography().subscribe(
      (res) => {
        this.geographies = res.details.geography;
      },
      (err) => {
        console.log(err);
        this.sharedService.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  createAccDefault() {
    return this.fb.group({
      name: [''],
      outcome: [''],
      geography: [''],
    });
  }

  account(): FormArray {
    return this.experienceForm.get('account') as FormArray;
  }

  addAcc() {
    this.account().push(this.createAccDefault());
  }

  updateValue(val) {
    this.experienceForm.patchValue({ experienceWithUSCompany: val });
    if (val == 'true') {
      if (!this.account().controls.length) {
        this.addAcc();
      }
      this.account().controls.forEach((ele: any) => {
        ele.get('name').setValidators([Validators.required]);
        ele.get('name').updateValueAndValidity();
        ele.get('outcome').setValidators([Validators.required]);
        ele.get('outcome').updateValueAndValidity();
        ele.get('geography').setValidators([Validators.required]);
        ele.get('geography').updateValueAndValidity();
      });
    } else {
      const control = <FormArray>this.experienceForm.controls['account'];
      for (let i = control.length - 1; i >= 0; i--) {
        control.removeAt(i);
      }
      this.account().controls.forEach((ele: any) => {
        ele.get('name').setValidators([Validators.required]);
        ele.get('name').updateValueAndValidity();
        ele.get('outcome').setValidators([[Validators.required]]);
        ele.get('outcome').updateValueAndValidity();
        ele.get('geography').setValidators([[Validators.required]]);
        ele.get('geography').updateValueAndValidity();
      });
    }
  }

  addPreviousUsExp() {
    if (!this.showExpForm) {
      this.isv
        .addUsExperience(
          {
            experienceWithUSCompany: this.experienceForm.value
              .experienceWithUSCompany,
          },
          this.productId
        )
        .subscribe(
          (data) => {
            // console.log(data);

            this.onCompleteStep();
            this.router.navigate(['admin/onboarding-progress']);
            this.stepSer.onNextStep();
          },
          (err) => {
            console.log(err);
            this.sharedService.showToastMessage('ERROR', err.error.error, 5000);
          }
        );
    } else {
      this.experienceForm.patchValue({ experienceWithUSCompany: true });
      this.isv
        .addUsExperience(this.experienceForm.value, this.productId)
        .subscribe(
          (data) => {
            this.onCompleteStep();
            this.router.navigate(['admin/onboarding-progress']);
            this.stepSer.onNextStep();
          },
          (err) => {
            console.log(err);
            this.sharedService.showToastMessage('ERROR', err.error.error, 5000);
          }
        );
    }
  }

  removeAccount(i) {
    this.account().removeAt(i);
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

  getProductDetails() {
    const productId = localStorage.getItem('productId');
    this.isv.getProductStatus(productId, 2).subscribe(
      (data) => {
        this.productDetails = data.details.product;
        // console.log(data.details.product.experienceWithUSCompany, this.productDetails.account);
        this.setExperienceDetatils();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  setExperienceDetatils() {
    this.showExpForm = this.productDetails.experienceWithUSCompany;
    this.experienceForm.patchValue({
      experienceWithUSCompany: this.productDetails.experienceWithUSCompany,
    });
    if (
      this.productDetails.experienceWithUSCompany &&
      this.productDetails.account &&
      this.productDetails.account.length
    ) {
      this.showExpForm = true;
      this.account().clear();
      this.productDetails.account.forEach((element) => {
        // console.log("element", element);

        this.account().push(
          this.fb.group({
            name: element.name,
            outcome: element.outcome,
            geography: element.geography,
          })
        );
      });
    }
  }
}
