import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CommonService } from 'src/app/utils/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-product',
  templateUrl: './all-product.component.html',
  styleUrls: ['./all-product.component.scss'],
})
export class AllProductComponent implements OnInit {
  public imageUrl = environment.apiUrl;

  p: any = 1;

  countPerPageText = '10 per page';
  statusFilText: string = 'Status';
  isvFilText: string = 'ISV';
  domainFilText: string = 'Domain';

  isvArr: any = [];
  productStatusArr: any = [];
  domainArr: any = [];
  rpStatusArr: any = [];

  totalPage: any;
  bkUptotalPage: any;
  totalPageArr: any = [];
  elementActive: any;

  public filters = {
    searchQuery: {
      productName: '',
      sellerId: '',
      status: '',
      domain: '',
    },
    skip: 0,
    count: 10,
  };

  productName: FormControl = new FormControl();
  sellerId: FormControl = new FormControl();
  status: FormControl = new FormControl();
  domain: FormControl = new FormControl();

  resArr: any = [];
  initialArr: any = [];

  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
  }

  ngOnInit(): void {
    this.getisvDropdown();
    this.getDomain();
    this.getProductStatus();
    this.fetchProduct();

    this.productName.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((term) => {
        if (term !== '') {
          this.filters.searchQuery.productName = term;
          this.fetchProduct();
        } else if (term === '') {
          this.filters.searchQuery.productName = '';
          this.fetchProduct();
        }
      });
  }

  fetchProduct() {
    this.getData.getAllProducts(this.filters).subscribe(
      (res) => {
        this.resArr = this.initialArr = res.details.products;
        this.totalPage = this.bkUptotalPage = res.details.totalPage;
        if (this.totalPage === 0) {
          this.common.showToastMessage('ERROR', 'No Records Found', 6000);
        }
        return;
      },
      (err) => {
        this.resArr = [];
        this.totalPage = 0;
        this.common.showToastMessage(`ERROR`, `No Records Found`, 6000);
      }
    );
  }

  pageChange(page) {
    this.p = page;
    this.filters.skip = (page - 1) * this.filters.count;

    const searchBar: any = document.getElementById('searchBar');
    if (searchBar.value === '') {
      this.filters.searchQuery.productName = '';
    }

    this.fetchProduct();
  }

  checkVal(opt, e, accVal) {
    const searchBar: any = document.getElementById('searchBar');
    if (searchBar.value === '') {
      this.filters.searchQuery.productName = '';
    }
    if (opt === '10PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (opt === '25PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (opt === '50PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (opt === 'sellerId') {
      this.isvFilText = accVal;
      this.filters.searchQuery.sellerId = e;
    }
    if (opt === 'status') {
      this.statusFilText = accVal;
      this.filters.searchQuery.status = e;
    }
    if (opt === 'domain') {
      this.domainFilText = accVal;
      this.filters.searchQuery.domain = e;
    }

    this.filters.skip = 0;
    this.p = 1;

    this.fetchProduct();
  }

  resetFilters() {
    this.sellerId.reset();
    this.status.reset();
    this.domain.reset();

    this.filters = {
      searchQuery: {
        productName: this.productName.value ? this.productName.value : '',
        sellerId: '',
        status: '',
        domain: '',
      },
      skip: 0,
      count: this.filters.count ? this.filters.count : 6,
    };
    // console.log(this.filters);
    this.fetchProduct();
  }

  goToRequest(id) {
    this.router.navigate([`/admin/add-product/${id}`]);
  }

  async getisvDropdown() {
    const res = await this.dropdown.getISVDropdown().toPromise();
    this.isvArr = res?.details?.isv;
  }

  async getProductStatus() {
    const res = await this.dropdown.getProductStatus().toPromise();
    this.productStatusArr = res?.details?.productStatus;
  }

  async getDomain() {
    const res = await this.dropdown.getDomains().toPromise();
    this.domainArr = res?.details?.domain;
  }
}
