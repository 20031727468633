import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CommonService } from 'src/app/utils/common.service';

@Component({
  selector: 'app-introproposal-rp',
  templateUrl: './introproposal-rp.component.html',
  styleUrls: ['./introproposal-rp.component.scss'],
})
export class IntroproposalRpComponent implements OnInit {
  resArr: any = [];
  totalPage: any;
  totalPageArr: any[] = [];
  rpArr: any = [];
  isvArr: any = [];
  accArr: any = [];
  statusArr: any = [];
  proposalRecArr: any = [];
  introStatusArr: any = [];

  searchTerm: FormControl = new FormControl();

  filters = {
    days: '',
    skip: '0',
    count: '10',
    status: '',
    introStatus: '',
    searchPattern: '',
    account: '',
    rp: '',
    isv: '',
  };
  introReqArr: any;
  introPropArr: any;

  p: any;
  countPerPageText: string = '10 per page';

  rpFilText: string = 'High Level Connector';
  proposalSentFilText: string = 'Proposal Sent';
  isvFilText: string = 'ISV';
  accFilText: string = 'Account';
  proposalStatusFilText: string = 'Proposal Status';
  introStatusFilText: any = 'Intro Status';

  constructor(
    private router: Router,
    private getData: GetDataService,
    private dropdown: DropdownService,
    private common: CommonService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
  }

  ngOnInit(): void {
    this.fetchIntroProposalForRP();
    this.refPartner();
    this.getISVDropDown();
    this.introStatusDropdownForIntroProposals();
    this.introProposalStatus();
    this.getAllAccountDropdown();

    this.searchTerm.valueChanges
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe(
        (term) => {
          this.filters.count = this.filters.count ? this.filters.count : `${6}`;
          this.filters.skip = '0';

          if (term) {
            this.filters.searchPattern = term;
            this.fetchIntroProposalForRP();
          } else if (term === '') {
            this.filters.searchPattern = term;
            this.fetchIntroProposalForRP();
          }
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;
          this.common.showToastMessage(`ERROR`, `Opps... Something went wrong`);
        }
      );
  }

  fetchIntroProposalForRP() {
    this.getData.getIntroProposalsForRPAndISV(this.filters).subscribe(
      (res) => {
        this.resArr = res.details?.data;
        this.totalPage = res.details?.total ? res.details.total : 0;

        if (this.totalPage === 0 && this.resArr.length === 0) {
          this.resArr = [];
          this.common.showToastMessage(`ERROR`, `No Records Found`, 7000);
          return;
        }
      },
      (err) => {
        this.resArr = [];
        this.totalPage = 0;
        this.common.showToastMessage(`ERROR`, `No Records Found`, 7000);
      }
    );
  }

  pageChange(page) {
    this.p = page;
    const tempSkipVar = (page - 1) * parseInt(this.filters.count);
    this.filters.skip = `${tempSkipVar}`;

    const searchBar: any = document.getElementById('searchBar');
    if (searchBar.value === '') {
      this.filters.searchPattern = '';
    }

    this.fetchIntroProposalForRP();
  }

  // Start Here For Filters
  checkVal(opt: string, e: any, accVal?: any) {
    const searchBar: any = document.getElementById('searchBar');

    if (searchBar.value === '') {
      this.filters.searchPattern = '';
    }

    if (opt === '10PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (opt === '25PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }
    if (opt === '50PERPAGE') {
      this.countPerPageText = accVal;
      this.filters.count = e;
    }

    if (opt === 'CLEARDAYS') {
      this.proposalSentFilText = accVal;
      this.filters.days = e;
    }
    if (opt === '7DAYS') {
      this.proposalSentFilText = accVal;
      this.filters.days = e;
    }
    if (opt === '30DAYS') {
      this.proposalSentFilText = accVal;
      this.filters.days = e;
    }
    if (opt === '90DAYS') {
      this.proposalSentFilText = accVal;
      this.filters.days = e;
    }

    if (opt === 'RP') {
      this.rpFilText =
        e?.lastName || e?.firstName
          ? `${e.firstName} ${e.lastName}`
          : 'High Level Connector';
      this.filters.rp = e?._id ? e?._id : '';
    }
    if (opt === 'ISV') {
      this.isvFilText = accVal;
      this.filters.isv = e;
    }
    if (opt === 'ACCOUNT') {
      this.accFilText = accVal;
      this.filters.account = e;
    }
    if (opt === 'PROPSTATUS') {
      this.proposalStatusFilText = accVal;
      this.filters.status = e;
    }
    if (opt === 'INTROSTATUS') {
      this.introStatusFilText = accVal;
      this.filters.introStatus = e;
    }

    this.filters.skip = `0`;

    console.log(this.filters);

    this.fetchIntroProposalForRP();
  }

  dateDiffCalc(introReq) {
    if (introReq?.status?.value.toLowerCase() !== 'pending') {
      return;
    }

    let givenDate: any;
    let currentDate: any = Date.now();
    var datePipe = new DatePipe('en-IN');

    givenDate = new Date(
      datePipe.transform(introReq?.proposalAcceptanceExpiryDate, 'MM/dd/yyyy')
    );
    currentDate = new Date(datePipe.transform(currentDate, 'MM/dd/yyyy'));

    const diffTime = Math.abs(givenDate - currentDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  async refPartner() {
    const res = await this.dropdown.getRpsList().toPromise();
    this.rpArr = res?.details?.data;
    this.rpArr = this.rpArr.sort((a, b) => {
      const nameA = a?.firstName.toUpperCase().trim();
      // ignore upper and lowercase
      const nameB = b?.firstName.toUpperCase().trim();
      // ignore upper and lowercase

      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    });
  }

  async getISVDropDown() {
    const res = await this.dropdown.getISVsDropDown().toPromise();
    this.isvArr = res?.details?.isv;
    this.isvArr = this.isvArr.sort((a, b) => {
      const nameA = a.companyName.toUpperCase();
      // ignore upper and lowercase
      const nameB = b.companyName.toUpperCase();
      // ignore upper and lowercase

      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    });
  }

  async introStatusDropdownForIntroProposals() {
    const res = await this.dropdown
      .introStatusDropdownForIntroProposals()
      .toPromise();
    this.introReqArr = res?.details?.status;
  }

  // 4
  async introProposalStatus() {
    const res = await this.dropdown.introProposalsStatus().toPromise();
    this.introPropArr = res?.details?.status;
  }

  async getAllAccountDropdown() {
    const res = await this.dropdown.getAllAccountDropdown().toPromise();
    this.accArr = res?.details?.accounts;
  }
}
