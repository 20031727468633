import { Component, Input, OnInit } from '@angular/core';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/utils/common.service';
import { IsvService } from 'src/app/services/isv.service';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
})
export class GettingStartedComponent implements OnInit {
  @Input() step: StepModel;

  public productName: any = '';
  public err: any;
  public productExists: any;
  public productList: any;
  public lastProgress: any;
  public hint: boolean = true;
  public toEdit: string;
  public productId: string = '';
  public productDetails: any;
  onBoardingStatus: boolean;

  constructor(
    private stepsService: StepsService,
    private isv: IsvService,
    public route: ActivatedRoute,
    public sharedService: CommonService,
    private auth: AuthService
  ) {
    this.getLastStep();
  }

  ngOnInit(): void {
    this.productId = localStorage.getItem('productId');
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');
    this.onBoardingStatus = this.auth.currentUserValue.onBoard;

    if (this.toEdit) {
      this.getProductDetail();
    }
  }

  getProductDetail() {
    // this.stepsService.getProductDetail(1).subscribe(
    //     res => {
    //         this.productDetails = res;
    //         this.productName = this.productDetails.productName;
    //     }
    // )
    this.isv.getSellerProductRegData(this.productId, 1).subscribe(
      (data) => {
        this.productDetails = data?.details?.product;
        this.productName = this.productDetails.productName;
      },
      (err) => {
        console.log(err);
        this.sharedService.showToastMessage('ERROR', err.error.message, 5000);
      }
    );
  }

  AddProductName() {
    if (this.productName != '') {
      this.isv
        .addProductName(
          { productName: this.productName },
          this.productId && this.toEdit ? this.productId : ''
        )
        .subscribe(
          (res) => {
            if (res.code === 200) {
              localStorage.setItem(
                'productId',
                this.toEdit ? res.details.productId : res.details.details._id
              );
              localStorage.setItem('productName', this.productName);
              this.stepsService.onNextStep();
            }
          },
          (err) => {
            console.log(err);
            this.err = err.error.message;
            this.sharedService.showToastMessage(
              'ERROR',
              err.error.message,
              5000
            );
            setTimeout(() => {
              this.err = '';
            }, 2000);
          }
        );
    }
  }

  getProductList() {
    // this.isv.getSellerProductList().subscribe(product => {
    //   // console.log(product.details.productList);
    //   this.productList = product.details.productList
    // }, err => {
    //   console.log(err);
    // })
  }

  getLastStep() {
    // this.isv.getStepStatus().subscribe(data => {
    //   this.lastProgress = data.details.product
    //   console.log(data.details.product, "last progress", this.lastProgress);
    // }, err => {
    //   console.log(err);
    // })
  }
}
