import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GetDataService {
  public currecyConRate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentCurr = new BehaviorSubject<any>(null);

  constructor(public http: HttpClient, private router: Router) {
    this.getConversionRate();
  }

  getIsvById(id) {
    // const id = this.actRoute.snapshot.params["id"];
    return this.http.get<any>(`${environment.apiUrl}sellerById/${id}`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getRpDetailsById(agentId) {
    return this.http.get<any>(`${environment.apiUrl}agentById/${agentId}`).pipe(
      map((el) => {
        // console.log(el);
        return el;
      })
    );
  }

  getProductDetailsById(productId) {
    return this.http
      .get<any>(`${environment.apiUrl}productById/${productId}`)
      .pipe(
        map((el) => {
          // console.log(el);
          return el;
        })
      );
  }

  getAccountDetailsById(accountId) {
    return this.http
      .get<any>(`${environment.apiUrl}accountById/${accountId}`)
      .pipe(
        map((el) => {
          // console.log(el);
          return el;
        })
      );
  }

  // :TODO:
  getAllIsv(query?) {
    // let params = new HttpParams()
    //   .set('searchQuery', stringfyString)
    //   .set('skip', skip);

    if (query && query !== '') {
      _.mapKeys(query.searchQuery, function (value, key) {
        if (_.isEmpty(value)) delete query.searchQuery[key];
      });

      query.count = +query.count;

      return this.http.get<any>(
        `${environment.apiUrl}allSellers?searchQuery=${JSON.stringify(
          query.searchQuery
        ).replace('%22', '""')}&skip=${query.skip}&count=${query.count}`
      );
    }
    return this.http.get<any>(`${environment.apiUrl}allSellers/`);
  }

  getAllRp(query?) {
    if (query && query !== '') {
      _.mapKeys(query.searchQuery, function (value, key) {
        if (_.isEmpty(value)) delete query.searchQuery[key];
      });
      query.count = +query.count;
      // let finalQuery = JSON.stringify(query.searchQuery).replace('%22', '""');
      // console.log(finalQuery);

      // let params = new HttpParams()
      //   .set('searchQuery', finalQuery)
      //   .set('skip', query.skip);

      return this.http.get<any>(
        `${environment.apiUrl}allAgents?searchQuery=${JSON.stringify(
          query.searchQuery
        ).replace('%22', '""')}&skip=${query.skip}&count=${query.count}`
      );
    }
    return this.http.get<any>(`${environment.apiUrl}allAgents/`);
  }

  getAllProducts(query?) {
    if (query && query !== '') {
      _.mapKeys(query.searchQuery, function (value, key) {
        if (_.isEmpty(value)) delete query.searchQuery[key];
      });

      // let finalQuery = JSON.stringify(query.searchQuery).replace('%22', '""');
      // // console.log(finalQuery);
      query.count = +query.count;
      // let params = new HttpParams()
      //   .set('searchQuery', finalQuery)
      //   .set('skip', query.skip);

      return this.http.get<any>(
        `${environment.apiUrl}allProducts?searchQuery=${JSON.stringify(
          query.searchQuery
        ).replace('%22', '""')}&skip=${query.skip}&count=${query.count}`
      );
    }
    return this.http.get<any>(`${environment.apiUrl}allProducts/`);
  }

  getAllAccount(query?) {
    if (query && query !== '') {
      _.mapKeys(query.searchQuery, function (value, key) {
        if (_.isEmpty(value)) delete query.searchQuery[key];
      });

      // let finalQuery = JSON.stringify(query.searchQuery).replace('%22', '""');
      // // console.log(finalQuery);
      query.count = +query.count;
      // let params = new HttpParams()
      //   .set('searchQuery', finalQuery)
      //   .set('skip', query.skip);

      return this.http.get<any>(
        `${environment.apiUrl}allAccounts?searchQuery=${JSON.stringify(
          query.searchQuery
        ).replace('%22', '""')}&skip=${query.skip}&count=${query.count}`
      );
    }
    return this.http.get<any>(`${environment.apiUrl}allAccounts/`);
  }

  getIntroRequestsAdmin(query?) {
    // console.log(query);
    if (query && JSON.stringify(query) !== '{}') {
      _.mapKeys(query, (value, key) => {
        if (_.isEmpty(value)) delete query[key];
      });

      let params = new HttpParams()
        .set('count', query.count ? query.count : '')
        .set('skip', query.skip ? query.skip : '')
        .set('days', query.days ? query.days : '')
        .set('rpId', query.rpId ? query.rpId : '')
        .set('isvId', query.isvId ? query.isvId : '')
        .set('searchPattern', query.searchPattern ? query.searchPattern : '')
        .set('status', query.status ? query.status : '');

      return this.http.get<any>(`${environment.apiUrl}getIntroRequestAdmin`, {
        params: params,
      });
    }
    return this.http.get<any>(`${environment.apiUrl}getIntroRequestAdmin/`);
  }

  getIntroProposalsForRPAndISV(query?) {
    if (query && JSON.stringify(query) !== '{}') {
      _.mapKeys(query, (value, key) => {
        if (_.isEmpty(value)) delete query[key];
      });
      // filters = {
      //   days: '',
      //   skip: '0',
      //   count: '6',
      //   status: '',
      //   introStatus: '',
      //   searchPattern: '',
      //   account: '',
      //   rp: '',
      //   isv: '',
      // };

      let params = new HttpParams()
        .set('count', query.count ? query.count : '')
        .set('skip', query.skip ? query.skip : '')
        .set('days', query.days ? query.days : '')
        .set('searchPattern', query.searchPattern ? query.searchPattern : '')
        .set('rp', query.rp ? query.rp : '')
        .set('isv', query.isv ? query.isv : '')
        .set('introStatus', query.introStatus ? query.introStatus : '')
        .set('account', query.account ? query.account : '')
        .set('status', query.status ? query.status : '')
        .set(
          'singleProposal',
          query.singleProposal ? query.singleProposal : ''
        );

      return this.http.get<any>(`${environment.apiUrl}introproposalForIsv`, {
        params: params,
      });
    }
    return this.http.get<any>(`${environment.apiUrl}introproposalForIsv/`);
  }

  getRpCommissions(payload) {
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { ...payload },
    };
    return this.http.get<any>(
      `${environment.apiUrl}getAllRpCommission`,
      httpOptions
    );
    // if (payload.searchPattern) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllRpCommission?searchPattern=${payload.searchPattern}&count=3`
    //   );
    // } else if (payload.rpId) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllRpCommission?rpId=${payload.rpId}&count=3`
    //   );
    // } else if (payload.days) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllRpCommission?days=${payload.days}&count=3`
    //   );
    // } else if (payload.isv) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllRpCommission?isv=${payload.isv}&count=3`
    //   );
    // } else if (payload.account) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllRpCommission?account=${payload.account}&count=3`
    //   );
    // } else if (payload.status) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllRpCommission?status=${payload.status}&count=3`
    //   );
    // } else if (payload.skip) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllRpCommission?skip=${payload.skip}&count=3`
    //   );
    // } else if (payload.count) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllRpCommission?count=${payload.count}`
    //   );
    // }
    // return this.http.get<any>(
    //   `${environment.apiUrl}getAllRpCommission?count=3`
    // );
  }

  getSponsoredPartners(payload) {
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { ...payload },
    };
    return this.http.get<any>(
      `${environment.apiUrl}getAllSponserRp`,
      httpOptions
    );
    // if (payload.searchPattern) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllSponserRp?searchPattern=${payload.searchPattern}&count=3`
    //   );
    // } else if (payload.rpId) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllSponserRp?count=3&rpId=${payload.rpId}`
    //   );
    // } else if (payload.days) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllSponserRp?count=3&days=${payload.days}`
    //   );
    // } else if (payload.status) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllSponserRp?count=3&status=${payload.status}`
    //   );
    // } else if (payload.count) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllSponserRp?count=${payload.count}`
    //   );
    // } else if (payload.skip) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllSponserRp?skip=${payload.skip}&count=3`
    //   );
    // } else {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}getAllSponserRp?count=${payload.count}&skip=${payload.skip}`
    //   );
    // }
  }

  getallSponsorReferalRewards(payload) {
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { ...payload },
    };
    return this.http.get<any>(
      `${environment.apiUrl}allSponsorReferalRewards`,
      httpOptions
    );
    // if (payload.searchPattern) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}allSponsorReferalRewards?searchPattern=${payload.searchPattern}`
    //   );
    // } else if (payload.sponsor) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}allSponsorReferalRewards?sponsor=${payload.sponsor}`
    //   );
    // } else if (payload.status) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}allSponsorReferalRewards?status=${payload.status}`
    //   );
    // } else if (payload.days) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}allSponsorReferalRewards?days=${payload.days}`
    //   );
    // } else if (payload.name) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}allSponsorReferalRewards?name=${payload.name}`
    //   );
    // } else if (payload.count) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}allSponsorReferalRewards?count=${payload.count}`
    //   );
    // } else if (payload.skip) {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}allSponsorReferalRewards?skip=${payload.skip}`
    //   );
    // } else {
    //   return this.http.get<any>(
    //     `${environment.apiUrl}allSponsorReferalRewards`
    //   );
    // }
  }

  // http://localhost:3224/opportunities?userType=Admin&limit=30
  // :ONLY FOR ADMIN:
  getAllOpportunities(query?) {
    if (query && JSON.stringify(query) !== '{}') {
      _.mapKeys(query, (value, key) => {
        if (_.isEmpty(value)) delete query[key];
      });

      // userType: Admin;
      // filterCompanySize: 5fe0099e74d1f813c35b0590
      // filterCountry: 5fe0099d74d1f813c35b057d
      // filterIndustry: 5fe0099d74d1f813c35b057e
      // filterStage: 5fe05b880780021e5a79df64
      // filterRpId: 5fde05d5ed5bf319a1504e7d
      // filterTerm: A-MAX
      // filterPeriod:YtD_2021
      // days:30
      // filterAccount:5fd74a8c0571940240a47dac
      // limit: 30;
      // page:2
      // filterIsvId:5fd88151a83b97286af89d25

      let params = new HttpParams()
        .set('userType', 'Admin')
        .set(
          'filterCompanySize',
          query.filterCompanySize ? query.filterCompanySize : ''
        )
        .set('filterCountry', query.filterCountry ? query.filterCountry : '')
        .set('filterIndustry', query.filterIndustry ? query.filterIndustry : '')
        .set('filterStage', query.filterStage ? query.filterStage : '')
        .set('filterRpId', query.filterRpId ? query.filterRpId : '')
        .set('filterIsvId', query.filterIsvId ? query.filterIsvId : '')
        .set('filterTerm', query.filterTerm ? query.filterTerm : '')
        .set('filterPeriod', query.filterPeriod ? query.filterPeriod : '')
        .set('days', query.days ? query.days : '')
        .set('filterAccount', query.filterAccount ? query.filterAccount : '')
        .set('limit', query.limit ? query.limit : '')
        .set('page', query.page ? query.page : '');

      return this.http.get<any>(`${environment.apiUrl}opportunities`, {
        params: params,
      });
    }

    let params = new HttpParams().set('userType', 'Admin');
    return this.http.get<any>(`${environment.apiUrl}opportunities/`, {
      params,
    });
  }
  // http://localhost:3224/opportunities?userType=Admin&limit=30
  // :ONLY FOR ADMIN:
  getAllDeals(query?) {
    if (query && JSON.stringify(query) !== '{}') {
      _.mapKeys(query, (value, key) => {
        if (_.isEmpty(value)) delete query[key];
      });

      // userType: Admin;
      // filterCompanySize: 5fe0099e74d1f813c35b0590
      // filterCountry: 5fe0099d74d1f813c35b057d
      // filterIndustry: 5fe0099d74d1f813c35b057e
      // filterStage: 5fe05b880780021e5a79df64
      // filterRpId: 5fde05d5ed5bf319a1504e7d
      // filterTerm: A-MAX
      // filterPeriod: YtD_2021
      // days: 30
      // filterAccount: 5fd74a8c0571940240a47dac
      // limit: 30;
      // page: 2
      // filterIsvId: 5fd88151a83b97286af89d25

      let params = new HttpParams()
        .set('userType', 'Admin')
        .set(
          'filterCompanySize',
          query.filterCompanySize ? query.filterCompanySize : ''
        )
        .set('filterCountry', query.filterCountry ? query.filterCountry : '')
        .set('filterIndustry', query.filterIndustry ? query.filterIndustry : '')
        // .set('filterStage', query.filterStage ? query.filterStage : '')
        .set('filterRpId', query.filterRpId ? query.filterRpId : '')
        .set('filterIsvId', query.filterIsvId ? query.filterIsvId : '')
        .set('filterTerm', query.filterTerm ? query.filterTerm : '')
        .set('filterPeriod', query.filterPeriod ? query.filterPeriod : '')
        // .set('days', query.days ? query.days : '')
        .set('filterAccount', query.filterAccount ? query.filterAccount : '')
        .set('limit', query.limit ? query.limit : '')
        .set('page', query.page ? query.page : '');

      return this.http.get<any>(`${environment.apiUrl}deals`, {
        params: params,
      });
    }

    let params = new HttpParams().set('userType', 'Admin');
    return this.http.get<any>(`${environment.apiUrl}deals/`, {
      params,
    });
  }

  getAllReferralRewards(payload: any) {
    const httpOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { ...payload },
    };
    return this.http.get<any>(
      `${environment.apiUrl}getAllReferralRewards`,
      httpOptions
    );
  }
  getConversionRate() {
    this.http
      .get<any>(`${environment.apiUrl}currencyConversionDropdown`)
      .subscribe((conv) => {
        // console.log(conv.details.conversion);
        this.currecyConRate.next(conv.details.conversion);
        localStorage.setItem(
          'converstionRate',
          conv.details.conversion.yearlyAverageRate
        );
      });
  }
}
