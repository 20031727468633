import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { abort } from 'process';
import { debounceTime, distinctUntilChanged, retry } from 'rxjs/operators';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CommonService } from 'src/app/utils/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-isv',
  templateUrl: './all-isv.component.html',
  styleUrls: ['./all-isv.component.scss'],
})
export class AllIsvComponent implements OnInit {
  p: any = 1;
  public imageUrl = environment.apiUrl;

  countPerPageText: string = '10 Per Page';
  statusFilText: string = 'Status';
  geoFilText: string = 'Geo';

  public filters: any = {
    searchQuery: { companyName: '', status: '', geography: '' },
    skip: 0,
    count: 10,
  };

  searchTerm: FormControl = new FormControl();

  resArr: any = [];

  sellerStatusArr: any = [];
  sellerStatusValueOne: any = [];
  sellerStatusValue: any = [];

  geographyArr: any = [];
  geographyValue: any = [];

  countriesArr: any = [];
  countriesValue: any = [];

  totalPageArr: any = [];
  elementActive: any;

  totalPage: any;
  countriesValueOne: any;

  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
  }

  ngOnInit(): void {
    this.getAllCountries();
    this.getSellerStatus();
    this.fetchIsv();

    this.searchTerm.valueChanges
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe(
        (term) => {
          this.filters.count = this.filters.count ? this.filters.count : 6;
          this.filters.skip = 0;
          this.p = 1;
          if (term) {
            this.filters.searchQuery.companyName = term;

            this.fetchIsv();
          } else if (term === '') {
            this.filters.searchQuery.companyName = term;

            this.fetchIsv();
          }
        },
        (err) => {
          this.resArr = [];
          this.totalPage = 0;
          this.common.showToastMessage(
            `ERROR`,
            `Oppss... Something Went Wrong`
          );
        }
      );
  }

  fetchIsv() {
    this.getData.getAllIsv(this.filters).subscribe(
      (res) => {
        this.resArr = res?.details?.sellers;
        this.totalPage = res?.details?.totalPage ? res?.details?.totalPage : 0;

        if (this.resArr.length <= 0 && this.totalPage === 0) {
          this.common.showToastMessage(`ERROR`, 'No Records Found', 7000);
          return;
        }
      },
      (err) => {
        this.resArr = [];
        this.totalPage = 0;
        this.common.showToastMessage(
          `ERROR`,
          `No Records Found For Selected Filter`
        );
      }
    );
  }

  checkVal(opt: string, e: any, accVal?) {
    if (this.searchTerm.value === '') {
      this.filters.searchQuery.companyName = '';
    }

    if (opt === '10PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = '10 Per Page';
    }
    if (opt === '25PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = '25 Per Page';
    }
    if (opt === '50PERPAGE') {
      this.filters.count = e;
      this.countPerPageText = '50 Per Page';
    }
    if (opt === 'GEO') {
      let tempCountry = '';
      this.countriesValueOne.forEach(
        (a, b) => (tempCountry = (tempCountry ? `${tempCountry},` : '') + a._id)
      );

      this.filters.searchQuery.geography = tempCountry;
    }
    if (opt === 'STATUS') {
      let tempStatus = '';
      this.sellerStatusValueOne.forEach(
        (a, b) => (tempStatus = (tempStatus ? `${tempStatus},` : '') + a._id)
      );

      this.filters.searchQuery.status = tempStatus;
    }

    this.filters.skip = 0;
    this.p = 1;

    this.fetchIsv();
  }

  clearItem(option, item) {
    if (option == 'STATUS') {
      this.sellerStatusValueOne = this.sellerStatusValueOne.filter(
        (el) => el._id !== item._id
      );

      let tempStatus = '';
      this.sellerStatusValueOne.forEach(
        (a, b) => (tempStatus = (tempStatus ? `${tempStatus},` : '') + a._id)
      );

      this.filters.searchQuery.status = tempStatus;
    }

    if (option == 'GEO') {
      this.countriesValueOne = this.countriesValueOne.filter(
        (el) => el._id !== item._id
      );

      let tempCountry = '';
      this.countriesValueOne.forEach(
        (a, b) => (tempCountry = (tempCountry ? `${tempCountry},` : '') + a._id)
      );

      this.filters.searchQuery.geography = tempCountry;
    }

    this.filters.skip = 0;
    this.p = 1;
    this.fetchIsv();
  }

  pageChange(page) {
    this.p = page;
    this.filters.skip = (page - 1) * this.filters.count;

    // const searchBar: any = document.getElementById('searchBar');
    if (this.searchTerm.value === '') {
      this.filters.searchQuery.companyName = '';
    }

    this.fetchIsv();
  }

  allFiltersReset() {
    this.filters = {
      searchQuery: {
        accountName: this.searchTerm.value ? this.searchTerm.value : '',
        status: '',
        geography: '',
      },
      count: this.filters.count ? this.filters.count : 6,
      skip: 0,
    };

    this.countPerPageText = '6 Per Page';
    this.statusFilText = 'Status';
    this.geoFilText = 'Geo';

    this.fetchIsv();
  }

  goToRequest(id) {
    this.router.navigate([`/admin/add-isv/${id}`]);
  }

  async getSellerStatus() {
    const res: any = await this.dropdown.getsellerStatus().toPromise();
    this.sellerStatusArr = res?.details?.sellerStatus;
  }

  async getAllCountries() {
    const res: any = await this.dropdown.getCountry().toPromise();
    this.countriesArr = res?.details?.countries;
  }
}

// pm2 start --name wholesoft npm -- start
