import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Dropdown } from 'src/app/interface/dropdown';
import { AddDataService } from 'src/app/services/add-data.service';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { CommonService } from 'src/app/utils/common.service';
import Swal from 'sweetalert2';
import { subDays, addYears } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { CustomBrowserAlertComponent } from '../../components/custom-browser-alert/custom-browser-alert.component';

@Component({
  selector: 'app-add-rp',
  templateUrl: './add-rp.component.html',
  styleUrls: ['./add-rp.component.scss'],
})
export class AddRpComponent implements OnInit {
  imgUrl: string = environment.apiUrl;
  showCreate: boolean;
  addNewRp: FormGroup;
  industry: string[] = [];
  rpStatusArr: Dropdown[];
  domain: string[] = [];
  filteredRpStatusArr: Dropdown[];
  submitted: boolean;
  userImageFile: File;
  jobTitleArr: Dropdown[];
  tierStatusArr: Dropdown[];
  geographyArr: Dropdown[];
  industryArr: Dropdown[];
  domainArr: Dropdown[];
  emailError: string;
  universalError: string = null;
  agentId: string;
  agentDetails: any;
  imagePath: any;
  message: string;
  rpImg: string | ArrayBuffer;
  rpUserImgCon: boolean = false;
  filteredStatusArr: any;
  checkBox: boolean;
  idOfNew: any;
  emailDisable: boolean = false;

  // Error Message Properties For Industry and Domain
  msgDomainOtherToOne: string = null;
  msgIndustry: string = null;
  msgIndustryOneToTwo: string = null;
  msgDomainOne: string = null;
  msgDomainThreeToOne: string;
  msgDomainThreeToTwo: any;
  oneToThree: any;
  msgDomainOneToThree: any;
  msgDomainOtherToTwo: string;
  idOfApproved: Dropdown;
  idOfActive: any;
  sponsoredByArr: any;

  formValid: boolean = false;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private dropdown: DropdownService,
    private dataService: AddDataService,
    private getData: GetDataService,
    private actRoute: ActivatedRoute,
    private alertService: CommonService,
    public dialog: MatDialog
  ) {
    this.agentId = actRoute.snapshot.params[`id`];
    this.alertService.showHeader.next(true);

    !this.agentId || this.agentId === ''
      ? (this.showCreate = true)
      : (this.showCreate = false);
  }

  ngOnInit(): void {
    // this.getJobTitle()
    this.getTierStatus();
    this.getGeography();
    this.getIndustry();
    this.getDomain();
    this.getRpStatus();
    this.sponsoredByDropdownList();

    this.addNewRp = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      rpImage: [''],
      jobTitle: ['', [Validators.required, Validators.maxLength(255)]],
      userLinkedin: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      mobile: [
        ///^[0-9-+()]*$/
        '',
        Validators.compose([
          Validators.minLength(8),
          Validators.maxLength(15),
          Validators.pattern(/^[0-9-+()]*$/),
        ]),
      ],
      status: ['', []],
      tier: ['', []],
      emailVerified: [false],
      isAgreementSigned: [false],
      startDate: [''],
      endDate: [''],
      referralFees: [''],
      sponsoredBy: [''],
      bankName: [''],
      iban: [''],
      bicSwift: [
        '',
        Validators.compose([
          ,
          Validators.maxLength(50),
          Validators.pattern(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/),
        ]),
      ],
      benificiaryName: ['', Validators.compose([Validators.maxLength(50)])],
      benificiaryAddress: [
        '',
        Validators.compose([, Validators.maxLength(200)]),
      ],
      benificiaryAccount: [
        '',
        Validators.compose([
          ,
          Validators.maxLength(50),
          Validators.pattern(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/),
        ]),
      ],
      geography: [''],
      industriesOne: ['', Validators.compose([])],
      industriesTwo: [''],
      domainOne: ['', Validators.compose([])],
      domainTwo: [''],
      domainThree: [''],
      isExtractLinkedin: [''],
      createdBy: [''],
      createdDate: [''],
    });

    if (this.showCreate) {
      this.addNewRp.get('status').disable();
      this.addNewRp.get('geography').disable();
      this.addNewRp.get('industriesOne').disable();
      this.addNewRp.get('industriesTwo').disable();
      this.addNewRp.get('domainOne').disable();
      this.addNewRp.get('domainTwo').disable();
      this.addNewRp.get('domainThree').disable();
      this.addNewRp.get('tier').disable();
      this.addNewRp.get('isAgreementSigned').disable();
      this.addNewRp.get('startDate').disable();
      this.addNewRp.get('endDate').disable();
      this.addNewRp.get('referralFees').disable();
      this.addNewRp.get('sponsoredBy').disable();
    }
  }

  async getInitialData() {
    if (this.agentId && this.agentId !== '') {
      const res = await this.getData.getRpDetailsById(this.agentId).toPromise();
      this.agentDetails = res.details.agent;

      // After Getting Data

      this.addNewRp.value.emailVerified =
        this.agentDetails?.userId?.emailVerified || false;

      if (this.agentDetails?.userId?.emailVerified === false) {
        this.addNewRp.get('status').disable();
      }

      this.rpUserImgCon =
        this.agentDetails.userId?.userImage &&
        this.agentDetails.userId.hasOwnProperty('userImage');

      if (this.agentDetails.status.value === 'Active') {
        this.addNewRp.controls.geography.setValidators([Validators.required]);
        this.addNewRp.controls.industriesOne.setValidators([
          Validators.required,
        ]);
        this.addNewRp.controls['geography'].updateValueAndValidity();
        this.addNewRp.controls['industriesOne'].updateValueAndValidity();
      }

      if (this.agentDetails.isAgreementSigned === true) {
        this.addNewRp.controls.startDate.setValidators([Validators.required]);
        this.addNewRp.controls.endDate.setValidators([Validators.required]);
        this.addNewRp.controls.referralFees.setValidators([
          Validators.required,
        ]);
        this.addNewRp.controls['startDate'].updateValueAndValidity();
        this.addNewRp.controls['endDate'].updateValueAndValidity();
        this.addNewRp.controls['referralFees'].updateValueAndValidity();
      }

      this.addNewRp.get('createdBy').disable();
      this.addNewRp.get('createdDate').disable();
      this.addNewRp.get('firstName').disable();
      this.addNewRp.get('lastName').disable();

      // console.log(this.agentDetails);
      if (this.agentDetails?.status?.value === 'New') {
        // this.addNewRp.get('isAgreementSigned').disable();
        this.addNewRp.controls.isAgreementSigned.disable();
        this.addNewRp.get('startDate').disable();
        this.addNewRp.get('endDate').disable();
        this.addNewRp.get('referralFees').disable();
        this.addNewRp.get('sponsoredBy').disable();
        this.addNewRp.get('geography').disable();
        this.addNewRp.get('industriesOne').disable();
        this.addNewRp.get('industriesTwo').disable();
        this.addNewRp.get('domainOne').disable();
        this.addNewRp.get('domainTwo').disable();
        this.addNewRp.get('domainThree').disable();
      }

      this.dropDownContent();
      this.setFormData(this.agentDetails);
      return this.agentDetails;
    }
    return;
  }

  isApprovedStatusPathValue() {
    // this.idOfNew = this.filteredStatusArr.find((el) =>
    //   el.value === 'New' ? el : ''
    // );

    this.idOfNew &&
      this.addNewRp.patchValue({
        status: this.idOfNew?._id,
      });
  }

  // e.preventDefault();
  detectChangeTrue(e) {
    if (!this.showCreate && this.addNewRp.value.emailVerified) {
      e.preventDefault();
      return;
    }

    if (this.emailDisable) {
      e.preventDefault();
      return;
    }

    if (
      this.addNewRp.value.emailVerified === false ||
      this.agentDetails?.userId?.emailVerified === false
    ) {
      return this.openDialog('CONFIRM', e);
    }
  }

  alertConfirmation(confirmed, e) {
    this.addNewRp.value.emailVerified = false;
    this.addNewRp.controls.emailVerified.setValue(false);
    if (confirmed) {
      this.addNewRp.value.emailVerified = true;
      this.addNewRp.controls.emailVerified.setValue(true);
      this.addNewRp.get('status').enable();
      Swal.fire({
        title: 'Email Verified Checked',
        text: "User's Email is Verified",
        icon: 'success',
      });

      this.emailDisable = true;
      // this.addNewRp.get("emailVerified").disable({
      //   onlySelf: true,
      // });
      // this.addNewRp.get("emailVerified")
    } else {
      e.preventDefault();
      this.addNewRp.controls.emailVerified.setValue(false);
      this.addNewRp.value.emailVerified = false;
      this.addNewRp.get('status').disable();
      Swal.fire({
        title: 'Email Verified Not Checked',
        text: "User's Email is not Verified",
        icon: 'warning',
      });
    }
  }
  openDialog(type: 'CONFIRM' | 'AGREE', e): void {
    const dialogRef = this.dialog.open(CustomBrowserAlertComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (type === 'CONFIRM') {
        this.alertConfirmation(confirm, e);
      } else {
        this.agreementConformation(confirm, e);
      }
    });
  }

  setFormData(userData) {
    var datePipe = new DatePipe('en-IN');
    const setDate = datePipe.transform(userData.createdAt, 'yyyy-MM-dd');

    this.addNewRp.patchValue({
      firstName: userData?.userId?.firstName ? userData?.userId?.firstName : '',
      lastName: userData?.userId?.lastName ? userData?.userId?.lastName : '',
      email: userData?.userId?.email ? userData?.userId?.email : '',
      jobTitle: userData?.jobTitle ? userData?.jobTitle : '',
      userLinkedin: userData?.userId?.userLinkedin
        ? userData?.userId?.userLinkedin
        : '',
      mobile: userData?.userId?.mobile ? userData?.userId?.mobile : '',
      status: userData?.status?._id ? userData?.status?._id : '',
      emailVerified: userData?.userId?.emailVerified
        ? userData?.userId?.emailVerified
        : '',
      tier: userData?.tier?._id ? userData?.tier?._id : '',
      createdDate: setDate ? setDate : '',
      createdBy:
        userData?.createdBy?.firstName || userData?.createdBy?.lastName
          ? userData?.createdBy?.firstName + ' ' + userData?.createdBy?.lastName
          : '',

      isAgreementSigned: userData?.isAgreementSigned
        ? userData?.isAgreementSigned
        : false,
      isExtractLinkedin: userData?.isExtractLinkedin
        ? userData?.isExtractLinkedin
        : '',
      startDate: userData?.wsmAgreement?.startDate
        ? userData?.wsmAgreement?.startDate
            .substr(0, 10)
            .toString()
            .split('-')
            .join('-')
        : '',
      endDate: userData?.wsmAgreement?.endDate
        ? userData?.wsmAgreement?.endDate
            .substr(0, 10)
            .toString()
            .split('-')
            .join('-')
        : '',

      referralFees: userData?.referralFees && userData?.referralFees,
      sponsoredBy: userData?.sponsoredBy ? userData?.sponsoredBy : '',
      bankName: userData?.bank?.name ? userData?.bank?.name : '',
      iban: userData?.bank?.iban ? userData?.bank?.iban : '',
      bicSwift: userData?.bank?.bicSwift ? userData?.bank?.bicSwift : '',
      benificiaryName: userData?.bank?.benificiaryName
        ? userData?.bank?.benificiaryName
        : '',
      benificiaryAddress: userData?.bank?.benificiaryAddress
        ? userData?.bank?.benificiaryAddress
        : '',
      benificiaryAccount: userData?.bank?.benificiaryAccount
        ? userData?.bank?.benificiaryAccount
        : '',
      geography: userData?.geography?._id ? userData?.geography?._id : '',
      industriesOne: userData?.industry[0]?.value
        ? userData.industry[0].value
        : '',
      industriesTwo: userData?.industry[1]?.value
        ? userData.industry[1].value
        : '',
      domainOne: userData?.domain[0]?.value ? userData?.domain[0]?.value : '',
      domainTwo: userData?.domain[1]?.value ? userData?.domain[1]?.value : '',
      domainThree: userData?.domain[2]?.value ? userData?.domain[2]?.value : '',
    });
  }

  // :MISTAKE: :TODO:
  chnageEmailVerfied(e) {
    if (e.target.value === this.idOfApproved._id) {
      this.checkBox = true;
      this.addNewRp.controls.emailVerified.setValidators([Validators.required]);

      this.addNewRp.controls.isAgreementSigned.enable();
      this.addNewRp.controls.geography.enable();
      this.addNewRp.controls.industriesOne.enable();
      this.addNewRp.controls.industriesTwo.enable();
      this.addNewRp.controls.domainOne.enable();
      this.addNewRp.controls.domainTwo.enable();
      this.addNewRp.controls.domainThree.enable();
    } else if (e.target.value === this.idOfActive._id) {
      this.addNewRp.controls.geography.setValidators([Validators.required]);
      this.addNewRp.controls.industriesOne.setValidators([Validators.required]);
    } else if (e.target.value === this.idOfNew._id) {
      this.addNewRp.controls.isAgreementSigned.disable();
      this.addNewRp.controls.geography.disable();
      this.addNewRp.controls.industriesOne.disable();
      this.addNewRp.controls.industriesTwo.disable();
      this.addNewRp.controls.domainOne.disable();
      this.addNewRp.controls.domainTwo.disable();
      this.addNewRp.controls.domainThree.disable();
    } else {
      this.checkBox = false;

      // this.addNewRp.patchValue({
      //   emailVerified: false,
      // });
    }

    e.target.value !== this.idOfApproved._id &&
      this.addNewRp.controls['emailVerified'].clearValidators();

    if (e.target.value !== this.idOfActive._id) {
      this.addNewRp.controls['geography'].clearValidators();
      this.addNewRp.controls['industriesOne'].clearValidators();
    }

    this.addNewRp.controls['emailVerified'].updateValueAndValidity();
    this.addNewRp.controls['geography'].updateValueAndValidity();
    this.addNewRp.controls['industriesOne'].updateValueAndValidity();
    this.addNewRp.controls['isAgreementSigned'].updateValueAndValidity();
  }

  afterAgreement(e) {
    if (this.addNewRp.controls.isAgreementSigned.status !== 'DISABLED') {
      if (this.addNewRp.value.isAgreementSigned) {
        e.preventDefault();
        return;
      }

      if (
        this.addNewRp.value.isAgreementSigned === false ||
        this.agentDetails?.isAgreementSigned === false
      ) {
        // return this.agreementConformation(e);
        return this.openDialog('AGREE', e);

      }
    }
  }

  agreementConformation(confirmed, e) {
    if (confirmed) {
      this.addNewRp.get('startDate').enable();
      this.addNewRp.get('endDate').enable();
      this.addNewRp.get('referralFees').enable();
      this.addNewRp.get('sponsoredBy').enable();

      this.addNewRp.controls.startDate.setValidators([Validators.required]);
      this.addNewRp.controls.endDate.setValidators([Validators.required]);
      this.addNewRp.controls.referralFees.setValidators([Validators.required]);
      this.addNewRp.controls.isAgreementSigned.setValue(true);

      const datePipePro = new DatePipe('en-IN');

      const stratDateVal = datePipePro.transform(new Date(), 'yyyy-MM-dd');

      let resultDate = addYears(new Date(), 1);
      resultDate = subDays(resultDate, 1);
      const finaldate = datePipePro.transform(resultDate, 'yyyy-MM-dd');

      this.addNewRp.controls.startDate.setValue(stratDateVal);
      this.addNewRp.controls.endDate.setValue(finaldate);

      Swal.fire({
        title: 'Agreement Signed',
        text: 'User has Signed Agreement',
        icon: 'success',
      });
    } else {
      e.preventDefault();
      this.addNewRp.controls.isAgreementSigned.setValue(false);
      Swal.fire({
        title: 'Email Verified Not Checked',
        text: "User's Email is not Verified",
        icon: 'warning',
      });
    }

    this.addNewRp.controls['startDate'].updateValueAndValidity();
    this.addNewRp.controls['endDate'].updateValueAndValidity();
    this.addNewRp.controls['referralFees'].updateValueAndValidity();
  }

  setRequireDate(e) {
    // const toDate: any = new Date(
    //   new Date().setFullYear(new Date().getFullYear() + 1)
    // );

    // if (this.showCreate) {
    //   return;
    // }

    let datePipePro = new DatePipe('en-IN');
    const stratDateVal = datePipePro.transform(new Date(), 'yyyy-MM-dd');
    const toDateVal = datePipePro.transform(
      new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      'yyyy-MM-dd'
    );

    // console.log(this.addNewRp.value.isAgreementSigned);
    this.addNewRp.value.isAgreementSigned = !this.addNewRp.value
      .isAgreementSigned;
    if (this.addNewRp.value.isAgreementSigned === true) {
      this.addNewRp.controls.startDate.setValue(stratDateVal);
      this.addNewRp.controls.endDate.setValue(toDateVal);
      this.addNewRp.controls.referralFees.setValue(5);

      this.addNewRp.controls.startDate.setValidators([Validators.required]);
      this.addNewRp.controls.endDate.setValidators([Validators.required]);
      this.addNewRp.controls.referralFees.setValidators([Validators.required]);
    } else {
      this.addNewRp.controls.startDate.reset();
      this.addNewRp.controls.endDate.reset();
      this.addNewRp.controls.referralFees.reset();

      this.addNewRp.controls.startDate.clearValidators();
      this.addNewRp.controls.endDate.clearValidators();
      this.addNewRp.controls.referralFees.clearValidators();
    }
    this.addNewRp.controls['startDate'].updateValueAndValidity();
    this.addNewRp.controls['endDate'].updateValueAndValidity();
    this.addNewRp.controls['referralFees'].updateValueAndValidity();
  }

  checkFormValid(e) {
    if (
      this.addNewRp.value.bankName ||
      this.addNewRp.value.iban ||
      this.addNewRp.value.bicSwift ||
      this.addNewRp.value.benificiaryName ||
      this.addNewRp.value.benificiaryAddress ||
      this.addNewRp.value.benificiaryAccount
    ) {
      // console.log("Entering if blcok ADD VALIDATORS");

      this.addNewRp.controls.bankName.setValidators([Validators.required]);
      this.addNewRp.controls.iban.setValidators([Validators.required]);
      this.addNewRp.controls.bicSwift.setValidators([
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/),
      ]);
      this.addNewRp.controls.benificiaryName.setValidators([
        Validators.required,
        Validators.maxLength(50),
      ]);
      this.addNewRp.controls.benificiaryAddress.setValidators([
        Validators.maxLength(200),
        Validators.required,
      ]);
      this.addNewRp.controls.benificiaryAccount.setValidators([
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/),
      ]);
    } else {
      !this.addNewRp.value.bankName &&
        this.addNewRp.controls.bankName.clearValidators();
      !this.addNewRp.value.iban &&
        this.addNewRp.controls.iban.clearValidators();
      !this.addNewRp.value.bicSwift &&
        this.addNewRp.controls.bicSwift.clearValidators();
      !this.addNewRp.value.benificiaryName &&
        this.addNewRp.controls.benificiaryName.clearValidators();
      !this.addNewRp.value.benificiaryAddress &&
        this.addNewRp.controls.benificiaryAddress.clearValidators();
      !this.addNewRp.value.benificiaryAccount &&
        this.addNewRp.controls.benificiaryAccount.clearValidators();
      // console.log(this.addNewRp.valid);
    }
    this.addNewRp.controls['bankName'].updateValueAndValidity();
    this.addNewRp.controls['iban'].updateValueAndValidity();
    this.addNewRp.controls['bicSwift'].updateValueAndValidity();
    this.addNewRp.controls['benificiaryName'].updateValueAndValidity();
    this.addNewRp.controls['benificiaryAddress'].updateValueAndValidity();
    this.addNewRp.controls['benificiaryAccount'].updateValueAndValidity();
  }

  // preview Image
  previewFile(files) {
    if (files.length === 0) {
      return;
    }
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    let reader = new FileReader();
    this.imagePath = files[0];
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.rpImg = reader.result;
    };
  }

  async getRpStatus() {
    const res = await this.dropdown.getRpStatus().toPromise();
    this.rpStatusArr = res?.details?.rpStatus;
    await this.getInitialData();
    await this.dropDownContent();

    this.idOfNew = this.rpStatusArr.find((el) =>
      el.value === 'New' ? el : ''
    );
    this.idOfApproved = this.rpStatusArr.find((el) =>
      el.value === 'Approved' ? el : ''
    );

    this.idOfActive = this.rpStatusArr.find((el) =>
      el.value === 'Active' ? el : ''
    );

    this.showCreate && this.isApprovedStatusPathValue();
  }

  async getTierStatus() {
    const res = await this.dropdown.getTierStatus().toPromise();
    this.tierStatusArr = res?.details?.tier;
  }

  async getGeography() {
    const res = await this.dropdown.getGeography().toPromise();
    this.geographyArr = res?.details?.geography;
  }

  async getIndustry() {
    const res = await this.dropdown.getIndustry().toPromise();
    this.industryArr = res?.details?.industry;
  }

  async getDomain() {
    const res = await this.dropdown.getRPDomains().toPromise();
    this.domainArr = res?.details?.rpDomain;
  }

  async sponsoredByDropdownList() {
    const res = await this.dropdown.sponsoredByDropdown().toPromise();
    this.sponsoredByArr = res?.details?.sponsoredBy;
    this.sponsoredByArr = this.sponsoredByArr.filter(
      (el) => el._id !== this.agentId
    );

    this.sponsoredByArr = this.sponsoredByArr.sort((acc, cur) => {
      const nameA = acc?.firstName.toLowerCase().trim();
      const nameB = cur?.firstName.toLowerCase().trim();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  // Status Dropdown Filtering
  dropDownContent() {
    if (this.showCreate) {
      this.filteredStatusArr = this.rpStatusArr.filter((el) => {
        return (
          el.value === 'New' ||
          el.value === 'Approved' ||
          el.value === 'Rejected'
        );
      });
    } else if (!this.showCreate && this.agentDetails.status.value === 'New') {
      this.filteredStatusArr = this.rpStatusArr.filter(
        (el) =>
          el.value === 'New' ||
          el.value === 'Approved' ||
          el.value === 'Rejected'
      );
    } else if (
      !this.showCreate &&
      this.agentDetails.status.value === 'Approved'
    ) {
      // console.log(this.rpStatusArr);
      this.filteredStatusArr = this.rpStatusArr.filter(
        (el) =>
          el.value === 'Active' ||
          el.value === 'Approved' ||
          el.value === 'Inactive'
      );
      // ||el.value === 'Rejected'
    } else if (
      !this.showCreate &&
      this.agentDetails.status.value === 'Rejected'
    ) {
      // this.addNewRp.get('isApprovedStatus').disable();
      this.filteredStatusArr = this.rpStatusArr.filter(
        (el) => el.value === 'Rejected'
      );
    } else if (
      (!this.showCreate && this.agentDetails.status.value === 'Active') ||
      this.agentDetails.status.value === 'Inactive'
    ) {
      // If Active, then status can be changed to Inactive or Archived
      this.filteredStatusArr = this.rpStatusArr.filter(
        (el) =>
          el.value === 'Active' ||
          el.value === 'Inactive' ||
          el.value === 'Archived' ||
          el.value === 'Rejected'
      );
    } else if (
      !this.showCreate &&
      this.agentDetails.status.value === 'Archived'
    ) {
      this.addNewRp.get('status').disable();
      this.filteredStatusArr = this.rpStatusArr.filter(
        (el) => el.value === 'Archived'
      );
    } else {
      this.filteredStatusArr = this.rpStatusArr;
    }
    // console.log(this.filteredStatusArr);
    /*else if (
      !this.showCreate &&
      this.agentDetails.status.value === "Inactive"
    ) {
      this.filteredStatusArr = this.rpStatusArr.filter(
        (el) =>
          el.value === "Active" ||
          el.value === "Inactive" ||
          el.value === "Archived"
      );
    }*/
  }

  // Clear Error Message INDUSTRY Miscellaneous
  additionalFunDomain(e) {
    if (this.msgDomainOne && this.addNewRp.value.domainOne) {
      if (this.addNewRp.value.domainTwo !== this.addNewRp.value.domainOne) {
        return (this.msgDomainOne = null);
      }
    }

    if (this.msgDomainThreeToTwo && this.addNewRp.value.domainTwo) {
      if (this.addNewRp.value.domainTwo !== this.addNewRp.value.domainThree) {
        return (this.msgDomainThreeToTwo = null);
      }
    }

    // One to Two  "Can't choose same Domain1-2"
    if (this.msgDomainOtherToOne && this.addNewRp.value.domainTwo) {
      if (this.addNewRp.value.domainTwo !== this.addNewRp.value.domainTwo) {
        return (this.msgDomainOtherToOne = null);
      }
    }

    if (this.msgDomainThreeToOne && this.addNewRp.value.domainThree) {
      if (this.addNewRp.value.domainOne !== this.addNewRp.value.domainThree) {
        return (this.msgDomainThreeToOne = null);
      }
    }

    if (this.oneToThree && this.addNewRp.value.domainThree) {
      if (this.addNewRp.value.domainOne !== this.addNewRp.value.domainThree) {
        return (this.oneToThree = null);
      }
    }
    if (this.msgDomainOneToThree && this.addNewRp.value.domainThree) {
      if (this.addNewRp.value.domainTwo !== this.addNewRp.value.domainThree) {
        return (this.msgDomainOneToThree = null);
      }
    }
    if (this.msgDomainOtherToTwo && this.addNewRp.value.domainTwo) {
      if (this.addNewRp.value.domainTwo !== this.addNewRp.value.domainOne) {
        return (this.msgDomainOtherToTwo = null);
      }
    }
  }

  // Clear Error Message INDUSTRY Miscellaneous
  additionalFunInd(e) {
    if (this.msgIndustry) {
      if (e.target.value !== this.addNewRp.value.industriesTwo) {
        this.msgIndustry = null;
      }
    }
    if (this.msgIndustryOneToTwo) {
      if (e.target.value !== this.addNewRp.value.industriesOne) {
        this.msgIndustryOneToTwo = null;
      }
    }
  }

  // Check if user has selected First industry same as Second one
  checkDupIndOneToTwo(e): any {
    if (this.addNewRp.value.industriesTwo) {
      this.addNewRp.value.industriesTwo === e.target.value
        ? (this.msgIndustryOneToTwo = "Can't choose same industry")
        : (this.msgIndustryOneToTwo = null);
    }
  }

  checkDupInd(e): any {
    if (this.addNewRp.value.industriesOne) {
      this.addNewRp.value.industriesOne === e.target.value
        ? (this.msgIndustry = "Can't choose same industry")
        : (this.msgIndustry = null);
    }
    return;
  }
  /* Industry same validation End */

  // If other Domains are selected and
  // Than DomainOne is selected same as 2nd or 3rd Check for Duplicacy
  checkDupDomainOneToTwo(e): any {
    if (this.addNewRp.value.domainTwo && this.addNewRp.value.domainTwo !== '') {
      if (this.addNewRp.value.domainTwo === e.target.value) {
        //Two
        return (this.msgDomainOtherToTwo = "Can't choose same Domain"); // Three to Two
      } else {
        return (this.msgDomainOtherToTwo = null);
      }
    }

    return;
  }

  // If other Domains are selected and
  // Than DomainOne is selected same as 2nd or 3rd Check for Duplicacy
  checkDupDomainOneToThree(e): any {
    if (
      this.addNewRp.value.domainThree &&
      this.addNewRp.value.domainThree !== ''
    ) {
      this.addNewRp.value.domainThree === e.target.value
        ? (this.oneToThree = "Can't choose same Domain")
        : (this.oneToThree = null);
    }

    return;
  }

  // Check if 3rd Doamin Dropdown has same value as 1st and 2nd
  checkDupDomainThreeTotwo(e): any {
    if (this.addNewRp.value.domainTwo && this.addNewRp.value.domainTwo !== '') {
      if (this.addNewRp.value.domainTwo === e.target.value) {
        return (this.msgDomainThreeToTwo = "Can't choose same Domain");
      } else {
        return (this.msgDomainThreeToTwo = null);
      }
    }

    return;
  }

  // Check if 3rd Doamin Dropdown has same value as 1st and 2nd
  checkDupDomainThreeToOne(e): any {
    if (this.addNewRp.value.domainOne && this.addNewRp.value.domainOne !== '') {
      if (this.addNewRp.value.domainOne === e.target.value) {
        return (this.msgDomainThreeToOne = "Can't choose same Domain");
      } else {
        return (this.msgDomainThreeToOne = null);
      }
    }
    return;
  }

  // Check if 2nd Domain has same value has 1st
  checkDupDomainTwotoOne(e): any {
    if (this.addNewRp.value.domainOne && this.addNewRp.value.domainOne !== '') {
      if (this.addNewRp.value.domainOne === e.target.value) {
        return (this.msgDomainOne = "Can't choose same Domain");
      } else {
        return (this.msgDomainOne = null);
      }
    }

    return;
  }

  checkDupDomainTwotoThree(e): any {
    if (
      this.addNewRp.value.domainThree &&
      this.addNewRp.value.domainThree !== ''
    ) {
      if (this.addNewRp.value.domainThree === e.target.value) {
        return (this.msgDomainOneToThree = "Can't choose same Domain");
      } else {
        return (this.msgDomainOneToThree = null);
      }
    }

    return;
  }

  // Extract Industry IDs
  getIndustryId(industryItem) {
    // console.log(industryItem);
    if (industryItem) {
      const res: Dropdown = this.industryArr.find(
        (el) => el.value === `${industryItem}`
      );
      // console.log(res._id);
      return res._id;
    }
    return;
  }

  // Extract Domain IDs
  getDomainId(domainItem) {
    if (domainItem) {
      // console.log(domainItem);
      const res: Dropdown = this.domainArr.find(
        (el) => el.value === `${domainItem}`
      );
      // console.log(res._id);
      return res._id;
    }
    return;
  }

  selectFile(e) {
    // console.log('2nd ==>', e.target.files);
    if (e.target.files.length > 0) {
      for (let i of e.target.files) {
        this.userImageFile = i;
      }
    }
  }

  enableEndDateFn(e) {
    const datePipePro = new DatePipe('en-IN');
    let resultDate = addYears(new Date(e.target.value), 1);
    resultDate = subDays(resultDate, 1);
    const finaldate = datePipePro.transform(resultDate, 'yyyy-MM-dd');

    this.addNewRp.patchValue({
      endDate: finaldate,
    });
  }

  addRp() {
    if (this.addNewRp.value.industriesOne)
      this.industry.push(this.getIndustryId(this.addNewRp.value.industriesOne));

    if (this.addNewRp.value.industriesTwo)
      this.industry.push(this.getIndustryId(this.addNewRp.value.industriesTwo));

    if (this.addNewRp.value.domainOne)
      this.domain.push(this.getDomainId(this.addNewRp.value.domainOne));

    if (this.addNewRp.value.domainTwo)
      this.domain.push(this.getDomainId(this.addNewRp.value.domainTwo));

    if (this.addNewRp.value.domainThree)
      this.domain.push(this.getDomainId(this.addNewRp.value.domainThree));

    const wsmAgreement = {
      startDate: this.addNewRp.value.startDate,
      endDate: this.addNewRp.value.endDate,
    };

    const bank = {
      name: this.addNewRp.value.bankName,
      iban: this.addNewRp.value.iban,
      bicSwift: this.addNewRp.value.bicSwift,
      benificiaryName: this.addNewRp.value.benificiaryName,
      benificiaryAddress: this.addNewRp.value.benificiaryAddress,
      benificiaryAccount: this.addNewRp.value.benificiaryAccount,
    };
    const finalObj = {
      userLinkedin: this.addNewRp.value.userLinkedin,
      jobTitle: this.addNewRp.value.jobTitle,
      industry: [...new Set(this.industry)],
      domain: [...new Set(this.domain)],
      referralFees:
        (this.addNewRp.value.referralFees &&
          this.addNewRp.value.referralFees) ||
        this.addNewRp.getRawValue().referralFees,
      geography: this.addNewRp.value.geography,

      bank: bank.name ? bank : {},
      isExtractLinkedin:
        this.addNewRp.value.isExtractLinkedin &&
        this.addNewRp.value.isExtractLinkedin,
      firstName: this.addNewRp.value.firstName,
      lastName: this.addNewRp.value.lastName,
      email: this.addNewRp.value.email,
      mobile: this.addNewRp.value.mobile,
      isAgreementSigned: this.addNewRp.value.isAgreementSigned
        ? this.addNewRp.value.isAgreementSigned
        : false,
      status: this.addNewRp.value.status ? this.addNewRp.value.status : '',
      tier: this.addNewRp.value.tier,
      sponsoredBy:
        (this.addNewRp.value.sponsoredBy && this.addNewRp.value.sponsoredBy) ||
        (this.addNewRp.getRawValue().sponsoredBy &&
          this.addNewRp.getRawValue().sponsoredBy),

      wsmAgreement:
        wsmAgreement.startDate && wsmAgreement.endDate ? wsmAgreement : {},
      emailVerified: this.addNewRp.value.emailVerified
        ? this.addNewRp.value.emailVerified
        : false,
    };

    const formData = new FormData();

    this.userImageFile && formData.append('userImage', this.userImageFile);
    formData.append('userLinkedin', this.addNewRp.value.userLinkedin);
    formData.append('jobTitle', this.addNewRp.value.jobTitle);
    this.addNewRp.value.geography &&
      formData.append('geography', finalObj.geography);
    formData.append('industry', JSON.stringify(finalObj.industry));
    formData.append('domain', JSON.stringify(finalObj.domain));
    this.addNewRp.value.isExtractLinkedin &&
      formData.append('isExtractLinkedin', finalObj.isExtractLinkedin);
    formData.append('bank', JSON.stringify(finalObj.bank));
    formData.append(
      'firstName',
      this.addNewRp.value.firstName
        ? this.addNewRp.value.firstName
        : this.agentDetails.userId.firstName
    );
    formData.append(
      'lastName',
      this.addNewRp.value.lastName
        ? this.addNewRp.value.lastName
        : this.agentDetails?.userId?.lastName
    );
    formData.append(
      'email',
      this.addNewRp.value.email
        ? this.addNewRp.value.email
        : this.agentDetails.userId.email
    );
    this.addNewRp.value.mobile && formData.append('mobile', finalObj.mobile);
    formData.append('isAgreementSigned', finalObj.isAgreementSigned);

    formData.append(
      'status',
      this.addNewRp.value.status
        ? this.addNewRp.value.status
        : this.idOfNew?._id
    );
    this.addNewRp.value.tier && formData.append('tier', finalObj.tier);

    (this.addNewRp.value.sponsoredBy ||
      this.addNewRp.getRawValue().sponsoredBy) &&
      formData.append('sponsoredBy', finalObj.sponsoredBy);

    if (
      this.addNewRp.value.referralFees ||
      this.addNewRp.getRawValue().referralFees
    ) {
      formData.append('referralFees', finalObj.referralFees);
    }

    formData.append('wsmAgreement', JSON.stringify(finalObj.wsmAgreement));

    formData.append('emailVerified', finalObj.emailVerified);

    this.submitted = true;

    if (this.agentId) {
      this.dataService.updateAgentDetails(this.agentId, formData).subscribe(
        (data: any) => {
          this.alertService.showSuccess('RP updated successfully');
          this.addNewRp.reset();
          this.userImageFile = null;
          // this.getInitialData();
          this.router.navigate(['/admin/all-rp']);
        },
        (err) => {
          // console.log(err);
          if (err.error.message === 'Email already exist on platform') {
            Swal.fire({
              title: 'Something went Wrong',
              text: err.error.message || err.error.error,
              icon: 'error',
            });
            this.emailError = 'Email already exist on platform';
          } else {
            Swal.fire({
              title: 'Something went Wrong',
              text: err.error.error || err.error.message,
              icon: 'error',
            });
          }
        }
      );
    } else {
      // Make request to Update Profile
      this.dataService.addRpService(formData).subscribe(
        (data: any) => {
          this.alertService.showSuccess('RP added successfully');
          this.afetrSubmitForm();
        },
        (err) => {
          console.log(err);
          if (err.error.message === 'Email already exist on platform') {
            this.emailError = 'Email already exist on platform';
            Swal.fire({
              title: 'Something went Wrong',
              text: err.error.message,
              icon: 'error',
            }).then((el) => {
              // console.log(el);
              setTimeout((el) => {
                this.emailError = null;
              }, 7000);
            });
          } else {
            Swal.fire({
              title: 'Something went Wrong',
              text: err.error.error || err.error.message,
              icon: 'error',
            });
          }
        }
      );
    }
  }

  afetrSubmitForm() {
    this.addNewRp.reset();
    // this.getInitialData();
    this.userImageFile = null;
    this.rpImg = null;
    this.isApprovedStatusPathValue();
    this.addNewRp.get('status').disable();
    this.addNewRp.get('geography').disable();
    this.addNewRp.get('industriesTwo').disable();
    this.addNewRp.get('domainOne').disable();
    this.addNewRp.get('domainTwo').disable();
    this.addNewRp.get('domainThree').disable();

    this.router.navigate(['/admin/all-rp']);
  }

  resetForm() {
    // console.log(this.addNewRp);

    this.addNewRp.reset();
    this.agentId = null;
    this.router.navigate(['/admin/all-rp']);
  }

  get f() {
    return this.addNewRp.controls;
  }
}
