import { Validators } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IsvService } from 'src/app/services/isv.service';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';
import { CommonService } from 'src/app/utils/common.service';
import { DropdownService } from 'src/app/services/dropdown.service';

@Component({
  selector: 'app-customer-ref',
  templateUrl: './customer-ref.component.html',
  styleUrls: ['./customer-ref.component.scss'],
})
export class CustomerRefComponent implements OnInit {
  @Input() step: StepModel;
  custRefForm: FormGroup;
  countries: any;
  industries: any;
  referenceField: any = [];
  fileName: any;
  isITAnalystReport: boolean;
  productId: any;
  payload: any;
  toEdit: any;
  productDetails: any;
  fileUpload: string | Blob;
  documentObject: any;
  documentArr = [];
  uploadedDocs: any;
  constructor(
    private fb: FormBuilder,
    private isv: IsvService,
    public route: ActivatedRoute,
    private shared: CommonService,
    private dropdown: DropdownService,
    private cdr: ChangeDetectorRef,
    private stepService: StepsService
  ) {
    // const customerRef = localStorage.getItem("customerRef")
    // if (customerRef) {
    //   this.stepService.onNextStep()
    // }
  }

  ngOnInit(): void {
    this.getProductDetails();
    this.productId = localStorage.getItem('productId');
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');
    this.getCountries();
    this.getIndustries();
    this.custRefForm = this.fb.group({
      reference: this.fb.array([this.defaultRef()]),
      sponsor1: ['', [Validators.required]],
      sponsor2: [''],
      sponsor3: [''],
      champion1: ['', [Validators.required]],
      champion2: [''],
      champion3: [''],
      itAnalysisReport: this.fb.array([this.newitAnalysisReport()]),
      isAnalystReport: [''],
    });
  }

  // Document array

  itAnalysisReport(): FormArray {
    return this.custRefForm.get('itAnalysisReport') as FormArray;
  }

  newitAnalysisReport(): FormGroup {
    return this.fb.group({
      text: '',
      filePath: '',
    });
  }

  addDoc() {
    this.itAnalysisReport().push(this.newitAnalysisReport());
  }

  // refrence array
  defaultRef() {
    return this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      industry: ['', Validators.compose([Validators.required])],
      country: ['', Validators.compose([Validators.required])],
    });
  }

  reference(): FormArray {
    return this.custRefForm.get('reference') as FormArray;
  }

  newreference(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      industry: ['', Validators.required],
      country: ['', Validators.required],
    });
  }

  addreference() {
    this.reference().push(this.newreference());
  }

  // removeRef(i: number) {
  //   this.reference().removeAt(i);
  // }

  getCountries() {
    this.dropdown.getCountry().subscribe((res) => {
      this.countries = res.details.countries;
    });
  }

  getIndustries() {
    this.dropdown.getIndustry().subscribe((res) => {
      this.industries = res.details.industry;
      // console.log(this.industries, "industries");
    });
  }

  addCustomerRef() {
    if (this.custRefForm.invalid) {
      return;
    } else {
      this.payload = {
        isAnalystReport: this.isITAnalystReport,
        reference: this.custRefForm.value.reference,
        itAnalysisReport: this.documentArr || [],
        sponsor: [this.custRefForm.value.sponsor1],
        champion: [this.custRefForm.value.champion1],
      };
      // }

      if (this.custRefForm.get('sponsor2').value != '') {
        this.payload.sponsor.push(this.custRefForm.get('sponsor2').value);
      }
      if (this.custRefForm.get('sponsor3').value != '') {
        this.payload.sponsor.push(this.custRefForm.get('sponsor3').value);
      }
      if (this.custRefForm.get('champion2').value != '') {
        this.payload.champion.push(this.custRefForm.get('champion2').value);
      }
      if (this.custRefForm.get('champion3').value != '') {
        this.payload.champion.push(this.custRefForm.get('champion3').value);
      }
      // console.log(this.payload);

      this.isv.addCustomerRefCoverage(this.payload, this.productId).subscribe(
        (res) => {
          // localStorage.setItem("customerRef", JSON.stringify(res))
          this.onCompleteStep();
          this.stepService.onNextStep();
        },
        (err) => {
          console.log(err);
          this.shared.showToastMessage('ERROR', err.error.error, 5000);
        }
      );
    }
  }

  onCompleteStep() {
    this.step.isComplete = true;
  }

  fileProgress(fileInput: any) {
    let file = fileInput.target.files[0];
    this.fileName = file.name;
    console.log(this.fileName);
  }

  get itAnalysisReports() {
    return this.custRefForm.controls.itAnalysisReport as FormArray;
  }

  removeFile(i) {
    // data.filePath = '';
    console.log('removefile');

    this.itAnalysisReports.value[i].filePath = '';
    this.itAnalysisReport().removeAt(i);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  removeReference(i) {
    this.reference().removeAt(i);
  }
  getProductDetails() {
    const productId = localStorage.getItem('productId');
    this.isv.getProductStatus(productId, 2).subscribe(
      (data) => {
        // console.log(data.details.product);
        this.productDetails = data.details.product;
        this.setProductDetails();
        this.setSponsorsAndChampions();
        this.setDocuments();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  setProductDetails() {
    if (this.productDetails.reference && this.productDetails.reference.length) {
      this.reference().clear();
      this.productDetails.reference.forEach((element) => {
        this.reference().push(
          this.fb.group({
            name: element.name,
            industry: element.industry._id,
            country: element.country._id,
          })
        );
      });
    }
  }

  setSponsorsAndChampions() {
    this.custRefForm.patchValue({
      sponsor1: this.productDetails.sponsor[0],
      sponsor2: this.productDetails.sponsor[1] || '',
      sponsor3: this.productDetails.sponsor[2] || '',
    });
    this.custRefForm.patchValue({
      champion1: this.productDetails.champion[0],
      champion2: this.productDetails.champion[1] || '',
      champion3: this.productDetails.champion[2] || '',
    });
  }

  setDocuments() {
    this.custRefForm.patchValue({
      isAnalystReport: this.productDetails.isAnalystReport,
    });
    this.isITAnalystReport = this.productDetails.isAnalystReport;
    if (
      this.productDetails.itAnalysisReport &&
      this.productDetails.itAnalysisReport.length
    ) {
      this.itAnalysisReport().clear();
      this.productDetails.itAnalysisReport.forEach((element) => {
        this.uploadedDocs = element;
        this.itAnalysisReport().push(
          this.fb.group({
            text: element.text,
            filePath: '',
          })
        );
      });
    }
  }

  async uploadDoc() {
    const fileData = new FormData();
    fileData.append('document', this.fileUpload);
    const filePathData = await this.isv.uploaddoc(fileData).toPromise();
    this.documentObject = {
      fileName: this.fileName,
      text: this.custRefForm.value.itAnalysisReport[
        this.custRefForm.value.itAnalysisReport.length - 1
      ].text,
      filePath: filePathData.details?.path,
    };

    this.documentArr.push(this.documentObject);
    this.custRefForm.value.itAnalysisReport = null;
    this.documentObject = {};
  }

  addFiles(e) {
    if (e.target.files.length > 0) {
      for (let i of e.target.files) {
        this.fileUpload = i;
      }
    }
    this.uploadDoc();
  }
}
