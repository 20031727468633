import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StepModel, StepsService } from 'src/app/services/isv.stepsService';

@Component({
  selector: 'app-isv-onboarding',
  templateUrl: './isv-onboarding.component.html',
  styleUrls: ['./isv-onboarding.component.scss'],
})
export class IsvOnboardingComponent implements OnInit {
  public currentStep: StepModel;
  public currentStepSub: Subscription;

  constructor(
    private stepsService: StepsService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentStepSub = this.stepsService
      .getCurrentStep()
      .subscribe((step: StepModel) => {
        setTimeout(() => {
          this.currentStep = step;
        });
      });
  }

  // showButtonLabel() {
  //   return !this.stepsService.isLastStep() ? "Continue" : "Finish";
  // }

  ngOnDestroy(): void {
    this.currentStepSub.unsubscribe();
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
}
