import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public showHeader = new BehaviorSubject(false);

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  // KINDLY DON'T REMOVE IT'S USED IN RP PROFILE
  showSuccess(text) {
    Swal.fire({
      text: text,
    });
  }

  showSwError(errorMessage) {
    Swal.fire('Oops...', errorMessage, 'error');
  }

  showToastMessage(type, message, duration = 5000) {
    // console.log(type, message, duration);
    switch (type) {
      case 'SUCCESS':
        this.toastr.success(message, 'Success', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
          closeButton: true,
        });
        break;
      case 'ERROR':
        this.toastr.error(message, 'Error', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
          closeButton: true,
        });
        break;
      case 'INFO':
        this.toastr.info(message, 'Info', {
          progressBar: true,
          progressAnimation: 'increasing',
          timeOut: duration,
          tapToDismiss: true,
          closeButton: true,
        });
        break;
    }
  }
}
