import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  authenticationService: any;

  constructor(private router: Router, private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let flag = false;
    //  console.log('user Auth guard Check');
    if (this.auth.currentUserValue?.token) {
      // console.log(this.auth.currentUserValue?.token);
      // console.log('User authenticated');
      flag = true;
    } else {
      // console.log('User not authenticated');
      this.router.navigate(['/login']);
    }

    return flag;
  }
}
