import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IsvService } from 'src/app/services/isv.service';
import { StepsService } from 'src/app/services/isv.stepsService';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  steps: any;
  currentStep: any;
  lastStep: any;
  public toEdit: string;

  constructor(
    private stepsService: StepsService,
    private isv: IsvService,
    public route: ActivatedRoute
  ) {
    stepsService.currentStep$.subscribe((data) => {
      this.currentStep = data.stepIndex;
    });
  }

  gsaStep1 = [
    { stepIndex: 1, stepName: 'Get Started' },
    { stepIndex: 2, stepName: 'Target Roles' },
    { stepIndex: 3, stepName: 'Target Industries' },
    { stepIndex: 4, stepName: 'Target Domain & Sub-Domain' },
    { stepIndex: 5, stepName: 'Target Geographies' },
  ];

  ngOnInit() {
    this.steps = this.stepsService.getSteps();
    this.toEdit = this.route.snapshot.queryParamMap.get('toEdit');

    this.getStepsProgress();
  }

  getStepsProgress() {
    const productId = localStorage.getItem('productId');
    if (productId) {
      this.isv.getproductStatus(productId).subscribe((data) => {
        this.lastStep = data.details.product.lastStep;
      });
    }
    // else {
    //   this.lastStep = 1.1
    // }
  }
}
