import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownService } from 'src/app/services/dropdown.service';
import { GetDataService } from 'src/app/services/get-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CommonService } from 'src/app/utils/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-charity-reward',
  templateUrl: './charity-reward.component.html',
  styleUrls: ['./charity-reward.component.scss'],
})
export class CharityRewardComponent implements OnInit {
  payload: any = {};
  totalRecords: any;
  count: any;
  page = 1;
  skip: any;
  pageSizes = [10, 25, 50];
  account: any;
  charityRewards: any;
  isv: any;
  paymentStat: any;
  charities: any;
  logoUrl = environment.apiUrl;
  creward: number;
  euro: string;
  constructor(
    private getData: GetDataService,
    private router: Router,
    private dropdown: DropdownService,
    private common: CommonService,
    private api: GetDataService,
    public sharedService: SharedServiceService
  ) {
    this.common.showHeader.next(true);
    this.api.currentCurr.subscribe((data) => {
      if (data === 'EUR') {
        this.euro = '€';
      } else {
        this.euro = '$';
      }
    });
  }

  ngOnInit(): void {
    this.getAllAccounts();
    this.getAllCharityNames();
    this.getAllIsv();
    this.getPaymentStatus();
    this.count = 10;
    this.skip = 0;
    this.payload = { count: this.count, skip: this.skip };
    this.getAllReferralRewards(this.payload);
  }

  getAllReferralRewards(payload) {
    this.api.getAllReferralRewards(payload).subscribe((data) => {
      this.charityRewards = data.details.rewards[0].data;
      this.totalRecords = data.details.rewards[0].total[0]?.count;
    });
  }

  getAllAccounts() {
    this.dropdown.getAllAccountDropdown().subscribe((data) => {
      this.account = data.details.accounts;
    });
  }

  getPaymentStatus() {
    this.dropdown.getpaymentStatus().subscribe((data) => {
      this.paymentStat = data.details.paymentStatus;
    });
  }
  getAllIsv() {
    this.dropdown.getISVDropdown().subscribe(
      (data) => {
        this.isv = data.details.isv;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getAllCharityNames() {
    this.dropdown.getAllCharity().subscribe((res) => {
      this.charities = res.details.charity;
    });
  }
  filterByAccount(val) {
    this.page = 1;
    this.payload = { account: val, count: this.count };
    this.getAllReferralRewards(this.payload);
  }
  filterByDays(val) {
    this.page = 1;
    this.payload = { days: val, count: this.count };
    this.getAllReferralRewards(this.payload);
  }
  filterByPaymentStatus(val) {
    this.page = 1;
    this.payload = { status: val, count: this.count };
    this.getAllReferralRewards(this.payload);
  }

  filterbyISV(val) {
    this.page = 1;
    this.payload = { isv: val, count: this.count };
    this.getAllReferralRewards(this.payload);
  }
  filterByCharity(val) {
    this.page = 1;
    this.payload = { charity: val, count: this.count };
    this.getAllReferralRewards(this.payload);
  }

  handlePageChange(event) {
    this.page = event;
    this.skip = (event - 1) * this.count;
    this.payload = { count: this.count, skip: this.skip };
    this.getAllReferralRewards(this.payload);
  }

  handlePageSizeChange(event): void {
    this.count = event;
    this.page = 1;
    this.payload = { count: event };
    this.getAllReferralRewards(this.payload);
  }

  search(e) {
    this.payload = { searchPattern: e.target.value, count: this.count };
    this.page = 1;
    if (
      this.payload.searchPattern != '' &&
      this.payload.searchPattern.length > 2
    ) {
      this.getAllReferralRewards(this.payload);
    } else if (this.payload.searchPattern === '') {
      this.payload.count = this.count;
      this.getAllReferralRewards(this.payload);
    }
  }
  getTotalRewards() {
    let sum = 0;
    let charityRew = 0;
    if (this.charityRewards) {
      this.charityRewards.forEach((element) => {
        sum += element.accountValue;
        if (element.charity) {
          charityRew += element.accountValue;
        }
      });
      this.creward = Math.trunc(charityRew);
      return Math.trunc(sum);
    }
  }
}
