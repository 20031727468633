<div class="head-mr"></div>
<main class="">
  <section class="table-board">
    <div class="container-fluid">
      <p class="tb-head capitalized">
        All Deals {{ totalPage ? " (" + totalPage + ")" : "" }}
      </p>
      <div class="search-box rps">
        <div class="search-in width36">
          <div class="in-search-sec">
            <input
              autofocus
              type="text"
              id="searchBar"
              class="search-bar-admin"
              placeholder="Search"
              [formControl]="filterTerm"
            />
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
        <div class="search-link">
          <!-- <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ periodFilText || "Period"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a
                      *ngIf="periodFilText !== 'Period'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('PERIOD', '', 'Period')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let qtr of quartersArr"
                      (click)="
                        checkVal(
                          'PERIOD',
                          qtr._id ? qtr._id : qtr.value,
                          qtr?.value
                        )
                      "
                      >{{ qtr?.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ isvFilText || "ISV"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': isvArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="isvFilText !== 'ISV'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('ISV', '', 'ISV')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let isv of isvArr; let i = index"
                      (click)="checkVal('ISV', isv._id, isv.companyName)"
                      >{{ isv?.companyName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ rpFilText || "Referral Partner"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': rpArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="rpFilText !== 'Referral Partner'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('RP', '', 'Referral Partner')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let rp of rpArr; let i = index"
                      (click)="
                        checkVal('RP', rp._id, rp.firstName + ' ' + rp.lastName)
                      "
                    >
                      <span> {{ rp?.firstName ? rp?.firstName : "" }} </span
                      >&nbsp;<span>
                        {{ rp?.lastName ? rp?.lastName : "" }}
                      </span>
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ accountFilText || "Account"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': accArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="accountFilText !== 'Account'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('ACCOUNT', '', 'Account')"
                      >{{ "All" }}</a
                    >

                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let acc of accArr; let i = index"
                      (click)="checkVal('ACCOUNT', acc._id, acc?.accountName)"
                      >{{ acc?.accountName }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ geoFilText || "Geography"
                    }}<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': geographyArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="geoFilText !== 'Geo'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('GEO', '', 'Geo')"
                      >{{ "Clear Filetr" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let geo of geographyArr"
                      (click)="checkVal('GEO', geo._id, geo.value)"
                      >{{ geo?.value }}</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ industryFilText || "Industry"
                    }}<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': industryArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="industryFilText !== 'Industry'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('INDUSTRY', '', 'Industry')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let ind of industryArr"
                      (click)="checkVal('INDUSTRY', ind._id, ind.value)"
                      >{{ ind?.value }}</a
                    >
                  </div>
                </div>
              </li>
              
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ "Company Size" }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': companySizeArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      *ngIf="companySizeFilText !== 'Company Size'"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('COMSIZE', '', 'Company Size')"
                      >All</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let cs of companySizeArr"
                      (click)="checkVal('COMSIZE', cs._id, cs?.value)"
                      >{{ cs?.value }}</a
                    >
                  </div>
                </div>
              </li>
            </ul>

          </div> -->

          <p-multiSelect
            [options]="quartersArr"
            [(ngModel)]="quarterValue"
            defaultLabel="Period"
            optionLabel="value"
            [maxSelectedLabels]="1"
            scrollHeight="250px"
            (onChange)="checkVal('PERIOD', $event)"
          ></p-multiSelect>

          <p-multiSelect
            [options]="isvArr"
            [(ngModel)]="isvValue"
            defaultLabel="ISV"
            optionLabel="companyName"
            [maxSelectedLabels]="1"
            [virtualScroll]="true"
            itemSize="30"
            scrollHeight="250px"
            (onChange)="checkVal('ISV', $event)"
          ></p-multiSelect>

          <p-multiSelect
            [options]="rpArr"
            [(ngModel)]="rpValue"
            defaultLabel="High Level Connector"
            optionLabel="fullName"
            [maxSelectedLabels]="1"
            [virtualScroll]="true"
            itemSize="30"
            scrollHeight="250px"
            (onChange)="checkVal('RP', $event)"
          ></p-multiSelect>

          <p-multiSelect
            [options]="accArr"
            [(ngModel)]="accValue"
            defaultLabel="Account"
            optionLabel="accountName"
            [maxSelectedLabels]="1"
            [virtualScroll]="true"
            itemSize="30"
            scrollHeight="250px"
            (onChange)="checkVal('ACCOUNT', $event)"
          ></p-multiSelect>

          <p-multiSelect
            [options]="geographyArr"
            [(ngModel)]="geographyValue"
            defaultLabel="Geo"
            optionLabel="value"
            [maxSelectedLabels]="1"
            scrollHeight="250px"
            (onChange)="checkVal('GEO', $event)"
          ></p-multiSelect>

          <p-multiSelect
            [options]="industryArr"
            [(ngModel)]="industryValue"
            defaultLabel="Industry"
            optionLabel="value"
            [maxSelectedLabels]="1"
            scrollHeight="250px"
            (onChange)="checkVal('INDUSTRY', $event)"
          ></p-multiSelect>

          <p-multiSelect
            [options]="companySizeArr"
            [(ngModel)]="companySizeValue"
            defaultLabel="Company Size"
            optionLabel="value"
            [maxSelectedLabels]="1"
            scrollHeight="250px"
            (onChange)="checkVal('COMPANYSIZE', $event)"
          ></p-multiSelect>

          <br />

          <div class="col-md-12 mb-4 text-white text-center d-inline">
            <span
              *ngFor="let item of quarterValue"
              class="badge customcolor msg"
              >PERIOD : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('PERIOD', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span *ngFor="let item of isvValue" class="badge customcolor msg"
              >ISV : {{ item?.companyName }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('ISV', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>

            <span *ngFor="let item of rpValue" class="badge customcolor msg">
              HLC : {{ item?.fullName }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('RP', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>

            <span *ngFor="let item of accValue" class="badge customcolor msg"
              >ACCOUNT : {{ item?.accountName }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('ACCOUNT', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span
              *ngFor="let item of geographyValue"
              class="badge customcolor msg"
              >COUNTRY : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('GEO', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span
              *ngFor="let item of industryValue"
              class="badge customcolor msg"
              >INDUSTRY : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('INDUSTRY', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
            <span
              *ngFor="let item of companySizeValue"
              class="badge customcolor msg"
              >COMPANYSIZE : {{ item?.value }} &nbsp;&nbsp;&nbsp;
              <i
                (click)="clearItem('COMPANYSIZE', item)"
                class="fa fa-times link"
                aria-hidden="true"
              ></i
            ></span>
          </div>

          <!-- <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ domainFilText || "Domain"
                    }}<i class="fa fa-angle-down ml-1"></i
                  ></a>
                  <div
                    class="dropdown-menu"
                    [ngClass]="{ 'overflow-class': domainArr.length > 11 }"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="checkVal('DOMAIN', '', 'Domain')"
                      >{{ "All" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      *ngFor="let domain of domainArr"
                      (click)="checkVal('DOMAIN', domain._id, domain?.value)"
                      >{{ domain?.value }}</a
                    >
                  </div>
                </div>
              </li> -->
        </div>
      </div>
      <div class="table-order upper">
        <table>
          <tbody>
            <tr>
              <th>ISV</th>
              <th>High Level Connector</th>
              <th>Account</th>
              <th>opportunity</th>
              <th>Closed Date</th>
              <th>Deal Value</th>
              <th>Link to Proposal</th>
            </tr>
            <tr
              *ngFor="
                let deal of resArr
                  | paginate
                    : {
                        itemsPerPage: filters.limit,
                        currentPage: p,
                        totalItems: totalPage
                      }
              "
            >
              <td>{{ deal?.vendorName }}</td>
              <td>
                <span>
                  {{ deal?.rpfirstName ? deal?.rpfirstName : "" }}
                </span>
                <span>
                  {{ deal?.rplastName ? deal?.rplastName : "" }}
                </span>
              </td>
              <td>{{ deal?.accountName }}</td>
              <td>{{ deal?.opportunityName }}</td>
              <td>
                {{
                  deal.dealClosedDate
                    ? sharedService.formatDays(deal?.dealClosedDate)
                    : ""
                }}
              </td>
              <td>{{ deal?.finalDealAmount }}</td>
              <td><img src="../assets/images/external_Link.svg" alt="" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-align pagi-page">
        <!-- <div class="pagination">
          <a href="javascript:void(0)">Prev</a>
          <a href="javascript:void(0)" class="active">1</a>
          <a href="javascript:void(0)">2</a>
          <a href="javascript:void(0)">3</a>
          <a href="javascript:void(0)">4</a>
          <a href="javascript:void(0)">5</a>
          <a href="./account-reward.html"
            >Next <i class="fa ml-1 fa-angle-right"></i
          ></a>
        </div> -->

        <div class="paginationClass">
          <pagination-controls
            (pageChange)="pageChange($event)"
            previousLabel="Prev"
            nextLabel="Next"
            class="float-right"
          ></pagination-controls>
        </div>
        <div class="user">
          <div class="btn-group">
            <button
              type="button"
              class="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
            >
              {{ countPerPageText || "10 Per Page"
              }}<i class="fa fa-angle-down ml-1"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-69px, 42px, 0px);
              "
            >
              <button
                class="dropdown-item"
                name="countPerPage"
                value="6"
                type="button"
                (click)="checkVal('10PERPAGE', '10', '10 Per Page')"
              >
                10 Per Page
              </button>
              <button
                class="dropdown-item"
                name="countPerPage"
                value="25"
                type="button"
                (click)="checkVal('25PERPAGE', '25', '25 Per Page')"
              >
                25 Per Page
              </button>
              <button
                class="dropdown-item"
                name="countPerPage"
                value="50"
                type="button"
                (click)="checkVal('50PERPAGE', '50', '50 Per Page')"
              >
                50 Per Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
