<div class="panel panel-primary setup-content" id="step-4">
  <section class="mail-verify lets padding-88">
    <div class="max-width680">
      <div class="condition-para">
        <div class="sign-head text-center">
          <h5>
            Have you measured the ROI and Payback period for <br />
            {{ productName }} ?
            <span class="ml-0">
              <button
                type="button"
                class="info"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="The best entry point person is NOT the eventual buyer or decision maker;  The BEST ENTRY POINT person could well become your eventual main Champion"
              >
                <img src="../assets/images/info.svg" />
              </button>
            </span>
          </h5>
        </div>

        <div class="rqi-btn">
          <div class="theme-btn regu width-fit blue text-center">
            <button
              (click)="roidetails = true; isRoiMeasured('true')"
              [class.selectedbutton]="roidetails == true"
              type="button"
              class="btn mr-1"
            >
              Yes
            </button>
            <button
              (click)="roidetails = false; isRoiMeasured('false')"
              [class.selectedbutton]="roidetails == false"
              type="button"
              class="btn ml-1"
            >
              No
            </button>
          </div>
        </div>

        <div *ngIf="roidetails" class="form-field sign-form">
          <form [formGroup]="roiForm">
            <div class="form-sec domain normal mb-15">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">
                      What is the ROI provided by {{ productName }}?</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="roiProvided"
                      placeholder="E.g. 30% in Test Design and Implementation phases, 50% on Maintenance"
                    />
                    <p
                      *ngIf="f.roiProvided.touched && f.roiProvided.errors"
                      class="text-danger"
                    >
                      *Roi Provided is required
                    </p>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="exampleFormControlSelect1"
                      >What is the average Payback period of
                      {{ productName }}?</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="paybackPeriod"
                      placeholder="E.g. 3 months"
                    />
                    <p
                      *ngIf="f.paybackPeriod.touched && f.paybackPeriod.errors"
                      class="text-danger"
                    >
                      *Payback Period is required
                    </p>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="exampleFormControlSelect1"
                      >What are the proof points that give you confidence in
                      such a Payback period?</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="proofPoints"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="theme-btn dom width-fit text-right">
          <button
            class="btn active nextBtn"
            (click)="addRoiDetails()"
            type="button"
            [class.active]="roiForm.valid"
            [disabled]="roiForm.invalid"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
