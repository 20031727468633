<header>
  <div class="container-fluid">
    <div class="col-sm-12 p-0 header-menu">
      <div class="row">
        <div class="col-sm-1 head-logo">
          <div class="top-menu-bar d-flex">
            <div class="logo">
              <img src="../assets/images/wholesoft_white.svg" alt="" />
            </div>
            <div class="toggle-top">
              <div id="toggle" (click)="toggleDisplayDivIf()">
                <div class="one"></div>
                <div class="two"></div>
                <div class="three"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-8 menu-links" *ngIf="!isShowDivIf">
          <div class="links">
            <ul>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    WSM Network<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-start"
                    style="
                      position: absolute;
                      will-change: transform;
                      top: 0px;
                      left: 0px;
                      transform: translate3d(0px, 24px, 0px);
                    "
                  >
                    <a class="dropdown-item" routerLink="/admin/wsm-network"
                      >View All Connections</a
                    >
                    <a
                      class="dropdown-item"
                      data-toggle="modal"
                      data-target="#exampleModal1"
                      href="javascript:void(0)"
                      [routerLink]="['/admin/wsm-network']"
                      (click)="saveStatus(1)"
                      >Add New Connection</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Software Vendors<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-start"
                    style="
                      position: absolute;
                      will-change: transform;
                      top: 0px;
                      left: 0px;
                      transform: translate3d(0px, 24px, 0px);
                    "
                  >
                    <a class="dropdown-item" routerLink="/admin/all-isv"
                      >View All ISVs</a
                    >
                    <a class="dropdown-item" routerLink="/admin/add-isv"
                      >Add New ISV</a
                    >
                    <a class="dropdown-item" href="javascript:void(0)"></a>
                    <a class="dropdown-item" routerLink="/admin/all-product"
                      >View All Products</a
                    >
                    <a class="dropdown-item" routerLink="/admin/add-product"
                      >Add New Product</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                  High Level Connectors<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-start"
                    style="
                      position: absolute;
                      will-change: transform;
                      top: 0px;
                      left: 0px;
                      transform: translate3d(0px, 24px, 0px);
                    "
                  >
                    <a class="dropdown-item" routerLink="/admin/all-rp"
                      >View All HLCs</a
                    >
                    <a class="dropdown-item" routerLink="/admin/add-rp"
                      >Add New HLC</a
                    >
                    <a class="dropdown-item" href="javascript:void(0)"></a>
                    <a class="dropdown-item" routerLink="/admin/rp-commissions"
                      >View HLC Commissions</a
                    >
                    <a class="dropdown-item" routerLink="/admin/sponsored-rps"
                      >View Sponsored HLCs</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      (click)="openDialog()"
                      >Sponsor New HLC</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/admin/referral-rewards"
                      >View Sponsorship Rewards</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Accounts<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-start"
                    style="
                      position: absolute;
                      will-change: transform;
                      top: 0px;
                      left: 0px;
                      transform: translate3d(0px, 24px, 0px);
                    "
                  >
                    <a class="dropdown-item" routerLink="/admin/all-account"
                      >View All Accounts</a
                    >
                    <a class="dropdown-item" routerLink="/admin/add-account"
                      >Add New Account</a
                    >
                    <a class="dropdown-item" routerLink="/admin/charity-reward"
                      >View Rewards & Charity Payments</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Intro Requests<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-start"
                    style="
                      position: absolute;
                      will-change: transform;
                      top: 0px;
                      left: 0px;
                      transform: translate3d(0px, 24px, 0px);
                    "
                  >
                    <a class="dropdown-item" routerLink="/admin/intro-requests"
                      >View All Intro Requests</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Intro Proposals<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-start"
                    style="
                      position: absolute;
                      will-change: transform;
                      top: 0px;
                      left: 0px;
                      transform: translate3d(0px, 24px, 0px);
                    "
                  >
                    <a
                      class="dropdown-item"
                      routerLink="/admin/introporposal-isv"
                      >View All ISV Intro Proposals</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/admin/introporposal-rp"
                      >View All HLC Intro Proposals</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown">
                  <a
                    class="btn dropdown-toggle"
                    href="javascript:void(0)"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Opportunities<i class="fa fa-angle-down ml-1"></i>
                  </a>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                    x-placement="bottom-start"
                    style="
                      position: absolute;
                      will-change: transform;
                      top: 0px;
                      left: 0px;
                      transform: translate3d(0px, 24px, 0px);
                    "
                  >
                    <a
                      class="dropdown-item"
                      routerLink="/admin/all-opportunities"
                      >View All Opportunities</a
                    >
                    <a class="dropdown-item" routerLink="/admin/all-deals"
                      >View All Deals</a
                    >
                    <a
                      class="dropdown-item"
                      routerLink="/admin/view-commissions"
                      >View Commissions</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3 last-user">
          <div class="user-top">
            <div class="form-group mb-0 flag-header">
              <form class="select-form">
                <div class="auto-width">
                  <mat-form-field
                    class="dropdown"
                    style="width: 104px; height: auto"
                  >
                    <mat-select
                      class="btn btn-secondary dropdown-toggle"
                      [(value)]="selected"
                      [ngClass]="selected"
                      (selectionChange)="conversionCurrency(selected)"
                    >
                      <mat-option value="USD">
                        <img src="/assets/images/flag.svg" />
                        <span>&nbsp;&nbsp; USD</span>
                      </mat-option>
                      <mat-option value="EUR">
                        <img
                          src="/assets/images/european-union.svg"
                          width="30"
                        />
                        <span>&nbsp;&nbsp; EUR</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="flagstrap-icon"></span>USD
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </button>
                  <div
                    class="dropdown-menu scroll-css"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="javascript:void(0)"
                      ><span class="flag one"></span>EUR</a
                    >
                  </div>
                </div> -->
              </form>
            </div>
            <!-- <div class="icons">
              <i class="fa" aria-hidden="true"
                ><img src="../assets/images/notification.svg"
              /></i>
              <i class="fa"><img src="../assets/images/chat.svg" /></i>
              <i class="fa"><img src="../assets/images/help.svg" /></i>
            </div> -->
            <div class="top-user">
              <img src="../assets/images/user.png" />
            </div>
            <div class="user">
              <div class="btn-group">
                <button
                  type="button"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  {{ userDetails.firstName + " " + userDetails.lastName
                  }}<i class="fa fa-angle-down ml-1"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" href="javascript:void(0)">Profile</a>
                  <a class="dropdown-item" href="javascript:void(0)"
                    >Change Password</a
                  >
                  <button
                    class="dropdown-item"
                    type="button"
                    (click)="logoutUser()"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="head-mr"></div>
<!-- <button
  class="dropdown-item"
  type="button"
  (click)="auth.logout()"
>
  Logout
</button> -->
